import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { SortableContainer, SortableElement, arrayMove, sortableHandle } from 'react-sortable-hoc';
// import arrayMove from 'array-move';
import AuthService from "../../components/services_member/auth.service";
import PostService from "../../components/services_member/post.service";

// import axios from 'axios';
// import { Link } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
// import Rang from '../components/lang';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Form, Tab, Nav, Row, Col } from "react-bootstrap";

import plus from '../../assets/img/icon-plus-white.svg';
import iconpen from '../../assets/img/icon-pen.svg';
import iconbin from '../../assets/img/icon-bin.svg';
import iconlag from '../../assets/img/icon-lag.svg';

import Modalcategorydel from "../../layout/modal/modalCategorydel";
import Modalcategoryadd from "../../layout/modal/modalCategoryadd";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

// const datadata = [{ name: "อาหารคาว" }, { name: "อาหารหวาน" }]

//Drag handler
const DragHandle = sortableHandle(() => (
    <span className="position-absolute cursor-pointer" style={{ left: "10px", top: "12px" }}>
        <img src={iconlag} alt="" className="" style={{ width: "15px" }} />
    </span>
));

const Modalcategory = (data) => {

    // console.log(data.datacat.datacat)

    let history = useNavigate();
    // console.log(data.datacat.datacat)
    let datatatb = data.datacat.datacat;

    const [validated, setValidated] = useState(false);

    const [modalcategorydel, setModalcategorydel] = useState({ view: false, id: 0 });
    const [modalcategoryadd, setModalcategoryadd] = useState({ view: false, id: 0 });

    const [source, setSource] = useState();

    // const [state, setState] = useState({ items: [{ name: 'test', focus: false }, { name: 'Item 2', focus: false }, { name: 'Item 3', focus: false }] });
    const [state, setState] = useState({ items: [...datatatb] });

    const fetchData = useCallback(async () => {
        PostService.getCategory(data.branchid).then(
            (response) => {
                if (response.data.status === 200) {

                    // setDatacat(response.data);
                    setState({ items: response.data.data });
                    // console.log(response.data)               
                }
            },
            (error) => {
                console.log(error);
            }
        )
    });

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setState(({ items }) => ({
            items: arrayMove(items, oldIndex, newIndex),
        }));
    };

    const clickinput = (value, evt) => {
        setSource(value);
    }

    const inchanginput = (value, evt) => {

        const val = evt.target.value;

        let items = [...state.items];
        let item = { ...items[value] };
        item.category = val;
        items[value] = item;
        setState({ items: items })
    }
    // console.log(state.items)
    // autoFocus={ source.active.active == value ? true : false }
    const SortableItem = SortableElement(({ value }) =>
        <li tabIndex={value} key={value}>
            <Row className="mt-2" >
                <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <div className="position-relative" >
                        <DragHandle />
                        <Form.Control type="text" name="category[]" readOnly={source == value ? false : true} autoFocus={source == value ? true : false} className='font-14 input-catrgory' onChange={evt => inchanginput(value, evt)} defaultValue={state.items[value].category} required style={{ paddingLeft: "35px", paddingRight: "70px" }} />
                        <Form.Control type="hidden" name="id_cat[]" className='font-14 input-catrgory' defaultValue={state.items[value].id} />
                        <span onClick={() => setModalcategoryadd({ view: true, id: value, idcat: state.items[value].id, text: state.items[value], type: 'edit' })} className="icon-input cursor-pointer" style={{ right: "35px", top: "12px", borderRight: "1px solid #2e384d", paddingRight: "12px" }}>
                            <img src={iconpen} alt="" className="" style={{ width: "15px" }} />
                        </span>
                        <span className="icon-input cursor-pointer" onClick={() => setModalcategorydel({ view: true, id: value, idcat: state.items[value].id, text: state.items[value].category })} style={{ right: "10px", top: "12px" }}>
                            <img src={iconbin} alt="" className="" style={{ width: "15px" }} />
                        </span>
                    </div>
                </Form.Group>
            </Row>
        </li>
    );

    const SortableList = SortableContainer(({ items }) => {

        return (
            <ul className='list-category'>
                {items.map((value, index) => (
                    <SortableItem key={index} index={index} value={index} />
                ))}
            </ul>
        );
    });

    const onclickadd = (type) => {
        // setState({ items: [...state.items, { id: "",category: "", focus: false }] })
        setModalcategoryadd({ view: true, type: type })
    }

    const deleteFile = (event, idcat) => {
        AuthService.delCategory(idcat, 'category').then(
            (response) => {
                if (response.status === 200) {
                    // console.log(response.data.status)
                    // window.location.reload();

                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: '',
                        text: "บันทึกข้อมูลเรียบร้อยแล้ว",
                        showConfirmButton: false,
                        timer: 1500
                    }).then(function () {
                        data.onHide('del')
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        )
        const s = state.items.filter((item, index) => index !== event);
        setState({ items: s });
        setModalcategorydel({ view: false })
    }

    const addFile = (event, idcat) => {

        fetchData();
        // const [state, setState] = useState({ items: [...datatatb] });

        // const s = state.items.filter((item, index) => index !== event);

        setModalcategoryadd({ view: false })
    }


    const handleSubmit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const dataf = new FormData(event.target);

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        dataf.append("branch_id", branch.id);
        dataf.append("type", "cat");

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

        } else {
            // data.onHide;
            // history("/setting/employee");
            AuthService.updateCategory(dataf).then(
                (res) => {
                    //   console.log(res)   
                    if (res.status === 200) {
                        // history("/setting/setting"); 
                        // window.location.reload();

                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: '',
                            text: "บันทึกข้อมูลเรียบร้อยแล้ว",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(function () {
                            data.onHide()
                        })
                    } else {
                        data.onHide()
                        // setErrors({email:"This email cannot be used.!"})
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
            // window.location.reload(true);
            // alert(data.get('service_charge'));
            // alert(555);
        }
        setValidated(true);
    };

    useEffect(() => {
        setState({ items: [...datatatb] })
    }, [datatatb]);

    console.log(state)

    return (
        <>
            <Modal {...data} className="modal-custom" size="md" backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton className="btn-close-white ">

                </Modal.Header>
                <Modal.Body className="p-0">
                    <div className="d-block w-100 scroll-custom">
                        <Modal.Title className='py-3 bg-white px-4 shadow-sm position-fixed w-100' style={{ zIndex: "1" }}>
                            <div className='text-default font-22'>
                                เพิ่ม / แก้ไข หมวดหมู่
                            </div>
                        </Modal.Title>
                        <Form noValidate validated={validated} onSubmit={handleSubmit} className="">
                            <div className='h-100per-modal bg-white px-4 pt-5'>
                                <div className='mt-5'>
                                    <SortableList items={state.items} onSortEnd={onSortEnd} useDragHandle />
                                    <span className="w-100 btn btn-bg py-2 mt-0 text-white" onClick={() => onclickadd('add')}>
                                        <img src={plus} alt="" className="" style={{ width: "15px" }} /> เพิ่มหมวดหมู่
                                    </span>
                                </div>
                            </div>
                            <div className='text-center py-3 px-3 bg-gray'>
                                <div className='row'>
                                    <div className="col-12 col-md-6 col-lg-6 pe-1">
                                        <span className="w-100 btn btn-calcel text-white py-2 me-3" onClick={data.onHide}>
                                            ยกเลิก
                                        </span>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 ps-1">
                                        <button type='submit' className="w-100 btn btn-submit text-white py-2 me-3">
                                            บันทึก
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
            <Modalcategorydel show={modalcategorydel.view} onHide={() => setModalcategorydel({ view: false })} id={modalcategorydel.id} action={() => deleteFile(modalcategorydel.id, modalcategorydel.idcat)} text={"ยืนยันการลบหมวดหมู่ " + modalcategorydel.text} />
            {modalcategoryadd.view ?
                <Modalcategoryadd show={modalcategoryadd.view} onHide={() => setModalcategoryadd({ view: false })} id={modalcategoryadd.id} actionAdd={() => addFile(modalcategoryadd.id, modalcategoryadd.idcat)} text={modalcategoryadd.text} type={modalcategoryadd.type} />
                : null}
        </>
    );
};

export default Modalcategory;