import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import 'react-bootstrap-typeahead/css/Typeahead.css';
// import range from 'lodash.range';
// var Typeahead = require('react-bootstrap-typeahead').Typeahead;

// import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';

// import '../../assets/css/radioandcheckbox.css';
// import { Form, Tab, Nav, Row, Col } from "react-bootstrap";

// import AuthService from "../../components/services_member/auth.service";
// import Swal from 'sweetalert2'
// import withReactContent from 'sweetalert2-react-content'

const AutoCompleteMember = ({ fnsetMember, ...data }) => {
    // define the array of data
    // const sportsData = ['Badminton', 'Basketball', 'Cricket', 'Football', 'Golf', 'Gymnastics', 'Hockey', 'Rugby', 'Snooker', 'Tennis'];
    // const options = range(0, 1000).map(o => `Option ${o.toString()}`);
    // console.log(data.fnsetMember)
    // interface Option {
    //     firstName: string;
    //     lastName: string;
    // }

    // const options = data.datamember;
    const [options, setOptions] = useState([]);

    useEffect(() => {
        setOptions(data.datamember)
    })

    const [selected, setSelected] = useState([]);

    // const setSelected = (e) => {
    // alert(e)
    // console.log(data)
    // }
    if (selected.length > 0) {
        fnsetMember(selected[0]);
    } else {
        fnsetMember(false);
    }

    //   public render() {
    return (<>
    {/* //     <AutoCompleteComponent id="atcelement" dataSource={sportsData} /> */}
        {options ?
            <Typeahead
                id="pagination-example"
                onChange={setSelected}
                clearButton
                labelKey={(option) => `${option.tel} : ${option.name}`}
                options={options}
                placeholder='เบอร์โทรสมาชิก'
            /> : null}
    </>
    );
    //   }
}
export default AutoCompleteMember;