import React, { useState, useEffect, useCallback } from 'react';
// import axios from 'axios';
import { Link } from "react-router-dom";
import { compareAsc, format } from 'date-fns';
// import DatePicker from "react-datepicker";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import help from '../../assets/img/help.svg';
import calendar from '../../assets/img/calendar.svg';
import ipdf from '../../assets/img/i-pdf.svg';
import iexcel from '../../assets/img/i-excel.svg';

import Menuleft from "../../layout/pagesadmin/menuleft";
import Menureport from "../../components/report/report_menu";

import { Form, Tab, Nav } from "react-bootstrap";
// import { Form, Col, Row, InputGroup, Button } from "react-bootstrap";
// import Modalreportsummary from "../../layout/modal/modalReportsummary";

import PostService from "../../components/services_member/post.service";

import {
    Chart as ChartJS,
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Doughnut, Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const Reportsummary = () => {
    const { t } = useTranslation();
    const [todayDate, setTodayDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());

    const dateoption = {
        locale: {
            format: 'DD/MM/YYYY'
        },
        startDate: todayDate,
        singleDatePicker: true,
        endDate: startDate,
        autoApply: true,
    };

    const [datestart, setDatestart] = useState(format(new Date(), 'dd/MM/yyyy'));
    // const [dateend, setDateend] = useState(format(new Date(), 'dd/MM/yyyy'));

    const [modalreportsummary, setModalreportsummary] = useState({ view: false, id: 0 });

    const [dataDonut, setDataDonut] = useState({ cash: 0, credit: 0, qrcode: 0 });

    const [validated, setValidated] = useState(false);

    const [loadingpage, setLoadingpage] = useState(true);

    const fetchData = useCallback(async (dd) => {

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        PostService.getSummaryall(branch.id, dd).then(
            (response) => {

                setLoadingpage(false)

                if (response.status === 200) {
                    // setDataemployeetable(response.data);
                    setModalreportsummary({ view: true, datasummary: response.reserve });

                    setDataDonut({
                        cash: sumall((response.reserve).filter(val => val.data_type == 1), 10),
                        credit: sumall((response.reserve).filter(val => val.data_type == 3), 10),
                        qrcode: sumall((response.reserve).filter(val => val.data_type == 2), 10)
                    })

                } else {
                    setModalreportsummary([]);
                }
            },
            (error) => {
                console.log(error);
            }
        )

    });

    useEffect(() => {
        fetchData(datestart)
    }, []);

    const handleSubmit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const dataf = new FormData(event.target);

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        dataf.append("branch_id", branch.id);
        dataf.append("date_start", datestart);
        // dataf.append("date_end", dateend);

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            PostService.getSummaryall(dataf).then(
                (response) => {

                    if (response.status === 200) {
                        // setDataemployeetable(response.data);
                        setModalreportsummary({ view: true, datasummary: response.reserve });
                    } else {
                        setModalreportsummary([]);
                    }
                },
                (error) => {
                    console.log(error);
                }
            )
        }
        setValidated(true);
    };

    const handledateday = (event, picker) => {

        setLoadingpage(true)

        setDatestart(event.target.value);
        fetchData(event.target.value)
        // setDatestart(format(new Date(picker.startDate._d), 'dd/MM/yyyy'));        
        // setDateend(format(new Date(picker.endDate._d), 'dd/MM/yyyy'));
    }

    const sumall = (content, type) => {

        var sumprice = 0;
        var summore = 0;
        var sumservice = 0;
        var sumvat = 0;
        var sumpromo = 0;
        var sumcut = 0;

        // console.log(content)
        if (content) {
            if (content.length) {
                sumprice = content.reduce((prev, current) => prev + +current.data_price, 0)
                summore = content.reduce((prev, current) => prev + +current.data_pricemore, 0)
                sumservice = content.reduce((prev, current) => prev + +current.data_service, 0)
                sumvat = content.reduce((prev, current) => prev + +current.data_vat, 0)
                sumpromo = content.reduce((prev, current) => prev + +current.data_promotion, 0)
                sumcut = content.reduce((prev, current) => prev + +current.totalCut, 0)
            }

            if (type === 1) {
                return (((sumprice + summore + sumservice + sumvat) - sumpromo) - sumcut).toLocaleString();
            } else if (type === 2) {
                return (sumprice + summore + sumservice + sumvat).toLocaleString();
            } else if (type === 3) {
                return (sumpromo).toLocaleString();
            } else if (type === 4) {
                return (sumservice).toLocaleString();
            } else if (type === 5) {
                return (sumvat).toLocaleString();
            } else if (type === 6) {
                return (sumprice + summore).toLocaleString();
            } else if (type === 7) {
                return (sumprice + summore - (sumpromo + sumcut)).toLocaleString();
            } else if (type === 8) {
                return (sumcut).toLocaleString();
            } else if (type === 9) { //sum cash
                return (sumcut).toLocaleString();
            } else if (type === 10) { //sum all no string
                return (((sumprice + summore + sumservice + sumvat) - sumpromo) - sumcut);
            } else if (type === 0) {
                return (sumprice + summore).toLocaleString();
            }
        }
    }

    const datatest = {
        labels: ['Cash ' + dataDonut.cash.toLocaleString() + '฿', 'Credit Card ' + dataDonut.credit.toLocaleString() + '฿', 'QR Code ' + dataDonut.qrcode.toLocaleString() + '฿'],
        datasets: [
            {
                data: [
                    ((dataDonut.cash / sumall(modalreportsummary.datasummary, 10)) * 100).toLocaleString(),
                    ((dataDonut.credit / sumall(modalreportsummary.datasummary, 10)) * 100).toLocaleString(),
                    ((dataDonut.qrcode / sumall(modalreportsummary.datasummary, 10)) * 100).toLocaleString()],
                backgroundColor: [
                    'rgba(0, 131, 25, 1)',
                    'rgba(236, 184, 48, 1)',
                    'rgba(170, 17, 17, 1)',
                ],
            },
        ],
    };

    const options = {
        cutout: 70,
        plugins: {
            legend: {
                // labels: {
                //     render: 'percentage',
                //     precision: 2,
                //     // padding: 30,
                //     // boxWidth: "40",
                //     boxHeight: "15",
                //     // color: 'rgb(255, 99, 132)
                // },
                labels: {
                    pointStyle: "<div>9999</div>",
                    usePointStyle: true
                },
                display: true,
                position: "bottom",
                align: "start",
            },
        },
        height: 100,
        radius: 80,
        rotation: 215,
    }

    return (
        <>
        <div className={!t('menu-a') ? "sidebar-mini sidebar-collapse" : ""} >
            <div className='wrapper-admin layout-fixed'>
                <nav className="main-header navbar bg-white shadow-sm" style={{ height: "60px" }}>
                    <Nav as="ul" variant="tabs" className="nav nav-tabs overflow-hidden ul-tabcustom">

                    </Nav>
                    <ul className="navbar-nav ml-auto" style={{ marginTop: "-20px" }}>
                        <li className="">
                            <div className='text-gray font-12'><img src={help} alt="" className="me-1" style={{ width: "14px" }} /> ขอความช่วยเหลือ</div>
                        </li>
                    </ul>
                </nav>

                <Menuleft type="report" />

                <div className="content-wrapper">
                    <section className="content scroll-custom">
                        <div className="row">
                            <div className="col-12 col-md-4 col-lg-4">
                                <div className='text-start box-setting bg-white rounded-3 mt-3 h-100per'>
                                    <Menureport type="summary" sub="day" />
                                </div>
                            </div>
                            <div className="col-12 col-md-8 col-lg-8 ps-0">
                                <Form noValidate validated={validated} onSubmit={handleSubmit} className="">
                                    <div className="bg-white rounded-3 ms-2 mt-3 h-100per position-relative">
                                        <div className='px-4'>
                                            <div className='text-start py-3 weight-500'>สรุปยอดขายของวันที่</div>
                                            <div className='position-relative'>
                                                <div className="row">
                                                    <div className="col-3">
                                                        <Form.Group controlId="dob" className="position-relative">
                                                            {/* <Form.Control type="date" name="dob" format="YYYY/MM/DD" required /> */}
                                                            {/* <DateRangePicker initialSettings={dateoption} onEvent={handledateday}> */}
                                                            <DateRangePicker initialSettings={dateoption} onApply={(e) => handledateday(e)}>
                                                                <input type="text" className="form-control" />
                                                            </DateRangePicker >
                                                            <div className='position-absolute' style={{ top: "12px", right: "12px" }}>
                                                                <img src={calendar} alt="" className="me-1" style={{ width: "18px" }} />
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-9">

                                                    </div>
                                                </div>
                                            </div>
                                            {loadingpage ?
                                                <div className='pt-5 mt-5'>
                                                    <i className="fa fa-spinner fa-spin" />
                                                    <div>Loading</div>
                                                </div>
                                                :

                                                <div className='row mt-3 pt-1 '>
                                                    <div className="col-12 col-md-6 col-lg-5 bg-white p-3 rounded-1">
                                                        <div className='text-start'>
                                                            <div>ยอดขายสุทธิ</div>
                                                            <div className='font-24 mt-2 mb-3'>
                                                                <b>฿{sumall(modalreportsummary.datasummary, 1)}</b><br />

                                                                {/* {modalreportsummary.datasummary ? <>
                                                               
                                                                <div className='font-14'>
                                                                    cash ฿{dataDonut.cash.toLocaleString()} = {((dataDonut.cash/sumall(modalreportsummary.datasummary, 10))*100).toLocaleString() }
                                                                </div>
                                                                <div className='font-14'>
                                                                    credit ฿{dataDonut.credit.toLocaleString()}  = {(((dataDonut.credit)/sumall(modalreportsummary.datasummary, 10))*100).toLocaleString()} 
                                                                </div>
                                                                <div className='font-14'>
                                                                    qr code ฿{dataDonut.qrcode.toLocaleString()}  = {((dataDonut.qrcode/sumall(modalreportsummary.datasummary, 10))*100).toLocaleString()}
                                                                </div>
                                                            </>
                                                                : null
                                                            } */}
                                                            </div>
                                                            <div>
                                                                <div className='row'>
                                                                    <div className="col-8 col-md-8 col-lg-8">
                                                                        <div className='font-14' style={{ lineHeight: "1.5" }}>
                                                                            ยอดรวม <br />
                                                                            ลดราคาโปรโมชั่น <br />
                                                                            ลดราคาอื่นๆ <br />
                                                                            ยอดขายหลังลดราคา <br />
                                                                            ค่าบริการ <br />
                                                                            ภาษีมูลค่าเพิ่ม (VAT) <br />
                                                                            {/* ลดราคาท้ายบิล <br />
                                                                        ยอดรวมปัดเศษ <br /> */}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4 col-md-4 col-lg-4 text-end">
                                                                        <div className='font-14' style={{ lineHeight: "1.5" }}>
                                                                            {sumall(modalreportsummary.datasummary, 0)} <br />
                                                                            - {sumall(modalreportsummary.datasummary, 3)} <br />
                                                                            - {sumall(modalreportsummary.datasummary, 8)} <br />
                                                                            {sumall(modalreportsummary.datasummary, 7)} <br />
                                                                            {sumall(modalreportsummary.datasummary, 4)} <br />
                                                                            {sumall(modalreportsummary.datasummary, 5)} <br />
                                                                            {/* 00.00 <br />
                                                                        00.00 <br /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-7 pe-0 rounded-1">
                                                        <div className='bg-white p-3 rounded-1'>
                                                            <div className='text-center font-14'>แบ่งยอดขายตามประเภทการชำระเงิน (%)</div>
                                                            <div className='px-5'>
                                                                <Doughnut data={datatest} options={options} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            {/* <div className='row mt-2 pt-1 pb-2'>
                                            <div className="col-12 col-md-12 col-lg-12 bg-white p-3 rounded-1">
                                                <div className='my-3 rounded-1 bg-white'>
                                                    <div className='text-gray font-12 ps-5'>จำนวนเงิน (บาท)</div>
                                                    <Line options={optionsline} data={dataline} />
                                                </div>
                                            </div>
                                        </div> */}
                                        </div>
                                    </div>
                                    {/* <div className='w-100 ps-2 mt-2'>
                                        <button type="submit" className="w-100 btn btn-bg text-white py-2">
                                            ดูรายงานยอดขาย
                                        </button>
                                    </div> */}
                                </Form>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            </div>
            {/* {modalreportsummary.view ?
                <Modalreportsummary show={modalreportsummary.view} onHide={() => setModalreportsummary({ view: false })} datasum={modalreportsummary.datasummary} datestart={datestart} dateend={dateend} />
                : null} */}
        </>
    );
};

export default Reportsummary;