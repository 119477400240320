import React, { useState, useRef } from 'react';
import { Table, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { compareAsc, format } from 'date-fns';

// import { BrowserRouter as Router } from "react-router-dom";
// import axios from 'axios';
// import { Link } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
// import Rang from '../components/lang';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";


import ipdf from '../../assets/img/i-pdf.svg';
import iexcel from '../../assets/img/i-excel.svg';


const Modalemployeereport = (datas) => {

    let history = useNavigate();

    const toHHMMSS = (time) => {
        var sec_num = parseInt(time, 10); // don't forget the second param
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);

        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        if (seconds < 10) { seconds = "0" + seconds; }
        // return hours+':'+minutes+':'+seconds;
        return hours + ':' + minutes;
    }

    const sumOt = (content) => { 
        return content.reduce((prev, current) => prev + +current.ot_sum,0)
    };
 
    // console.log((datas.dataemployee))

    return (
        <>
            <Modal {...datas} className="modal-custom" size="lg" backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton className="btn-close-white ">

                </Modal.Header>
                <Modal.Body className="p-0">
                    <div className="d-block w-100 scroll-custom">

                        <Modal.Title className='py-3 bg-white px-4 shadow-sm position-fixed w-100' style={{ zIndex: "1" }}>
                            <div className='text-default font-22'>
                                รายงานการทำงาน
                            </div>
                            <div className='font-14 text-gray weight-300'>
                                {(datas.dataemployee).length === 1 ? datas.dataemployee[0].name + " |" : null}  {datas.datestart} - {datas.dateend}
                            </div>

                            <div className='position-absolute font-16' style={{ right: "15px", top: "25px" }}>
                                นำออกเป็น
                                <span className='ms-2 me-1'>
                                    <img src={ipdf} />
                                </span>
                                <span>
                                    <img src={iexcel} />
                                </span>
                            </div>
                        </Modal.Title>
                        <div className='h-100per-modal bg-white px-4 py-5'>
                            <div className='mt-5 pt-3'>
                                {(datas.dataemployee).length === 1 ?
                                    <Table bordered className='table-report'>
                                        <thead>
                                            <tr>
                                                <th>วันที่</th>
                                                <th>เข้างาน</th>
                                                <th>เลิกงาน</th>
                                                <th>OT</th>
                                                <th>เหตุผล</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {datas.dataemployee.filter(valv => valv.table_member.length).map((val, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        {
                                                            val.table_member.length ? val.table_member.map((val2, index2) => (
                                                                <div key={index2} className="mb-1">
                                                                    {format(new Date(val2.date_create), 'dd/MM/yyyy')}
                                                                </div>
                                                            ))
                                                                : <>-</>
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            val.table_member.length ? val.table_member.map((val2, index2) => (
                                                                <div key={index2} className="mb-1">
                                                                    {toHHMMSS(val2.attend)}
                                                                </div>
                                                            ))
                                                                : <>-</>
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            val.table_member.length ? val.table_member.map((val2, index2) => (
                                                                <div key={index2} className="mb-1">
                                                                    {toHHMMSS(val2.finish)}
                                                                </div>
                                                            ))
                                                                : <>-</>
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            val.table_member.length ? val.table_member.map((val2, index2) => (
                                                                <div key={index2} className="mb-1">
                                                                    {val2.ot}
                                                                </div>
                                                            ))
                                                                : <>-</>
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            val.table_member.length ? val.table_member.map((val2, index2) => (
                                                                <div key={index2} className="mb-1">
                                                                    {val2.note}
                                                                </div>
                                                            ))
                                                                : <>-</>
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    :
                                    <Table bordered className='table-report'>
                                        <thead>
                                            <tr>
                                                <th>ชื่อพนักงาน</th>
                                                <th>วันที่</th>
                                                <th>เข้างาน</th>
                                                <th>เลิกงาน</th>
                                                <th>OT</th>
                                                <th>เหตุผล</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {datas.dataemployee.filter(valv => valv.table_member.length).map((val, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        {val.name}
                                                    </td>
                                                    <td>
                                                        {
                                                            val.table_member.length ? val.table_member.map((val2, index2) => (
                                                                <div key={index2} className="mb-1">
                                                                    {format(new Date(val2.date_create), 'dd/MM/yyyy')}
                                                                </div>
                                                            ))
                                                                : <>-</>
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            val.table_member.length ? val.table_member.map((val2, index2) => (
                                                                <div key={index2} className="mb-1">
                                                                    {toHHMMSS(val2.attend)}
                                                                </div>
                                                            ))
                                                                : <>-</>
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            val.table_member.length ? val.table_member.map((val2, index2) => (
                                                                <div key={index2} className="mb-1">
                                                                    {toHHMMSS(val2.finish)}
                                                                </div>
                                                            ))
                                                                : <>-</>
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            val.table_member.length ? val.table_member.map((val2, index2) => (
                                                                <div key={index2} className="mb-1">
                                                                    {val2.ot}
                                                                </div>
                                                            ))
                                                                : <>-</>
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            val.table_member.length ? val.table_member.map((val2, index2) => (
                                                                <div key={index2} className="mb-1">
                                                                    {val2.note}
                                                                </div>
                                                            ))
                                                                : <>-</>
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                }
                            </div>
                        </div>
                        <div className='bg-white px-4 py-3 text-end'>
                            <div className='font-12 text-gray weight-300'>
                                {(datas.dataemployee).length === 1 ? datas.dataemployee[0].name + " |" : null}  {datas.datestart} - {datas.dateend}
                            </div>
                            <div className='font-14 weight-400 my-1'>
                                รวมเวลางาน <span className='d-inline-block' style={{ width: "100px" }}>70 ชั่วโมง</span>
                            </div>
                            <div className='font-14 weight-400'>

                                รวม OT <span className='d-inline-block' style={{ width: "100px" }}>{sumOt(datas.dataemployee)} ชั่วโมง</span>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Modalemployeereport;