import React, { useState } from 'react';
// import axios from 'axios';
// import { useNavigate } from "react-router-dom";

import help from '../../assets/img/help.svg';


import Menuleft from "../../layout/pagesadmin/menuleft";
import Menusetting from "../../components/setting/setting_menu";

import { Form, Nav, Row, Col, ListGroup, Badge } from "react-bootstrap";
// import { Form, Col, Row, InputGroup, Button } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

// import "react-datepicker/dist/react-datepicker.css";

const Settingprinter = () => {
    const { t } = useTranslation();
    // let history = useNavigate();
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const data = new FormData(event.target);

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

        } else {

            // history("/questionnaire2");
            // alert(data.get('service_charge'));
            alert(555);
        }
        setValidated(true);
    };

    const [swt, setSwt] = useState(false);
    const [swt2, setSwt2] = useState(false);

    return (
        <>
            <div className={!t('menu-a') ? "sidebar-mini sidebar-collapse" : ""} >
                <div className='wrapper-admin layout-fixed'>
                    <nav className="main-header navbar bg-white shadow-sm" style={{ height: "60px" }}>
                        <Nav as="ul" variant="tabs" className="nav nav-tabs overflow-hidden ul-tabcustom">

                        </Nav>
                        <ul className="navbar-nav ml-auto" style={{ marginTop: "-20px" }}>
                            <li className="">
                                <div className='text-gray font-12'><img src={help} alt="" className="me-1" style={{ width: "14px" }} /> ขอความช่วยเหลือ</div>
                            </li>
                        </ul>
                    </nav>

                    <Menuleft type="setting" />

                    <div className="content-wrapper">
                        <section className="content scroll-custom">
                            <div className="row">
                                <div className="col-12 col-md-5 col-lg-5">
                                    <div className='text-start box-setting bg-white rounded-3 mt-3 h-100per'>
                                        <Menusetting type="printer" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-7 col-lg-7 ps-0">
                                    <Form noValidate validated={validated} onSubmit={handleSubmit} className="">
                                        <div className="bg-white rounded-3 ms-2 mt-3 h-100per position-relative">
                                            <div className='px-4 py-2 text-start box-list'>
                                                <ListGroup as="ol" className="mt-1">
                                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start borderhr py-3 border-0" >
                                                        <div className="w-100">
                                                            <span className="d-block align-middle">
                                                                <div>ค้นหาเครื่องพิมพ์</div>
                                                            </span>
                                                        </div>
                                                        <Badge bg="" className="p-0">
                                                            <div className="form-check form-switch">
                                                                <input className="form-check-input float-end" type="checkbox" id="flexSwitchCheckChecked" name='service_swit' value="1" onClick={() => setSwt(!swt)} checked={swt} />
                                                            </div>
                                                        </Badge>
                                                    </ListGroup.Item>
                                                </ListGroup>
                                                <Row className="align-items-end mb-3">
                                                    <Form.Group as={Col} md="12" className="position-relative" controlId="validationCustom01">
                                                        <Form.Label className="font-12 text-gray mb-2">เลือกเครื่องพิมพ์</Form.Label>
                                                        <Form.Select aria-label="Default select example" name='rounding'>
                                                            <option value="">ไม่มี</option>
                                                            <option value="1">ปัดเศษไปจำนวนใกล้เคียง</option>
                                                            <option value="2">ปัดเศษขึ้น</option>
                                                            <option value="3">ปัดเศษลง</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Row>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </div>
        </>
    );
};

export default Settingprinter;