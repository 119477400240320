import React, { useState, useRef } from 'react';
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import AuthService from "../../components/services_member/auth.service";

// import { BrowserRouter as Router } from "react-router-dom";
// import axios from 'axios';
// import { Link } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
// import Rang from '../components/lang';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import { Form, Tab, Nav, Row, Col } from "react-bootstrap";

import noimg from '../../assets/img/no-img-user.svg';
import calendar from '../../assets/img/calendar.svg';
import icondel from '../../assets/img/icon-del.svg';

import Modalcropbranch from "../../layout/modal/modalCropbranch";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import InputMask from 'react-input-mask';

const Modalemployee = (data) => {

    let history = useNavigate();

    const [validated, setValidated] = useState(false);

    const formRef = useRef(null);

    const [blob, setBlob] = useState(null)
    const [inputImg, setInputImg] = useState('');
    const [modalcrop, setModalcrop] = useState({ view: false, id: 0 });
    const [dataemployee, setDataemployee] = useState(data.dataemployee[0]);

    const [startDate, setStartDate] = useState(new Date(dataemployee.date_start));

    const dateoption = {
        locale: {
            format: 'DD/MM/YYYY'
        },
        startDate: startDate,
        singleDatePicker: true,
        autoApply: true,
    };

    // 


    const getBlob = (blob) => {
        // pass blob up from the ImageCropper component
        setBlob(blob);
        setModalcrop({ view: false });
    }

    const onInputChange = (e) => {
        // convert image file to base64 string
        const file = e.target.files[0]
        const reader = new FileReader();

        reader.addEventListener('load', () => {
            setInputImg(reader.result)
            setModalcrop({ view: true, id: 1, getblob: getBlob, inputImg: reader.result })
        }, false)

        if (file) {
            reader.readAsDataURL(file)
        }
    }

    const handleSubmit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const dataf = new FormData(event.target);

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        dataf.append("branch_id", branch.id);
        dataf.append("blob", JSON.stringify(blob));
        dataf.append("type", "edit");

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

        } else {

            AuthService.updateEmployee(dataf).then(
                (res) => {
                    //   console.log(res)   
                    if (res.status === 200) {
                        // history("/setting/setting"); 
                        // window.location.reload();  
                        formRef.current.reset();

                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: '',
                            text: "บันทึกข้อมูลเรียบร้อยแล้ว",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(function () {                            
                            // setValidated(false);                            
                            data.onHide();
                        }) 
                    } else {
                        // setErrors({email:"This email cannot be used.!"})
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        }
        setValidated(true);
    };

    const handleSubmitdel = (event, idcat) => {
        AuthService.delEmployee(event, 'employee').then(
            (response) => {
                // console.log(response.status)
                if (response.status === 200) {

                    // window.location.reload();
                    
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: '',
                        text: "ลบข้อมูลเรียบร้อยแล้ว",
                        showConfirmButton: false,
                        timer: 1500
                    }).then(function () {
                        data.onHide()
                    }) 
                }
            },
            (error) => {
                console.log(error);
            }
        )
    }

    // console.log((JSON.parse(dataemployee.access).filter(val => val === '1'))[0] === '1' ? true : false)

    return (
        <>
            <Modal {...data} className="modal-custom" size="md" backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton className="btn-close-white ">

                </Modal.Header>
                <Modal.Body className="p-0">
                    <div className="d-block w-100 scroll-custom">
                        {
                            data.type === "detail" ? (
                                <div className='pt-5 pb-4 bg-white px-4 text-start'>
                                    <div className='text-default font-22 weight-500'>
                                        รายละเอียดพนักงาน
                                    </div>
                                    <div className='mb-3'>
                                        <div className='py-5 text-center' >
                                            <label htmlFor="upload" className='px-5 d-block'>
                                                {
                                                    blob ? (
                                                        <img src={blob} alt="" className="w-50" />
                                                    ) :
                                                        dataemployee.img ?
                                                            <img src={process.env.REACT_APP_API_URL + "/uploads/employee/" + dataemployee.img} alt="" className="w-50" />
                                                            :
                                                            <img src={noimg} alt="" className="w-50" />
                                                }
                                            </label>
                                        </div>
                                        <div>ชื่อพนักงาน : {dataemployee.name}</div>
                                        <div>อีเมล : {dataemployee.email}</div>
                                        <div>เบอร์โทร : {dataemployee.tel}</div>
                                        <div>ตำแหน่ง : {dataemployee.position === '0' ? 'ผู้จัดการร้าน' : dataemployee.position === '1' ? 'แคชเชียร์' : dataemployee.position === '2' ? 'Checker' : 'พนักงานครัว'}</div>
                                        <div>วันที่เริ่มงาน : {dataemployee.date_start}</div>
                                    </div>
                                </div>
                            ) :
                                data.type === "del" ? (
                                    <div className='pt-5 pb-4 bg-white px-4 text-center'>
                                        <div><img src={icondel} alt="" className="" style={{ width: "50px" }} /></div>
                                        <div className='font-18 weight-500 mt-4'>ยืนยันการลบพนักงาน {dataemployee.name}</div>
                                        <div className='text-center px-3 mt-4'>
                                            <div className='row'>
                                                <div className="col-12 col-md-6 col-lg-6 pe-1">
                                                    <span className="w-100 btn btn-calcel text-white py-2 me-3" onClick={data.onHide}>
                                                        ยกเลิก
                                                    </span>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6 ps-1">
                                                    <button type='submit' onClick={() => handleSubmitdel(dataemployee.id)} className="w-100 btn btn-bg text-white py-2 me-3">
                                                        ยืนยัน
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <Modal.Title className='py-3 bg-white px-4 shadow-sm position-fixed w-100' style={{ zIndex: "1" }}>
                                            <div className='text-default font-22'>
                                                {data.type === "edit" ? ("แก้ไขพนักงาน") : ("เพิ่มพนักงาน")}
                                            </div>
                                        </Modal.Title>
                                        <Form noValidate validated={validated} onSubmit={handleSubmit} ref={formRef}>
                                            <div className='h-100per-modal bg-white px-4 pt-5'>
                                                <div>
                                                    <div className='py-5 text-center' >
                                                        <label htmlFor="upload" className='cursor px-5 d-block'>
                                                            {
                                                                blob ? (
                                                                    <img src={blob} alt="" className="w-50" />
                                                                ) :
                                                                    dataemployee.img ?
                                                                        <img src={process.env.REACT_APP_API_URL + "/uploads/employee/" + dataemployee.img} alt="" className="w-50" />
                                                                        :
                                                                        <img src={noimg} alt="" className="w-50" />
                                                            }
                                                        </label>
                                                        <input type='file' name="file" id="upload" accept='image/*' onChange={onInputChange} hidden />
                                                        <div className='mt-2 font-14 text-gray'>อัพโหลดภาพพนักงาน</div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <Row className="mt-0">
                                                        <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                                            <Form.Label className="font-12 text-gray mb-0">ชื่อพนักงาน</Form.Label>
                                                            <Form.Control type="text" name="name" defaultValue={dataemployee.name} className='font-14 input-custom' placeholder='xxxxxxxx' required />
                                                            <Form.Control type="hidden" name="id" defaultValue={dataemployee.id} className='font-14 input-custom' />
                                                        </Form.Group>
                                                    </Row>
                                                    <Row className="mt-3">
                                                        <Form.Group as={Col} md="6" className="position-relative pe-1" controlId="validationCustom01">
                                                            <Form.Label className="font-12 text-gray mb-0">Username</Form.Label>
                                                            <Form.Control type="text" name="username" defaultValue={dataemployee.username} className='font-14 input-custom' placeholder='xxxxxxxx' />
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6" className="position-relative pe-1" controlId="validationCustom01">
                                                            <Form.Label className="font-12 text-gray mb-0">Password</Form.Label>
                                                            <Form.Control type="password" name="password" defaultValue={dataemployee.password} className='font-14 input-custom' placeholder='xxxxxxxx' />
                                                        </Form.Group>
                                                    </Row> 

                                                    <Row className="align-items-end mt-3 mb-3">
                                                        <Form.Group as={Col} md="6" className="position-relative pe-1" controlId="validationCustom01">
                                                            <Form.Label className="font-12 text-gray mb-0">อีเมล</Form.Label>
                                                            <Form.Control type="text" name='email' defaultValue={dataemployee.email} className='font-14 input-custom' placeholder='xxx@xxx.xxx' />
                                                        </Form.Group>
                                              
                                                        <Form.Group as={Col} md="6" className="position-relative pe-1" controlId="validationCustom01">
                                                            <Form.Label className="font-12 text-gray mb-0">เบอร์โทร</Form.Label>
                                                            {/* <Form.Control type="text" name='tel' defaultValue={dataemployee.tel} className='font-14 input-custom' placeholder='xxx-xxx-xxxx' required /> */}
                                                            <InputMask
                                                                mask="999-999-9999"
                                                                maskChar={null}
                                                                defaultValue={dataemployee.tel}
                                                                className='font-14 input-custom form-control'
                                                                type="text"
                                                                placeholder="xxx-xxx-xxxx"
                                                                name="tel"
                                                                pattern="\d{3}[\-]\d{3}[\-]\d{4}"
                                                            />
                                                        </Form.Group>
                                                    </Row>
                                                    <Row className="align-items-end mt-3 mb-3">
                                                        <Form.Group as={Col} md="6" className="position-relative" controlId="validationCustom01">
                                                            <Form.Label className="font-12 text-gray mb-2">ตำแหน่ง</Form.Label>
                                                            <Form.Select aria-label="Default select example" className='font-14' name='position' defaultValue={dataemployee.position}>
                                                                <option value="0">ผู้จัดการร้าน</option>
                                                                <option value="1">แคชเชียร์</option>
                                                                <option value="2">Checker</option>
                                                                <option value="3">พนักงานครัว</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6" controlId="dob" className='position-relative'>
                                                            <Form.Label className="font-12 text-gray mb-2">วันที่เริ่มงาน</Form.Label>
                                                            <DateRangePicker initialSettings={dateoption}>
                                                                <input type="text" name="date_start" className="form-control font-14" />
                                                            </DateRangePicker >
                                                            <div className='position-absolute' style={{ bottom: "12px", right: "12px" }}>
                                                                <img src={calendar} alt="" className="me-1" style={{ width: "18px" }} />
                                                            </div>
                                                        </Form.Group>
                                                    </Row>
                                                    <div className='pb-5'>
                                                        <Form.Label className="font-12 text-gray mb-2">สิทธิ์การเข้าถึง </Form.Label>
                                                        {/* {console.log(dataemployee.access ? "5" : "0")} */}
                                                        <div className="ex-checkbox4 mb-2 ">
                                                            <input type="checkbox" id="checkbox_one" name="access[]" value="1" defaultChecked={dataemployee.access ? (JSON.parse(dataemployee.access)).filter(val => val === '1')[0] === '1' ? true : false : null} />
                                                            <label className="checkbox_one ps-4 " style={{ width: "100%" }} >
                                                                ดูออเดอร์เข้าครัว กำหนดสถานะรายการอาหารได้
                                                                แก้ไขและจัดการรายการอาหารเข้าครัวทั้งหมดได้
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox4 mb-2">
                                                            <input type="checkbox" id="checkbox_one" name="access[]" value="2" defaultChecked={dataemployee.access ? (JSON.parse(dataemployee.access)).filter(val => val === '2')[0] === '2' ? true : false : null} />
                                                            <label className="checkbox_one ps-4" style={{ width: "100%" }} >
                                                                ดูรายการของเสียได้ และเพิ่ม-ลดรายการของเสียได้
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox4 mb-2">
                                                            <input type="checkbox" id="checkbox_one" name="access[]" value="3" defaultChecked={dataemployee.access ? (JSON.parse(dataemployee.access)).filter(val => val === '3')[0] === '3' ? true : false : null} />
                                                            <label className="checkbox_one ps-4" style={{ width: "100%" }} >
                                                                จัดการคลังวัตถุดิบได้
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox4 mb-2">
                                                            <input type="checkbox" id="checkbox_one" name="access[]" value="4" defaultChecked={dataemployee.access ? (JSON.parse(dataemployee.access)).filter(val => val === '4')[0] === '4' ? true : false : null} />
                                                            <label className="checkbox_one ps-4" style={{ width: "100%" }} >
                                                                ดูตารางการทำงานของพนักงานได้
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox4 mb-2">
                                                            <input type="checkbox" id="checkbox_one" name="access[]" value="5" defaultChecked={dataemployee.access ? (JSON.parse(dataemployee.access)).filter(val => val === '5')[0] === '5' ? true : false : null}/>
                                                            <label className="checkbox_one ps-4" style={{ width: "100%" }} >
                                                                จัดการออเดอร์การขาย
                                                            </label>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='text-center py-3 px-3 bg-gray'>
                                                <div className='row'>
                                                    <div className="col-12 col-md-6 col-lg-6 pe-1">
                                                        <span className="w-100 btn btn-calcel text-white py-2 me-3" onClick={data.onHide}>
                                                            ยกเลิก
                                                        </span>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6 ps-1">
                                                        <button type='submit' className="w-100 btn btn-submit text-white py-2 me-3">
                                                            บันทึก
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </>
                                )}
                    </div>
                </Modal.Body>
            </Modal>

            <Modalcropbranch show={modalcrop.view} onHide={() => setModalcrop({ view: false })} id={modalcrop.id} getblob={getBlob} inputimg={modalcrop.inputImg} text="ภาพพนักงาน" />
        </>
    );
};

export default Modalemployee;