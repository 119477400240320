import React, { useState, useCallback, useEffect } from 'react';
// import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { compareAsc, format } from 'date-fns';

import AuthService from "../../components/services_member/auth.service";
import PostService from "../../components/services_member/post.service";

import help from '../../assets/img/help.svg';
import search from '../../assets/img/search.svg';

import Menuleft from "../../layout/pagesadmin/menuleft";
import Tabcontent from "../bar/bar_tab";

// import { Link } from "react-tiger-transition";
import { Form, Tab, Nav } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
const Bar = () => {
    const { t } = useTranslation();
    let history = useNavigate();
    const user = AuthService.getCurrentUser();
    const branch = AuthService.getCurrentBranch();

    localStorage.removeItem("table_order");

    if (!user) {
        history("/");
    } else {
        if (!branch) {
            history("/branch");
        }
    }

    const [dataorder, setDataorder] = useState([]);

    const [dateday, setDateday] = useState(format(new Date(), 'dd/MM/yyyy'));

    // const [reserveview, setReserveview] = useState({ search: true, reserve: false, detail: false });
    const [outputB, setOutputB] = useState();
    const [dataProductServe, setDataProductServe] = useState([]);

    const [loading, setLoding] = useState(true);

    const fetchData = useCallback(async (dd) => {

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        PostService.getOrderall(branch.id, dd).then(
            (response) => {
                if (response.data.status === 200) {
                    setDataorder(response.data.reserve);
                    if (response.data.data_product_serve) {
                        setDataProductServe(response.data.data_product_serve);

                    }

                    setLoding(false)

                    const output = [];
                    for (let i = 0; i < response.data.reserve.length; i++) {
                        output.push(...response.data.reserve[i].data_order)
                    }
                    setOutputB(output.filter(vls => vls.kitchen_id === '5'))

                } else {

                    setDataorder([]);
                    setOutputB([]);

                    if (response.data.data_product_serve) {
                        setDataProductServe(response.data.data_product_serve);
                    }

                    setLoding(false)

                }
            },
            (error) => {
                console.log(error);
            }
        )
    });

    useEffect(() => {
        fetchData(dateday)
    }, []);

    const refreshfetch = () => {
        fetchData(dateday)
        setLoding(true)
    }


    return (
        <>
        <div className={!t('menu-a') ? "sidebar-mini sidebar-collapse" : ""} >
            <div className='wrapper-admin layout-fixed'>
                <Tab.Container id="left-tabs-example" defaultActiveKey="kitchen_all">
                    <nav className="main-header navbar bg-white shadow-sm" style={{ height: "60px" }}>
                        <Nav as="ul" variant="tabs" className="nav nav-tabs overflow-hidden ul-tabcustom">
                            <Nav.Item as="li">
                                <Nav.Link eventKey="kitchen_all">ออเดอร์เข้าบาร์</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <Nav.Link eventKey="serve_all">รายการนำเสิร์ฟ</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <Nav.Link eventKey="order_all">ประวัติอาหารนำเสิร์ฟ</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <ul className="navbar-nav ml-auto" style={{ marginTop: "-20px" }}>
                            <li className="">
                                <div className='text-gray font-12'>
                                    <img src={help} alt="" className="me-1" style={{ width: "14px" }} /> ขอความช่วยเหลือ
                                </div>
                            </li>
                        </ul>
                    </nav>

                    <Menuleft type="bar" />

                    <div className="content-wrapper">
                        <section className="content scroll-custom position-relative overflow-hidden">
                            {loading ?
                                <div className='position-absolute w-100 absolute-center h-100' style={{ top: '0', background: "rgba(255,255,255,.7)", zIndex: '99' }}>
                                    <div className='position-absolute' style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                        <i className="fa fa-spinner fa-spin" />
                                        <div>Loading</div>
                                    </div>
                                </div>
                                : null}
                            <Tabcontent dataorder={dataorder} dataplang={outputB} dataProduct={dataProductServe} refresh={refreshfetch} />
                        </section>

                    </div>
                </Tab.Container>
            </div >
        </div >
        </>
    );
};

export default Bar;