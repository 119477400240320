import React, { useState, useEffect, useCallback } from 'react';
import { Form, Col, Row } from "react-bootstrap";

import AuthService from "../components/services_member/auth.service";
import PostService from "../components/services_member/post.service";

import { useNavigate, useParams,Link } from "react-router-dom";

// import { Link } from "react-tiger-transition";

import imglogin from '../assets/img/img-login-2.jpg';
import eye from '../assets/img/eye.svg';
import loading from '../assets/img/loading_icon.gif';
import noimg from '../assets/img/no-img.svg';

const Loginmember = () => {

    // localStorage.removeItem("member");
    // localStorage.removeItem("member_data");
    // localStorage.removeItem("branch_data");

    // localStorage.removeItem("position_data");
    // localStorage.removeItem("more_data");
    const { id } = useParams();

    let history = useNavigate();

    const [dataBranch, setDataBranch] = useState(false);

    const fetchData = useCallback(async () => {

        PostService.getBranchone(id).then(
            (response) => {
                if (response.data.status === 200) {
                    setDataBranch(response.data.data);
                    localStorage.setItem("link_login", id);
                }
            },
            (error) => {
                console.log(error);
            }
        )
    });

    // console.log(dataBranch.id)

    const fetchData2 = useCallback(async () => {
        const user = AuthService.getCurrentUser();
        if (user) {
            PostService.getAllBranchPosts().then(

                (response) => {
                    // console.log(response);
                    if (response.status === 200) {
                        // setDatabranch(response.data);
                        localStorage.setItem("member_data", JSON.stringify(response.member));
                        AuthService.getBranch(dataBranch.id).then(
                            (response) => {
                                if (response.status === 200) {
                                    localStorage.setItem("branch_data", JSON.stringify(response.data));
                                    history("/table/manage");
                                }
                            },
                            (error) => {
                                if (error.response && error.response.status === 403) {
                                   console.log('error get branch')
                                }
                            }
                        )
                        
                    }
                },
                (error) => {
                    alert(error.response)
                    // if (error.response && error.response.status === 403) {
                    //     history("/");
                    //     window.location.reload();
                    //     localStorage.removeItem("member_data");
                    // }
                }
            )

            

        } else {
            history("/");
        }
    });

    useEffect(() => {
        fetchData()
    }, []);

    const [form, setForm] = useState({})
    const [errors, setErrors] = useState(false)

    const [validated, setValidated] = useState(false);
    const [clssLoading, setClssLoading] = useState(false);

    const handleSubmit = async (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const data = new FormData(event.target);

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            setClssLoading(true);
            try {
                AuthService.loginmember(data.get('username'), data.get('password')).then(
                    (res) => {
                        if (res.status === 200) {
                            // history("/business");                              
                            // setClssLoading(false);
                            // history("/branch");
                            // history("/table/manage");
                            // window.location.reload(); 
                            // alert('yes')

                            let timer1 = setTimeout(() => fetchData2(), 1000);
                            return () => {
                                clearTimeout(timer1);
                            };
                        } else {
                            setErrors(true);
                            setClssLoading(false);
                        }
                    },
                    (error) => {
                        console.log(error);
                        setClssLoading(false);
                        // setErrors(true)
                    }
                );
            } catch (err) {
                console.log(err);
                // setErrors(true)
            }
        }
        setValidated(true);
    };

    const [passwordShown, setPasswordShown] = useState(false);

    // Password toggle handler
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    return (
        <>
            {/* box header  */}
            {/* <Header /> */}

            <div className="wrapper" style={{ backgroundColor: "#fff", zIndex: "1" }}>
                <section className="" >
                    <div className="container-fluid">
                        <div className="row justify-content-center align-items-center" style={{ height: "100vh" }}>
                            <div className="col-12 col-md-6 col-lg-6 text-start px-md-0 px-lg-0 px-xl-5">
                                <div className='px-md-0 px-xl-5'>
                                    <div className='px-md-0 px-xl-5'>
                                        <Form noValidate validated={validated} onSubmit={handleSubmit} className="px-5">
                                            <div className='m-auto' style={{ width: "250px" }}>
                                                {dataBranch.logo ?
                                                    <img src={process.env.REACT_APP_API_URL + "/uploads/branch/" + dataBranch.logo} alt="" className="w-100 px-5" />
                                                    :
                                                    <img src={noimg} alt="" className="w-100 px-5" />
                                                }
                                            </div>
                                            <h3 className='mb-3 text-center'>{dataBranch.name}</h3>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md="12" className="position-relative" controlId="validationCustom01">
                                                    <Form.Label className='text-gray mb-1'>Username</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        className=""
                                                        placeholder="Your Username"
                                                        name="username"
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="12" controlId="validationCustom02" className="position-relative mt-3">
                                                    <Form.Label className='text-gray mb-1'>Password</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type={passwordShown ? "text" : "password"}
                                                        placeholder="Password"
                                                        className=""
                                                        name="password"
                                                    />
                                                    <span className="sh-pass cursor-pointer" onClick={togglePassword}>
                                                        {passwordShown ? <><span className='hide-password'></span>
                                                            <img src={eye} alt="" className="" />
                                                        </> :
                                                            <img src={eye} alt="" className="" />
                                                        }
                                                    </span>
                                                </Form.Group>
                                                <Form.Group as={Col} md="12" className="position-relative" controlId="validationCustom01">
                                                    <div className="mt-2 text-end w-100">
                                                        {errors ? (
                                                            <div className='position-absolute font-14' style={{ color: "#dc3545" }}>ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง !</div>
                                                        ) : ""}
                                                        {/* <Link to="/forgotpassword" transition="default-fade" className="mt-1 text-gray text-decoration-none">ลืมรหัสผ่าน</Link> */}
                                                    </div>
                                                </Form.Group>
                                            </Row>


                                            <button type="submit" className="w-100 btn btn-bg text-white mt-2">
                                                {clssLoading ? <span><i className="fa fa-spinner fa-spin" /></span> : <span>เข้าสู่ระบบ</span>}
                                            </button>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >

            {/* <Footer /> */}

        </>
    );
};

export default Loginmember;