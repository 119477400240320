import React, { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import Loadingpage from "../components/loading_page";

const Logoumember = () => {

    localStorage.removeItem("member");
    localStorage.removeItem("member_data");
    localStorage.removeItem("branch_data");

    // localStorage.removeItem("position_data");
    // localStorage.removeItem("more_data");
    const { id } = useParams();

    let history = useNavigate();

    useEffect(() => {
        // fetchData()
        history("/loginmember/" + id);
    }, []);

    // const [clssLoading, setClssLoading] = useState(false);

    return (
        <>
            {/* box header  */}
            {/* <Header /> */}
            <Loadingpage timeout="1000" />
            {/* <Footer /> */}

        </>
    );
};

export default Logoumember;