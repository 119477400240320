import React, { useState, useEffect, useCallback } from 'react';
// import axios from 'axios';
import { useNavigate,Link } from "react-router-dom";
import AuthService from "../../components/services_member/auth.service";
import PostService from "../../components/services_member/post.service";
import help from '../../assets/img/help.svg';


import Menuleft from "../../layout/pagesadmin/menuleft";
import Menusetting from "../../components/setting/setting_menu";

import { Form, Nav, Row, Col, ListGroup, Badge } from "react-bootstrap";
// import { Form, Col, Row, InputGroup, Button } from "react-bootstrap";


import Modalcategory from "../../layout/modal/modalCategory";
import Modaladdproduct from "../../layout/modal/modalProduct";
import Modalproductlist from "../../layout/modal/modalProductlist";

import Modalproductpromotion from "../../layout/modal/modalProductpromotionadd";
import Modalproductpromotionlist from "../../layout/modal/modalProductpromotionlist";
// import "react-datepicker/dist/react-datepicker.css";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useTranslation } from 'react-i18next';
const Settingproduct = () => {
    const { t } = useTranslation();
    let history = useNavigate();
    // [{"more_name":"test","more_price":"1"},{"more_name":"test 2","more_price":"2"}]
    localStorage.setItem("more_data", JSON.stringify({ topic: '', more_n: '', more_data: [{ more_name: '', more_price: '' }] }));

    const [datacat, setDatacat] = useState(false);
    const [swt, setSwt] = useState([]);
    const [swt2, setSwt2] = useState([]);

    const [modalcategory, setModalcategory] = useState({ view: false, id: 0, datacat: [] });
    const [modaladdproduct, setModaladdproduct] = useState({ view: false, id: 0, datacat: [], datawarehouse: [] });

    const [modalproductlist, setModalproductlist] = useState({ view: false, catid: 0, datacat: [], dataproductall: [], datawarehouse: [], dataproduct: [] });
    const [modalproductpromotion, setModalproductpromotion] = useState({ view: false, id: 0 });
    const [modalproductpromotionlist, setModalproductpromotionlist] = useState({ view: false, datapromotion: [] });


    const user = AuthService.getCurrentUser();
    const branch = AuthService.getCurrentBranch();

    if (!user) {
        history("/");
    } else {
        if (!branch) {
            history("/branch");
        }
    }

    const fetchData = useCallback(async () => {
        PostService.getCategory(branch.id).then(
            (response) => {
                if (response.data.status === 200) {

                    setDatacat(response.data);
                    setModalproductpromotionlist({ datapromotion: response.data.promotionall });
                    const dafa = response.data.data[0];
                    // console.log(dafa)
                    if (dafa) {
                        setSwt({ view: '1' })
                    }

                    const dafa2 = response.data.promotion;
                    if (dafa2) {
                        setSwt2({ view: '1' })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        )
    });

    useEffect(() => {
        fetchData()
    }, []);

    const setCategory = (e) => {
        if (datacat) {
            // setModalcategory({ view: true, datacat: JSON.parse(datacat.data.category) })
            setModalcategory({ view: true, datacat: datacat.data })
            // console.log(datacat.data)
        } else {
            setModalcategory({ view: true, datacat: [] })
        }
    }

    const setProduct = (e) => {
        if (datacat) {
            setModaladdproduct({ view: true, datacat: datacat.data, datawarehouse: datacat.warehouse })
        } else {
            setModaladdproduct({ view: true, datacat: [], datawarehouse: [] })
        }
    }

    const setProductlist = (e) => {
        if (datacat) {
            setModalproductlist({ view: true, catid: datacat.data[0].id, datacat: datacat.data, dataproductall: datacat.product, datawarehouse: datacat.warehouse, dataproduct: datacat.product.filter(val => val.cat_id === datacat.data[0].id) })
            // console.log(datacat)
        } else {
            setModalproductlist({ view: true, catid: 0, datacat: [], dataproductall: [], datawarehouse: [], datapro: [] })
        }
    }

    // console.log(JSON.parse(datacat.data.category))

    const handleSwt = (e) => {

        const data = new FormData();

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        data.append("branch_id", branch.id);
        data.append("type", "status");

        if (e === '1') {
            setSwt({ view: '0' });
            data.append("status", "0");

        } else {
            setSwt({ view: '1' });
            data.append("status", "1");
        }

        AuthService.updateCategory(data).then(
            (res) => {
                //   console.log(res)   
                if (res.status === 200) {
                    // history("/setting/setting"); 
                    // window.location.reload();                    
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: '',
                        text: "บันทึกข้อมูลเรียบร้อยแล้ว",
                        showConfirmButton: false,
                        timer: 1500
                    }).then(function () {
                        fetchData()
                    })
                } else {
                    // setErrors({email:"This email cannot be used.!"})
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    const handleSwt2 = (e) => {

        const data = new FormData();

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        data.append("branch_id", branch.id);
        data.append("type", "status");

        if (e === '1') {
            setSwt2({ view: '0' });
            data.append("status", "0");

        } else {
            setSwt2({ view: '1' });
            data.append("status", "1");
        }

        AuthService.updatePromotion(data).then(
            (res) => {
                //   console.log(res)   
                if (res.status === 200) {
                    // history("/setting/setting"); 
                    // window.location.reload();
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: '',
                        text: "บันทึกข้อมูลเรียบร้อยแล้ว",
                        showConfirmButton: false,
                        timer: 1500
                    }).then(function () {
                        fetchData()
                    })
                } else {
                    // setErrors({email:"This email cannot be used.!"})
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    const hideModallist = (e) => {
        // setModalproductlist({ view: false, datacat:[],dataproduct:[] });
        // window.location.reload(true);
        fetchData();

        // alert(e)

        if (e !== 'del') {
            setModalcategory({ view: false, id: 0, datacat: [] });
        }
        setModaladdproduct({ view: false, id: 0, datacat: [] });

        setModalproductlist({ view: false, catid: 0, datacat: [], dataproductall: [], dataproduct: [] });

        setModalproductpromotion({ view: false, id: 0 });
        setModalproductpromotionlist({ view: false, datapromotion: [] });
    }

    const setModalpromolist = () => {
        setModalproductpromotionlist({ view: true, datapromotion: modalproductpromotionlist.datapromotion });
    }

    const hidePromotionlist = (e) => {
        // setModalproductlist({ view: false, datacat:[],dataproduct:[] });
        window.location.reload(true);
    }
    // console.log(modalproductlist);
    return (
        <>
            <div className={!t('menu-a') ? "sidebar-mini sidebar-collapse" : ""} >
                <div className='wrapper-admin layout-fixed'>
                    <nav className="main-header navbar bg-white shadow-sm" style={{ height: "60px" }}>
                        <Nav as="ul" variant="tabs" className="nav nav-tabs overflow-hidden ul-tabcustom">

                        </Nav>
                        <ul className="navbar-nav ml-auto" style={{ marginTop: "-20px" }}>
                            <li className="">
                                <div className='text-gray font-12'><img src={help} alt="" className="me-1" style={{ width: "14px" }} /> ขอความช่วยเหลือ</div>
                            </li>
                        </ul>
                    </nav>

                    <Menuleft type="setting" />

                    <div className="content-wrapper">
                        <section className="content scroll-custom">
                            <div className="row">
                                <div className="col-12 col-md-5 col-lg-5">
                                    <div className='text-start box-setting bg-white rounded-3 mt-3 h-100per'>
                                        <Menusetting type="product" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-7 col-lg-7 ps-0">
                                    <div className="bg-white rounded-3 ms-2 mt-3 h-100per position-relative">
                                        <div className='px-4 py-2 text-start box-list'>
                                            <ListGroup as="ol" className="mt-1">
                                                <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start borderhr py-3" >
                                                    <div className="w-75">
                                                        <span className="d-block align-middle">
                                                            <div><b>จัดการเมนู</b></div>
                                                            {swt.view === '1' ? (
                                                                <>
                                                                    <Row className="align-items-end mt-4 mb-1">
                                                                        <Form.Group as={Col} md="6" className="position-relative pe-1" controlId="validationCustom01">
                                                                            <button onClick={() => setCategory()} className="w-100 btn btn-border-default py-2 me-3 weight-500">
                                                                                เพิ่ม / แก้ไข หมวดหมู่
                                                                            </button>
                                                                        </Form.Group>
                                                                    </Row>
                                                                    <Row className="align-items-end mt-2 mb-1">
                                                                        <Form.Group as={Col} md="6" className="position-relative pe-1" controlId="validationCustom01">
                                                                            <button onClick={() => setProduct()} className="w-100 btn btn-border-default py-2 me-3 weight-500">
                                                                                เพิ่มเมนู
                                                                            </button>
                                                                        </Form.Group>
                                                                    </Row>
                                                                    <Row className="align-items-end mt-2 mb-1">
                                                                        <Form.Group as={Col} md="6" className="position-relative pe-1" controlId="validationCustom01">
                                                                            <button onClick={() => setProductlist()} className="w-100 btn btn-border-default py-2 me-3 weight-500">
                                                                                แก้ไขเมนู
                                                                            </button>
                                                                        </Form.Group>
                                                                    </Row>
                                                                </>
                                                            ) : ""}
                                                        </span>
                                                    </div>
                                                    <Badge bg="" className="p-0">
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input float-end" type="checkbox" id="flexSwitchCheckChecked" name='service_swit' value="1" onClick={() => handleSwt(swt.view)} defaultChecked={swt.view === '1' ? true : null} />
                                                        </div>
                                                    </Badge>
                                                </ListGroup.Item>
                                                <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start borderhr py-3" >
                                                    <div className="w-75">
                                                        <span className="d-block align-middle mt-3">
                                                            <div><b>จัดการโปรโมชั่น</b></div>
                                                            {swt2.view === '1' ? (
                                                                <>
                                                                    <Row className="align-items-end mt-4 mb-1">
                                                                        <Form.Group as={Col} md="6" className="position-relative pe-1" controlId="validationCustom01">
                                                                            <button onClick={() => setModalproductpromotion({ view: true })} className="w-100 btn btn-border-default py-2 me-3 weight-500">
                                                                                เพิ่มโปรโมชั่น
                                                                            </button>
                                                                        </Form.Group>
                                                                    </Row>
                                                                    <Row className="align-items-end mt-2 mb-1">
                                                                        <Form.Group as={Col} md="6" className="position-relative pe-1" controlId="validationCustom01">
                                                                            <button onClick={() => setModalpromolist()} className="w-100 btn btn-border-default py-2 me-3 weight-500">
                                                                                แก้ไขโปรโมชั่น
                                                                            </button>
                                                                        </Form.Group>
                                                                    </Row>
                                                                </>
                                                            ) : ""}
                                                        </span>
                                                    </div>
                                                    <Badge bg="" className="p-0">
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input float-end" type="checkbox" id="flexSwitchCheckChecked" name='vat_swit' value="1" onClick={() => handleSwt2(swt2.view)} defaultChecked={swt2.view === '1' ? true : null} />
                                                            {/* <label className="form-check-label" >Checked switch checkbox input</label> */}
                                                        </div>
                                                    </Badge>
                                                </ListGroup.Item>

                                            </ListGroup>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </div>
            {modalcategory.view ?
                <Modalcategory show={modalcategory.view} onHide={(e) => hideModallist(e)} id={modalcategory.id} datacat={modalcategory} branchid={branch.id} />
                : null}

            {modaladdproduct.view ?
                <Modaladdproduct show={modaladdproduct.view} onHide={() => hideModallist()} type="add" datacat={modaladdproduct} />
                : null}

            {modalproductlist.view ?
                <Modalproductlist show={modalproductlist.view} onHide={(e) => hideModallist(e)} datacat={modalproductlist} />
                : null}

            {modalproductpromotion.view ?
                <Modalproductpromotion show={modalproductpromotion.view} onHide={() => setModalproductpromotion({ view: false })} id={modalproductpromotion.id} type="add" />
                : null}

            {modalproductpromotionlist.view ?
                <Modalproductpromotionlist show={modalproductpromotionlist.view} onHide={() => hidePromotionlist()} datapromotion={modalproductpromotionlist.datapromotion} />
                : null}
        </>
    );
};

export default Settingproduct;