import React, { useState, useRef } from 'react';
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import AuthService from "../../components/services_member/auth.service";

// import { BrowserRouter as Router } from "react-router-dom";
// import axios from 'axios';
// import { Link } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
// import Rang from '../components/lang';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import { Form, Tab, Nav, Row, Col } from "react-bootstrap";

import noimg from '../../assets/img/no-img-user.svg';
import calendar from '../../assets/img/calendar.svg';
import icondel from '../../assets/img/icon-del.svg';

import Modalcropbranch from "../../layout/modal/modalCropbanner";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import InputMask from 'react-input-mask';

const Modalbanner = (data) => {

    let history = useNavigate();

    const [validated, setValidated] = useState(false);

    const formRef = useRef(null);

    const [blob, setBlob] = useState(null)
    const [inputImg, setInputImg] = useState('');
    const [modalcrop, setModalcrop] = useState({ view: false, id: 0 });

    const [errorblob, setErrorblob] = useState();
    const getBlob = (blob) => {
        // pass blob up from the ImageCropper component
        setBlob(blob);
        setModalcrop({ view: false });
    }

    const onInputChange = (e) => {
        // convert image file to base64 string
        const file = e.target.files[0]
        const reader = new FileReader();

        reader.addEventListener('load', () => {
            setInputImg(reader.result)
            setModalcrop({ view: true, id: 1, getblob: getBlob, inputImg: reader.result })
        }, false)

        if (file) {
            reader.readAsDataURL(file)
        }
    }

    const handleSubmit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const dataf = new FormData(event.target);

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        dataf.append("branch_id", branch.id);
        dataf.append("blob", JSON.stringify(blob));
        dataf.append("type", "add");

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

            if (!blob) {
                setErrorblob("Please select uploads file.")
            }

        } else {

            if (blob) {

                AuthService.updateBanner(dataf).then(
                    (res) => {
                        //   console.log(res)   
                        if (res.status === 200) {
                            // history("/setting/setting"); 
                            // window.location.reload();

                            Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: '',
                                text: "เพิ่ม Banner เรียบร้อยแล้ว",
                                showConfirmButton: false,
                                timer: 1500
                            }).then(function () {
                                data.onHide();
                                // formRef.current.reset();
                                setValidated(false);
                            })
                        } else {
                            // setErrors({email:"This email cannot be used.!"})
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            } else {
                setErrorblob("Please select uploads file. !")

            }
        }
        setValidated(true);
    };

    const handleSubmitedit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const dataf = new FormData(event.target);

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        dataf.append("id", data.databanner[0].id);
        dataf.append("branch_id", branch.id);
        dataf.append("blob", JSON.stringify(blob));
        dataf.append("type", "edit");

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

        } else {

            AuthService.updateBanner(dataf).then(
                (res) => {
                    //   console.log(res)   
                    if (res.status === 200) {
                        // history("/setting/setting"); 
                        // window.location.reload();  
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: '',
                            text: "แก้ไข Banner เรียบร้อยแล้ว",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(function () {
                            data.onHide();
                            setValidated(false);
                        })
                    } else {
                        // setErrors({email:"This email cannot be used.!"})
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        }
        setValidated(true);
    };

    const handleSubmitdel = (event, idcat) => {
        AuthService.delBanner(event, 'banner').then(
            (response) => {
                // console.log(response.status)
                if (response.status === 200) {

                    // window.location.reload();

                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: '',
                        text: "ลบ Banner เรียบร้อยแล้ว",
                        showConfirmButton: false,
                        timer: 1500
                    }).then(function () {
                        data.onHide()
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        )
    }

    // console.log((JSON.parse(databanner.access).filter(val => val === '1'))[0] === '1' ? true : false)

    return (
        <>
            <Modal {...data} className="modal-custom" size="md" backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton className="btn-close-white ">

                </Modal.Header>
                <Modal.Body className="p-0">
                    <div className="d-block w-100 scroll-custom">
                        {

                            data.type === "del" ? (
                                <div className='pt-5 pb-4 bg-white px-4 text-center'>
                                    <div><img src={icondel} alt="" className="" style={{ width: "50px" }} /></div>
                                    <div className='font-18 weight-500 mt-4'>ยืนยันการลบ Banner</div>
                                    <div className='text-center px-3 mt-4'>
                                        <div className='row'>
                                            <div className="col-12 col-md-6 col-lg-6 pe-1">
                                                <span className="w-100 btn btn-calcel text-white py-2 me-3" onClick={data.onHide}>
                                                    ยกเลิก
                                                </span>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 ps-1">
                                                <button type='submit' onClick={() => handleSubmitdel(data.databanner[0].id)} className="w-100 btn btn-bg text-white py-2 me-3">
                                                    ยืนยัน
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : data.type === "add" ? (
                                <>
                                    <Modal.Title className='py-3 bg-white px-4 shadow-sm position-fixed w-100' style={{ zIndex: "1" }}>
                                        <div className='text-default font-22'>
                                            เพิ่ม Banner
                                        </div>
                                    </Modal.Title>
                                    <Form noValidate validated={validated} onSubmit={handleSubmit} className="">
                                        <div className='h-100per-modal bg-white px-4 pt-5'>
                                            <div>
                                                <div className='py-5 text-center' >
                                                    <label htmlFor="upload" className='cursor px-5 d-block'>
                                                        {
                                                            blob ? (
                                                                <img src={blob} alt="" className="w-50" />
                                                            ) : <img src={noimg} alt="" className="w-50" />
                                                        }
                                                    </label>
                                                    <input type='file' name="file" id="upload" required accept='image/*' onChange={onInputChange} hidden />
                                                    <div className='mt-2 font-14 text-gray'>อัพโหลดภาพ Banner</div>
                                                    <div className='font-12 weight-300' style={{ color: "red" }}>{errorblob}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='text-center py-3 px-3 bg-gray'>
                                            <div className='row'>
                                                <div className="col-12 col-md-6 col-lg-6 pe-1">
                                                    <span className="w-100 btn btn-calcel text-white py-2 me-3" onClick={data.onHide}>
                                                        ยกเลิก
                                                    </span>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6 ps-1">
                                                    <button type='submit' className="w-100 btn btn-submit text-white py-2 me-3">
                                                        บันทึก
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </>
                            ) : (
                                <>
                                    <Modal.Title className='py-3 bg-white px-4 shadow-sm position-fixed w-100' style={{ zIndex: "1" }}>
                                        <div className='text-default font-22'>
                                            แก้ไข Banner
                                        </div>
                                    </Modal.Title>
                                    {console.log(data.databanner[0])}
                                    <Form noValidate validated={validated} onSubmit={handleSubmitedit} ref={formRef}>
                                        <div className='h-100per-modal bg-white px-4 pt-5'>
                                            <div>
                                                <div className='py-5 text-center' >
                                                    <label htmlFor="upload" className='cursor px-5 d-block'>
                                                        {
                                                            blob ? (
                                                                <img src={blob} alt="" className="w-50" />
                                                            ) :
                                                                data.databanner[0].banner ?
                                                                    <img src={process.env.REACT_APP_API_URL + "/uploads/banner/" + data.databanner[0].banner} alt="" className="w-50" />
                                                                    :
                                                                    <img src={noimg} alt="" className="w-50" />
                                                        }
                                                    </label>
                                                    <input type='file' name="file" id="upload" accept='image/*' onChange={onInputChange} hidden />
                                                    <div className='mt-2 font-14 text-gray'>อัพโหลดภาพ Banner</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='text-center py-3 px-3 bg-gray'>
                                            <div className='row'>
                                                <div className="col-12 col-md-6 col-lg-6 pe-1">
                                                    <span className="w-100 btn btn-calcel text-white py-2 me-3" onClick={data.onHide}>
                                                        ยกเลิก
                                                    </span>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6 ps-1">
                                                    <button type='submit' className="w-100 btn btn-submit text-white py-2 me-3">
                                                        บันทึก
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </>
                            )
                        }
                    </div>
                </Modal.Body>
            </Modal>

            <Modalcropbranch show={modalcrop.view} onHide={() => setModalcrop({ view: false })} id={modalcrop.id} getblob={getBlob} inputimg={modalcrop.inputImg} text="ภาพพนักงาน" />
        </>
    );
};

export default Modalbanner;