import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button } from "react-bootstrap";

import { compareAsc, format } from 'date-fns'

import '../../assets/css/radioandcheckbox.css';
import { Form, Tab, Nav, Row, Col, Accordion, ListGroup, Badge } from "react-bootstrap";

import AuthService from "../../components/services_member/auth.service";
import PostService from "../../components/services_member/post.service";

import cplus from '../../assets/img/c-plus.svg';
import clob from '../../assets/img/c-lob.svg';

const Modatablemove = ({idOrder,noOrder,...data}) => {

    const [dateday, setDateday] = useState(format(new Date(), 'dd/MM/yyyy'));

    const [state, setState] = useState({ count: 1 });

    const [checkedtable, setCheckedtable] = useState({ id: 0, zone: "", table: "" });

    const [datatable, setDatatable] = useState([]);
    const [datareserve, setDatareserve] = useState([]);

    const fetchData = useCallback(async (ee) => {

        const branch = JSON.parse(localStorage.getItem("branch_data"));
        PostService.getTableall(branch.id, ee).then(
            (response) => {
                if (response.data.status === 200) {
                    setDatatable(response.data.data);
                    setDatareserve(response.data.reserve);
                }
            },
            (error) => {
                console.log(error);
            }
        )
    });

    useEffect(() => {
        fetchData(dateday)
    }, []);

    const handleTable = (id, zone, table, table_number, reserve) => {
        setCheckedtable({ id: id, zone: zone, table_id: table, table: table_number})
    }

    // console.log(datareserve)
    // console.log(datatable)

    // console.log(datatable.edatatables.filter(({ id }) => !datareserve.includes(id)))

    const handleTablesubmit = (event) => {

            event.preventDefault();
            // const form = event.currentTarget;
            const dataf = new FormData();
            const branch = JSON.parse(localStorage.getItem("branch_data"));

            dataf.append("id", idOrder);
            dataf.append("branch_id", branch.id);
            dataf.append("zone", checkedtable.id);
            dataf.append("table_id", checkedtable.table_id);

            AuthService.moveReserveorder(dataf).then(
                (res) => {
                    if (res.status === 200) {                        
                        data.onHide();       
                    } else {
                        console.log(res.message)
                    }
                },
                (error) => {
                    console.log(error);
                }
            );

        // console.log(checkedtable)

    }
// console.log(idOrder)
    return (
        <>
            <Modal {...data} className="modal-custom" size="md" backdrop="static" keyboard={false}>
                <Modal.Header closeButton className="btn-close-white ">

                </Modal.Header>
                <Modal.Body className="p-0 bg-white">
                    <div className="d-block w-100 overflow-hidden">

                        <Modal.Title className='p-3 mb-2' style={{ boxShadow: "0px 0 15px -5px #2E384D" }}>
                            <div className='font-22 mt-3 weight-500 ps-3'>เลือกพื้นที่</div>
                        </Modal.Title>
                        <Modal.Body className='scroll-custom p-0 pb-3'>
                            <div className="h-100per-modal">
                                <div className='custom-accordion'>
                                    <Accordion>
                                        {datatable.map((value, index) => (
                                            <Accordion.Item eventKey={index} key={index}>
                                                <Accordion.Header><span className='weight-500 font-18'>{value.zone}</span></Accordion.Header>
                                                <Accordion.Body className='p-0 custom-list-group'>
                                                    <ListGroup>
                                                        {(JSON.parse(value.detail)).map((val, ind) => (

                                                            datareserve.filter(valb => valb.zone === value.id).filter(valv => valv.table_reserve === `${val.id}`).length ?

                                                                datareserve.filter(valb => valb.zone === value.id).filter(valv => valv.table_reserve === `${val.id}`).map((val2, ind2) => (

                                                                    <ListGroup.Item key={ind} className={checkedtable.table_id === val.id ? "active cursor-text" : "cursor-text"} >
                                                                        {val.table_number} <span style={{color:"red"}}>(ไม่ว่าง)</span>
                                                                        <Badge className="text-default float-end">
                                                                            <div className='font-14 weight-400 text-light'>{val.number} ที่นั่ง</div>
                                                                        </Badge>
                                                                    </ListGroup.Item>
                                                                ))
                                                                : (
                                                                    <ListGroup.Item key={ind} className={checkedtable.table_id === val.id ? "active" : null} onClick={() => handleTable(value.id, value.zone, val.id, val.table_number, 0)}>{val.table_number}
                                                                        <Badge className="text-default float-end"><div className='font-14 weight-400 text-light'>{val.number} ที่นั่ง</div></Badge>
                                                                    </ListGroup.Item>
                                                                )
                                                        ))}
                                                    </ListGroup>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ))}
                                    </Accordion>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer style={{ backgroundColor: "#EAEAEA" }}>
                            <div className='w-100 mt-2'>
                                <div className='row'>
                                    <div className="col-12 col-md-6 col-lg-6 pe-1">
                                        <span onClick={data.onHide} transition="default-fade" className="w-100 btn btn-calcel text-white py-2">
                                            ยกเลิก
                                        </span>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 ps-1">
                                        <button onClick={handleTablesubmit} className="w-100 btn btn-submit text-white py-2">
                                            บันทึก
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Modal.Footer>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    );
};

export default Modatablemove;