import React, { useState } from 'react';
import { Form, Col, Row } from "react-bootstrap";

// import axios from 'axios';
import { useNavigate,Link } from "react-router-dom";

// import back from '../../assets/img/icon-back.svg';
// import { Link } from "react-tiger-transition";

import AuthService from "../../components/services_member/auth.service";
// import Checklogin from "../../components/check_login";

// state = { open: false };

// toggleMenu = () => {
//     this.setState({ open: !this.state.open });
//     console.log(this.state.open);
// };

const Businesspin = () => {

    let history = useNavigate();

    // const [memberid, setMemberid] = useState(false);

    // const getMemid = (respone) => {
    //     if(respone){
    //         setMemberid(respone.data.profile);
    //     }
    // }

    const datamember = JSON.parse(localStorage.getItem("member_data"));
    const databusiness = JSON.parse(localStorage.getItem("businessdata"));
    const user = AuthService.getCurrentUser();

    if (!user) {
        history("/");
    }

    return (
        <>
            {/* <Checklogin memid={getMemid}/> */}
            <div className="wrapper" style={{ backgroundColor: "#EAEAEA", zIndex: "1" }}>
                <section className="" >
                    <div className="container-fluid">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-12 col-md-7 col-xl-4 text-start h-100vh position-relative">
                                <div className='position-absolute w-100 position-center px-3'>
                                    <div className='box-shadow py-4 bg-white px-5'>
                                        <div className='mt-4 text-center'>
                                            <h3>รับรหัส PIN</h3>
                                            <div className='text-gray font-14'>รหัส PIN สำหรับ { databusiness.name }</div>
                                            <div className='py-5 my-3'>
                                                <h2>{ databusiness.pin }</h2>
                                            </div>
                                            <div className='text-gray mb-3 font-14'>
                                                กรุณาจดจำรหัสนี้ เพิื่อกรอก PIN ทุกครั้งก่อนเข้าใช้งาน
                                                รหัสนี้จะถูกส่งไปยังอีเมล <span className='text-decoration-underline text-default'>{datamember.email}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <Link to="/business" transition="default-fade" className="w-100 btn btn-bg text-white px-5">ถัดไป</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        </>
    );
};

export default Businesspin;