import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button } from "react-bootstrap";

import { compareAsc, format } from 'date-fns'

import '../../assets/css/radioandcheckbox.css';
import { Form, Tab, Nav, Row, Col, Accordion, ListGroup, Badge } from "react-bootstrap";

import AuthService from "../../components/services_member/auth.service";
import PostService from "../../components/services_member/post.service";

import cplus from '../../assets/img/c-plus.svg';
import clob from '../../assets/img/c-lob.svg';
import { DatasetController } from 'chart.js';

const Modalmenuselecttable = (data) => {

    const [dateday, setDateday] = useState(format(new Date(), 'dd/MM/yyyy'));

    const [state, setState] = useState({ count: 1 });

    const [checkedtable, setCheckedtable] = useState({ id: 0, zone: "", table: "" });

    // const [datatable, setDatatable] = useState([]);
    const [datareserve, setDatareserve] = useState([]);

    const fetchData = useCallback(async (ee) => {

        const branch = JSON.parse(localStorage.getItem("branch_data"));
        PostService.getTableall(branch.id, ee).then(
            (response) => {
                if (response.data.status === 200) {
                    // setDatatable(response.data.data);
                    setDatareserve(response.data.reserve);
                }
            },
            (error) => {
                console.log(error);
            }
        )
    });

    useEffect(() => {
        fetchData(dateday)
    }, []);

    const handleTable = (id) => {
        // alert(id)
        setCheckedtable({ id: id })
    }

    const handleTablesubmit = (event) => {

        if(checkedtable.id === 0){            
            data.onHide();
        }else{
            localStorage.setItem('table_order', JSON.stringify({ id: 0, zone: 0, table_id: 0, table: 0, reserve:checkedtable.id, date:dateday }));
            data.onHide(checkedtable.id)
        }
        
    }

    // console.log(datareserve)

    return (
        <>
            <Modal {...data} className="modal-custom" size="md" backdrop="static" keyboard={false}>
                <Modal.Header closeButton className="btn-close-white ">

                </Modal.Header>
                <Modal.Body className="p-0 bg-white">
                    <div className="d-block w-100 overflow-hidden">

                        <Modal.Title className='p-3 mb-2' style={{ boxShadow: "0px 0 15px -5px #2E384D" }}>
                            <div className='font-22 mt-3 weight-500 ps-3'>เลือก Drive Thru</div>
                        </Modal.Title>
                        <Modal.Body className='scroll-custom p-0 pb-3'>
                            <div className="h-100per-modal">
                                <div className='custom-accordion custom-list-group'>
                                    <ListGroup>
                                        {datareserve ? datareserve.filter(valb => valb.zone === '0').map((val, ind) => (

                                            <ListGroup.Item className={checkedtable.id === val.id ? "py-3 active" : "py-3"} 
                                            onClick={() => handleTable(val.id)} key={ind} >
                                                <div><b>Drive Thru : คิวที่ {val.table_reserve}</b></div>
                                                <div className='font-15 weight-400 '>Order #{val.no}</div>
                                            </ListGroup.Item>
                                        )) : null }
                                    </ListGroup>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer style={{ backgroundColor: "#EAEAEA" }}>
                            <div className='w-100 mt-2'>
                                <div className='row'>
                                    <div className="col-12 col-md-6 col-lg-6 pe-1">
                                        <span onClick={data.onHide} transition="default-fade" className="w-100 btn btn-calcel text-white py-2">
                                            ยกเลิก
                                        </span>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 ps-1">
                                        <button onClick={handleTablesubmit} className="w-100 btn btn-submit text-white py-2">
                                            บันทึก
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Modal.Footer>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    );
};

export default Modalmenuselecttable;