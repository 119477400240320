import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Modal, Button } from "react-bootstrap";
import { useNavigate,Link } from "react-router-dom";

import ReactToPrint from 'react-to-print';

import ComponentToPrint from '../../components/ComponentToPrint';

import AuthService from "../../components/services_member/auth.service";
import PostService from "../../components/services_member/post.service";

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { compareAsc, format } from 'date-fns';

import { Form, Tab, Nav, Row, Col } from "react-bootstrap";

import calendar from '../../assets/img/calendar.svg';

import ipdf from '../../assets/img/i-pdf.svg';
import iexcel from '../../assets/img/i-excel.svg';
import dellist from '../../assets/img/del-list.svg';
import addlist from '../../assets/img/add-list.svg';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
// import { Link } from "react-tiger-transition";

const Modalconfirmpayment = (datas) => {

    let history = useNavigate();

    const MySwal = withReactContent(Swal)

    // let datatatb = JSON.parse(datas.add_vat);
    // console.log(JSON.parse(datas.datamember.add_vat))

    // let datatatb = datas.type === "edit" ? JSON.parse(datas.datamember.add_vat) : [];

    // const [inputFields, setInputFields] = useState(datatatb);

    const [checkbox, setCheckbox] = useState({ type: 0, price: 0 });

    const [todayDate, setTodayDate] = useState(new Date());

    const [dateday, setDateday] = useState(format(new Date(), 'dd/MM/yyyy'));

    // const [method2, setMethod2] = useState(datas.type === "edit" ? datas.datamember.vat : []);

    // const dateoption = {
    //     locale: {
    //         format: 'DD/MM/YYYY'
    //     },
    //     startDate: todayDate,
    //     maxDate: todayDate,
    //     // singleDatePicker: true,
    //     // autoApply: true,
    // };

    const [validated, setValidated] = useState(false);
    const handleSubmitedit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const data = new FormData(event.target);

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        data.append("branch_id", branch.id);

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

        } else {

            AuthService.updateConfirmpayment(data).then(
                (res) => {
                    if (res.status === 200) {

                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: '',
                            text: "ชำระเงินเรียบร้อยแล้ว",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(function () {

                            // localStorage.removeItem("table_order");
                            // history("/table/manage");
                            // useEffect(() => {
                            fetchData(dateday);
                            // }, []);
                        })
                    } else {
                        console.log(res.message)
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        }
        setValidated(true);
    }

    const clickTrip = () => {
        setCheckbox({ type: 1, price: parseFloat((datas.datatotal.price) - (datas.datatotal.total)).toFixed(2) });

    }
    const handleChange = event => {
        setCheckbox({ type: 0, price: event.target.value });
    };

    const [prasree, setPrasree] = useState();
    const clickPasree = (e) => {
    //    console.log(e.target.value)
        if(e.target.value === '1'){
            setPrasree(true)
        }else{
            setPrasree(false)
        }        
    }

    const [method2, setMethod2] = useState();

    const [dataorder, setDataorder] = useState([]);
    const [dataorderlist, setDataorderlist] = useState([]);
    const [datapromotion, setDatapromotion] = useState([]);

    const [datareceipt, setDatareceipt] = useState();
    const [datapayment, setDatapayment] = useState();
    const [dataactive, setDataactive] = useState({ order: '' });
    const [orderview, setOrderview] = useState();

    const fetchData = useCallback(async (dd) => {

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        PostService.getReceiptone(branch.id, dd, datas.id).then(
            (response) => {
                if (response.data.status === 200) {

                    // console.log(response)

                    setDataorder(response.data.reserve);
                    setDataorderlist(response.data.reserve);
                    setDatareceipt(response.data.receipt)

                    setDataactive({ order: datas.id })
                    setOrderview(response.data.reserve[0])
                    setDatapromotion(response.data.reserve[0].promotion_data ? JSON.parse(response.data.reserve[0].promotion_data) : null);
                    setDatapayment(response.data.reserve[0].payment_data ? JSON.parse(response.data.reserve[0].payment_data) : null);

                } else {

                    setDataorder([]);
                    setDataorderlist([]);
                    // setDatapromotion([]);
                    setDatareceipt();
                    // setDataorder([]);
                    // setDataorderlist([]);
                    // setDatapromotion([]);
                    // setDatareceipt();
                    // setDatapayment();
                }
            },
            (error) => {
                console.log(error);
            }
        )
    });

    // useEffect(() => {
    //     fetchData(dateday);
    // }, []);

    const componentRef = useRef(null);
    const sumAll = () => {

        var sum = 0;
        var total = 0;
        orderview.data_order.filter(valb => valb.status !== '5').map((val, index) => (
            <>
                {sum = sum + (parseFloat(val.price) * val.qty)}

                {val.choice ? (JSON.parse(val.choice)).map((vall, indel) =>
                    total = total + (parseFloat(vall.more_price))
                ) : null}

            </>
        )
        )

        return parseFloat(sum + total)
    }

    const sumPromotion = (sumall) => {
        if (orderview.promotion_id !== '0') {
            if (orderview.promotion_data) {
                if (JSON.parse(orderview.promotion_data).method_reduction === '1') {
                    return parseFloat(sumall * JSON.parse(orderview.promotion_data).price / 100).toFixed(2)
                } else {
                    return parseFloat(JSON.parse(orderview.promotion_data).price).toFixed(2)
                }
            } else {
                return "00.00";
            }
        } else {
            return "00.00";
        }
    }

    const sumTotal = (sum, sumpromotion) => {

        var sumservice = 0;
        var sumvat = 0;
        var sumall = sum - sumpromotion;

        // if (datareserve.payment_data) {

        //     let datapayment = JSON.parse(datareserve.payment_data)

        if (datapayment) {
            if (datapayment.service_charge === '1') {
                var sumservice = parseFloat(sumall * (datapayment.service_rate) / 100).toFixed(2)
            }
            if (datapayment.vat === '1') {
                var sumvat = parseFloat(sumall * (datapayment.vat_rate) / 100).toFixed(2)
            }
        }
        // }
        return parseFloat((sum - parseFloat(sumpromotion)) + parseFloat(sumservice) + parseFloat(sumvat)).toFixed(2)

    }

    const sumMath = (price) => {
        if (datapayment) {
            if (datapayment.rounding === '0') {
                return parseFloat(price).toFixed(2)
            } else if (datapayment.rounding === '1') {
                return parseFloat(Math.round(price))
            } else if (datapayment.rounding === '2') {
                return parseFloat(Math.ceil(price))
            } else {
                return parseFloat(Math.floor(price))
            }
        }
    }

    const printSuccess = (e) => {
        // handleSubmitedit();

        localStorage.removeItem("table_order");
        history("/table/manage");
    }

    return (
        <>
            <Modal {...datas} className="modal-custom" size={datas.type === "detail" ? "lg" : "md"} backdrop="static"
                keyboard={false}>
                {/* <Modal.Header closeButton className="btn-close-white ">

                </Modal.Header> */}
                <Modal.Body className="p-0">
                    <div className="d-block w-100 scroll-custom">

                        <Modal.Title className='py-3 bg-white px-4 position-fixed w-100' style={{ zIndex: "1" }}>
                            <div className='text-default font-18 text-center pt-3'>
                                ชำระโดย{datas.datatotal.type === 1 ? "เงินสด" : datas.datatotal.type === 3 ? " Credit Card" : "โอนเงิน"}
                                <div className='font-14'>Order #{datas.datatotal.no}</div>

                            </div>
                        </Modal.Title>
                        <Form noValidate validated={validated} onSubmit={handleSubmitedit} className="">
                            <div className='h-100per-modal bg-white px-4 py-5'>

                                {datas.datatotal.type === 1 ? <>
                                    <div>
                                        <div className="mt-5 pt-3">
                                            <b>ยอดชำระ
                                                <span className='float-end cursor ms-3'>
                                                    {(datas.datatotal.total).toLocaleString('en-US',{maximumFractionDigits:2})}
                                                </span>
                                            </b>
                                        </div>
                                        <div className="mt-3">
                                            <b>เงินสด
                                                <span className='float-end cursor ms-3'>
                                                    {parseFloat(datas.datatotal.price).toFixed(2)}
                                                </span>
                                            </b>
                                        </div>
                                        <div className="mt-3">
                                            <b>เงินทอน
                                                <span className='float-end cursor ms-3'>
                                                    {parseFloat((datas.datatotal.price) - (datas.datatotal.total)).toFixed(2)}
                                                </span>
                                            </b>
                                        </div>
                                    </div>
                                    {orderview ? null : <>
                                        <hr className='hr-custom' />
                                        <div className="mt-4 pt-2">
                                            <b>ทิป</b>
                                        </div>
                                        <div className='mt-2'>
                                            <input className='form-control text-end' name="trip"
                                                onChange={handleChange} value={checkbox.price} placeholder="0.00" />
                                        </div>
                                        <div className='mt-3'>
                                            <div className="ex-checkbox mb-2">
                                                <input type="checkbox" id="checkbox_one" name="vat_type" value="1" onChange={(e) => clickTrip(e)} defaultChecked={checkbox.type === 1 ? "checked" : null} />
                                                <label className="checkbox_one mb-2 ps-4" style={{ width: "100%" }} >
                                                    ทิปทั้งหมด
                                                </label>
                                            </div>
                                        </div>
                                    </>}
                                </> :
                                    <div>
                                        <div className="mt-5 pt-3">
                                            <b>ยอดชำระ
                                                <span className='float-end cursor ms-3'>
                                                    {(datas.datatotal.total).toLocaleString('en-US',{maximumFractionDigits:2})}
                                                </span>
                                            </b>
                                        </div>
                                        {/* <div className="mt-3">
                                            <b>โอนเงิน
                                                <span className='float-end cursor ms-3'>
                                                    {parseFloat(datas.datatotal.price).toFixed(2)}
                                                </span>
                                            </b>
                                        </div> */}
                                        <input type="hidden" className='form-control text-end' name="trip" value="00.00" />
                                        <input type="hidden" className='form-control text-end' name="vat_type" value="" />
                                        {/* <hr className='hr-custom' />
                                        <div className="mt-4 pt-2">
                                            <b>ทิป</b>
                                        </div>
                                        <div className='mt-2'>
                                            <input className='form-control text-end' name="trip" onChange={handleChange} value={checkbox.price} placeholder="0.00" />
                                        </div>
                                        <div className='mt-3'>
                                            <div className="ex-checkbox mb-2">
                                                <input type="checkbox" id="checkbox_one" name="vat_type" value="1" onChange={(e) => clickTrip(e)} defaultChecked={checkbox.type === 1 ? "checked" : null} />
                                                <label className="checkbox_one mb-2 ps-4" style={{ width: "100%" }} >
                                                    ทิปทั้งหมด
                                                </label>
                                            </div>
                                        </div> */}

                                    </div>
                                }

                                <input type="hidden" name="price" value={parseFloat(datas.datatotal.price).toFixed(2)} />
                                <input type="hidden" name="type" value={datas.datatotal.type} />
                                <input type="hidden" name="total" value={datas.datatotal.total} />
                                <input type="hidden" name="totalCut" value={datas.datacut} />
                                <input type="hidden" name="id" value={datas.id} />
                                {orderview ? null : <>
                                    <div className='mt-3'>
                                        <div className="ex-radio mb-2">
                                            <input type="radio" name="vat_prasree" value="3" defaultChecked="true" onChange={(e) => clickPasree(e)}/>
                                            <span className="checkbox_one mb-2 ps-4" style={{ width: "100%" }} >
                                                บิลเงินสด
                                            </span>
                                        </div>
                                        <div className="ex-radio mb-2">
                                            <input type="radio" name="vat_prasree" value="2" onChange={(e) => clickPasree(e)}/>
                                            <span className="checkbox_one mb-2 ps-4" style={{ width: "100%" }} >
                                                ใบกำกับภาษีอย่างย่อ
                                            </span>
                                        </div>
                                        <div className="ex-radio mb-2">
                                            <input type="radio" name="vat_prasree" value="1" onChange={(e) => clickPasree(e)} />
                                            <span className="checkbox_one mb-2 ps-4" style={{ width: "100%" }} >
                                                ต้องการใบกำกับภาษี
                                            </span>
                                        </div>
                                    </div>

                                    {datas.datatotal.datamember ?
                                        <input type="hidden" name="member_id" value={datas.datatotal.datamember.id} />
                                    : null }
                                    {prasree ?
                                        <div>
                                            <div className=''>
                                                <div className='text-default font-16 weight-500'>
                                                    ข้อมูลสำหรับออกใบกำกับภาษี
                                                    {/* {console.log(datas.datatotal.datamember)} */}
                                                </div>
                                                <div className=''>
                                                    {datas.datatotal.datamember ?
                                                        JSON.parse(datas.datatotal.datamember.add_vat).map((input, index) => (
                                                            <div className=''>
                                                                <div className="ex-radio mt-3">
                                                                    <input type="radio" name="vat" onChange={() => setMethod2(input.id)} value={index} defaultChecked={input.id == datas.datatotal.datamember.vat ? true : false} />
                                                                    <span className='font-12 text-gray'>ชื่อ {input.name_vat} <br />เลขประจำตัวผู้เสียภาษี {input.number_vat} <br /> ที่อยู่ {input.add_vat}</span>
                                                                    <input type="hidden" name="name_vat_a[]" value={input.name_vat} />
                                                                    <input type="hidden" name="number_vat_a[]" value={input.number_vat} />
                                                                    <input type="hidden" name="add_vat_a[]" value={input.add_vat} />
                                                                </div>
                                                            </div>
                                                        )) :

                                                        <div className='w-100 pe-3'>
                                                            <div className="ex-radio mt-3">
                                                                <input type="hidden" name="member_id" value="" />
                                                                <input type="radio" name="vat" value="new" onChange={() => setMethod2('new')} defaultChecked={true} />
                                                                <span className='font-12 text-gray w-100'>
                                                                    <div className='ms-0'>
                                                                        <Row className="align-items-end mb-3">
                                                                            <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                                                                <Form.Label className="font-12 text-gray mb-0">ชื่อ </Form.Label>
                                                                                <Form.Control type="text" defaultValue="" name='name_vat' className='font-14 input-custom'
                                                                                    placeholder='xxxxxxx'
                                                                                    required={true} />
                                                                            </Form.Group>
                                                                        </Row>
                                                                        <Row className="align-items-end mt-3 mb-3">
                                                                            <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                                                                <Form.Label className="font-12 text-gray mb-0">เลขประจำตัวผู้เสียภาษี</Form.Label>
                                                                                <Form.Control type="text" defaultValue="" name='number_vat' className='font-14 input-custom'
                                                                                    placeholder='xxxxxxxxxxxxx'
                                                                                    required={true} />
                                                                            </Form.Group>
                                                                        </Row>
                                                                        <Row className="align-items-end mt-3 mb-3">
                                                                            <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                                                                <Form.Label className="font-12 text-gray mb-0">ที่อยู่</Form.Label>
                                                                                <Form.Control type="text" defaultValue="" name='add_vat' className='font-14 input-custom'
                                                                                    placeholder='xxxxxxx'
                                                                                    required={true} />
                                                                            </Form.Group>
                                                                        </Row>
                                                                    </div>
                                                                </span>
                                                            </div>
                                                        </div>

                                                    }

                                                    {datas.datatotal.datamember ?

                                                        <div className='w-100 pe-3'>
                                                            <div className="ex-radio mt-3">
                                                                <input type="radio" name="vat" value="new" onChange={() => setMethod2('new')} />
                                                                <span className='font-12 text-gray w-100'>
                                                                    <div className='ms-0'>
                                                                        
                                                                        <Row className="align-items-end mb-3">
                                                                            <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                                                                <Form.Label className="font-12 text-gray mb-0">ชื่อ </Form.Label>
                                                                                <Form.Control type="text" defaultValue="" name='name_vat' className='font-14 input-custom'
                                                                                    placeholder='xxxxxxx'
                                                                                    required={method2 === 'new' ? true : false} />
                                                                            </Form.Group>
                                                                        </Row>
                                                                        <Row className="align-items-end mt-3 mb-3">
                                                                            <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                                                                <Form.Label className="font-12 text-gray mb-0">เลขประจำตัวผู้เสียภาษี</Form.Label>
                                                                                <Form.Control type="text" defaultValue="" name='number_vat' className='font-14 input-custom'
                                                                                    placeholder='xxxxxxxxxxxxx'
                                                                                    required={method2 === 'new' ? true : false} />
                                                                            </Form.Group>
                                                                        </Row>
                                                                        <Row className="align-items-end mt-3 mb-3">
                                                                            <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                                                                <Form.Label className="font-12 text-gray mb-0">ที่อยู่</Form.Label>
                                                                                <Form.Control type="text" defaultValue="" name='add_vat' className='font-14 input-custom'
                                                                                    placeholder='xxxxxxx'
                                                                                    required={method2 === 'new' ? true : false} />
                                                                            </Form.Group>
                                                                        </Row>
                                                                    </div>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        : null}

                                                </div>

                                            </div>
                                        </div>
                                        : null}

                                </>}
                            </div>
                            <div className='text-center py-3 px-3 bg-white'>
                                {orderview ? <>
                                    <div className='row'>
                                        <div className="col-12 col-md-6 col-lg-6 pe-1">
                                            <Link to="/table/manage" className="w-100 btn btn-blue text-white py-2 me-3">Back</Link>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-6 pe-1">
                                            <div>
                                                <ReactToPrint
                                                    trigger={() => <span disabled={orderview ? false : true} className="w-100 btn btn-submit text-white py-2 me-3">พิมพ์ใบเสร็จ</span>}
                                                    content={() => componentRef.current}
                                                    documenttTitle='name document'
                                                    onAfterPrint={() => printSuccess('yes')}
                                                />

                                                <div className='position-absolute d-none'>
                                                    <div className="ticket" ref={componentRef} >
                                                        <div className="" />
                                                        <ComponentToPrint
                                                            overview={orderview}
                                                            datareceipt={datareceipt}
                                                            datapromotion={datapromotion} dataactive={dataactive}
                                                            dataorderlist={dataorderlist}
                                                            datapayment={datapayment}
                                                            sumAll={sumAll()}
                                                            sumPromotion={sumPromotion(sumAll())}
                                                            sumTotal={sumTotal(parseFloat(sumAll()), (sumPromotion(sumAll())+datas.datacut))}
                                                            // sumMath={sumMath(sumTotal(parseFloat(sumAll()), (sumPromotion(sumAll())+datas.datacut)))}
                                                            sumMath={datas.datatotal.total}
                                                            sumCut={parseFloat(datas.datacut)}
                                                            type="receipt"
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        {/* <div className="col-12 col-md-6 col-lg-6 ps-1">
                                        
                                    </div> */}
                                    </div>
                                </>
                                    : <>
                                        <div className='row'>
                                            <div className="col-12 col-md-6 col-lg-6 pe-1">
                                                <span onClick={() => datas.onHide()} className="w-100 btn btn-blue text-white py-2 me-3">Back</span>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 ps-1">
                                                <button type='submit' className="w-100 btn btn-submit text-white py-2 me-3">
                                                    บันทึก
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </Form>

                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Modalconfirmpayment;