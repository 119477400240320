import React, { useState, useEffect } from 'react';
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import AuthService from "../../components/services_member/auth.service";
// import arrayMove from 'array-move';

// import { Link } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
// import Rang from '../components/lang';

import { Form, Row, Col } from "react-bootstrap";
import noimg from '../../assets/img/no-img-promotion.svg';

import Modalcropbranch from "./modalCropbranch";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const Modaladdproductpromotionadd = (data) => {

    // console.log(data.dataproone)
    let history = useNavigate();

    const [validated, setValidated] = useState(false);

    const [swt, setSwt] = useState([]);

    const [method, setMethod] = useState(data.dataproone.method_reduction);
    const [method2, setMethod2] = useState(data.dataproone.price_reduction);
    const [method3, setMethod3] = useState(data.dataproone.type_reduction);

    const [datapromo, setDatapromo] = useState(data.dataproone);

    const [blob, setBlob] = useState(null)
    const [inputImg, setInputImg] = useState('');
    const [modalcrop, setModalcrop] = useState({ view: false, id: 0 });

    const getBlob = (blob) => {
        setBlob(blob);
        setModalcrop({ view: false });
        // setErrorblob(false)
    }

    const onInputChange = (e) => {
        // convert image file to base64 string
        const file = e.target.files[0]
        const reader = new FileReader();

        reader.addEventListener('load', () => {
            setInputImg(reader.result)
            setModalcrop({ view: true, id: 1, getblob: getBlob, inputImg: reader.result })
        }, false)

        if (file) {
            reader.readAsDataURL(file)
        }
    }

    const handleSwt = (e) => {
        if (e === '1') {
            setSwt({ view: '0' });
            // data.append("status", "0");
        } else {
            setSwt({ view: '1' });
            // data.append("status", "1");
        }
    }

    const handleSubmit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const data = new FormData(event.target);

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        data.append("id", datapromo.id);
        data.append("branch_id", branch.id);
        data.append("status", swt.view);
        data.append("type", "promotion");
        data.append("blob", JSON.stringify(blob));

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

        } else {

            AuthService.updatePromotion(data).then(
                (res) => {
                    //   console.log(res)   
                    if (res.status === 200) {

                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: '',
                            text: "บันทึกข้อมูลเรียบร้อยแล้ว",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(function () {
                            window.location.reload();
                        })
                    } else {
                        // console.log(res.message)
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        }
        setValidated(true);
    };

    useEffect(() => {
        if (datapromo.status === '0') {
            setSwt({ view: '0' })
        } else {
            setSwt({ view: '1' })
        }

    }, []);

    console.log(swt)

    return (
        <>
            <Modal {...data} className="modal-custom" size="lg" backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton className="btn-close-white ">

                </Modal.Header>
                <Modal.Body className="p-0">
                    <div className="d-block w-100 scroll-custom">
                        <div className='row'>
                            <div className="col-12 col-md-5 col-lg-5 bg-default px-4">
                                <div className='py-5 text-center' >
                                    <label htmlFor="upload" className='cursor px-1 d-block'>
                                        {
                                            blob ? (
                                                <img src={blob} alt="" className="w-50" />
                                            ) :
                                                datapromo.img ?
                                                    <img src={process.env.REACT_APP_API_URL + "/uploads/promotion/" + datapromo.img} alt="" className="w-50" />
                                                    :
                                                    <img src={noimg} alt="" className="w-50" />
                                        }
                                    </label>
                                    <input type='file' name="file" id="upload" accept='image/*' onChange={onInputChange} hidden />
                                    <div className='mt-2 font-14 text-gray'>อัพโหลดภาพเมนู</div>
                                </div>
                                <div className='text-white position-fixed font-14' style={{ bottom: "15px" }}>
                                    สถานะ
                                    <div className="form-check form-switch mt-2">
                                        <input className="form-check-input d-inline-block" type="checkbox" id="flexSwitchCheckChecked" name='vat_swit' value="1" onClick={() => handleSwt(swt.view)} defaultChecked={swt.view === '1' ? true : null} />
                                        <label className="form-check-label font-12 mt-1 pt-1 ps-2" >เปิดโปรโมชั่น</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-7 col-lg-7 ps-0">
                                <Form noValidate validated={validated} onSubmit={handleSubmit} className="">
                                    <div className='h-100per-modal bg-white px-4 pt-4'>
                                        <div className='text-default font-18 weight-500'>
                                            แก้ไขโปรโมชั่น
                                        </div>
                                        <div className='mt-1'>
                                            <Row className="mt-0">
                                                <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                                    <Form.Label className="font-12 text-gray mb-0">ชื่อโปรโมชั่น</Form.Label>
                                                    <Form.Control type="text" name="promotion" defaultValue={datapromo.promotion} className='font-14 input-custom' placeholder='xxxxx' required />
                                                </Form.Group>
                                            </Row>
                                            <div className='mt-2'>ชนิดส่วนลด</div>
                                            <div className="ex-radio d-inline-block">
                                                <input type="radio" name="type_reduction" value="0" onChange={() => setMethod3(0)} defaultChecked={method3 == 0 ? true : false} />
                                                <span className="radio_one mb-2 ps-4 text-gray font-12" style={{ width: "100%" }} >
                                                    ส่วนลดทั้งหมด
                                                </span>
                                            </div>
                                            <div className="ex-radio mt-2 d-inline-block  ms-3">
                                                <input type="radio" name="type_reduction" value="1" onChange={() => setMethod3(1)} defaultChecked={method3 == 1 ? true : false} />
                                                <span className="radio_one mb-2 ps-4 text-gray font-12" style={{ width: "100%" }} >
                                                    ส่วนลดค่าอาหาร
                                                </span>
                                            </div>
                                            <div className="ex-radio d-inline-block ms-3">
                                                <input type="radio" name="type_reduction" value="2" onChange={() => setMethod3(2)} defaultChecked={method3 == 2 ? true : false} />
                                                <span className="radio_one mb-2 ps-4 text-gray font-12" style={{ width: "100%" }} >
                                                    ส่วนลดค่าเครื่องดื่ม
                                                </span>
                                            </div>
                                            <div className='mt-2'>รูปแบบการลดราคา</div>
                                            <div className="ex-radio mt-2">
                                                <input type="radio" name="price_reduction" value="1" onChange={() => setMethod2(1)} defaultChecked={method2 == 1 ? true : false} />
                                                <span className="radio_one mb-2 ps-4 text-gray font-12" style={{ width: "100%" }} >
                                                    ลดท้ายบิล
                                                </span>
                                            </div>


                                            {/* <div className="ex-radio mb-0">
                                                <input type="radio" name="price_reduction" value="2" onChange={() => setMethod2(2)} defaultChecked={ method2 == 2 ? true : false }/>
                                                <span className="radio_one mb-2 ps-4 text-gray font-12" style={{ width: "100%" }} >
                                                    ลดท้ายเมนู
                                                </span>
                                            </div> */}

                                            <div className='mt-2'>วิธีการลด</div>
                                            <div className="ex-radio mt-2 d-inline-block">
                                                <input type="radio" name="method_reduction" value="1" onChange={() => setMethod(1)} defaultChecked={method == 1 ? true : false} />
                                                <span className="radio_one mb-2 ps-4 text-gray font-12" style={{ width: "100%" }} >
                                                    ลดเป็นเปอร์เซน
                                                </span>
                                            </div>
                                            <div className="ex-radio ms-3 d-inline-block">
                                                <input type="radio" name="method_reduction" value="2" onChange={() => setMethod(2)} defaultChecked={method == 2 ? true : false} />
                                                <span className="radio_one mb-2 ps-4 text-gray font-12" style={{ width: "100%" }} >
                                                    ลดเป็นจำนวนเงิน
                                                </span>
                                            </div>
                                            <Row className="align-items-end mt-3 mb-3">
                                                <Form.Group as={Col} md="6" className="position-relative pe-1" controlId="validationCustom01">
                                                    <Form.Label className="font-12 text-gray mb-0">ราคา</Form.Label>
                                                    <Form.Control type="text" name='price' defaultValue={datapromo.price} className='font-14 input-custom' placeholder='xxx' required />
                                                </Form.Group>
                                                <Form.Group as={Col} md="6" className="position-relative pe-1" controlId="validationCustom01">
                                                    <Form.Label className="mb-0">{method == 2 ? "บาท" : "%"}</Form.Label>
                                                </Form.Group>
                                            </Row>
                                            <div className='font-14'>*ส่วนลดจะถูกคำนวนพร้อมค่าอาหาร ไม่รวม Vat</div>
                                        </div>
                                    </div>
                                    <div className='text-center py-3 px-3 bg-gray'>
                                        <div className='row'>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <button type='submit' className="w-100 btn btn-submit text-white py-2 me-3">
                                                    แก้ไขโปรโมชั่น
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >

            <Modalcropbranch show={modalcrop.view} onHide={() => setModalcrop({ view: false })} id={modalcrop.id} getblob={getBlob} inputimg={modalcrop.inputImg} text="ภาพเมนู" />

        </>
    );
};

export default Modaladdproductpromotionadd;