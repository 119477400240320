import React, { useState } from 'react';
// import axios from 'axios';
import { useNavigate,Link } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";

import help from '../../assets/img/help.svg';
import chairgreen from '../../assets/img/chair-green.svg';
import chairorange from '../../assets/img/chair-orange.svg';
import chairred from '../../assets/img/chair-red.svg';

import Menuleft from "../../layout/pagesadmin/menuleft";
import Tabcontent from "../../pages/table/table_tab_content";

import { Form, Tab, Nav } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
// import { Form, Col, Row, InputGroup, Button } from "react-bootstrap";

const Tabledetail = () => {

    // let history = useNavigate();
    const { t } = useTranslation();

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

        } else {
            // event.preventDefault();
            // event.stopPropagation();

            // if(password.value === password2.value){
            //     alert('Demo yes');
            // }else{
            //     alert('Demo No');
            // } 
            // history("/questionnaire2");
        }

        setValidated(true);



    };

    return (
        <>
            <div className={!t('menu-a') ? "sidebar-mini sidebar-collapse" : ""} >
                <div className='wrapper-admin layout-fixed'>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="zone_all">
                        <nav className="main-header navbar bg-white shadow-sm" style={{ height: "60px" }}>
                            <Nav as="ul" variant="tabs" className="nav nav-tabs overflow-hidden ul-tabcustom">
                                <Nav.Item as="li">
                                    <Nav.Link eventKey="zone_all">ทั้งหมด</Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Nav.Link eventKey="zone_a">โซน A</Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Nav.Link eventKey="zone_b" className=''>โซน B</Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Nav.Link eventKey="zone_c" className=''>โซน C</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <ul className="navbar-nav ml-auto" style={{ marginTop: "-20px" }}>
                                <li className="">
                                    <div className='text-gray font-12'><img src={help} alt="" className="me-1" style={{ width: "14px" }} /> ขอความช่วยเหลือ</div>
                                </li>
                            </ul>
                        </nav>

                        <Menuleft type="table" />

                        <div className="content-wrapper">
                            <section className="content scroll-custom">
                                <div className="row">
                                    <div className="col-12 col-md-8 col-lg-8 ">
                                        <Tabcontent />
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4 ps-0">
                                        <Form noValidate validated={validated} onSubmit={handleSubmit} className="">
                                            <div className="bg-white rounded-3 ms-2 mt-3 h-100per-right position-relative">
                                                <div className='px-4 text-start '>
                                                    <div className='py-3 font-18'><b>รายละเอียด</b></div>
                                                    <div className='position-relative mt-3 text-start'>
                                                        <div className=' font-18'><b>โซน A : A05</b></div>
                                                        <div className='font-14 mt-2'>จำนวนที่นั่ง : 3</div>
                                                        <div className='font-14 mt-1'>วัน - เวลา : 29/11/2021 - 12:00</div>
                                                        <div className='font-14 mt-1'>ชื่อลูกค้า : คุณโบ</div>
                                                        <div className='font-14 mt-1'>เบอร์โทร : 062-356-4198</div>
                                                        <div className='font-14 mt-1'>รายละเอียดเพิ่มเติม : ขอความเป็นส่วนตัว
                                                            มีเก้าอี้เสริมสำหรับเด็ก</div>
                                                    </div>
                                                    <div className='mt-5'>
                                                        <div className='font-18'><b>สถานะ</b></div>
                                                        <div className='font-14 mt-1'>ทานที่ร้าน</div>
                                                    </div>
                                                    <div className='mt-4'>
                                                        <div className='font-18'><b>เวลา</b></div>
                                                        <div className='font-14 mt-1'>15:30 - ปัจจุบัน</div>
                                                    </div>
                                                    <div className='mt-4'>
                                                        <Link to="/" transition="default-fade" className="w-50 btn btn-border-blue py-2">
                                                            ดูรายการที่สั่ง
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w-100 ps-2 mt-2' >
                                                <Link to="/table/manage" transition="default-fade" className="w-100 btn btn-submit text-white py-2">
                                                    มาแล้ว
                                                </Link>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </section>

                        </div >
                    </Tab.Container >
                </div >
            </div>
        </>
    );
};

export default Tabledetail;