import React, { useState, useCallback, useEffect, useRef } from 'react';
import ReactToPrint from 'react-to-print';

import ComponentToPrint from '../../components/ComponentToPrint';
// import axios from 'axios';
import { useNavigate, Link } from "react-router-dom";
import { compareAsc, format } from 'date-fns';

import { Form, Tab, Nav, DropdownButton, Dropdown } from "react-bootstrap";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import AuthService from "../../components/services_member/auth.service";
import PostService from "../../components/services_member/post.service";

import Menuleft from "../../layout/pagesadmin/menuleft";

import help from '../../assets/img/help.svg';
import btnremove from '../../assets/img/btn-remove.svg';
import calendar from '../../assets/img/calendar.svg';
import memedit from '../../assets/img/mem_edit.svg';

import Modalremoveorder from "../../layout/modal/modalRemoveorder";
import Modalmenudetail from "../../layout/modal/modalMenuorder";
import Modalcancleproduct from "../../layout/modal/modalCancleproduct";
import Moment from 'moment';
import { useTranslation } from 'react-i18next';
const Reportreceipt = () => {
    const { t } = useTranslation();
    const MySwal = withReactContent(Swal)

    localStorage.removeItem("table_order");

    let history = useNavigate();
    const user = AuthService.getCurrentUser();
    const branch = AuthService.getCurrentBranch();

    if (!user) {
        history("/");
    } else {
        if (!branch) {
            history("/branch");
        }
    }

    const [dataorder, setDataorder] = useState([]);
    const [dataorderlist, setDataorderlist] = useState([]);
    const [dataorderlistprint, setDataorderlistprint] = useState([]);
    const [datapromotion, setDatapromotion] = useState([]);

    const [datareceipt, setDatareceipt] = useState();
    const [datapayment, setDatapayment] = useState();

    const [dateday, setDateday] = useState(format(new Date(), 'dd/MM/yyyy'));

    const [dataactive, setDataactive] = useState({ order: '' });

    const [orderview, setOrderview] = useState();
    const [modalremoveorder, setModalremoveorder] = useState({ view: false, id: 0, no: 0 });

    const fetchData = useCallback(async (dd) => {

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        PostService.getReceiptall(branch.id, dd).then(
            (response) => {
                if (response.data.status === 200) {

                    // console.log(response.data)
                    setDataorder(response.data.reserve);
                    setDataorderlist(response.data.reserve);
                    setDatareceipt(response.data.receipt)
                    // setDataproduct(response.data.data_product);
                    // setDatapromotion(response.data.promotion);
                    // setDatapayment(response.data.payment);
                } else {
                    setDataorder([]);
                    setDataorderlist([]);
                    // setDatapromotion([]);
                    setDatareceipt();
                    // setDatapayment();
                }
            },
            (error) => {
                console.log(error);
            }
        )
    });

    useEffect(() => {
        fetchData(dateday);
    }, []);



    const dateoption = {
        locale: {
            format: 'DD/MM/YYYY'
        },
        startDate: dateday,
        // maxDate: dateday,
        singleDatePicker: true,
        autoApply: true,
    };

    const handledateday = (event) => {
        fetchData(event.target.value)
        setDateday(event.target.value)
        setOrderview()
    }

    const handlerefresh = (id) => {
        fetchData(dateday);
    }

    const selectlist = (id) => {
        setDataactive({ order: id })

        PostService.getReceiptone(branch.id, dateday, id).then(
            (response) => {
                if (response.data.status === 200) {

                    // setDataorder(response.data.reserve);
                    setDataorderlistprint(response.data.reserve);
                    // setDatareceipt(response.data.receipt)

                    // setDataactive({ order: id })
                    // setOrderview(response.data.reserve[0])
                    // setDatapromotion(response.data.reserve[0].promotion_data ? JSON.parse(response.data.reserve[0].promotion_data) : null);
                    // setDatapayment(response.data.reserve[0].payment_data ? JSON.parse(response.data.reserve[0].payment_data) : null);
                }
            },
            (error) => {
                console.log(error);
            }
        )

        setOrderview(dataorder.filter(vls => vls.id === id)[0])

        // console.log(JSON.parse(dataorder.filter(vls => vls.id === id)[0].promotion_data))
        setDatapromotion(dataorder.filter(vls => vls.id === id)[0].promotion_data ? JSON.parse(dataorder.filter(vls => vls.id === id)[0].promotion_data) : null);
        setDatapayment(dataorder.filter(vls => vls.id === id)[0].payment_data ? JSON.parse(dataorder.filter(vls => vls.id === id)[0].payment_data) : null);
        // JSON.parse(val.choice)
    }

    const clickonhide = (e) => {
        setModalremoveorder({ view: false, id: 0, no: 0 })
        fetchData(dateday);
        setOrderview()
    }

    const handleTablesubmit = () => {

        // localStorage.setItem('table_order', JSON.stringify({ id: orderview.zone, zone: orderview.zone_name, table_id: orderview.table_reserve, table: orderview.table_name, reserve: orderview.id, date: dateday }));
        // history("/order/ordermenu");
    }

    const [kitchendata, setKitchendata] = useState({ id: '0', label: 'ทั้งหมด' });
    const [showDropdown, setShowDropdown] = useState(false);
    const [kitchendata2, setKitchendata2] = useState({ id: '0', label: 'ทั้งหมด' });
    const [showDropdown2, setShowDropdown2] = useState(false);

    const [modalmenudetail, setModalmenudetail] = useState({ view: false, id: 0, data: [] });

    const handlemodalordertable = (id) => {
        // alert(id)
        if (id === '0') {
            setKitchendata({ id: id, label: 'ทั้งหมด' })
            if (kitchendata2.id == 0) {
                setDataorderlist(dataorder)
            } else if (kitchendata2.id == 3) {
                setDataorderlist(dataorder.filter(vls => vls.prasee === 3))
            } else if (kitchendata2.id == 2) {
                setDataorderlist(dataorder.filter(vls => vls.prasee === 2))
            } else {
                setDataorderlist(dataorder.filter(vls => vls.prasee === 1))
            }
        } else if (id === '1') {
            setKitchendata({ id: id, label: 'ทานที่ร้าน' })

            if (kitchendata2.id == 0) {
                setDataorderlist(dataorder.filter(vls => vls.zone !== '0'))
            } else if (kitchendata2.id == 3) {
                setDataorderlist((dataorder.filter(vls => vls.zone !== '0')).filter(vls => vls.prasee === 3))
            } else if (kitchendata2.id == 2) {
                setDataorderlist((dataorder.filter(vls => vls.zone !== '0')).filter(vls => vls.prasee === 2))
            } else {
                setDataorderlist((dataorder.filter(vls => vls.zone !== '0')).filter(vls => vls.prasee === 1))
            }

        } else if (id === '2') {
            setKitchendata({ id: id, label: 'Drive Thru' })
            if (kitchendata2.id == 0) {
                setDataorderlist(dataorder.filter(vls => vls.zone === '0'))
            } else if (kitchendata2.id == 3) {
                setDataorderlist((dataorder.filter(vls => vls.zone === '0')).filter(vls => vls.prasee === 3))
            } else if (kitchendata2.id == 2) {
                setDataorderlist((dataorder.filter(vls => vls.zone === '0')).filter(vls => vls.prasee === 2))
            } else {
                setDataorderlist((dataorder.filter(vls => vls.zone === '0')).filter(vls => vls.prasee === 1))
            }
        }
        setOrderview()
    }
    const handlemodalordertable2 = (id) => {

        if (id === '0') {
            setKitchendata2({ id: id, label: 'ทั้งหมด' })
            // setDataorderlist(dataorder)
            if (kitchendata.id == 0) {
                setDataorderlist(dataorder)
            } else if (kitchendata.id == 1) {
                setDataorderlist((dataorder.filter(vls => vls.zone !== '0')))
            } else {
                setDataorderlist((dataorder.filter(vls => vls.zone === '0')))
            }
        } else if (id === '1') {
            setKitchendata2({ id: id, label: 'ใบกำกับภาษี' })
            // setDataorderlist(dataorder.filter(vls => vls.prasee === 0))
            if (kitchendata.id == 0) {
                setDataorderlist(dataorder.filter(vls => vls.prasee === 1))
            } else if (kitchendata.id == 1) {
                setDataorderlist((dataorder.filter(vls => vls.zone !== '0')).filter(vls => vls.prasee === 1))
            } else {
                setDataorderlist((dataorder.filter(vls => vls.zone === '0')).filter(vls => vls.prasee === 1))
            }
        } else if (id === '2') {
            setKitchendata2({ id: id, label: 'ใบกำกับภาษีอย่างย่อ' })
            // setDataorderlist(dataorder.filter(vls => vls.prasee === 1))
            if (kitchendata.id == 0) {
                setDataorderlist(dataorder.filter(vls => vls.prasee === 2))
            } else if (kitchendata.id == 1) {
                setDataorderlist((dataorder.filter(vls => vls.zone !== '0')).filter(vls => vls.prasee === 2))
            } else {
                setDataorderlist((dataorder.filter(vls => vls.zone === '0')).filter(vls => vls.prasee === 2))
            }
        } else if (id === '3') {
            setKitchendata2({ id: id, label: 'บิลเงินสด' })
            // setDataorderlist(dataorder.filter(vls => vls.prasee === 1))
            if (kitchendata.id == 0) {
                setDataorderlist(dataorder.filter(vls => vls.prasee === 3))
            } else if (kitchendata.id == 1) {
                setDataorderlist((dataorder.filter(vls => vls.zone !== '0')).filter(vls => vls.prasee === 3))
            } else {
                setDataorderlist((dataorder.filter(vls => vls.zone === '0')).filter(vls => vls.prasee === 3))
            }
        }
        setOrderview()
    }

    // console.log(dataorderlist)

    const toggleDropdown = () => {
        if (showDropdown) {
            setShowDropdown(false);
        } else {
            setShowDropdown(true);
        }
    }

    const toggleDropdown2 = () => {
        if (showDropdown2) {
            setShowDropdown2(false);
        } else {
            setShowDropdown2(true);
        }
    }

    const sumAll = () => {

        var sum = 0;
        var total = 0;
        orderview.data_order.filter(valb => valb.status !== '5').map((val, index) => (
            <>
                {sum = sum + (parseFloat(val.price) * val.qty)}

                {val.choice ? (JSON.parse(val.choice)).map((vall, indel) =>
                    total = total + (parseFloat(vall.more_price * val.qty))
                ) : null}

            </>
        )
        )

        return parseFloat(sum + total)
    }

    const sumAllFood = () => {
        var sum = 0;
        var total = 0;
        if (orderview) {
            orderview.data_order.filter(valb => valb.status !== '5').filter(valb => valb.kitchen_id !== '5').map((val, index) => (
                <>
                    {sum = sum + (parseFloat(val.price) * val.qty)}

                    {val.choice ? (JSON.parse(val.choice)).map((vall, indel) =>
                        total = total + (parseFloat(vall.more_price* val.qty))
                    ) : null}
                </>
            )
            )
            return parseFloat(sum + total)
        }
    }

    const sumAllDrink = () => {
        var sum = 0;
        var total = 0;
        if (orderview) {
            orderview.data_order.filter(valb => valb.status !== '5').filter(valb => valb.kitchen_id === '5').map((val, index) => (
                <>
                    {sum = sum + (parseFloat(val.price) * val.qty)}

                    {val.choice ? (JSON.parse(val.choice)).map((vall, indel) =>
                        total = total + (parseFloat(vall.more_price* val.qty))
                    ) : null}
                </>
            )
            )
            return parseFloat(sum + total)
        }
    }

    // const sumPromotion = (sumall) => {
    //     if (orderview.promotion_id !== '0') {
    //         if (orderview.promotion_data) {
    //             if (JSON.parse(orderview.promotion_data).method_reduction === '1') {
    //                 return parseFloat(sumall * JSON.parse(orderview.promotion_data).price / 100).toFixed(2)
    //             } else {
    //                 return parseFloat(JSON.parse(orderview.promotion_data).price).toFixed(2)
    //             }
    //         } else {
    //             return "00.00";
    //         }
    //     } else {
    //         return "00.00";
    //     }
    // }

    const sumPromotion = (sumall) => {

        if (orderview.promotion_id !== '0') {
            if (orderview.promotion_data) {
                if (JSON.parse(orderview.promotion_data).method_reduction === '1') {
                    if (JSON.parse(orderview.promotion_data).type_reduction) {
                        if (JSON.parse(orderview.promotion_data).type_reduction === '0') {
                            return parseFloat(sumall * JSON.parse(orderview.promotion_data).price / 100).toFixed(2)                        
                        }else if(JSON.parse(orderview.promotion_data).type_reduction === '1'){
                            return parseFloat(sumAllFood() * JSON.parse(orderview.promotion_data).price / 100).toFixed(2)                        
                        }else{
                            return parseFloat(sumAllDrink() * JSON.parse(orderview.promotion_data).price / 100).toFixed(2)
                        }
                    }else{
                        return parseFloat(sumall * JSON.parse(orderview.promotion_data).price / 100).toFixed(2)       
                    }                    
                } else {
                    if (JSON.parse(orderview.promotion_data).type_reduction) {
                        if (JSON.parse(orderview.promotion_data).type_reduction === '0') {
                            return parseFloat(JSON.parse(orderview.promotion_data).price)
                        }else if(JSON.parse(orderview.promotion_data).type_reduction === '1'){
                            if(sumAllFood() > 0){
                                if(sumAllFood() < parseFloat(JSON.parse(orderview.promotion_data).price)){
                                    return sumAllFood()
                                }else{
                                    return parseFloat(JSON.parse(orderview.promotion_data).price)
                                }
                            }else{
                                return 0;
                            }
                        }else{
                            if(sumAllDrink() > 0){
                                if(sumAllDrink() < parseFloat(JSON.parse(orderview.promotion_data).price)){
                                    return sumAllDrink()
                                }else{
                                    return parseFloat(JSON.parse(orderview.promotion_data).price)
                                }
                            }else{
                                return 0;
                            }
                        }
                    }else{
                        return parseFloat(JSON.parse(orderview.promotion_data).price)
                    }
                }
            } else {
                return "00";
            }
        } else {
            return "00";
        }
        // return sumAllFood();
    }

    const sumTotal = (sum, sumpromotion) => {

        var sumservice = 0;
        var sumvat = 0;
        var sumall = sum - sumpromotion;

        // if (datareserve.payment_data) {

        //     let datapayment = JSON.parse(datareserve.payment_data)

        if (datapayment) {
            if (datapayment.service_charge === '1') {
                var sumservice = parseFloat(sumall * (datapayment.service_rate) / 100).toFixed(2)
            }
            if (datapayment.vat === '1') {
                var sumvat = parseFloat(sumall * (datapayment.vat_rate) / 100).toFixed(2)
            }
        }
        // }
        return parseFloat((sum - parseFloat(sumpromotion)) + parseFloat(sumservice) + parseFloat(sumvat)).toFixed(2)

    }

    const sumMath = (price) => {
        // return parseFloat(price)
        // console.log(datapayment.rounding)
        if (datapayment) {
            if (datapayment.rounding === '0') {
                return parseFloat(price)
            } else if (datapayment.rounding === '1') {
                return parseFloat(Math.round(price))
            } else if (datapayment.rounding === '2') {
                return parseFloat(Math.ceil(price))
            } else {
                return parseFloat(Math.floor(price))
            }
        }
    }

    const componentRef = useRef(null);
    // const handlePrint = useReactToPrint({
    //     content: () => componentRef.current,
    // });
    const printSuccess = (e) => {
        // alert(e)
    }

    const [modalcancleproduct, setModalcancleproduct] = useState({ view: false, id: 0 });
    const handlemodal = (id) => {
        // dataorder.filter(vls => vls.id === dataactive.order)[0].data_order
        // console.log(dataorder.filter(vls => vls.id === dataactive.order)[0])
        // dataorder.filter(vls => vls.id === dataactive.order)[0].data_order
        if (dataorder.filter(vls => vls.id === dataactive.order)[0].data_order) {
            if (dataorder.filter(vls => vls.id === dataactive.order)[0].data_order.length) {

                const dataorder_no = dataorder.filter(vls => vls.id === dataactive.order)[0].no;
                const dataorder_zone = dataorder.filter(vls => vls.id === dataactive.order)[0].zone_name;
                const dataorder_table = dataorder.filter(vls => vls.id === dataactive.order)[0].table_name;
                const dataproduct_name = (dataorder.filter(vls => vls.id === dataactive.order)[0].data_order.filter(valb => valb.id == id))[0].name_product;
                const dataproduct_date = (dataorder.filter(vls => vls.id === dataactive.order)[0].data_order.filter(valb => valb.id == id))[0].date_kitchen;
                const dataproduct_id = (dataorder.filter(vls => vls.id === dataactive.order)[0].data_order.filter(valb => valb.id == id))[0].id;
                const dataproduct_qty = (dataorder.filter(vls => vls.id === dataactive.order)[0].data_order.filter(valb => valb.id == id))[0].qty;

                setModalcancleproduct({ view: true, type: "cancle", dataid: { order_no: dataorder_no, order_zone: dataorder_zone, order_table: dataorder_table, product: dataproduct_name, product_id: dataproduct_id, date_time: dataproduct_date, qty: dataproduct_qty } })
            }
        }
    }

    const handlemodalEdit = (id) => {
        // console.log(id)
        if (dataorder.filter(vls => vls.id === dataactive.order)[0].data_order) {
            if (dataorder.filter(vls => vls.id === dataactive.order)[0].data_order.length) {
                setModalmenudetail({ view: true, type: "edit", id: id, dataone: (dataorder.filter(vls => vls.id === dataactive.order)[0].data_order.filter(valb => valb.id == id)) })
            }
        }
    }

    const clickrefresh = () => {
        setModalcancleproduct({ view: false, dataid: [] });
        setModalmenudetail({ view: false, dataid: [] });
        // setSelectdata();
        handlerefresh();
        clickonhide();
    }

    return (
        <>
<div className={!t('menu-a') ? "sidebar-mini sidebar-collapse" : ""} >
            <div className='wrapper-admin layout-fixed'>
                <Tab.Container id="left-tabs-example" defaultActiveKey="food_all">
                    <nav className="main-header navbar bg-white shadow-sm" style={{ height: "60px" }}>
                        <Nav as="ul" className="nav overflow-hidden " style={{ marginTop: "-4px" }}>
                            <li>
                                <div className="bg-white rounded-3 ms-2 position-relative">
                                    <div className=''>
                                        <div className='text-start py-3 weight-500 d-inline-block me-3'>เลือกวันที่</div>
                                        <div className='position-relative d-inline-block'>
                                            <Form.Group controlId="dob">
                                                <DateRangePicker initialSettings={dateoption} onApply={(e) => (handledateday(e))}>
                                                    <input type="text" className="form-control px-2 py-2" name="dateday" />
                                                </DateRangePicker >
                                                <div className='position-absolute' style={{ top: "10px", right: "12px" }}>
                                                    <img src={calendar} alt="" className="" style={{ width: "17px" }} />
                                                </div>
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </Nav>
                        <ul className="navbar-nav ml-auto" style={{ marginTop: "-20px" }}>
                            <li className="">
                                <div className='text-gray font-12'>
                                    <img src={help} alt="" className="me-1" style={{ width: "14px" }} /> ขอความช่วยเหลือ
                                </div>
                            </li>
                        </ul>
                    </nav>
                    <Menuleft type="receipt" />
                    <div className="content-wrapper">
                        <section className="content scroll-custom">
                            <div className="row">
                                <div className="col-12 col-md-8 col-lg-8 ">
                                    <div className='w-100 mb-2 mt-3 bg-blue'>
                                        <div className="row ">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="w-100 text-white py-2">
                                                    <DropdownButton show={showDropdown} onClick={() => toggleDropdown()} id="dropdown-item-button" className='w-100' title={kitchendata.label}>
                                                        <Dropdown onClick={(e) => handlemodalordertable('0')} className='px-3 py-1 cursor' >ทั้งหมด</Dropdown>
                                                        <Dropdown onClick={(e) => handlemodalordertable('1')} className='px-3 py-1 cursor' >ทานที่ร้าน</Dropdown>
                                                        <Dropdown onClick={(e) => handlemodalordertable('2')} className='px-3 py-1 cursor' >Drive Thru</Dropdown>
                                                    </DropdownButton>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="w-100 text-white py-2">
                                                    <DropdownButton show={showDropdown2} onClick={() => toggleDropdown2()} id="dropdown-item-button" className='w-100' title={kitchendata2.label}>
                                                        <Dropdown onClick={(e) => handlemodalordertable2('0')} className='px-3 py-1 cursor' >ทั้งหมด</Dropdown>
                                                        <Dropdown onClick={(e) => handlemodalordertable2('3')} className='px-3 py-1 cursor' >บิลเงินสด</Dropdown>
                                                        <Dropdown onClick={(e) => handlemodalordertable2('2')} className='px-3 py-1 cursor' >ใบกำกับภาษีอย่างย่อ</Dropdown>
                                                        <Dropdown onClick={(e) => handlemodalordertable2('1')} className='px-3 py-1 cursor' >ใบกำกับภาษี</Dropdown>
                                                    </DropdownButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-white rounded-3 h-100per-top-b">
                                        <div className='text-start'>
                                            {dataorderlist.length ? dataorderlist.sort((a, b) => b.id - a.id).map((val, ind) => (
                                                <div className='border_hr' key={ind}>
                                                    {val.data_order.length ?
                                                        <span onClick={() => selectlist(val.id)} className={dataactive.order === val.id ? "a-list-table py-4 px-4 cursor active" : "a-list-table py-4 px-4 cursor"} >
                                                            <div className="row" >
                                                                <div className="col-12 col-md-12 col-lg-12 text-start">
                                                                    <div className='float-end'>
                                                                        <Link to={"/payment/payment/" + val.id} className='cursor-pointer'>
                                                                            <img src={memedit} />
                                                                        </Link>
                                                                        <button className='btn' onClick={() => setModalremoveorder({ view: true, id: val.id, no: val.no })}>
                                                                            <img src={btnremove} className="remove-order" />
                                                                        </button>
                                                                    </div>
                                                                    <div className='weight-500 mt-2 font-16'>
                                                                        {val.zone === '0' ? "คิวที่ " + val.table_reserve : val.zone_name + " : " + val.table_name}
                                                                        <span className='mx-2'>-</span> Order #{val.no}</div>
                                                                    <div className='weight-400 mt-1 text-gray font-14'>
                                                                        {val.date_reserve ? <>
                                                                            วันที่ {format(new Date(val.date_reserve), 'dd/MM/yyyy')} - {val.time_update}
                                                                        </>
                                                                            : null}
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </span>
                                                        : null}
                                                </div>
                                            )) : <div className='text-gray font-14 text-center p-5'>- ไม่มีออเดอร์ -</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 col-lg-4 ps-0">
                                    <div className='w-100 mt-3 mb-2'>
                                        <div className="bg-white rounded-3 ms-2 mt-2 h-100per-right position-relative">
                                            <div className='text-start p-3'>
                                                {orderview ?
                                                    <div>
                                                        <div>
                                                            <img src={process.env.REACT_APP_API_URL + "/uploads/receipt/" + datareceipt.logo} alt="" className="w-25 pe-4" />
                                                            <span className='font-16 float-end weight-500'>
                                                                {orderview.zone === '0' ? "Drive Thru : คิวที่ " + orderview.table_reserve : orderview.zone_name + " : " + orderview.table_name}
                                                            </span>
                                                        </div>
                                                        <div className='weight-500 mt-2 font-14'>Order #{orderview.no}</div>
                                                        <div className='weight-400 mt-1 text-gray font-14'>
                                                            {orderview.date_reserve ? <>
                                                                {format(new Date(orderview.date_reserve), 'dd/MM/yyyy')} - {orderview.time_update}
                                                            </>
                                                                : null}
                                                        </div>


                                                        {orderview.bill_data ? JSON.parse(orderview.bill_data).data_prasree ? <>
                                                            {orderview.prasee === 1 ? <>

                                                                <div className='mt-4 font-12' style={{ lineHeight: "1.5" }}>
                                                                    {datareceipt.company} <br />
                                                                    เลขประจําตัวผู้เสียภาษี : {datareceipt.id_num} <br />
                                                                    ที่อยู่ : {datareceipt.address} <br />
                                                                </div>
                                                                <hr className='hr-custom' />
                                                                <div className='mt-3 font-12' style={{ lineHeight: "1.5" }}>
                                                                    ใบเสร็จรับเงิน/ใบกำกับภาษี <span className='float-end font-16 weight-500'>ต้นฉบับ</span>
                                                                    <br />
                                                                    สำหรับลูกค้า :  #RT-{Moment(orderview.date_reserve).format('yyyyMMDD')}{orderview.number_bill}<br />
                                                                    <br />
                                                                    ชื่อลูกค้า : {JSON.parse(orderview.bill_data).data_prasree.name_vat} <br />
                                                                    ที่อยู่ : {JSON.parse(orderview.bill_data).data_prasree.add_vat} <br />
                                                                    เลขประจําตัวผู้เสียภาษี : {JSON.parse(orderview.bill_data).data_prasree.number_vat} <br />
                                                                </div>
                                                            </>
                                                                : orderview.prasee === 3 ?
                                                                    <div className='mt-3 font-12' style={{ lineHeight: "1.5" }}>
                                                                        สำหรับลูกค้า :  #RC-{Moment(orderview.date_reserve).format('yyyyMMDD')}{orderview.number_bill}<br />
                                                                    </div>
                                                                    :
                                                                    <>
                                                                        <div className='mt-4 font-12' style={{ lineHeight: "1.5" }}>
                                                                            {datareceipt.company} <br />
                                                                            เลขประจําตัวผู้เสียภาษี : {datareceipt.id_num} <br />
                                                                            ที่อยู่ : {datareceipt.address} <br />
                                                                        </div>
                                                                        <div className='mt-3 font-12' style={{ lineHeight: "1.5" }}>
                                                                            ใบกำกับภาษีอย่างย่อ <span className='float-end font-16 weight-500'>ต้นฉบับ</span>
                                                                            <br />
                                                                            สำหรับลูกค้า :  #R-{Moment(orderview.date_reserve).format('yyyyMMDD')}{orderview.number_bill}<br />
                                                                        </div>
                                                                    </>
                                                            }

                                                            <hr className='hr-custom' />
                                                            {/* {console.log(orderview.bill_data)} */}
                                                        </>
                                                            : null : null
                                                        }

                                                        <div className='weight-500 mt-1'>รายการอาหาร</div>
                                                        <div className='mt-2 mb-4'>

                                                            {
                                                                dataorderlist.filter(vls => vls.id === dataactive.order)[0].data_order ? dataorderlist.filter(vls => vls.id === dataactive.order)[0].data_order.length ? (dataorderlist.filter(vls => vls.id === dataactive.order)[0].data_order).filter(valb => valb.status !== '5').sort((a, b) => a.id - b.id).map((value, index) => (
                                                                    <div className='mb-2' key={index}>
                                                                        <div className='font-14 position-relative cursor-pointer' style={{ paddingRight: "100px" }}>
                                                                            <span onClick={() => handlemodalEdit(value.id)}>{value.name_product}<span className='font-12 ms-3'>X</span> {value.qty}</span>
                                                                            <span className='position-absolute font-13' style={{ top: "0", right: "50px" }}>
                                                                                {parseFloat(value.price * value.qty).toFixed(2)}
                                                                            </span>
                                                                            <span className='position-absolute' style={{ top: "-3px", right: "0" }}>
                                                                                <span className='btn-lob rounded font-12 p-1' onClick={() => handlemodal(value.id)} style={{ verticalAlign: "middle", width: "44px", height: "21px" }}>
                                                                                    ยกเลิก
                                                                                </span>
                                                                            </span>
                                                                        </div>
                                                                        {value.choice ? (JSON.parse(value.choice)).map((val, inde) => (
                                                                            <div className='ms-3 mt-1' key={inde}>
                                                                                <div className='font-13 pe-5 position-relative'>
                                                                                    - {val.more_name}
                                                                                    <span className='position-absolute ' style={{ top: "0", right: "0" }}>{parseFloat(val.more_price * value.qty).toFixed(2)}</span>

                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                            : null}
                                                                    </div>
                                                                ))
                                                                    : <div className='text-center my-4 font-12 weight-300 text-gray'>- ยังไม่มีรายการ - </div> : null}
                                                        </div>
                                                        <hr className='hr-custom' />
                                                        {dataorderlist.filter(vls => vls.id === dataactive.order)[0].data_order ? dataorderlist.filter(vls => vls.id === dataactive.order)[0].data_order.length ? (dataorderlist.filter(vls => vls.id === dataactive.order)[0].data_order).filter(valb => valb.status === '5').length ?
                                                            <>
                                                                <div className='weight-500 mt-1'>รายการยกเลิก</div>
                                                                <div className='mt-2 mb-4'>

                                                                    {
                                                                        dataorderlist.filter(vls => vls.id === dataactive.order)[0].data_order ? dataorderlist.filter(vls => vls.id === dataactive.order)[0].data_order.length ? (dataorderlist.filter(vls => vls.id === dataactive.order)[0].data_order).filter(valb => valb.status === '5').sort((a, b) => a.id - b.id).map((value, index) => (
                                                                            <div className='mb-2' key={index}>
                                                                                <div className='font-14 pe-5 position-relative'>
                                                                                    <span>{value.name_product}<span className='font-12 ms-3'>X</span> {value.qty}</span>

                                                                                    <span className='position-absolute font-13' style={{ top: "0", right: "0" }}>{parseFloat(value.price * value.qty).toFixed(2)}</span>
                                                                                </div>
                                                                                {value.choice ? (JSON.parse(value.choice)).map((val, inde) => (
                                                                                    <div className='ms-3 mt-1' key={inde}>
                                                                                        <div className='font-13 pe-5 position-relative'>
                                                                                            - {val.more_name}
                                                                                            <span className='position-absolute ' style={{ top: "0", right: "0" }}>{parseFloat(val.more_price * value.qty).toFixed(2)}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                                    : null}
                                                                            </div>
                                                                        ))
                                                                            : <div className='text-center my-4 font-12 weight-300 text-gray'>- ยังไม่มีรายการ - </div> : null}
                                                                </div>
                                                                <hr className='hr-custom' />
                                                            </>
                                                            : null : null : null}

                                                        <div className='weight-500 mt-1'>โปรโมชั่น</div>
                                                        <div className='mt-2 mb-4'>
                                                            {orderview.promotion_id !== '0' ? (
                                                                <div className='mb-2 font-14'>
                                                                    <span className=''>{datapromotion.promotion}</span>
                                                                    <span className='float-end'>
                                                                        {datapromotion.method_reduction === '1' ?
                                                                            "-" + datapromotion.price + "%"
                                                                            : parseFloat(datapromotion.price).toFixed(2)
                                                                        }
                                                                    </span>
                                                                </div>
                                                            ) : <div>-</div>}
                                                        </div>

                                                        <div className='weight-500 mt-1'>
                                                            ราคาอาหาร
                                                            <span className='float-end'>{(sumAll()).toLocaleString('en-US', { maximumFractionDigits: 2 })}</span>
                                                        </div>
                                                        <div className='mt-1 mb-4'>
                                                            {datapayment ? <>
                                                                {datapayment.service_charge === '1' ?
                                                                    <div className='mb-1 font-14'>
                                                                        <span className=''>ค่าบริการ ({datapayment.service_rate}%)</span>
                                                                        <span className='float-end'>{parseFloat((sumAll() - (parseFloat(sumPromotion(sumAll())) + (orderview.totalCut ? parseFloat(orderview.totalCut) : 0))) * (datapayment.service_rate) / 100).toFixed(2)}</span>
                                                                    </div>
                                                                    : null}
                                                                {datapayment.vat === '1' ?
                                                                    <div className='mb-1 font-14'>
                                                                        <span className=''>Vat ({datapayment.vat_rate}%)</span>
                                                                        <span className='float-end'>
                                                                            {parseFloat((sumAll() - (parseFloat(sumPromotion(sumAll())) + (orderview.totalCut ? parseFloat(orderview.totalCut) : 0))) * (datapayment.vat_rate) / 100).toFixed(2)}

                                                                        </span>
                                                                    </div>
                                                                    : null}

                                                            </> : null}
                                                            <div className='mb-1 font-14'>
                                                                <span className=''>ส่วนลดโปรโมชั่น</span>
                                                                <span className='float-end'><span>- </span>
                                                                    {sumPromotion(sumAll())}
                                                                </span>
                                                            </div>
                                                            <div className='mb-1 font-14'>
                                                                <span className=''>ส่วนลดอื่นๆ</span>
                                                                <span className='float-end'><span>- </span>
                                                                    {(orderview.totalCut ? parseFloat(orderview.totalCut) : 0)}
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className='mt-1 font-18'>
                                                            {/* <b>ยอดชำระ  <span className='float-end'>{sumMath(sumTotal(parseFloat(sumAll()), (parseFloat(sumPromotion(sumAll())) + (orderview.totalCut ? parseFloat(orderview.totalCut) : 0)))).toLocaleString()}</span></b> */}
                                                            <b>ยอดชำระ  <span className='float-end'>{sumMath(sumTotal(parseFloat(sumAll()), (parseFloat(sumPromotion(sumAll())) + (orderview.totalCut ? parseFloat(orderview.totalCut) : 0)))).toLocaleString()}</span></b>
                                                        </div>
                                                    </div>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className='w-100 ps-2 mt-1'>
                                            <div className='row'>

                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <div>
                                                        <ReactToPrint
                                                            trigger={() => <button disabled={orderview ? false : true} className="w-100 btn btn-blue text-white py-2 mt-2 me-3">พิมพ์ใบเสร็จ</button>}
                                                            content={() => componentRef.current}
                                                            documenttTitle='name document'
                                                            onAfterPrint={() => printSuccess('yes')}
                                                        />
                                                        {/* <ComponentToPrint ref={componentRef} /> */}
                                                        {/* <button onClick={handlePrint}>Print this out!</button> */}

                                                        {orderview ?
                                                            <div className='position-absolute d-none'>
                                                                <div className="ticket" ref={componentRef} >
                                                                    <div className="" />
                                                                    <ComponentToPrint
                                                                        overview={orderview}
                                                                        datareceipt={datareceipt}
                                                                        datapromotion={datapromotion} dataactive={dataactive}
                                                                        dataorderlist={dataorderlistprint}
                                                                        datapayment={datapayment}
                                                                        sumAll={sumAll()}
                                                                        sumPromotion={sumPromotion(sumAll())}
                                                                        sumTotal={sumTotal(parseFloat(sumAll()), (parseFloat(sumPromotion(sumAll())) + (orderview.totalCut ? parseFloat(orderview.totalCut) : 0)))}
                                                                        sumMath={sumMath(sumTotal(parseFloat(sumAll()), (parseFloat(sumPromotion(sumAll())) + (orderview.totalCut ? parseFloat(orderview.totalCut) : 0))))}
                                                                        sumCut={(orderview.totalCut ? parseFloat(orderview.totalCut) : 0)}
                                                                        type="receipt" />
                                                                </div>
                                                            </div>
                                                            : null}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </Tab.Container>
            </div>
            </div>
            {modalremoveorder.view ?
                <Modalremoveorder show={modalremoveorder.view} onHide={clickonhide} id={modalremoveorder.id} no={modalremoveorder.no} />
                : null}

            {modalcancleproduct.view ?
                <Modalcancleproduct show={modalcancleproduct.view} onHide={clickrefresh} type={modalcancleproduct.type} data={modalcancleproduct.dataid} />
                : null}

            {modalmenudetail.view ?
                <Modalmenudetail show={modalmenudetail.view} onHide={clickrefresh} id={modalmenudetail.id} dataone={modalmenudetail.dataone} type={modalmenudetail.type} idreserve={dataactive.order} />
                : null}
        </>
    );
};

export default Reportreceipt;