import React, { useState, useCallback, useEffect } from 'react';
// import axios from 'axios';
import { useNavigate } from "react-router-dom";
import Moment from 'moment';
import { compareAsc, format } from 'date-fns';

import '../assets/css/print.css';

// import PostService from "./services_member/post.service";
// import AuthService from "./services_member/auth.service";

// const getToken = () => {
//     const tokenString = localStorage.getItem("login_member");
//     const userToken = JSON.parse(tokenString);
//     return userToken?.token
// }

const ComponentToPrint = (data) => {

    let history = useNavigate();

    // const getPageMargins = () => {
    //     return `@page { margin: 0 !important; }`;
    // };
    // console.log(data.dataorderlist.filter(vls => vls.id === data.dataactive.order).length ? data.dataorderlist.filter(vls => vls.id === data.dataactive.order)[0].data_order : null)

    console.log(data.overview)

    return (
        <>
            {data.datareceipt ?
                <div className="ticket pb-5" >
                    <div className='d-block text-start'>
                        <img src={process.env.REACT_APP_API_URL + "/uploads/receipt/" + data.datareceipt.logo} alt="" className="w-25" />
                        <span className='float-end weight-500'>
                            {data.overview.zone === '0' ? "Drive Thru : คิวที่ " + data.overview.table_reserve : data.overview.zone_name + " : " + data.overview.table_name}
                        </span>
                    </div>
                    <div className='mt-2 font-10 text-start'>Order #{data.overview.no}</div>
                    <div className='mt-0 font-10 text-start'>
                        {data.overview.date_reserve ? <>
                            {format(new Date(data.overview.date_reserve), 'dd/MM/yyyy')} - {data.overview.time_update}
                        </>
                            : null}
                    </div>

                    {data.type === "receipt" ? <>
                        {data.overview.bill_data ? JSON.parse(data.overview.bill_data).data_prasree ? <>
                            {data.overview.prasee === 1 ? <>
                                <div className='mt-3 font-9' style={{ lineHeight: "1.2" }}>
                                    {data.datareceipt.company} <br />
                                    เลขประจําตัวผู้เสียภาษี : {data.datareceipt.id_num} <br />
                                    ที่อยู่ : {data.datareceipt.address} <br />
                                </div>
                                <hr className='hr-custom' />
                                <div className='mt-3 font-9' style={{ lineHeight: "1.3" }}>
                                    ใบเสร็จรับเงิน/ใบกำกับภาษี <span className='float-end font-12 weight-500'>ต้นฉบับ</span>
                                    <br />
                                    สำหรับลูกค้า :  #RT-{Moment(data.overview.date_reserve).format('yyyyMMDD')}{data.overview.number_bill}<br />
                                    <br />
                                    ชื่อลูกค้า : {JSON.parse(data.overview.bill_data).data_prasree.name_vat} <br />
                                    ที่อยู่ : {JSON.parse(data.overview.bill_data).data_prasree.add_vat} <br />
                                    เลขประจําตัวผู้เสียภาษี : {JSON.parse(data.overview.bill_data).data_prasree.number_vat} <br />
                                </div>
                            </> : data.overview.prasee === 3 ? <>
                                <div className='mt-3 font-9' style={{ lineHeight: "1.3" }}>
                                    สำหรับลูกค้า :  #RC-{Moment(data.overview.date_reserve).format('yyyyMMDD')}{data.overview.number_bill}<br />
                                </div>
                            </> : <>
                                <div className='mt-3 font-9' style={{ lineHeight: "1.2" }}>
                                    {data.datareceipt.company} <br />
                                    เลขประจําตัวผู้เสียภาษี : {data.datareceipt.id_num} <br />
                                    ที่อยู่ : {data.datareceipt.address} <br />
                                </div>
                                <hr className='hr-custom' />
                                <div className='mt-3 font-9' style={{ lineHeight: "1.3" }}>
                                    ใบกำกับภาษีอย่างย่อ <span className='float-end font-12 weight-500'>ต้นฉบับ</span>
                                    <br />
                                    สำหรับลูกค้า :  #R-{Moment(data.overview.date_reserve).format('yyyyMMDD')}{data.overview.number_bill}<br />
                                </div>
                            </>
                            }
                            <hr className='hr-custom' />
                        </>
                            : null : null
                        }
                    </>
                        : null}

                    <div className='weight-500 mt-1 font-12'>รายการอาหาร</div>
                    <div className='mt-2 mb-4'>
                        {data.dataorderlist.length ?
                            data.dataorderlist.filter(vls => vls.id === data.dataactive.order).length ?
                                data.dataorderlist.filter(vls => vls.id === data.dataactive.order)[0].data_order ?
                                    data.dataorderlist.filter(vls => vls.id === data.dataactive.order)[0].data_order.length ?
                                        (data.dataorderlist.filter(vls => vls.id === data.dataactive.order)[0].data_order).filter(valb => valb.status !== '5').sort((a, b) => a.id - b.id).map((value, index) => (
                                            <div className='mb-2' key={index}>
                                                <div className='font-9 position-relative pe-5'>
                                                    <span className='font-9'>{value.name_product}</span>
                                                    <span className='font-10 ms-2'>X</span> {value.qty}

                                                    <span className='position-absolute font-8' style={{ top: "0", right: "0" }}>{parseFloat(value.price * value.qty).toFixed(2)}</span>
                                                </div>
                                                {value.choice ? (JSON.parse(value.choice)).map((val, inde) => (

                                                    <div className='font-9 position-relative pe-5 ms-2' key={inde}>
                                                        <span className='font-9'>- {val.more_name}</span>
                                                        {/* {val.more_price === 0 ? */}
                                                        <span className='position-absolute font-8' style={{ top: "0", right: "0" }}>{parseFloat(val.more_price * value.qty).toFixed(2)}</span>
                                                        {/* : null} */}
                                                    </div>
                                                ))
                                                    : null}
                                            </div>
                                        ))
                                        : <div className='text-center my-4 font-9 weight-300 text-gray'>- ยังไม่มีรายการ - </div> : null : null : null}
                    </div>
                    <hr className='hr-custom' />
                    {data.dataorderlist.length ? data.dataorderlist.filter(vls => vls.id === data.dataactive.order).length ?
                        data.dataorderlist.filter(vls => vls.id === data.dataactive.order)[0].data_order ?
                            data.dataorderlist.filter(vls => vls.id === data.dataactive.order)[0].data_order.length ?
                                (data.dataorderlist.filter(vls => vls.id === data.dataactive.order)[0].data_order).filter(valb => valb.status === '5').length ?
                                    <>
                                        <div className='weight-500 mt-1 font-12'>รายการยกเลิก</div>
                                        <div className='mt-2 mb-4'>

                                            {
                                                data.dataorderlist.filter(vls => vls.id === data.dataactive.order)[0].data_order ? data.dataorderlist.filter(vls => vls.id === data.dataactive.order)[0].data_order.length ? (data.dataorderlist.filter(vls => vls.id === data.dataactive.order)[0].data_order).filter(valb => valb.status === '5').sort((a, b) => a.id - b.id).map((value, index) => (
                                                    <div className='mb-2' key={index}>
                                                        <div className='font-9 position-relative pe-5'>
                                                            <span className='font-9'>{value.name_product}</span>
                                                            <span className='font-10 ms-2'>X</span> {value.qty}
                                                            <span className='position-absolute font-8' style={{ top: "0", right: "0" }}>{parseFloat(value.price * value.qty).toFixed(2)}</span>
                                                        </div>
                                                        {value.choice ? (JSON.parse(value.choice)).map((val, inde) => (
                                                            <div className='font-9 position-relative pe-5 ms-2' key={inde}>
                                                                <span className='font-9'>- {val.more_name}</span>
                                                                <span className='font-8' style={{ top: "0", right: "0" }}>{parseFloat(val.more_price).toFixed(2)}</span>

                                                            </div>
                                                        ))
                                                            : null}


                                                    </div>
                                                ))
                                                    : <div className='text-center my-4 font-9 weight-300 text-gray'>- ยังไม่มีรายการ - </div> : null}
                                        </div>
                                        <hr className='hr-custom' />
                                    </>
                                    : null : null : null : null : null}

                    <div className='weight-500 mt-1 font-12'>โปรโมชั่น</div>
                    <div className='mt-2 mb-4'>
                        {data.overview.promotion_id !== '0' ? (
                            <div className='mb-2 font-9'>
                                <span className='font-9'>{data.datapromotion.promotion}</span>
                                <span className='float-end font-9'>
                                    {data.datapromotion.method_reduction === '1' ?
                                        "-" + data.datapromotion.price + "%"
                                        : parseFloat(data.datapromotion.price).toFixed(2)
                                    }
                                </span>
                            </div>
                        ) : <div>-</div>}
                    </div>
                    <div className='weight-500 mt-1 font-12'>
                        ราคาอาหาร
                        <span className='float-end font-10'>
                            {data.sumAll ? (data.sumAll).toLocaleString('en-US', { maximumFractionDigits: 2 }) : null}
                        </span>
                    </div>
                    <div className='mt-1'>
                        {data.datapayment ? <>
                            {data.datapayment.service_charge === '1' ?
                                <div className='mb-1 font-9'>
                                    <span className='font-9'>ค่าบริการ ({data.datapayment.service_rate}%)</span>
                                    <span className='float-end font-9'>
                                        {parseFloat((data.sumAll - (parseFloat(data.sumPromotion) + parseFloat(data.sumCut))) * (data.datapayment.service_rate) / 100).toFixed(2)}
                                    </span>
                                </div>
                                : null}
                            {data.datapayment.vat === '1' ?
                                <div className='mb-1 font-9'>
                                    <span className='font-9'>Vat ({data.datapayment.vat_rate}%)</span>
                                    <span className='float-end font-9'>{parseFloat((data.sumAll - (parseFloat(data.sumPromotion) + parseFloat(data.sumCut))) * (data.datapayment.vat_rate) / 100).toFixed(2)}</span>
                                </div>
                                : null}

                        </> : null}
                    </div>
                    <div className='mt-1'>
                        <div className='mb-1 font-9'>
                            <span className='font-9'>ส่วนลดโปรโมชั่น</span>
                            <span className='float-end font-9'>{data.sumPromotion}</span>
                        </div>
                    </div>
                    <div className='mt-1'>
                        <div className='mb-1 font-9'>
                            <span className='font-9'>ส่วนลดอื่นๆ</span>
                            <span className='float-end font-9'>{parseFloat(data.sumCut).toFixed(2)}</span>
                        </div>
                    </div>
                    <div className='mb-4 font-12 weight-500'>
                        <span className='font-12'>ยอดชำระ</span>
                        <span className='float-end font-10'>
                            {data.sumMath ? (data.sumMath).toLocaleString() : null}
                        </span>
                    </div>
                    <p className='mb-4 font-12 weight-500'>.</p>
                    <hr className='hr-custom' />
                </div>
                : null}

        </>
    );
};

export default ComponentToPrint;