import React, { useState, useCallback, useEffect, useRef } from 'react';
// import axios from 'axios';
import { useNavigate, useParams,Link } from "react-router-dom";
import { compareAsc, format } from 'date-fns'

import AuthService from "../../components/services_member/auth.service";
import PostService from "../../components/services_member/post.service";
import Orderdetail from "../order/order_food_detail";

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import '../../assets/css/respornsive.css';

import noimg from '../../assets/img/no-img-product.svg';
import cart from '../../assets/img/cart.svg';
import search from '../../assets/img/search.svg';
import ads1 from '../../assets/img/ads1.png';
import ads2 from '../../assets/img/ads2.png';
import ads3 from '../../assets/img/ads3.png';


// import { Link } from "react-tiger-transition";
import { Form, Tab, Nav, DropdownButton, Dropdown } from "react-bootstrap";
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next';


const Orderfood = () => {
    const { t } = useTranslation();
    const { id } = useParams();

    const ref = useRef([]);

    const tableorder = JSON.parse(localStorage.getItem("table_order"));

    let history = useNavigate();
    const user = AuthService.getCurrentUser();
    const branch = AuthService.getCurrentBranch();

    // localStorage.removeItem("table_order");

    // if (!user) {
    //     history("/");
    // } else {
    //     if (!branch) {
    //         history("/branch");
    //     }
    // }

    const [dateday, setDateday] = useState(format(new Date(), 'dd/MM/yyyy'));

    // const [datacat, setDatacat] = useState([{ id: '11113', branch_id: '5', category: 'อาหารทั้งหมด', position: '0', status: '1' },
    // { id: '0', branch_id: '5', category: 'Recommend', position: '0', status: '1' },
    // { id: '11111', branch_id: '5', category: 'Food', position: '0', status: '1' },
    // { id: '11112', branch_id: '5', category: 'Drink', position: '0', status: '1' }]);
    const [datacat, setDatacat] = useState([]);

    const [active, setActive] = useState({ id: '11113', text: 'Food' });
    const [active2, setActive2] = useState({ id: '11113', text: 'Drink' });
    const [dataproduct, setDataproduct] = useState([]);
    const [dproduct, setDproduct] = useState([]);
    const [dproductSearch, setDproductSearch] = useState();
    const [datadetail, setDatadetail] = useState();
    const [datareserve, setDatareserve] = useState([]);

    const responsive = {
        0: { items: 3 },
        568: { items: 3 },
        1024: { items: 5 },
    };

    const responsiveads = {
        0: { items: 1 },
        568: { items: 1 },
        1024: { items: 1 },
    };

    const [databanner, setDatabanner] = useState([]);

    const fetchData = useCallback(async (id) => {

        PostService.getBannerall(id).then(
            (response) => {
                if (response.data.status === 200) {
                    setDatabanner(response.data.data);
                } else {
                    setDatabanner([]);
                }
            },
            (error) => {
                console.log(error);
            }
        )
    });

    const fetchData2 = useCallback(async (dd, id) => {

        // const branch = JSON.parse(localStorage.getItem("branch_data"));

        PostService.getReserveone(id, dd).then(
            (response) => {
                if (response.data.status === 200) {
                    setDatareserve(response.data.reserve)
                    setDatacat(response.data.data_cat)
                    // if(datacat.length === 4){
                    //     setDatacat(datacat => [...datacat, ...response.data.data_cat])
                    // }
                    // setValue(value => [value,...response.data.data_cat])
                    setDataproduct(response.data.data_product)
                    setDproduct(response.data.data_product)

                    fetchData(response.data.reserve.branch_id);
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: '',
                        text: "ไม่มีรายการเลือกโต๊ะอาหาร",
                        showConfirmButton: false,
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        )
    });

    const fetchData3 = useCallback(async (dd, id) => {

        // const branch = JSON.parse(localStorage.getItem("branch_data"));

        PostService.getReserveone(id, dd).then(
            (response) => {
                if (response.data.status === 200) {
                    setDatareserve(response.data.reserve)
                    // setDatacat(response.data.data_cat)
                    setDatacat(datacat)
                    // setValue(value => [value,...response.data.data_cat])
                    setDataproduct(response.data.data_product)
                    setDproduct(response.data.data_product)

                    fetchData(response.data.reserve.branch_id);
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: '',
                        text: "ไม่มีรายการเลือกโต๊ะอาหาร",
                        showConfirmButton: false,
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        )
    });

    useEffect(() => {
        fetchData2(dateday, id);

        // if (tableview) {
        //     const tableorder = JSON.parse(localStorage.getItem("table_order"));
        //     fetchData2(tableorder.date, tableview.reserve);
        // }

    }, []);

    const Qtyshow = (pp) => {
        // return '0'

        let valueAdded = 0
        let count = 0

        if (datareserve && datareserve.data_order) {
            datareserve.data_order.map((val, inde) => {
                count = parseInt(val.qty)
                valueAdded += count
            })
        }

        return <>
            {valueAdded !== 0 ?
                // <div className='position-absolute text-white font-18 weight-500 text-center-qty' style={{ top: "10px", right: "10px" }}>
                //     {valueAdded}
                // </div>
                <span className='position-absolute font-12 d-flex'
                    style={{
                        right: "8px", top: "5px", zIndex: "9", borderRadius: "50%", color: "#fff",
                        background: "#A6231B", width: "20px", height: "20px", justifyContent: "center", alignItems: "center"
                    }}>
                    {valueAdded}
                </span>
                : null
            }
        </>
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        const data = new FormData(event.target);
        // console.log(data.get('search'))

        const keyword = data.get('search');

        // console.log(keyword)

        if (keyword !== '') {
            // const results = dproduct.filter((dproduct) => {
            //     return dproduct.name.toLowerCase().startsWith(keyword.toLowerCase());
            // });

            const results = dproduct.filter(valb => valb.name.match(new RegExp(keyword, "i")));

            setDproductSearch(keyword)

            setDataproduct(results);
            // console.log(results)
            // alert(5)
        } else {
            setDproductSearch();
            setDataproduct(dproduct);
            // alert(6)
            // console.log(dproduct)
        }
    }

    const resetdetail = () => {
        setDatadetail();
        fetchData2(dateday, id);
        fetchData3(dateday, id);
    }

    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);

    const onPressStart = (index) => {
        // ref[index].current?.scrollIntoView({ behavior: 'smooth' });

        ref.current[index]?.scrollIntoView({ behavior: 'smooth', block: "start", inline: "nearest" })
        setShow(false)
        setShow2(false)
    };
    const onPressStart2 = (index) => {
        // ref[index].current?.scrollIntoView({ behavior: 'smooth' });
        ref.current[index]?.scrollIntoView({ behavior: 'smooth', block: "start", inline: "nearest" })
        setShow(false)
        setShow2(false)
    };



    // const handleSelect = (key) => {
    //     setDataKey(key)
    // }
    console.log(datareserve.id)

    return (
        <>
        <div className={!t('menu-a') ? "sidebar-mini sidebar-collapse" : ""} >
            <div className='wrapper-admin layout-fixed'>
                <Tab.Container id="left-tabs-example" defaultActiveKey="food_all">

                    {datadetail ?
                        <Orderdetail data={datadetail} resetdetail={resetdetail} idOrder={datareserve.id} />
                        :

                        <div className="container bg-white">
                            <section className="content scroll-custom">
                                <div className="row">
                                    <div className="col-12 col-md-8 col-lg-8 text-start">
                                        <div className='mt-3'>
                                            <h4 className='mb-0'>{datareserve.zone_name} : {datareserve.table_reserve_name}</h4>
                                            <div>{datareserve.number_seats ? <span>{datareserve.number_seats} ท่าน</span> : "-"}</div>
                                            <div className='position-fixed' style={{ right: "0px", top: "4px", zIndex: "9" }}>
                                                <Link to={"/ordercart/" + id}>
                                                    <img src={cart} alt="" className="" />
                                                    <Qtyshow />
                                                </Link>
                                            </div>
                                        </div>
                                        <div className='mt-4'>
                                            <Form onSubmit={handleSubmit} className="">
                                                <div className='position-relative'>
                                                    <Form.Group controlId="dob">
                                                        <Form.Control type="text" name='search' className='font-14 py-2 ps-5' placeholder='ค้นหาเมนูอาหาร' />
                                                        <div className='position-absolute' style={{ bottom: "6px", left: "3px" }}>
                                                            <button type="submit" className='btn p-0'><img src={search} alt="" className="me-1" style={{ width: "25px" }} /></button>
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                                {dproductSearch ? <>
                                    <div className='mb-3 mt-2 ads'>
                                        <AliceCarousel mouseTracking
                                            responsive={responsiveads}
                                            infinite
                                            controlsStrategy="alternate"
                                            disableButtonsControls
                                            activeIndex={0}
                                        >
                                            {databanner.map((value, index) => (
                                                <img key={index} src={process.env.REACT_APP_API_URL + "/uploads/banner/" + value.banner} alt="" className="w-100 rounded" />
                                            ))}

                                        </AliceCarousel>
                                    </div>
                                    <div className='menu-slide mb-3 mt-5'>
                                        <div className="item active  text-start">รายการค้นหา "{dproductSearch}"</div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-12 col-md-4 col-lg-4 px-3">
                                            <div className="row">
                                                {dataproduct.length ? dataproduct.sort((a, b) => a.position - b.position).filter(valb => valb.status !== "0").map((value, index) => (
                                                    <div className="col-6 col-md-4 col-lg-4 text-start p-2" key={index}>
                                                        <Link to={"/orderfooddetail/" + id + "/" + datareserve.id + "/" + value.id} className='position-relative cursor-pointer a-default' >
                                                            <div className='cursor-pointer' >
                                                                <div className='position-relative'>
                                                                    {
                                                                        value.img ?
                                                                            <img src={process.env.REACT_APP_API_URL + "/uploads/product/" + value.img} alt="" className="w-100 rounded" />
                                                                            :
                                                                            <img src={noimg} alt="" className="w-100 rounded" />
                                                                    }
                                                                </div>
                                                                <div className='font-14 mt-3'>{value.name}</div>
                                                                <div className='font-16 mt-2 mb-5'><b>{value.price}</b></div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                ))
                                                    : <div className='py-5'>ไม่มีรายการค้นหา</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </>
                                    : <>
                                        <div className='mb-3 mt-2 ads'>
                                            <AliceCarousel mouseTracking
                                                responsive={responsiveads}
                                                infinite
                                                controlsStrategy="alternate"
                                                disableButtonsControls
                                                activeIndex={0}
                                            >
                                                {databanner.map((value, index) => (
                                                    <img key={index} src={process.env.REACT_APP_API_URL + "/uploads/banner/" + value.banner} alt="" className="w-100 rounded" />
                                                ))}

                                            </AliceCarousel>
                                        </div>
                                        <div className='row menu-stiky'>
                                            <div className="col-12 col-md-12 col-lg-12" style={{ paddingBottom: '15px' }}>
                                                <div className='menu-slide my-3'>
                                                    {/* <AliceCarousel mouseTracking
                                                responsive={responsive}
                                                // infinite
                                                controlsStrategy="alternate"
                                                disableDotsControls
                                                disableButtonsControls
                                                activeIndex={0}
                                            >
                                                {datacat.map((val, inde) => (
                                                    <div className={active == val.id ? "item active font-14" : "item font-14"} key={inde} onClick={() => setActive(val.id)}>{val.category}</div>
                                                ))}

                                            </AliceCarousel> */}

                                                    <Nav as="ul" variant="tabs" className="nav nav-tabs ul-tabcustom nav-tabs-none">
                                                        {/* <Nav.Item as="li">
                                                    <Nav.Link eventKey="promotion_all">Recommend</Nav.Link>
                                                </Nav.Item> */}
                                                        {/* <Nav.Item as="li">
                                                    <Nav.Link onClick={(e) => setActive({ id: '11111', text: 'อาหารทั้งหมด' })} className="ps-0">Food</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item as="li">
                                                    <Nav.Link onClick={(e) => setActive({ id: '11112', text: 'อาหารทั้งหมด' })}>Drink</Nav.Link>
                                                </Nav.Item> */}

                                                        <Nav.Item as="li">
                                                            <Dropdown className='' show={show}>
                                                                <Dropdown.Toggle variant="menu-tab" id="dropdown-basic" onClick={() => setShow(!show)}>
                                                                    {active.text}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    {datacat.filter(valb => valb.type === '0').sort((a, b) => a.category > b.category ? 1 : -1).map((val, inde) => (
                                                                        <a onClick={() => onPressStart(val.id)} key={inde} className="dropdown-item">
                                                                            <span >{val.category}</span>
                                                                        </a>
                                                                    ))}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </Nav.Item>
                                                        <Nav.Item as="li">
                                                            <Dropdown className='' show={show2}>
                                                                <Dropdown.Toggle variant="menu-tab" id="dropdown-basic" onClick={() => setShow2(!show2)}>
                                                                    {active2.text}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    {datacat.filter(valb => valb.type === '1').sort((a, b) => a.category > b.category ? 1 : -1).map((val, inde) => (
                                                                        // <Dropdown.Item eventKey={val.id} key={inde} onSelect={(e) => setActive({ id: val.id, text: val.category })}>{val.category}</Dropdown.Item>
                                                                        <a onClick={() => onPressStart2(val.id)} key={inde} className="dropdown-item">
                                                                            <span >{val.category}</span>
                                                                        </a>
                                                                    ))}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </Nav.Item>
                                                    </Nav>


                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            <div className="col-12 col-md-4 col-lg-4 px-3">
                                                {/* {
                                                    active.id === '11113' ?
                                                        <div className="row">

                                                            {dataproduct.length ? dataproduct.sort((a, b) => a.position - b.position).filter(valb => valb.status !== "0").map((value, index) => (

                                                                <div className="col-6 col-md-4 col-lg-4 text-start p-2" key={index}>
                                                                    <div className='position-relative' onClick={() => setDatadetail(dataproduct.filter(valb => valb.id === value.id))}>
                                                                        <div className='cursor-pointer' >
                                                                            <div className='position-relative'>
                                                                                {
                                                                                    value.img ?
                                                                                        <img src={process.env.REACT_APP_API_URL + "/uploads/product/" + value.img} alt="" className="w-100 rounded" />
                                                                                        :
                                                                                        <img src={noimg} alt="" className="w-100 rounded" />
                                                                                }
                                                                            </div>
                                                                            <div className='font-14 mt-3'>{value.name}</div>
                                                                            <div className='font-16 mt-2 mb-5'><b>{value.price}</b></div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            ))
                                                                : <div className='py-5'>ไม่มีรายการค้นหา</div>
                                                            }
                                                        </div> :

                                                        active.id === '11111' ?
                                                            <div className="row">

                                                                {dataproduct.length ? dataproduct.sort((a, b) => a.position - b.position).filter(valb => valb.status !== "0").filter(valb => valb.kitchen_id !== '5').map((value, index) => (

                                                                    <div className="col-6 col-md-4 col-lg-4 text-start p-2" key={index}>
                                                                        <div className='position-relative' onClick={() => setDatadetail(dataproduct.filter(valb => valb.id === value.id))}>
                                                                            <div className='cursor-pointer' >
                                                                                <div className='position-relative'>
                                                                                    {
                                                                                        value.img ?
                                                                                            <img src={process.env.REACT_APP_API_URL + "/uploads/product/" + value.img} alt="" className="w-100 rounded" />
                                                                                            :
                                                                                            <img src={noimg} alt="" className="w-100 rounded" />
                                                                                    }
                                                                                </div>
                                                                                <div className='font-14 mt-3'>{value.name}</div>
                                                                                <div className='font-16 mt-2 mb-5'><b>{value.price}</b></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                ))
                                                                    : <div className='py-5'>ไม่มีรายการค้นหา</div>
                                                                }
                                                            </div>

                                                            : active.id === '11112' ?
                                                                <div className="row">

                                                                    {dataproduct.length ? dataproduct.sort((a, b) => a.position - b.position).filter(valb => valb.status !== "0").filter(valb => valb.kitchen_id === '5').map((value, index) => (

                                                                        <div className="col-6 col-md-4 col-lg-4 text-start p-2" key={index}>
                                                                            <div className='position-relative' onClick={() => setDatadetail(dataproduct.filter(valb => valb.id === value.id))}>
                                                                                <div className='cursor-pointer' >
                                                                                    <div className='position-relative'>
                                                                                        {
                                                                                            value.img ?
                                                                                                <img src={process.env.REACT_APP_API_URL + "/uploads/product/" + value.img} alt="" className="w-100 rounded" />
                                                                                                :
                                                                                                <img src={noimg} alt="" className="w-100 rounded" />
                                                                                        }
                                                                                    </div>
                                                                                    <div className='font-14 mt-3'>{value.name}</div>
                                                                                    <div className='font-16 mt-2 mb-5'><b>{value.price}</b></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    ))
                                                                        : <div className='py-5'>ไม่มีรายการค้นหา</div>
                                                                    }
                                                                </div>
                                                                :

                                                                <div className="row">

                                                                    {dataproduct.length ? dataproduct.sort((a, b) => a.position - b.position).filter(valb => valb.status !== "0").filter(active.id !== '0' ? valb => valb.cat_id === active.id : valb => valb.recommend === '1').map((value, index) => (

                                                                        <div className="col-6 col-md-4 col-lg-4 text-start p-2" key={index}>
                                                                            <div className='position-relative' onClick={() => setDatadetail(dataproduct.filter(valb => valb.id === value.id))}>
                                                                                <div className='cursor-pointer' >
                                                                                    <div className='position-relative'>
                                                                                        {
                                                                                            value.img ?
                                                                                                <img src={process.env.REACT_APP_API_URL + "/uploads/product/" + value.img} alt="" className="w-100 rounded" />
                                                                                                :
                                                                                                <img src={noimg} alt="" className="w-100 rounded" />
                                                                                        }
                                                                                    </div>
                                                                                    <div className='font-14 mt-3'>{value.name}</div>
                                                                                    <div className='font-16 mt-2 mb-5'><b>{value.price}</b></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    ))
                                                                        : <div className='py-5'>ไม่มีรายการค้นหา</div>
                                                                    }
                                                                </div>
                                                } */}

                                                <div className="row">
                                                    {datacat.length ? datacat.filter(valb => valb.status !== "0").sort((a, b) => a.category > b.category ? 1 : -1).map((val, ind) => (
                                                        <div className="col-12 col-md-12 col-lg-12 text-start p-2" key={ind}>
                                                            <div ref={element => ref.current[val.id] = element} id={"section-" + val.id} style={{ paddingTop: '60px' }}><b>{val.category}</b><hr /></div>
                                                            <div className="row">
                                                                {
                                                                    dataproduct.filter(valb => valb.cat_id === val.id).sort((a, b) => a.position - b.position).filter(valb => valb.status !== "0").map((value, index) => (

                                                                        <div className="col-6 col-md-4 col-lg-4 text-start p-2" key={index}>
                                                                            <div className='position-relative'>
                                                                                <Link to={"/orderfooddetail/" + id + "/" + datareserve.id + "/" + value.id} className='cursor-pointer a-default' >
                                                                                    <div className='position-relative'>
                                                                                        {
                                                                                            value.img ?
                                                                                                <img src={process.env.REACT_APP_API_URL + "/uploads/product/" + value.img} alt="" className="w-100 rounded" />
                                                                                                :
                                                                                                <img src={noimg} alt="" className="w-100 rounded" />
                                                                                        }
                                                                                    </div>
                                                                                    <div className='font-14 mt-3'>{value.name} </div>
                                                                                    <div className='font-16 mt-2 mb-3'><b>{value.price}</b></div>
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>

                                                    ))
                                                        : <div className='py-5'>ไม่มีรายการ</div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </section>
                            {/* <div ref={ref} id='about'>scroll Me</div> */}
                        </div>

                    }

                </Tab.Container>
            </div >
        </div >

            {/* {modalselecttable.view ?
                <Modalmenuselecttable show={modalselecttable.view} onHide={clickrefresh} id={modalselecttable.id} />
                : null} */}
        </>
    );
};

export default Orderfood;