import React, { useState } from 'react';
// import axios from 'axios';
import { useHistory,Link } from "react-router-dom";

import sorn from '../../assets/img/sorn.svg';

// import { Link } from "react-tiger-transition";

import { ListGroup, Accordion } from "react-bootstrap";

const Menureport = (type) => {

    // let history = useHistory();
    const [menuactive, setMenuactive] = useState(type.type);
    const [menuactivesub, setMenuactivesub] = useState(type.sub);

    return (
        <>

            <ListGroup>
                <ListGroup.Item>
                    {/* <Link to="/report/summary" transition="default-fade" className={menuactive == "summary" ? "active" : ""}>
                        รายงานยอดขาย
                        <span className='float-end'>
                            <img src={sorn} alt="" className="" />
                        </span>
                    </Link> */}
                    <Accordion defaultActiveKey={menuactive == "summary" ? "1" : "0"} className='acc-custom'>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>รายงานยอดขาย</Accordion.Header>
                            <Accordion.Body>
                                <Link to="/report/summary" transition="default-fade" className={menuactivesub == "day" ? "active ps-5" : "ps-5"} style={{borderTop:"1px solid rgba(0,0,0,.125)"}}>
                                    รายวัน
                                    <span className='float-end'>
                                        <img src={sorn} alt="" className="" />
                                    </span>
                                </Link>
                                <Link to="/report/summarymonth" transition="default-fade" className={menuactivesub == "month" ? "active ps-5" : "ps-5"} style={{borderTop:"1px solid rgba(0,0,0,.125)"}}>
                                    รายเดือน
                                    <span className='float-end'>
                                        <img src={sorn} alt="" className="" />
                                    </span>
                                </Link>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Link to="/report/employee" transition="default-fade" className={menuactive == "employee" ? "active" : ""}>
                        รายงานพนักงาน
                        <span className='float-end'>
                            <img src={sorn} alt="" className="" />
                        </span>
                    </Link>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Link to="/report/wastedata" transition="default-fade" className={menuactive == "waste" ? "active" : ""}>
                        รายงานการทำของเสีย
                        <span className='float-end'>
                            <img src={sorn} alt="" className="" />
                        </span>
                    </Link>
                </ListGroup.Item>
            </ListGroup>

        </>
    );
};

export default Menureport;