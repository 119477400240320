import React, { useState, useCallback } from 'react';
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import icondel from '../../assets/img/icon-del.svg';

const Modalcategorydel = (data) => {

    let history = useNavigate();

    const [validated, setValidated] = useState(false);
// console.log(data.action)
    return (
        <>
            <Modal {...data} className="modal-custom" size="md" backdrop="static"
                keyboard={false} style={{ background: "rgba(0,0,0,0.7)" }}>
                <Modal.Header closeButton className="btn-close-white">

                </Modal.Header>
                <Modal.Body className="p-0">
                    <div className="d-block w-100 scroll-custom">
                        <div className='pt-5 pb-4 bg-white px-4 text-center'>
                            <div><img src={icondel} alt="" className="" style={{ width: "50px" }} /></div>
                            <div className='font-18 weight-500 mt-4'>{ data.text }</div>
                            <div className='text-center px-3 mt-4'>
                                <div className='row'>
                                    <div className="col-12 col-md-6 col-lg-6 pe-1">
                                        <button className="w-100 btn btn-calcel text-white py-2 me-3" onClick={data.onHide}>
                                            ยกเลิก
                                        </button>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 ps-1">
                                        <button className="w-100 btn btn-bg text-white py-2 me-3" onClick={data.action}>
                                            ยืนยัน
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
              
            </Modal>


        </>
    );
};

export default Modalcategorydel;