import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

// import { BrowserRouter as Router } from "react-router-dom";
// import axios from 'axios';
// import { Link } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
// import Rang from '../components/lang';
import AuthService from "../../components/services_member/auth.service";

import { Form, Row, Col } from "react-bootstrap";

import icondel from '../../assets/img/icon-del.svg';
import dellist from '../../assets/img/del-list.svg';
import addlist from '../../assets/img/add-list.svg';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const Modaltable = (data) => {

    let history = useNavigate();

    let datatatb = data.datatable;

    const [inputFields, setInputFields] = useState([{ ...datatatb }]);

    const handleFormChange = (index, event) => {
        let datae = [...inputFields];
        datae[index][event.target.name] = event.target.value;
        setInputFields(datae);
    }

    const addFields = () => {
        let newfield = { table_number: '', number: '' }
        setInputFields([...inputFields, newfield])
    }

    const removeFields = (index) => {
        let datae = [...inputFields];
        datae.splice(index, 1)
        setInputFields(datae)
    }

    const [validated, setValidated] = useState(false);
    const formRef = useRef(null);

    const handleSubmit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const datat = new FormData(event.target);

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        datat.append("branch_id", branch.id);

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

        } else {

            AuthService.createTable(datat).then(
                (res) => {
                    //   console.log(res)   
                    if (res.status === 200) {
                        // localStorage.removeItem("register");
                        // localStorage.setItem('branchdata', JSON.stringify(res.data));
                        // history("/branchpin"); 
                        // window.location.reload();


                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: '',
                            text: "บันทึกข้อมูลเรียบร้อยแล้ว",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(function () {
                            data.onHide();
                            formRef.current.reset();
                            setValidated(false);
                        })
                    } else {
                        // setErrors({email:"This email cannot be used.!"})
                    }
                },
                (error) => {
                    console.log(error);
                }
            );

            // window.location.reload(true);
        }
        setValidated(true);
    };

    const handleSubmitdel = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const datat = new FormData(event.target);

        datat.append("id", data.id);

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {

            AuthService.deleteTable(datat).then(
                (res) => {
                    //   console.log(res)   
                    if (res.status === 200) {
                        // window.location.reload();

                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: '',
                            text: "ลบข้อมูลเรียบร้อยแล้ว",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(function () {
                            data.onHide()
                        })
                    } else {
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
            // window.location.reload(true);
        }
        setValidated(true);
    };

    const handleSubmitupdate = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const datat = new FormData(event.target);

        datat.append("id", data.id);

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {

            AuthService.updateTable(datat).then(
                (res) => {
                    if (res.status === 200) {
                        // window.location.reload();

                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: '',
                            text: "บันทึกข้อมูลเรียบร้อยแล้ว",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(function () {
                            data.onHide()
                            formRef.current.reset();
                            setValidated(false);
                        })
                    } else {
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
            // window.location.reload(true);
        }
        setValidated(true);
    };

    useEffect(() => {
        setInputFields(datatatb)
    }, [datatatb]);

    console.log(data)

    return (
        <>
            <Modal {...data} className="modal-custom" size="md" backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton className="btn-close-white ">

                </Modal.Header>
                <Modal.Body className="p-0">
                    <div className="d-block w-100 scroll-custom">
                        {
                            data.type === "detail" ? (
                                <div className='pt-5 pb-4 bg-white px-4 text-start'>
                                    <div className='text-default font-22 weight-500'>
                                        รายละเอียดพื้นที่
                                    </div>
                                    <div className='mb-3 mt-4'>

                                        <div className=''><b>{data.zone}</b></div>
                                        {inputFields.map((input, index) => (
                                            <div key={index}>
                                                <div>เลขโต๊ะ : {input.table_number}</div>
                                                <div className='mb-3'>จำนวนที่นั่ง : {input.number}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) :
                                data.type === "del" ? (
                                    <div className='pt-5 pb-4 bg-white px-4 text-center'>
                                        <Form noValidate validated={validated} onSubmit={handleSubmitdel} ref={formRef}>
                                            <div><img src={icondel} alt="" className="" style={{ width: "50px" }} /></div>
                                            <div className='font-18 weight-500 mt-4'>ยืนยันการลบพื้นที่ {data.zone}</div>
                                            <div className='text-center px-3 mt-4'>
                                                <div className='row'>
                                                    <div className="col-12 col-md-6 col-lg-6 pe-1">
                                                        <button className="w-100 btn btn-calcel text-white py-2 me-3" onClick={data.onHide}>
                                                            ยกเลิก
                                                        </button>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6 ps-1">
                                                        <button type='submit' className="w-100 btn btn-bg text-white py-2 me-3">
                                                            ยืนยัน
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                ) : (

                                    data.type === "edit" ? (
                                        <>
                                            <Modal.Title className='py-3 bg-white px-4 shadow-sm position-fixed w-100' style={{ zIndex: "1" }}>
                                                <div className='text-default font-22'>
                                                    แก้ไขพื้นที่
                                                </div>
                                            </Modal.Title>
                                            <Form noValidate validated={validated} onSubmit={handleSubmitupdate} ref={formRef}>
                                                <div className='h-100per-modal bg-white px-4 pt-5'>

                                                    <div>
                                                        <Row className="mt-5">
                                                            <Form.Group as={Col} md="6" className="position-relative pe-1" controlId="validationCustom01">
                                                                <Form.Label className="font-12 text-gray mb-0">ชื่อพื้นที่</Form.Label>
                                                                <Form.Control type="text" name="zone" defaultValue={data.zone} className='font-14 input-custom' placeholder='xxxxxxxx' required />
                                                            </Form.Group>
                                                        </Row>

                                                        <div>
                                                            {inputFields.filter((e) => e.id === 0).map((input, index) =>
                                                                <Row className="align-items-end mt-3 mb-3" key={index}>
                                                                    <Form.Group as={Col} md="4" className="position-relative" controlId="validationCustom01">
                                                                        <Form.Label className="font-12 text-gray mb-0">เลขโต๊ะ </Form.Label>
                                                                        <Form.Control type="text" name='table_number[]' defaultValue={input.table_number} className='font-14 input-custom' required />
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} md="4" className="position-relative" controlId="validationCustom01">
                                                                        <Form.Label className="font-12 text-gray mb-0">จำนวนที่นั่ง</Form.Label>
                                                                        <Form.Control type="text" name='number[]' defaultValue={input.number} className='font-14 input-custom' required />
                                                                    </Form.Group>

                                                                    {inputFields.length === 1 ? (
                                                                        <Form.Group as={Col} md="4" className="position-relative pifo-b" controlId="validationCustom01">
                                                                            <button onClick={() => removeFields()} className="btn py-0 px-2" style={{ opacity: "0" }}>
                                                                                <img src={dellist} alt="" className="" style={{ width: "30px" }} />
                                                                            </button>
                                                                            <span className='cursor' onClick={addFields}>
                                                                                <img src={addlist} alt="" className="" style={{ width: "30px" }} />
                                                                            </span>
                                                                        </Form.Group>
                                                                    ) : null}
                                                                </Row>
                                                            )}

                                                            {inputFields.map((input, index) =>
                                                                index !== 0 ?
                                                                    <Row className="align-items-end mt-3 mb-3 pifo" key={index}>
                                                                        <Form.Group as={Col} md="4" className="position-relative" controlId="validationCustom01">
                                                                            <Form.Control type="text" name='table_number[]' defaultValue={input.table_number} className='font-14 input-custom' required onChange={event => handleFormChange(index, event)} />
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="4" className="position-relative" controlId="validationCustom01">
                                                                            <Form.Control type="text" name='number[]' defaultValue={input.number} className='font-14 input-custom' required onChange={event => handleFormChange(index, event)} />
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="4" className="position-relative pifo-b" controlId="validationCustom01">
                                                                            <button onClick={() => removeFields(index)} className="btn py-0 px-2">
                                                                                <img src={dellist} alt="" className="" style={{ width: "30px" }} />
                                                                            </button>
                                                                            <span className='cursor' onClick={addFields}>
                                                                                <img src={addlist} alt="" className="" style={{ width: "30px" }} />
                                                                            </span>
                                                                        </Form.Group>
                                                                    </Row>
                                                                    : null
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='text-center py-3 px-3 bg-gray'>
                                                    <div className='row'>
                                                        <div className="col-12 col-md-6 col-lg-6 pe-1">
                                                            <span className="w-100 btn btn-calcel text-white py-2 me-3" onClick={data.onHide}>
                                                                ยกเลิก
                                                            </span>
                                                        </div>
                                                        <div className="col-12 col-md-6 col-lg-6 ps-1">
                                                            <button type='submit' className="w-100 btn btn-submit text-white py-2 me-3">
                                                                บันทึก
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        </>
                                    ) : (
                                        <>
                                            <Modal.Title className='py-3 bg-white px-4 shadow-sm position-fixed w-100' style={{ zIndex: "1" }}>
                                                <div className='text-default font-22'>
                                                    สร้างพื้นที่ใหม่
                                                </div>
                                            </Modal.Title>
                                            <Form noValidate validated={validated} onSubmit={handleSubmit} ref={formRef}>
                                                <div className='h-100per-modal bg-white px-4 pt-5'>

                                                    <div>
                                                        <Row className="mt-5">
                                                            <Form.Group as={Col} md="6" className="position-relative pe-1" controlId="validationCustom01">
                                                                <Form.Label className="font-12 text-gray mb-0">ชื่อพื้นที่</Form.Label>
                                                                <Form.Control type="text" name="zone" className='font-14 input-custom' placeholder='xxxxxxxx' required />
                                                            </Form.Group>
                                                        </Row>

                                                        <div>
                                                            <Row className="align-items-end mt-3 mb-3">
                                                                <Form.Group as={Col} md="4" className="position-relative" controlId="validationCustom01">
                                                                    <Form.Label className="font-12 text-gray mb-0">เลขโต๊ะ</Form.Label>
                                                                    <Form.Control type="text" name='table_number[]' className='font-14 input-custom' required />
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="4" className="position-relative" controlId="validationCustom01">
                                                                    <Form.Label className="font-12 text-gray mb-0">จำนวนที่นั่ง</Form.Label>
                                                                    <Form.Control type="text" name='number[]' className='font-14 input-custom' required />
                                                                </Form.Group>
                                                                {inputFields.length === 0 ? (
                                                                    <Form.Group as={Col} md="4" className="position-relative pifo-b" controlId="validationCustom01">
                                                                        <button onClick={() => removeFields()} className="btn py-0 px-2" style={{ opacity: "0" }}>
                                                                            <img src={dellist} alt="" className="" style={{ width: "30px" }} />
                                                                        </button>
                                                                        <span className='cursor' onClick={addFields}>
                                                                            <img src={addlist} alt="" className="" style={{ width: "30px" }} />
                                                                        </span>
                                                                    </Form.Group>
                                                                ) : ""}

                                                            </Row>
                                                            {inputFields.map((input, index) => (
                                                                <Row className="align-items-end mt-3 mb-3 pifo" key={index}>
                                                                    <Form.Group as={Col} md="4" className="position-relative" controlId="validationCustom01">
                                                                        <Form.Control type="text" name='table_number[]' className='font-14 input-custom' required onChange={event => handleFormChange(index, event)} />
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} md="4" className="position-relative" controlId="validationCustom01">
                                                                        <Form.Control type="text" name='number[]' className='font-14 input-custom' required onChange={event => handleFormChange(index, event)} />
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} md="4" className="position-relative pifo-b" controlId="validationCustom01">
                                                                        <button onClick={() => removeFields(index)} className="btn py-0 px-2">
                                                                            <img src={dellist} alt="" className="" style={{ width: "30px" }} />
                                                                        </button>
                                                                        <span className='cursor' onClick={addFields}>
                                                                            <img src={addlist} alt="" className="" style={{ width: "30px" }} />
                                                                        </span>
                                                                    </Form.Group>
                                                                </Row>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='text-center py-3 px-3 bg-gray'>
                                                    <div className='row'>
                                                        <div className="col-12 col-md-6 col-lg-6 pe-1">
                                                            <button className="w-100 btn btn-calcel text-white py-2 me-3" onClick={data.onHide}>
                                                                ยกเลิก
                                                            </button>
                                                        </div>
                                                        <div className="col-12 col-md-6 col-lg-6 ps-1">
                                                            <button type='submit' className="w-100 btn btn-submit text-white py-2 me-3">
                                                                บันทึก
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        </>
                                    )
                                )}
                    </div>
                </Modal.Body>
            </Modal>

        </>
    );
};

export default Modaltable;