import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate,Link } from "react-router-dom";
import AuthService from "../../components/services_member/auth.service";
import PostService from "../../components/services_member/post.service";
import help from '../../assets/img/help.svg';


import Menuleft from "../../layout/pagesadmin/menuleft";
import Menusetting from "../../components/setting/setting_menu";


import { Form, Nav, Row, Col, ListGroup, Badge } from "react-bootstrap";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useTranslation } from 'react-i18next';
const Settingpayment = () => {
    const { t } = useTranslation();
    let history = useNavigate();

    const user = AuthService.getCurrentUser();
    const branch = AuthService.getCurrentBranch();

    if (!user) {
        history("/");
    } else {
        if (!branch) {
            history("/branch");
        }
    }
    const [datapayment, setDatapayment] = useState([]);
    const [swt, setSwt] = useState([]);
    const [swt2, setSwt2] = useState([]);


    const fetchData = useCallback(async () => {
        PostService.getPayment(branch.id).then(
            (response) => {
                if (response.data.status === 200) {
                    setDatapayment(response.data.data);
                    const dafa = response.data.data;
                    setSwt({ view: dafa.service_charge })
                    setSwt2({ view: dafa.vat })
                }
            },
            (error) => {
                console.log(error);
            }
        )
    });

    useEffect(() => {
        fetchData()
    }, []);


    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const data = new FormData(event.target);

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        data.append("branch_id", branch.id);

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

        } else {

            AuthService.updatePayment(data).then(
                (res) => {
                    //   console.log(res)   
                    if (res.status === 200) {
                        // history("/setting/setting"); 
                        // window.location.reload();
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: '',
                            text: "บันทึกข้อมูลเรียบร้อยแล้ว",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(function () {
                            fetchData()
                        })

                    } else {
                        // setErrors({email:"This email cannot be used.!"})
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
            // history("/questionnaire2");
            // alert(data.get('service_charge'));
            // alert(555);
        }
        setValidated(true);
    };

    const handleSwt = (e) => {
        if (e === '1') {
            setSwt({ view: '0' })
        } else {
            setSwt({ view: '1' })
        }
    }

    const handleSwt2 = (e) => {
        if (e === '1') {
            setSwt2({ view: '0' })
        } else {
            setSwt2({ view: '1' })
        }
    }

    // console.log(datapayment.branch_id)
    // console.log(swt2)
    return (
        <>
            <div className={!t('menu-a') ? "sidebar-mini sidebar-collapse" : ""} >
                <div className='wrapper-admin layout-fixed'>
                    <nav className="main-header navbar bg-white shadow-sm" style={{ height: "60px" }}>
                        <Nav as="ul" variant="tabs" className="nav nav-tabs overflow-hidden ul-tabcustom">

                        </Nav>
                        <ul className="navbar-nav ml-auto" style={{ marginTop: "-20px" }}>
                            <li className="">
                                <div className='text-gray font-12'><img src={help} alt="" className="me-1" style={{ width: "14px" }} /> ขอความช่วยเหลือ</div>
                            </li>
                        </ul>
                    </nav>

                    <Menuleft type="setting" />

                    <div className="content-wrapper">
                        <section className="content scroll-custom">
                            <div className="row">
                                <div className="col-12 col-md-5 col-lg-5">
                                    <div className='text-start box-setting bg-white rounded-3 mt-3 h-100per'>
                                        <Menusetting type="payment" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-7 col-lg-7 ps-0">
                                    <Form noValidate validated={validated} onSubmit={handleSubmit} className="">
                                        <div className="bg-white rounded-3 ms-2 mt-3 h-100per-right position-relative">
                                            <div className='px-4 py-2 text-start box-list'>
                                                <ListGroup as="ol" className="mt-1">
                                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start borderhr py-3" >
                                                        <div className="w-50">
                                                            <span className="d-block align-middle">
                                                                <div>ค่าบริการ </div>
                                                                {swt.view === '1' ? (
                                                                    <Row className="align-items-end mt-3 mb-3">
                                                                        <Form.Group as={Col} md="6" className="position-relative pe-1" controlId="validationCustom01">
                                                                            <Form.Label className="font-12 text-gray mb-0">อัตราค่าบริการ</Form.Label>
                                                                            <Form.Control type="text" name='service_rate' defaultValue={datapayment.service_rate} className='font-14 input-custom' required={swt.view === '1' ? true : null} />
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6" className="position-relative pe-1" controlId="validationCustom01">
                                                                            <span>%</span>
                                                                        </Form.Group>
                                                                    </Row>
                                                                ) : ""}
                                                            </span>
                                                        </div>
                                                        <Badge bg="" className="p-0">
                                                            <div className="form-check form-switch">
                                                                <input className="form-check-input float-end" type="checkbox" id="flexSwitchCheckChecked" name='service_charge' value="1" onClick={() => handleSwt(swt.view)} defaultChecked={swt.view === '1' ? true : null} />
                                                            </div>
                                                        </Badge>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start borderhr py-3" >
                                                        <div className="w-50">
                                                            <span className="d-block align-middle mt-3">
                                                                <div>ภาษี (Vat)</div>
                                                                {swt2.view === '1' ? (
                                                                    <>
                                                                        <Row className="align-items-end mt-3 mb-3">
                                                                            <Form.Group as={Col} md="6" className="position-relative pe-1" controlId="validationCustom01">
                                                                                <Form.Label className="font-12 text-gray mb-0">อัตราภาษีมูลค่าเพิ่ม</Form.Label>
                                                                                <Form.Control type="text" name="vat_rate" defaultValue={datapayment.vat_rate} className='font-14 input-custom' required={swt2.view === '1' ? true : null} />
                                                                            </Form.Group>
                                                                            <Form.Group as={Col} md="6" className="position-relative pe-1" controlId="validationCustom01">
                                                                                <span>%</span>
                                                                            </Form.Group>
                                                                        </Row>
                                                                        <div>
                                                                            <div className="ex-radio mb-2">
                                                                                <input type="radio" id="radio_one" name="vat_type" value="1" defaultChecked={datapayment.vat_type === '1' ? "checked" : null} />
                                                                                <span className="radio_one mb-2 ps-4" style={{ width: "100%" }} >
                                                                                    รวมในค่าอาหาร
                                                                                </span>
                                                                            </div>
                                                                            <div className="ex-radio mb-2">
                                                                                <input type="radio" id="radio_one" name="vat_type" value="2" defaultChecked={datapayment.vat_type === '2' ? "checked" : null} />
                                                                                <span className="radio_one mb-2 ps-4" style={{ width: "100%" }} >
                                                                                    บวกเพิ่มจากราคาอาหาร
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ) : ""}
                                                            </span>
                                                        </div>
                                                        <Badge bg="" className="p-0">
                                                            <div className="form-check form-switch">
                                                                <input className="form-check-input float-end" type="checkbox" id="flexSwitchCheckChecked" name='vat' value="1" onClick={() => handleSwt2(swt2.view)} defaultChecked={swt2.view === '1' ? true : null} />
                                                                {/* <label className="form-check-label" >Checked switch checkbox input</label> */}
                                                            </div>
                                                        </Badge>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start borderhr py-3" >
                                                        <div className="w-100">
                                                            <span className="d-block align-middle mt-3">
                                                                <div>การปัดเศษ</div>
                                                                <Row className="align-items-end mt-3 mb-3">
                                                                    <Form.Group as={Col} md="12" className="position-relative" controlId="validationCustom01">
                                                                        <Form.Label className="font-12 text-gray mb-2">เลือกวิธีการปัดเศษ</Form.Label>
                                                                        <Form.Select aria-label="Default select example" className='font-14' name='rounding'>
                                                                            <option value="0" selected={datapayment.rounding === '0' ? true : null}>ไม่มี</option>
                                                                            <option value="1" selected={datapayment.rounding === '1' ? true : null}>ปัดเศษไปจำนวนใกล้เคียง</option>
                                                                            <option value="2" selected={datapayment.rounding === '2' ? true : null}>ปัดเศษขึ้น</option>
                                                                            <option value="3" selected={datapayment.rounding === '3' ? true : null}>ปัดเศษลง</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                </Row>
                                                            </span>
                                                        </div>
                                                    </ListGroup.Item>
                                                </ListGroup>
                                            </div>
                                        </div>
                                        <div className='w-100 ps-2 mt-2'>
                                            <div className='row'>
                                                <div className="col-12 col-md-6 col-lg-6 pe-1">
                                                    <Link to="/setting/setting" transition="default-fade" className="w-100 btn btn-calcel text-white py-2 me-3">
                                                        ยกเลิก
                                                    </Link>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6 ps-1">
                                                    <button type='submit' className="w-100 btn btn-submit text-white py-2 me-3">
                                                        บันทึก
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </div>
        </>
    );
};

export default Settingpayment;