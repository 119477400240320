import React, { useState, useEffect, useCallback } from 'react';
// import axios from 'axios';
import {Link } from "react-router-dom";
// import DatePicker from "react-datepicker";
// import help from '../../assets/img/help.svg';

import noimg from '../../assets/img/no-img-product.svg';
import noimgpromo from '../../assets/img/no-img-promotion.svg';
import icheck from '../../assets/img/icon-check-white.svg';

// import Menuleft from "../../layout/pagesadmin/menuleft";

// import { Link } from "react-tiger-transition";
import { Tab } from "react-bootstrap";
// import { Form, Col, Row, InputGroup, Button } from "react-bootstrap";
import Modalmenudetail from "../../layout/modal/modalMenuorder";

import AuthService from "../../components/services_member/auth.service";


const Ordermenutab = (data) => {

    const [modalmenudetail, setModalmenudetail] = useState({ view: false, id: 0, data: [] });

    const clickrefresh = () => {
        setModalmenudetail({ view: false, id: 0, data: [] });
        data.handlerefresh()
    }

    const Qtyshow = (pp) => {

        let valueAdded = 0
        let count = 0

        if (data.datareserve && data.datareserve.data_order) {
            data.datareserve.data_order.filter(valb => valb.id_product === pp.idd).map((val, inde) => {
                count = parseInt(val.qty)
                valueAdded += count
            })
        }

        return <>
            {valueAdded !== 0 ?
                <div className='position-absolute text-white font-18 weight-500 text-center-qty' style={{ top: "10px", right: "10px" }}>{valueAdded}</div>
                : null
            }
        </>
    }

    const clickrepromotion = (id) => {
        // setModalmenudetail({ view: false, id: 0, data: [] });
        // alert(id)
        const data2 = new FormData();

        if(data.tableview){        

            data2.append("id", data.tableview.reserve);
            data2.append("idpromotion", id);
            data2.append("type", "update");

            AuthService.promotionOrderlist(data2).then(
                (res) => {
                    if (res.status === 200) {
                        // window.location.reload();
                        // datas.reloaddata();
                        data.handlerefresh();
                    } else {
                        console.log(res.message)
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        }else{
           data.modalshow()
        }
    }

    // console.log(data.tableview);
    return (
        <>
            <Tab.Content className="bg-white rounded-3 mt-3 h-100per">
                <Tab.Pane eventKey="promotion_all">
                    <div className='m-4 border_hr' >
                        <div className="row">
                            {data.datapromotion.sort((a, b) => a.position - b.position).map((value, index) => (

                                <div className="col-6 col-md-4 col-lg-4 text-start" key={index}>
                                    {value.status === "0" ?
                                        <div className='cursor-pointer'>
                                            <div className='position-relative'>
                                                {
                                                    value.img ?
                                                        <img src={process.env.REACT_APP_API_URL + "/uploads/promotion/" + value.img} alt="" className="w-100 rounded" />
                                                        :
                                                        <img src={noimgpromo} alt="" className="w-100 rounded" />
                                                }
                                                <div className='position-absolute bg-rgba rounded'>
                                                    <span className='position-absolute position-center text-white'>ปิดโปรโมชั่น</span>
                                                </div>
                                            </div>
                                            <div className='font-16 mt-3 weight-500'>{value.promotion}</div>
                                        </div>
                                        :

                                        <div className='cursor-pointer position-relative' onClick={() => clickrepromotion(value.id)}>
                                            {
                                                value.id === data.datareserve.promotion_id ?
                                                    <div className='position-absolute text-white font-18 weight-500 text-center-qty' style={{ top: "10px", right: "10px" }}>
                                                        <img src={icheck} />
                                                    </div>
                                                    : null
                                            }
                                            <div className='position-relative'>
                                                {
                                                    value.img ?
                                                        <img src={process.env.REACT_APP_API_URL + "/uploads/promotion/" + value.img} alt="" className="w-100 rounded" />
                                                        :
                                                        <img src={noimgpromo} alt="" className="w-100 rounded" />
                                                }
                                            </div>
                                            <div className='font-16 mt-3 weight-500'>{value.promotion} </div>
                                        </div>
                                    }
                                </div>

                            ))}
                        </div>
                    </div>
                </Tab.Pane>
                <Tab.Pane eventKey="food_all">
                    <div className='m-4 border_hr' >
                        <div className="row">
                            {data.dataproduct.sort((a, b) => a.position - b.position).map((value, index) => (

                                <div className="col-6 col-md-4 col-lg-4 text-start" key={index}>
                                    <div className='position-relative'>
                                        <Qtyshow idd={value.id} />
                                        {value.status === "0" ?
                                            <div className='cursor-pointer' >
                                                <div className='position-relative'>
                                                    {
                                                        value.img ?
                                                            <img src={process.env.REACT_APP_API_URL + "/uploads/product/" + value.img} alt="" className="w-100 rounded" />
                                                            :
                                                            <img src={noimg} alt="" className="w-100 rounded" />
                                                    }

                                                    <div className='position-absolute bg-rgba rounded'>
                                                        <span className='position-absolute position-center text-white'>ไม่พร้อมจำหน่าย</span>
                                                    </div>
                                                </div>
                                                <div className='font-16 mt-3'>{value.name}</div>
                                                <div className='font-18 mt-2 mb-5'><b>{(parseFloat(value.price)).toLocaleString('en-US', { maximumFractionDigits: 2 })}</b></div>
                                            </div>
                                            :
                                            data.tableview ?
                                                <div className='cursor-pointer' onClick={() => setModalmenudetail({ view: true, id: value.id, type: "product", data: data.dataproduct.filter(vls => vls.id === value.id) })}>
                                                    <div className='position-relative'>
                                                        {
                                                            value.img ?
                                                                <img src={process.env.REACT_APP_API_URL + "/uploads/product/" + value.img} alt="" className="w-100 rounded" />
                                                                :
                                                                <img src={noimg} alt="" className="w-100 rounded" />
                                                        }
                                                    </div>
                                                    <div className='font-16 mt-3'>{value.name}</div>
                                                    <div className='font-18 mt-2 mb-5'><b>{(parseFloat(value.price)).toLocaleString('en-US', { maximumFractionDigits: 2 })}</b></div>
                                                </div>
                                                :
                                                <div className='cursor-pointer' onClick={data.modalshow}>
                                                    <div className='position-relative'>
                                                        {
                                                            value.img ?
                                                                <img src={process.env.REACT_APP_API_URL + "/uploads/product/" + value.img} alt="" className="w-100 rounded" />
                                                                :
                                                                <img src={noimg} alt="" className="w-100 rounded" />
                                                        }
                                                    </div>
                                                    <div className='font-16 mt-3'>{value.name}</div>
                                                    <div className='font-18 mt-2 mb-5'><b>{(parseFloat(value.price)).toLocaleString('en-US', { maximumFractionDigits: 2 })}</b></div>
                                                </div>
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Tab.Pane>
                {data.datacat.map((value, index) => (
                    <Tab.Pane eventKey={value.id} key={index}>
                        <div className='m-4 border_hr'>
                            <div className="row">
                                {data.dataproduct.filter(valb => valb.cat_id === value.id).sort((a, b) => a.position - b.position).map((value, index) => (

                                    <div className="col-6 col-md-4 col-lg-4 text-start" key={index}>
                                        <div className='position-relative'>
                                            <Qtyshow idd={value.id} />
                                            {value.status === "0" ?
                                                <div className='cursor-pointer'>
                                                    <div className='position-relative'>
                                                        {
                                                            value.img ?
                                                                <img src={process.env.REACT_APP_API_URL + "/uploads/product/" + value.img} alt="" className="w-100 rounded" />
                                                                :
                                                                <img src={noimg} alt="" className="w-100 rounded" />
                                                        }

                                                        <div className='position-absolute bg-rgba rounded'>
                                                            <span className='position-absolute position-center text-white'>ไม่พร้อมจำหน่าย</span>
                                                        </div>

                                                    </div>
                                                    <div className='font-16 mt-3'>{value.name}</div>
                                                    <div className='font-18 mt-2 mb-5'><b>{(parseFloat(value.price)).toLocaleString('en-US', { maximumFractionDigits: 2 })}</b></div>
                                                </div>
                                                :
                                                data.tableview ?
                                                    <div className='cursor-pointer' onClick={() => setModalmenudetail({ view: true, id: value.id, type: "product", data: data.dataproduct.filter(vls => vls.id === value.id) })}>
                                                        <div className='position-relative'>
                                                            {
                                                                value.img ?
                                                                    <img src={process.env.REACT_APP_API_URL + "/uploads/product/" + value.img} alt="" className="w-100 rounded" />
                                                                    :
                                                                    <img src={noimg} alt="" className="w-100 rounded" />
                                                            }
                                                        </div>
                                                        <div className='font-16 mt-3'>{value.name}</div>
                                                        <div className='font-18 mt-2 mb-5'><b>{(parseFloat(value.price)).toLocaleString('en-US', { maximumFractionDigits: 2 })}</b></div>
                                                    </div>
                                                    :
                                                    <div className='cursor-pointer' onClick={data.modalshow}>
                                                        <div className='position-relative'>
                                                            {
                                                                value.img ?
                                                                    <img src={process.env.REACT_APP_API_URL + "/uploads/product/" + value.img} alt="" className="w-100 rounded" />
                                                                    :
                                                                    <img src={noimg} alt="" className="w-100 rounded" />
                                                            }
                                                        </div>
                                                        <div className='font-16 mt-3'>{value.name}</div>
                                                        <div className='font-18 mt-2 mb-5'><b>{(parseFloat(value.price)).toLocaleString('en-US', { maximumFractionDigits: 2 })}</b></div>
                                                    </div>
                                            }
                                        </div>
                                    </div>

                                ))}
                            </div>
                        </div>
                    </Tab.Pane>
                ))}
            </Tab.Content>
            {data.tableview ?
                <Modalmenudetail show={modalmenudetail.view} onHide={clickrefresh} id={modalmenudetail.id} dataone={modalmenudetail.data} type={modalmenudetail.type} idreserve={data.tableview.reserve} />
                : null}
        </>
    );
};

export default Ordermenutab;