import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';

import './assets/css/style.css';
import './assets/css/respornsive.css';


import Login from './pages/login';
import Loginmember from './pages/login_member';
import Logoutmember from './pages/logoutAll';
import Register from './pages/register/register';
import Registerb from './pages/register/register_b';
import Registersuccess from './pages/register/registersuccess';

import Forgotpassword from './pages/register/forgotpassword';
import Newpassword from './pages/register/newpassword';

import Business from './pages/business/business';
import Addbusiness from './pages/business/addbusiness';
import Businesspin from './pages/business/business_pin';
import Businesspininput from './pages/business/business_pininput';
import Editbusiness from './pages/business/editbusiness';

import Branch from './pages/branch/branch';
import Addbranch from './pages/branch/addbranch';
import Editbranch from './pages/branch/editbranch';
import Branchpin from './pages/branch/branch_pin';
import Branchaddpin from './pages/branch/branch_addpin';
import Branchpininput from './pages/branch/branch_pininput';

import Tablemanage from './pages/table/table_manage';
import Tablereserve from './pages/table/table_reserve';
import Tablereserveedit from './pages/table/table_reserve_edit';
import Tabledetail from './pages/table/table_detail';
import Tabledetailreserve from './pages/table/table_detail_reserve';

import Menu from './pages/menu/menu_manage';
import Ordermenu from './pages/order/order_menu';
import Orderopen from './pages/order/order_open';

import Kitchen from './pages/kitchen/kitchen';
import Bar from './pages/bar/bar';

import Setting from './pages/setting/setting';
import Settingpayment from './pages/setting/setting_payment';
import Settingproduct from './pages/setting/setting_product';
import Settingemployee from './pages/setting/setting_employee';
import Settingtable from './pages/setting/setting_table';
import Settingprinter from './pages/setting/setting_printer';
import Settinginvoice from './pages/setting/setting_invoice';
import Settingbanner from './pages/setting/setting_banner';
import Settinglinklogin from './pages/setting/setting_linklogin';

import Report from './pages/report/report';
import Reportsummary from './pages/report/report_summary';
import Reportsummarymonth from './pages/report/report_summary_month';
import Reportemployee from './pages/report/report_employee';
import Reportwastedata from './pages/report/report_waste';
import Reportwaste from './pages/report/waste';
import Reportreceipt from './pages/report/report_receipt';

import Member from './pages/member/member';
import Membertable from './pages/member/table_member';

import Payment from './pages/payment/payment';
import TestPayment from './pages/payment/testPayment';

import Orderfood from './pages/order/order_food';
import Ordercart from './pages/order/order_cart';
import Orderfooddetail from './pages/order/order_food_detail';

import Warehouse from './pages/warehouse/warehouse';
import Warehousecreate from './pages/warehouse/warehouse_create';
import Warehousemove from './pages/warehouse/warehouse_move';
import Warehousewithdraw from './pages/warehouse/warehouse_withdraw';
import Warehousereport from './pages/warehouse/warehouse_report';

// import { Navigation, Route, Screen, glide, fade } from "react-tiger-transition";

// glide({
//   name: 'glide-left'
// });
// glide({
//   name: 'glide-right',
//   direction: 'right'
// });
// fade({
//   name: "default-fade"
// });

function App() {

  return (

    <div className="App">
      <BrowserRouter basename=''>
      {/* <Switch> */}
        <Routes>
          <Route exact path="/" element={<Login />}/> 

          <Route path="/loginmember/:id" element={<Loginmember />}></Route>
          <Route path="/logoutmember/:id" element={<Logoutmember />}></Route>

          <Route path="/register" element={<Register />}/>          
          <Route path="/register_b" element={<Registerb />}/>          
          <Route path="/registersuccess" element={<Registersuccess />}/>  
          
          <Route path="/forgotpassword" element={<Forgotpassword />}/>  
          <Route path="/newpassword" element={<Newpassword />}/>  

          <Route path="/business" element={<Business />}/>          
          <Route path="/addbusiness" element={<Addbusiness />}/>          
          <Route path="/businesspin" element={<Businesspin />}/>          
          <Route path="/businesspininput" element={<Businesspininput />}/> 
          <Route path="/editbusiness" element={<Editbusiness />}/>  

          <Route path="/branch" element={<Branch />}></Route>
          <Route path="/addbranch" element={<Addbranch />}></Route>
          <Route path="/branchpin" element={<Branchpin />}/>      
              
          <Route path="/branchaddpin" element={<Branchaddpin />}></Route>

          <Route path="/branchpininput" element={<Branchpininput />}/> 
          <Route path="/editbranch" element={<Editbranch />}></Route>
          

          <Route path="/table/manage" element={<Tablemanage />} />
          <Route path="/table/reserve" element={<Tablereserve />}></Route>
          <Route path="/table/reserve_edit" element={<Tablereserveedit />}></Route>
          <Route path="/table/detail" element={<Tabledetail />}></Route>
          <Route path="/table/detail_reserve" element={<Tabledetailreserve />}></Route>

          <Route path="/menu/manage" element={<Menu />}></Route>

          <Route path="/order/ordermenu" element={<Ordermenu />}></Route>
          <Route path="/order/orderopen" element={<Orderopen />}></Route>

          <Route path="/kitchen/kitchen" element={<Kitchen />}></Route>
          <Route path="/bar/bar" element={<Bar />}></Route>

          <Route path="/setting/setting" element={<Setting />}></Route>
          <Route path="/setting/payment" element={<Settingpayment/>}></Route>
          <Route path="/setting/product" element={<Settingproduct />}></Route>
          <Route path="/setting/employee" element={<Settingemployee />}></Route>
          <Route path="/setting/table" element={<Settingtable />}></Route>
          <Route path="/setting/printer" element={<Settingprinter />}></Route>
          <Route path="/setting/invoice" element={<Settinginvoice />}></Route>
          <Route path="/setting/banner" element={<Settingbanner />}></Route>
          <Route path="/setting/linklogin" element={<Settinglinklogin />}></Route>

          <Route path="/report/report" element={<Report />}></Route>
          <Route path="/report/summary" element={<Reportsummary />}></Route>
          <Route path="/report/summarymonth" element={<Reportsummarymonth />}></Route>
          <Route path="/report/employee" element={<Reportemployee />}></Route>
          <Route path="/report/wastedata" element={<Reportwastedata />}></Route>
          <Route path="/report/waste" element={<Reportwaste />}></Route>
          <Route path="/report/reportreceipt" element={<Reportreceipt />}></Route>
          
          <Route path="/member/member" element={<Member />}></Route>
          <Route path="/member/table_member" element={<Membertable />}></Route>
          
          <Route path="/payment/payment/:id" element={<Payment />}></Route>
          <Route path="/payment/testpayment" element={<TestPayment />}></Route>

          <Route path="/orderfood/:id" element={<Orderfood />}></Route>
          <Route path="/orderfooddetail/:decode/:id/:type" element={<Orderfooddetail />}></Route>
          <Route path="/ordercart/:id" element={<Ordercart />}></Route>
          
          <Route path="/warehouse/warehouse" element={<Warehouse />}></Route>
          <Route path="/warehouse/warehousecreate" element={<Warehousecreate />}></Route>
          <Route path="/warehouse/warehousemove" element={<Warehousemove />}></Route>
          <Route path="/warehouse/warehousewithdraw" element={<Warehousewithdraw />}></Route>
          <Route path="/warehouse/warehousereport" element={<Warehousereport />}></Route>
                     
        </Routes>
        {/* </Switch> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
