import React, { useState, useCallback, useEffect } from 'react';

import { useNavigate,Link } from "react-router-dom";

import PostService from "../../components/services_member/post.service";
import AuthService from "../../components/services_member/auth.service";

import help from '../../assets/img/help.svg';
import iconedit from '../../assets/img/icon-edit.svg';
import icondel from '../../assets/img/icon-del.svg';
import iconplus from '../../assets/img/icon-plus-white.svg';


import Menuleft from "../../layout/pagesadmin/menuleft";
import Menusetting from "../../components/setting/setting_menu";

import { Nav, ListGroup } from "react-bootstrap";

// import noimg from '../../assets/img/no-img.svg';

import Modalemployee from "../../layout/modal/modalEmployee";
import Modalemployeeadd from "../../layout/modal/modalEmployeeadd";
import { useTranslation } from 'react-i18next';
const Settingemployee = () => {
    const { t } = useTranslation();
    let history = useNavigate();

    const user = AuthService.getCurrentUser();
    const branch = AuthService.getCurrentBranch();

    if (!user) {
        history("/");
    } else {
        if (!branch) {
            history("/branch");
        }
    }

    const [dataemployee, setDataemployee] = useState([]);
    const [modalemployee, setModalemployee] = useState({ view: false, id: 0, type: false, dataem: [] });
    const [modalemployeeadd, setModalemployeeadd] = useState({ view: false, id: 0, type: false });

    const fetchData = useCallback(async () => {
        PostService.getEmployeeall(branch.id).then(
            (response) => {
                if (response.data.status === 200) {
                    setDataemployee(response.data.data);
                } else {
                    setDataemployee([]);
                }
            },
            (error) => {
                console.log(error);
            }
        )
    });

    useEffect(() => {
        fetchData()
    }, []);

    const modalem = (id, type) => {
        if (type === "edit") {
            setModalemployee({ view: true, id: id, type: "edit", dataem: dataemployee.filter(val => val.id === id) })
        } else if (type === "detail") {
            //detail
            setModalemployee({ view: true, id: id, type: "detail", dataem: dataemployee.filter(val => val.id === id) })
        } else {
            // del
            setModalemployee({ view: true, id: id, type: "del", dataem: dataemployee.filter(val => val.id === id) })
        }
    }


    const setReset = (e) => {
        fetchData();
        setModalemployeeadd({ view: false })
        setModalemployee({ view: false })
    }

    // console.log(dataemployee)

    return (
        <>
            <div className={!t('menu-a') ? "sidebar-mini sidebar-collapse" : ""} >
                <div className='wrapper-admin layout-fixed'>
                    <nav className="main-header navbar bg-white shadow-sm" style={{ height: "60px" }}>
                        <Nav as="ul" variant="tabs" className="nav nav-tabs overflow-hidden ul-tabcustom">

                        </Nav>
                        <ul className="navbar-nav ml-auto" style={{ marginTop: "-20px" }}>
                            <li className="">
                                <div className='text-gray font-12'><img src={help} alt="" className="me-1" style={{ width: "14px" }} /> ขอความช่วยเหลือ</div>
                            </li>
                        </ul>
                    </nav>

                    <Menuleft type="setting" />

                    <div className="content-wrapper">
                        <section className="content scroll-custom">
                            <div className="row">
                                <div className="col-12 col-md-5 col-lg-5">
                                    <div className='text-start box-setting bg-white rounded-3 mt-3 h-100per'>
                                        <Menusetting type="employee" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-7 col-lg-7 ps-0">
                                    <div className='bg-white rounded-3 ms-2 mt-3 border-bottom-none pt-1 pb-2'>
                                        <div className='text-start py-3 shadow-sm px-4'>
                                            <b>พนักงาน</b>
                                            <span className='float-end' style={{ marginTop: "-7px" }}>
                                                <button onClick={() => setModalemployeeadd({ view: true, id: 0, type: "edit" })} className="btn btn-submit text-white py-1 px-3 font-14">
                                                    <img src={iconplus} alt="" className="" /> เพิ่มพนักงาน
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="bg-white rounded-3 ms-2 h-100per-right-c position-relative border-top-none">
                                        <div className='text-start box-employee' style={{ marginTop: "-5px" }}>
                                            <ListGroup>
                                                {dataemployee.map((value, index) => (
                                                    <ListGroup.Item key={index}>
                                                        <span onClick={() => modalem(value.id, "detail")}>{value.name} ({value.position === '0' ? 'ผู้จัดการร้าน' : value.position === '1' ? 'แคชเชียร์' : value.position === '2' ? 'Checker' : 'พนักงานครัว'})</span>
                                                        <span className='float-end'>
                                                            <span className='button-edit me-2' onClick={() => modalem(value.id, "edit")}><img src={iconedit} alt="" className="" /></span>
                                                            <span className='button-del' onClick={() => modalem(value.id, "del")}><img src={icondel} alt="" className="" /></span>
                                                        </span>
                                                    </ListGroup.Item>
                                                ))}
                                            </ListGroup>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </div>
            {modalemployee.view ?
                <Modalemployee show={modalemployee.view} onHide={() => setReset()} dataemployee={modalemployee.dataem} type={modalemployee.type} />

                : null}

            <Modalemployeeadd show={modalemployeeadd.view} onHide={() => setReset()} type={modalemployeeadd.type} />
        </>
    );
};

export default Settingemployee;