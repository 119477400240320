import React, { useState, useCallback, useEffect } from 'react';

import { compareAsc, format } from 'date-fns'

import AuthService from "../../components/services_member/auth.service";
import PostService from "../../components/services_member/post.service";

import { useNavigate,Link } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";

import { Form, Tab, Nav } from "react-bootstrap";
// import { Form, Col, Row, InputGroup, Button } from "react-bootstrap";

import Modalcancelreserve from "../../layout/modal/modalCancelreserve";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

import iconqrcode from '../../assets/img/qrcode.jpg';

import Reserdetailedit from "../../pages/table/table_reserve_edit";

import Modalqrcodemenu from "../../layout/modal/modalQrcodemenu";
import { useTranslation } from 'react-i18next';

const Tabledetailreserve = (data) => {

    const { t } = useTranslation();
    let history = useNavigate();

    const [modalcancelreserve, setModalcancelreserve] = useState({ view: false, datatable: [], data: [] });

    const [reserveeditview, setReserveeditview] = useState(false);

    const [datatable, setDatatable] = useState(data.datatable);
    const [dateday, setDateday] = useState(format(new Date(data.detailreserve.date_reserve), 'dd/MM/yyyy'));

    const [dataactive, setDataactive] = useState({ zone: '', table: '' });

    const [modalqrcodemenu, setModalqrcodemenu] = useState({ view: false, id: 0 });

    // console.log(data.datatableall)

    const setStatus = (e) => {
        // alert(e)
        const data2 = new FormData();

        data2.append("id", e);
        data2.append("status", '2');

        AuthService.updateReservestatus(data2).then(
            (res) => {
                //   console.log(res)   
                if (res.status === 200) {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: '',
                        text: "เดินทางมาถึงแล้ว",
                        showConfirmButton: false,
                        timer: 1500
                    }).then(function () {
                        window.location.reload()
                    })

                } else {
                    // console.log(res.message)
                }
            },
            (error) => {
                console.log(error);
            }
        );

    }

    const switview = () => {
        setReserveeditview(!reserveeditview)
    }

    // const selectlist = (zone, table) => {
    //     data.nadlereserve(zone, table)
    //     setDataactive({ zone: zone, table: table })
    // }

    const handleTablesubmit = (zone1) => {

        const table = (JSON.parse(data.datatable.detail)).filter(valb => valb.id === parseInt(data.detailreserve.table_reserve))[0].table_number;

        localStorage.setItem('table_order', JSON.stringify({ id: data.detailreserve.zone, zone: zone1, table_id: data.detailreserve.table_reserve, table: table, reserve: data.detailreserve.id, date: dateday }));

        history("/order/ordermenu");
    }

    console.log(data.detailreserve)
    return (
        <>
            {reserveeditview ?
                <Reserdetailedit datatable={data.datatableall} datareserve={data.detailreserve} dateday={dateday} swit={switview} />
                :
                <>
                    <div className="bg-white rounded-3 ms-2 mt-3 h-100per-right position-relative">
                        <div className='px-4'>
                            <div className='text-start py-3 font-18'><b>รายละเอียด</b></div>
                            <div className='position-relative mt-3 text-start'>
                                <div className=' font-18'><b>{data.datatable.zone} :
                                    <span className='me-2 float-end cursor' onClick={() => setModalqrcodemenu({ view: true,id:data.detailreserve.id,zone: data.datatable.zone,table: (JSON.parse(data.datatable.detail)).filter(valb => valb.id === parseInt(data.detailreserve.table_reserve))[0].table_number, no: data.detailreserve.no })}>
                                        <img src={iconqrcode} style={{ width: '30px' }} />
                                    </span>

                                    <span>{(JSON.parse(data.datatable.detail)).filter(valb => valb.id === parseInt(data.detailreserve.table_reserve))[0].table_number}</span>


                                </b></div>
                                <div className='font-14 mt-2'>จำนวนที่นั่ง : {data.detailreserve.number_seats}</div>
                                <div className='font-14 mt-1'>วัน - เวลา : {format(new Date(data.detailreserve.date_reserve), 'dd/MM/yyyy')} - {data.detailreserve.time_reserve}</div>
                                <div className='font-14 mt-1'>ชื่อลูกค้า : {data.detailreserve.name_member}</div>
                                <div className='font-14 mt-1'>เบอร์โทร : {data.detailreserve.tel_member}</div>
                                <div className='font-14 mt-1'>รายละเอียดเพิ่มเติม : {data.detailreserve.detail}</div>
                                {data.detailreserve.status === '1' ?
                                    <div>
                                        <div className='mt-3'>
                                            <span onClick={() => switview()} className="w-50 btn btn-border-orange py-2">
                                                แก้ไข
                                            </span>
                                        </div>
                                        <div className='mt-2'>
                                            <button className="w-50 btn btn-border-red py-2" onClick={() => setModalcancelreserve({ view: true, datatable: data.datatable, data: data.detailreserve })}>
                                                ยกเลิกการจอง
                                            </button>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <div className='mt-4'>
                                            <div className=' font-18'><b>สถานะ :</b></div>
                                            <div className='font-14 mt-1'>ทานที่ร้าน</div>
                                        </div>
                                        <div className='mt-4'>
                                            <div className=' font-18'><b>เวลา :</b></div>
                                            <div className='font-14 mt-1'>{data.detailreserve.time_update} - ปัจจุบัน</div>
                                        </div>
                                        <div className='mt-3'>
                                            <button onClick={() => handleTablesubmit(data.datatable.zone, "002")} transition="default-fade" className="w-50 btn btn-border-blue py-2">
                                                ดูรายการที่สั่ง
                                            </button>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>

                    </div>
                    <div className='w-100 ps-2 mt-2' >
                        {data.detailreserve.status === '1' ?
                            <span className="w-100 btn btn-submit text-white py-2" onClick={(e) => setStatus(data.detailreserve.id)}>
                                มาแล้ว
                            </span>
                            :
                            <Link to={"/payment/payment/" + data.detailreserve.id} transition="default-fade" className="w-100 btn btn-submit text-white py-2">
                                ชำระเงิน
                            </Link>
                        }
                    </div>
                    {modalcancelreserve.view ?
                        <Modalcancelreserve show={modalcancelreserve.view} onHide={() => setModalcancelreserve({ view: false, id: 0 })} datatable={modalcancelreserve.datatable} data={modalcancelreserve.data} />
                        : null}

                    {modalqrcodemenu.view ?
                        <Modalqrcodemenu show={modalqrcodemenu.view} onHide={() => setModalqrcodemenu({ view: false })} id={modalqrcodemenu.id} zone={modalqrcodemenu.zone} table={modalqrcodemenu.table} no={modalqrcodemenu.no} date={format(new Date(data.detailreserve.date_reserve), 'dd/MM/yyyy') + " - " + data.detailreserve.time_reserve} />
                        : null}
                </>
            }
        </>
    );
};

export default Tabledetailreserve;