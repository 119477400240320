import React, { useState, useCallback, useEffect } from 'react';
// import axios from 'axios';
import { useNavigate } from "react-router-dom";
import Moment from 'moment';
import { compareAsc, format } from 'date-fns';

import '../assets/css/print.css';

// import PostService from "./services_member/post.service";
// import AuthService from "./services_member/auth.service";

// const getToken = () => {
//     const tokenString = localStorage.getItem("login_member");
//     const userToken = JSON.parse(tokenString);
//     return userToken?.token
// }

const ComponentToorder = (data) => {

    let history = useNavigate();

    // const getPageMargins = () => {
    //     return `@page { margin: 0 !important; }`;
    // };
    // console.log(data.datareceipt.logo)
    return (
        <>
            {data.select.map((value2, index2) => (
                <div className="ticket mb-5" key={index2}>
                    <div className='mt-3 font-12 text-start'>
                        <div className='font-10'>
                            <div className='font-14'>ส่งออเดอร์กำลังปรุง</div>
                            <div className='font-10 weight-400'>
                                #{data.order.filter(vls => vls.id === (data.plang.filter(vls => vls.id === value2)[0].id_order))[0].no}
                            </div>
                            <div className='font-10 weight-400'>
                                {data.plang.filter(vls => vls.id === value2)[0].name_product}
                                <span className='ps-3 font-10'>
                                    X {data.plang.filter(vls => vls.id === value2)[0].qty}
                                </span>
                                <span>
                                    {data.plang.filter(vls => vls.id === value2)[0].choice ? (JSON.parse(data.plang.filter(vls => vls.id === value2)[0].choice)).map((valsc, indesc) => (
                                        <div className='ms-3 pe-5' key={indesc}>
                                            <div className='font-10'>- {valsc.more_name}</div>
                                        </div>
                                    ))
                                        : null}
                                </span>

                                {data.plang.filter(vls => vls.id === value2)[0].note ? <div className='font-10'>หมายเหตุ : {data.plang.filter(vls => vls.id === value2)[0].note}</div> : null}
                            </div>
                            <div className='font-10 weight-400'>
                                {data.order.filter(vls => vls.id === (data.plang.filter(vls => vls.id === value2)[0].id_order))[0].zone === '0' ? "Drive Thru" :
                                    data.order.filter(vls => vls.id === (data.plang.filter(vls => vls.id === value2)[0].id_order))[0].zone_name + ":" + data.order.filter(vls => vls.id === (data.plang.filter(vls => vls.id === value2)[0].id_order))[0].table_name}
                            </div>
                            <div className=' font-10 weight-400'>เวลา {Moment(data.plang.filter(vls => vls.id === value2)[0].date_kitchen).format('HH:mm')} น.
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default ComponentToorder;