import React, { useState, useEffect, useCallback } from 'react';
// import axios from 'axios';
import { useNavigate } from "react-router-dom";

import help from '../../assets/img/help.svg';

import Menuleft from "../../layout/pagesadmin/menuleft";
import Menusetting from "../../components/setting/setting_menu";
import { Form, Nav, Row, Col } from "react-bootstrap";

import AuthService from "../../components/services_member/auth.service";
import PostService from "../../components/services_member/post.service";
import { useTranslation } from 'react-i18next';
const Settinglinklogin = () => {
    const { t } = useTranslation();
    let history = useNavigate();

    const user = AuthService.getCurrentUser();
    const branch = AuthService.getCurrentBranch();

    if (!user) {
        history("/");
    } else {
        if (!branch) {
            history("/branch");
        }
    }

    const [datareceipt, setDatareceipt] = useState(false);

    const fetchData = useCallback(async () => {
        PostService.getReceipt(branch.id).then(
            (response) => {
                if (response.data.status === 200) {
                    setDatareceipt(response.data.data);
                    // setBlob(datareceipt.logo)
                    // const dafa = response.data.data[0];
                }
            },
            (error) => {
                console.log(error);
            }
        )
    });

    useEffect(() => {
        fetchData()
    }, []);

    console.log(datareceipt)

    return (
        <>
            <div className={!t('menu-a') ? "sidebar-mini sidebar-collapse" : ""} >
                <div className='wrapper-admin layout-fixed'>
                    <nav className="main-header navbar bg-white shadow-sm" style={{ height: "60px" }}>
                        <Nav as="ul" variant="tabs" className="nav nav-tabs overflow-hidden ul-tabcustom">

                        </Nav>
                        <ul className="navbar-nav ml-auto" style={{ marginTop: "-20px" }}>
                            <li className="">
                                <div className='text-gray font-12'><img src={help} alt="" className="me-1" style={{ width: "14px" }} /> ขอความช่วยเหลือ</div>
                            </li>
                        </ul>
                    </nav>

                    <Menuleft type="setting" />

                    <div className="content-wrapper">
                        <section className="content scroll-custom">
                            <div className="row">
                                <div className="col-12 col-md-5 col-lg-5">
                                    <div className='text-start box-setting bg-white rounded-3 mt-3 h-100per'>
                                        <Menusetting type="linklogin" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-7 col-lg-7 ps-0">
                                    <div className='bg-white rounded-3 ms-2 mt-3 border-bottom-none pb-2 pt-1'>
                                        <div className='text-start py-3 weight-500 shadow-sm px-4'>URL ล็อกอินสำหรับพนักงาน</div>
                                        <Form.Control type="hidden" name='id' defaultValue={datareceipt.id} className='font-14 input-custom' />
                                    </div>
                                    <div className="bg-white rounded-3 ms-2 h-100per-right-c position-relative border-top-none">
                                        <div className='px-4 py-2 text-start box-list'>
                                            <Row className="align-items-end mt-3 mb-3">
                                                <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                                    <Form.Label className="font-12 text-gray mb-0">Link URL</Form.Label>
                                                    <Form.Control type="text" name='company' defaultValue={datareceipt.linklogin} className='font-14 input-custom' required />
                                                </Form.Group>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Settinglinklogin;