import React, { useState, useEffect, useCallback } from 'react';
// import axios from 'axios';
import { useNavigate,Link } from "react-router-dom";

import help from '../../assets/img/help.svg';


import Menuleft from "../../layout/pagesadmin/menuleft";
import Menusetting from "../../components/setting/setting_menu";

import { Form, Nav, Row, Col } from "react-bootstrap";

import noimg from '../../assets/img/no-img.svg';

import Modalcropbranch from "../../layout/modal/modalCropbranch";

import AuthService from "../../components/services_member/auth.service";
import PostService from "../../components/services_member/post.service";


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useTranslation } from 'react-i18next';
const Settinginvoice = () => {

    const { t } = useTranslation();
    let history = useNavigate();

    const user = AuthService.getCurrentUser();
    const branch = AuthService.getCurrentBranch();

    if (!user) {
        history("/");
    } else {
        if (!branch) {
            history("/branch");
        }
    }

    const [datareceipt, setDatareceipt] = useState(false);

    const [validated, setValidated] = useState(false);
    const [errorblob, setErrorblob] = useState();

    const [blob, setBlob] = useState(null)
    const [inputImg, setInputImg] = useState('');
    const [modalcrop, setModalcrop] = useState({ view: false, id: 0 });

    const getBlob = (blob) => {
        // pass blob up from the ImageCropper component
        setBlob(blob);
        setModalcrop({ view: false });
        setErrorblob(false)
    }

    const onInputChange = (e) => {
        // convert image file to base64 string
        const file = e.target.files[0]
        const reader = new FileReader();

        reader.addEventListener('load', () => {
            setInputImg(reader.result)
            setModalcrop({ view: true, id: 1, getblob: getBlob, inputImg: reader.result })
        }, false)

        if (file) {
            reader.readAsDataURL(file)
        }
    }

    const fetchData = useCallback(async () => {
        PostService.getReceipt(branch.id).then(
            (response) => {
                if (response.data.status === 200) {
                    setDatareceipt(response.data.data);
                    // setBlob(datareceipt.logo)
                    // const dafa = response.data.data[0];
                }
            },
            (error) => {
                console.log(error);
            }
        )
    });

    useEffect(() => {
        fetchData()
    }, []);

    const handleSubmit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const data = new FormData(event.target);

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        data.append("branch_id", branch.id);
        data.append("blob", JSON.stringify(blob));

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

            if (!datareceipt.logo) {
                if (!blob) {
                    setErrorblob("Please select uploads file.");
                }
            }
        } else {
            if (!datareceipt.logo) {
                if (!blob) {
                    setErrorblob("Please select uploads file.");
                } else {
                    AuthService.updateReceipt(data).then(
                        (res) => {
                            //   console.log(res)   
                            if (res.status === 200) {
                                // window.location.reload();

                                Swal.fire({
                                    position: 'center',
                                    icon: 'success',
                                    title: '',
                                    text: "บันทึกข้อมูลเรียบร้อยแล้ว",
                                    showConfirmButton: false,
                                    timer: 1500
                                }).then(function () {
                                    fetchData();
                                    setValidated(false);
                                })
                            } else {
                                // console.log(res.message)
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                }
            } else {
                AuthService.updateReceipt(data).then(
                    (res) => {
                        //   console.log(res)   
                        if (res.status === 200) {
                            // window.location.reload();
                            Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: '',
                                text: "บันทึกข้อมูลเรียบร้อยแล้ว",
                                showConfirmButton: false,
                                timer: 1500
                            }).then(function () {
                                fetchData();
                                setValidated(false);
                            })
                        } else {
                            // console.log(res.message)
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            }
        }
        setValidated(true);
    };

    return (
        <>
            <div className={!t('menu-a') ? "sidebar-mini sidebar-collapse" : ""} >
                <div className='wrapper-admin layout-fixed'>
                    <nav className="main-header navbar bg-white shadow-sm" style={{ height: "60px" }}>
                        <Nav as="ul" variant="tabs" className="nav nav-tabs overflow-hidden ul-tabcustom">

                        </Nav>
                        <ul className="navbar-nav ml-auto" style={{ marginTop: "-20px" }}>
                            <li className="">
                                <div className='text-gray font-12'><img src={help} alt="" className="me-1" style={{ width: "14px" }} /> ขอความช่วยเหลือ</div>
                            </li>
                        </ul>
                    </nav>

                    <Menuleft type="setting" />

                    <div className="content-wrapper">
                        <section className="content scroll-custom">
                            <div className="row">
                                <div className="col-12 col-md-5 col-lg-5">
                                    <div className='text-start box-setting bg-white rounded-3 mt-3 h-100per'>
                                        <Menusetting type="invoice" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-7 col-lg-7 ps-0">
                                    <Form noValidate validated={validated} onSubmit={handleSubmit} className="">
                                        <div className='bg-white rounded-3 ms-2 mt-3 border-bottom-none pb-2 pt-1'>
                                            <div className='text-start py-3 weight-500 shadow-sm px-4'>รูปแบบใบเสร็จ</div>
                                            <Form.Control type="hidden" name='id' defaultValue={datareceipt.id} className='font-14 input-custom' />
                                        </div>
                                        <div className="bg-white rounded-3 ms-2 h-100per-right-b position-relative border-top-none">
                                            <div className='px-4 py-2 text-start box-list'>

                                                <div>
                                                    <div className='pb-3 py-3 text-center' >
                                                        <label htmlFor="upload" className='cursor'>
                                                            {
                                                                blob ? (
                                                                    <img src={blob} alt="" style={{ width: "150px" }} />
                                                                ) :
                                                                    datareceipt.logo ?
                                                                        <img src={process.env.REACT_APP_API_URL + "/uploads/receipt/" + datareceipt.logo} alt="" style={{ width: "150px" }} />
                                                                        :
                                                                        <img src={noimg} alt="" style={{ width: "150px" }} />
                                                            }
                                                        </label>
                                                        <input type='file' name="file" id="upload" accept='image/*' onChange={onInputChange} hidden />
                                                        <div className='mt-2 font-14 text-gray'>อัพโหลดภาพโลโก้</div>
                                                        <div className='font-12 weight-300' style={{ color: "red" }}>{errorblob}</div>
                                                    </div>
                                                </div>
                                                <Row className="align-items-end mt-3 mb-3">
                                                    <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                                        <Form.Label className="font-12 text-gray mb-0">ชื่อบริษัท</Form.Label>
                                                        <Form.Control type="text" name='company' defaultValue={datareceipt.company} className='font-14 input-custom' required />
                                                    </Form.Group>
                                                </Row>

                                                <Row className="align-items-end mt-3 mb-3">
                                                    <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                                        <Form.Label className="font-12 text-gray mb-0">เลขประจําตัวผู้เสียภาษี</Form.Label>
                                                        <Form.Control type="text" name='id_num' defaultValue={datareceipt.id_num} className='font-14 input-custom' required />
                                                    </Form.Group>
                                                </Row>
                                                <Row className="align-items-end mt-3 mb-3">
                                                    <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                                        <Form.Label className="font-12 text-gray mb-0">ที่อยู่</Form.Label>
                                                        <Form.Control type="text" name='address' defaultValue={datareceipt.address} className='font-14 input-custom' required />
                                                    </Form.Group>
                                                </Row>
                                                <Row className="align-items-end mt-3 mb-3">
                                                    <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                                        <Form.Label className="font-12 text-gray mb-0">เบอร์โทร</Form.Label>
                                                        <Form.Control type="text" name='tel' defaultValue={datareceipt.tel} className='font-14 input-custom' placeholder='xxx-xxx-xxxx' required />
                                                    </Form.Group>
                                                </Row>
                                                <Row className="align-items-end mt-3 mb-3">
                                                    <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                                        <Form.Label className="font-12 text-gray mb-0">อีเมล</Form.Label>
                                                        <Form.Control type="email" name='email' defaultValue={datareceipt.email} className='font-14 input-custom' placeholder='xxx@xxx.xxx' required />
                                                    </Form.Group>
                                                </Row>
                                            </div>
                                        </div>
                                        <div className='w-100 ps-2 mt-2'>
                                            <div className='row'>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <Link to="/table/detail_reserve" transition="default-fade" className="w-100 btn btn-calcel text-white py-2 me-3">
                                                        ยกเลิก
                                                    </Link>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <button type='submit' className="w-100 btn btn-submit text-white py-2 me-3">
                                                        บันทึก
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </div>
            <Modalcropbranch show={modalcrop.view} onHide={() => setModalcrop({ view: false })} id={modalcrop.id} getblob={getBlob} inputimg={modalcrop.inputImg} />
        </>
    );
};

export default Settinginvoice;