import React, { useState, useRef } from 'react';
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import AuthService from "../../components/services_member/auth.service";

// import { BrowserRouter as Router } from "react-router-dom";
// import axios from 'axios';
// import { Link } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
// import Rang from '../components/lang';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import { Form, Tab, Nav, Row, Col } from "react-bootstrap";

import noimg from '../../assets/img/no-img-user.svg';
import calendar from '../../assets/img/calendar.svg';
import icondel from '../../assets/img/icon-del.svg';

import Modalcropbranch from "../../layout/modal/modalCropbranch";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import InputMask from 'react-input-mask';

const Modalemployeeadd = (datas) => {

    let history = useNavigate();

    const [todayDate, setTodayDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());

    const dateoption = {
        locale: {
            format: 'DD/MM/YYYY'
        },
        startDate: todayDate,
        endDate: startDate,
        singleDatePicker: true,
        autoApply: true,
    };

    const [validated, setValidated] = useState(false);
    const formRef = useRef(null);

    const [blob, setBlob] = useState(null)
    const [inputImg, setInputImg] = useState('');
    const [modalcrop, setModalcrop] = useState({ view: false, id: 0 });
    const [errorblob, setErrorblob] = useState();

    const getBlob = (blob) => {
        // pass blob up from the ImageCropper component
        setBlob(blob);
        setModalcrop({ view: false });
    }

    const onInputChange = (e) => {
        // convert image file to base64 string
        const file = e.target.files[0]
        const reader = new FileReader();

        reader.addEventListener('load', () => {
            setInputImg(reader.result)
            setModalcrop({ view: true, id: 1, getblob: getBlob, inputImg: reader.result })
        }, false)

        if (file) {
            reader.readAsDataURL(file)
        }
    }

    const handleSubmit = (event) => {
// alert(55)
        event.preventDefault();

        const form = event.currentTarget;

        const data = new FormData(event.target);

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        data.append("branch_id", branch.id);
        data.append("blob", JSON.stringify(blob));
        data.append("type", "add");

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();
// alert(55)
            // if (!blob) {           
            //     setErrorblob("Please select uploads file.")
            // }

        } else {

            // if(blob){

            AuthService.updateEmployee(data).then(
                (res) => {
                    //   console.log(res)   
                    if (res.status === 200) {
                        // history("/setting/setting"); 
                        // window.location.reload();
                        formRef.current.reset();

                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: '',
                            text: "บันทึกข้อมูลเรียบร้อยแล้ว",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(function () {
                            datas.onHide();                            
                            setValidated(false);
                        }) 
                    } else {
                        // setErrors({email:"This email cannot be used.!"})
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
            // } else {
            //     setErrorblob("Please select uploads file. !")
            
            // }
        }
        setValidated(true);
    };


    return (
        <>
            <Modal {...datas} className="modal-custom" size="md" backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton className="btn-close-white ">

                </Modal.Header>
                <Modal.Body className="p-0">
                    <div className="d-block w-100 scroll-custom">
                        {
                            datas.type === "detail" ? (
                                <div className='pt-5 pb-4 bg-white px-4 text-start'>
                                    <div className='text-default font-22 weight-500'>
                                        รายละเอียดพนักงาน
                                    </div>
                                    <div className='mb-3'>
                                        <div className='py-5 text-center' >
                                            <label htmlFor="upload" className='px-5 d-block'>
                                                <img src={noimg} alt="" className="w-50" />
                                            </label>
                                        </div>
                                        <div>ชื่อพนักงาน : ป่านคุง</div>
                                        <div>อีเมล : parnkung@gmail.com</div>
                                        <div>เบอร์โทร : 081-615-6535</div>
                                        <div>ตำแหน่ง : เชฟ</div>
                                        <div>วันที่เริ่มงาน : 29/11/2021</div>
                                    </div>
                                </div>
                            ) :
                                datas.type === "del" ? (
                                    <div className='pt-5 pb-4 bg-white px-4 text-center'>
                                        <Form noValidate validated={validated} onSubmit={handleSubmit} ref={formRef}>
                                            <div><img src={icondel} alt="" className="" style={{ width: "50px" }} /></div>
                                            <div className='font-18 weight-500 mt-4'>ยืนยันการลบพนักงาน ป่านคุง</div>
                                            <div className='text-center px-3 mt-4'>
                                                <div className='row'>
                                                    <div className="col-12 col-md-6 col-lg-6 pe-1">
                                                        <button className="w-100 btn btn-calcel text-white py-2 me-3" onClick={datas.onHide}>
                                                            ยกเลิก
                                                        </button>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6 ps-1">
                                                        <button type='submit' className="w-100 btn btn-bg text-white py-2 me-3">
                                                            ยืนยัน
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                ) : (
                                    <>
                                        <Modal.Title className='py-3 bg-white px-4 shadow-sm position-fixed w-100' style={{ zIndex: "1" }}>
                                            <div className='text-default font-22'>
                                                เพิ่มพนักงาน
                                            </div>
                                        </Modal.Title>
                                        <Form noValidate validated={validated} onSubmit={handleSubmit} className="" ref={formRef}>
                                            <div className='h-100per-modal bg-white px-4 pt-5'>
                                                <div>
                                                    <div className='py-5 text-center' >
                                                        <label htmlFor="upload" className='cursor px-5 d-block'>
                                                            {
                                                                blob ? (
                                                                    <img src={blob} alt="" className="w-50" />
                                                                ) : <img src={noimg} alt="" className="w-50" />
                                                            }
                                                        </label>
                                                        <input type='file' name="file" id="upload" accept='image/*' onChange={onInputChange} hidden />
                                                        <div className='mt-2 font-14 text-gray'>อัพโหลดภาพพนักงาน</div>
                                                        <div className='font-12 weight-300' style={{color:"red"}}>{errorblob}</div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <Row className="mt-0">
                                                        <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                                            <Form.Label className="font-12 text-gray mb-0">ชื่อพนักงาน</Form.Label>
                                                            <Form.Control type="text" name="name" className='font-14 input-custom' placeholder='xxxxxxxx' required />
                                                            <Form.Control type="hidden" name="id" className='font-14 input-custom' />
                                                        </Form.Group>
                                                    </Row>
                                                    <Row className="mt-3">
                                                        <Form.Group as={Col} md="6" className="position-relative pe-1" controlId="validationCustom01">
                                                            <Form.Label className="font-12 text-gray mb-0">Username</Form.Label>
                                                            <Form.Control type="text" name="username"  className='font-14 input-custom' placeholder='xxxxxxxx' />
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6" className="position-relative pe-1" controlId="validationCustom01"> 
                                                            <Form.Label className="font-12 text-gray mb-0">Password</Form.Label>
                                                            <Form.Control type="password" name="password" className='font-14 input-custom' placeholder='xxxxxxxx' />
                                                        </Form.Group>
                                                    </Row> 

                                                    <Row className="align-items-end mt-3 mb-3">
                                                        <Form.Group as={Col} md="6" className="position-relative pe-1" controlId="validationCustom01">
                                                            <Form.Label className="font-12 text-gray mb-0">อีเมล</Form.Label>
                                                            <Form.Control type="email" name='email' className='font-14 input-custom' placeholder='xxx@xxx.xxx'/>
                                                        </Form.Group>
                                                    
                                                        <Form.Group as={Col} md="6" className="position-relative pe-1" controlId="validationCustom01">
                                                            <Form.Label className="font-12 text-gray mb-0">เบอร์โทร</Form.Label>
                                                            {/* <Form.Control type="text" name='tel' className='font-14 input-custom' placeholder='xxx-xxx-xxxx' required /> */}
                                                            <InputMask
                                                                mask="999-999-9999"
                                                                maskChar={null}     
                                                                className='font-14 input-custom form-control'
                                                                type="text"
                                                                placeholder="xxx-xxx-xxxx"
                                                                name="tel"
                                                                pattern="\d{3}[\-]\d{3}[\-]\d{4}"
                                                            />
                                                        </Form.Group>
                                                    </Row>
                                                    <Row className="align-items-end mt-3 mb-3">
                                                        <Form.Group as={Col} md="6" className="position-relative" controlId="validationCustom01">
                                                            <Form.Label className="font-12 text-gray mb-2">ตำแหน่ง</Form.Label>
                                                            <Form.Select aria-label="Default select example" className='font-14' name='position' >
                                                                <option value="0">ผู้จัดการร้าน</option>
                                                                <option value="1">แคชเชียร์</option>
                                                                <option value="2">Checker</option>
                                                                <option value="3">พนักงานครัว</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6" controlId="dob" className='position-relative'>
                                                            <Form.Label className="font-12 text-gray mb-2">วันที่เริ่มงาน</Form.Label>
                                                            {/* <DateRangePicker selected={startDate} minDate={todayDate} dateFormat="dd/MM/yyyy" onChange={(date) => setStartDate(date)} className="form-control font-14" /> */}
                                                            <DateRangePicker initialSettings={dateoption}>
                                                                <input type="text" name="date_start" className="form-control font-14" />
                                                            </DateRangePicker >
                                                            <div className='position-absolute' style={{ bottom: "12px", right: "12px" }}>
                                                                <img src={calendar} alt="" className="me-1" style={{ width: "18px" }} />
                                                            </div>
                                                        </Form.Group>
                                                    </Row>
                                                    <div className='pb-5'>
                                                        <Form.Label className="font-12 text-gray mb-2">สิทธิ์การเข้าถึง</Form.Label>
                                                        <div className="ex-checkbox4 mb-2 ">
                                                            <input type="checkbox" id="checkbox_one" name="access[]" value="1" />
                                                            <label className="checkbox_one ps-4 " style={{ width: "100%" }} >
                                                                ดูออเดอร์เข้าครัว กำหนดสถานะรายการอาหารได้
                                                                แก้ไขและจัดการรายการอาหารเข้าครัวทั้งหมดได้
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox4 mb-2">
                                                            <input type="checkbox" id="checkbox_one" name="access[]" value="2" />
                                                            <label className="checkbox_one ps-4" style={{ width: "100%" }} >
                                                                ดูรายการของเสียได้ และเพิ่ม-ลดรายการของเสียได้
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox4 mb-2">
                                                            <input type="checkbox" id="checkbox_one" name="access[]" value="3" />
                                                            <label className="checkbox_one ps-4" style={{ width: "100%" }} >
                                                                จัดการคลังวัตถุดิบได้
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox4 mb-2">
                                                            <input type="checkbox" id="checkbox_one" name="access[]" value="4" />
                                                            <label className="checkbox_one ps-4" style={{ width: "100%" }} >
                                                                ดูตารางการทำงานของพนักงานได้
                                                            </label>
                                                        </div>
                                                        <div className="ex-checkbox4 mb-2">
                                                            <input type="checkbox" id="checkbox_one" name="access[]" value="5" />
                                                            <label className="checkbox_one ps-4" style={{ width: "100%" }} >
                                                                จัดการออเดอร์การขาย
                                                            </label>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div className='text-center py-3 px-3 bg-gray'>
                                                <div className='row'>
                                                    <div className="col-12 col-md-6 col-lg-6 pe-1">
                                                        <span className="w-100 btn btn-calcel text-white py-2 me-3" onClick={datas.onHide}>
                                                            ยกเลิก
                                                        </span>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6 ps-1">
                                                        <button type='submit' className="w-100 btn btn-submit text-white py-2 me-3">
                                                            บันทึก
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </>
                                )}
                    </div>
                </Modal.Body>
            </Modal>

            <Modalcropbranch show={modalcrop.view} onHide={() => setModalcrop({ view: false })} id={modalcrop.id} getblob={getBlob} inputimg={modalcrop.inputImg} text="ภาพพนักงาน" />
        </>
    );
};

export default Modalemployeeadd;