import React, { useState, useCallback, useEffect } from 'react';
// import axios from 'axios';
import { useNavigate, useParams,Link } from "react-router-dom";
import { compareAsc, format } from 'date-fns'

import AuthService from "../../components/services_member/auth.service";
import PostService from "../../components/services_member/post.service";
import Orderdetail from "../order/order_food_detail";

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import '../../assets/css/respornsive.css';

import cplus from '../../assets/img/c-plus.svg';
import sorn from '../../assets/img/back-sorn.svg';

// import { Link } from "react-tiger-transition";
import { Form, Tab } from "react-bootstrap";
import Swal from 'sweetalert2'

import Moment from 'moment';

import { useTranslation } from 'react-i18next';

const Ordercart = () => {
    const { t } = useTranslation();
    const { id } = useParams();

    Moment.locale('en');

    const tableorder = JSON.parse(localStorage.getItem("table_order"));

    let history = useNavigate();
    const user = AuthService.getCurrentUser();
    const branch = AuthService.getCurrentBranch();

    // localStorage.removeItem("table_order");

    // if (!user) {
    //     history("/");
    // } else {
    //     if (!branch) {
    //         history("/branch");
    //     }
    // }

    const [dateday, setDateday] = useState(format(new Date(), 'dd/MM/yyyy'));

    const [datacat, setDatacat] = useState([{ id: '0', branch_id: '2', category: 'ทั้งหมด', position: '0', status: '1' }]);
    const [active, setActive] = useState('0');
    const [dataproduct, setDataproduct] = useState([]);
    const [dproduct, setDproduct] = useState([]);
    const [datadetail, setDatadetail] = useState();
    const [datareserve, setDatareserve] = useState([]);


    const responsive = {
        0: { items: 3 },
        568: { items: 3 },
        1024: { items: 5 },
    };

    const fetchData2 = useCallback(async (dd, id) => {

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        PostService.getReserveone(id, dd).then(
            (response) => {
                if (response.data.status === 200) {
                    setDatareserve(response.data.reserve)
                    // setDatacat(response.data.data_cat)
                    setDatacat(datacat => [...datacat, ...response.data.data_cat])
                    // setValue(value => [value,...response.data.data_cat])
                    setDataproduct(response.data.data_product)
                    setDproduct(response.data.data_product)
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: '',
                        text: "ไม่มีรายการเลือกโต๊ะอาหาร",
                        showConfirmButton: false,
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        )
    });

    useEffect(() => {
        fetchData2(dateday, id);
    }, []);

    const handleTokitchen = () => {
        // alert(orderview.id)
        const data2 = new FormData();

        data2.append("id", datareserve.id);
        data2.append("type", "kitchen");

        // console.log(datas.datareserve.id)

        AuthService.holdOrderlist(data2).then(
            (res) => {
                if (res.status === 200) {
                    // handlerefresh(orderview.id)
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: '',
                        text: "สั่งอาหารเรียบร้อยแล้ว",
                        showConfirmButton: false,
                        timer: 1500
                    }).then(function () {
                        fetchData2(dateday, id);
                        setActive(1);
                        // selectlist(orderview.id);
                        // setOrderview(dataorder.filter(vls => vls.id === orderview.id)[0]);

                    })
                } else {
                    console.log(res.message)
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    const incrementCount = (did) => {
        // alert(e)
        const data2 = new FormData();

        data2.append("id", did);

        AuthService.removeOrderlist(data2).then(
            (res) => {
                if (res.status === 200) {
                    // window.location.reload();
                    fetchData2(dateday, id);
                } else {
                    console.log(res.message)
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }


    const resetdetail = () => {
        setDatadetail();
        fetchData2(dateday, id);
    }

    const SendtoKit = (e) => {

        let view = true;

        if (datareserve.data_order) {
            if (datareserve.data_order.length) {
                if (datareserve.data_order.filter(valb => valb.status <= 1).length) {
                    view = false;
                }
            }
        } else {
            view = true;
        }

        if (view) {
            return <Link to={"/orderfood/" + id} transition="default-fade" className="w-100 btn btn-submit-mem text-white py-2 " >
                สั่งอาหารเพิ่ม
            </Link>;
        } else {
            return <button type='submit' onClick={() => handleTokitchen()} className="w-100 btn btn-submit-mem text-white py-2 " >
                สั่งอาหารทันที
            </button>;
        }

    }

    console.log(dataproduct)

    return (
        <>
            {dataproduct.length ?
                <div className={!t('menu-a') ? "sidebar-mini sidebar-collapse" : ""} >
                    <div className='wrapper-admin layout-fixed'>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="food_all">

                            {datadetail ?
                                <Orderdetail data={datadetail} resetdetail={resetdetail} idOrder={id} />
                                :

                                <div className="container bg-white" style={{ height: "100vh" }}>
                                    <section className="content scroll-custom">
                                        <div className="row">
                                            <div className="col-12 col-md-8 col-lg-8 text-start">
                                                <div className='mt-3 text-end'>
                                                    <div className='float-start'>
                                                        <Link to={"/orderfood/" + id} transition="default-fade" className="py-2 " >
                                                            <img src={sorn} style={{ width: "10px" }} className="pt-2" />
                                                        </Link>
                                                    </div>
                                                    <h4 className='mb-0'>{datareserve.zone_name} : {datareserve.table_reserve_name}</h4>
                                                    <div className='font-12 text-gray'>
                                                        {Moment(datareserve.date_create).format('DD/MM/yyyy')} | {Moment(datareserve.date_create).format('HH:mm')}
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                        <div className='menu-slide mb-3 mt-5'>
                                            <AliceCarousel mouseTracking
                                                responsive={responsive}
                                                // infinite
                                                controlsStrategy="alternate"
                                                disableDotsControls
                                                disableButtonsControls
                                                activeIndex={0}
                                            >
                                                <div className={active == 0 ? "item active" : "item"} onClick={() => setActive(0)}>รายการอาหารที่สั่ง</div>
                                                <div className={active == 1 ? "item active" : "item"} onClick={() => setActive(1)}>สถานะ</div>

                                            </AliceCarousel>
                                        </div>
                                        {active == 0 ?
                                            <div className='mt-3 mb-4 text-start' style={{ height: "calc(100vh - (6rem + 120px))", overflowY: "auto" }}>

                                                {datareserve.data_order ? datareserve.data_order.length ? (datareserve.data_order.filter(valb => valb.status < 2)).sort((a, b) => a.id - b.id).map((value, index) => (

                                                    <div className='mb-2 border-custom' key={index}>
                                                        <span className='font-12 pe-5'>
                                                            {value.name_product} <span className='font-10 ms-3'>X</span> {value.qty}
                                                        </span>
                                                        {value.status === "0" || value.status === "1" ?

                                                            <span className='float-end'>
                                                                <span className='btn-lob rounded' onClick={() => incrementCount(value.id)} style={{ verticalAlign: "middle", width: "21px", height: "21px" }}>
                                                                    <img src={cplus} style={{ width: "12px", transform: "rotate(45deg)" }} />
                                                                </span>
                                                            </span>
                                                            : null
                                                        }

                                                        {value.choice ? (JSON.parse(value.choice)).map((val, inde) => (
                                                            <div className='ms-1 pe-5' key={inde}>
                                                                <div className='font-10'>- {val.more_name}</div>
                                                            </div>
                                                        ))
                                                            : null}
                                                    </div>
                                                ))
                                                    : <div className='text-center my-4 font-12 weight-300 text-gray'>- ยังไม่มีรายการ - </div> : null}
                                            </div>
                                            :
                                            <div className='mt-3 mb-4 text-start' style={{ height: "calc(100vh - (6rem + 120px))", overflowY: "auto" }}>

                                                {datareserve.data_order ? datareserve.data_order.length ? (datareserve.data_order.filter(valb => valb.status > 1)).sort((a, b) => a.id - b.id).map((value, index) => (

                                                    <div className='mb-2 border-custom' key={index}>
                                                        <span className='font-12 pe-5'>
                                                            {value.name_product} <span className='font-10 ms-3'>X</span> {value.qty}
                                                        </span>
                                                        <span className='float-end'>
                                                            {
                                                                value.status === "2" ? <span className='me-2 font-12 weight-500' style={{ color: "#2951A5" }}>เข้าครัวแล้ว</span> :

                                                                    value.status === "3" ? <span className='me-2 font-12 weight-500' style={{ color: "#E8710A" }}>กำลังปรุง</span> :

                                                                        value.status === "4" ? <span className='me-2 font-12 weight-500' style={{ color: "#008319" }}>ได้รับแล้ว</span> :

                                                                            value.status === "5" ? <span className='me-2 font-12 weight-500' style={{ color: "#FF2121" }}>ยกเลิก</span> : null
                                                            }

                                                        </span>
                                                        {value.choice ? (JSON.parse(value.choice)).map((val, inde) => (
                                                            <div className='ms-1 pe-5' key={inde}>
                                                                <div className='font-10'>- {val.more_name}</div>
                                                            </div>
                                                        ))
                                                            : null}
                                                    </div>
                                                ))
                                                    : <div className='text-center my-4 font-12 weight-300 text-gray'>- ยังไม่มีรายการ - </div> : null}
                                            </div>
                                        }
                                    </section>
                                    <div className='position-fixed w-100 px-3 py-3 bg-white' style={{ right: "0px", bottom: "0px", zIndex: "9" }}>
                                        <SendtoKit />
                                    </div>
                                </div>

                            }
                        </Tab.Container>
                    </div>
                </div>
                : null}
        </>
    );
};

export default Ordercart;