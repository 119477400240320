import React, { useState, useCallback, useEffect, useRef } from 'react';
// import axios from 'axios';
import { useNavigate, Link } from "react-router-dom";
import AuthService from "../../components/services_member/auth.service";
import PostService from "../../components/services_member/post.service";

import '../../assets/css/adminlte.min.css';

import logo from '../../assets/img/ryuden-logo.svg';
import calendar from '../../assets/img/calendar-check.svg';
import menu from '../../assets/img/menu.svg';
import checkout from '../../assets/img/checkout.svg';
import orderopen from '../../assets/img/order-open.svg';
import dalivery from '../../assets/img/dalivery.svg';
import member from '../../assets/img/member.svg';
import orderto from '../../assets/img/order-to.svg';
import waste from '../../assets/img/waste.svg';
import raw from '../../assets/img/raw.svg';
import peple from '../../assets/img/peple.svg';
import historyi from '../../assets/img/history.svg';
import setting from '../../assets/img/cog.svg';
import report from '../../assets/img/chart-bar.svg';
import noimg from '../../assets/img/no-img.svg';
import logout from '../../assets/img/logout.png';
import menuIcon from '../../assets/img/menu.png';

// import { Link } from "react-tiger-transition";

import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

import { compareAsc, format } from 'date-fns';

const Menuleft = (data) => {

    const { t } = useTranslation();

    let history = useNavigate();

    const user = AuthService.getCurrentUser();
    const branch2 = AuthService.getCurrentBranch();

    const branch = JSON.parse(localStorage.getItem("branch_data"));
    const memberdata = JSON.parse(localStorage.getItem("member_data"));
    const linklogin = (localStorage.getItem("link_login"));

    if (!user) {
        window.location.href = "/";
    } else {
        if (!branch2) {
            window.location.href = "/branch";
        }
    }

    const [menuactive, setMenuactive] = useState(data.type);
    const [databranch, setDatabranch] = useState(branch2);
    const [dataordertotal, setDataordertotal] = useState({ order_total: 0, total_kit: 0, total_bar: 0 });

    const [dateday, setDateday] = useState(format(new Date(), 'dd/MM/yyyy'));

    const fetchData = useCallback(async (dd) => {

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        PostService.getOrderalltotal(branch.id, dd).then(
            (response) => {
                if (response.data.status === 200) {
                    setDataordertotal({ order_total: response.data.order_total, total_kit: response.data.total_kit, total_bar: response.data.total_bar });
                } else {
                    setDataordertotal({ order_total: 0, total_kit: 0, total_bar: 0 });
                }
            },
            (error) => {
                console.log(error);
            }
        )
    });

    const [langActive, setLangActive] = useState('en');
    const called = useRef(true)

    useEffect(() => {

        if (called.current) {
            fetchData(dateday);
        }

        return () => {
            called.current = false;
        }

        if (typeof window !== 'undefined') {

            const storageLanguage = localStorage.getItem('language');
            const allowedLanguages = ['en', 'th'];

            if (storageLanguage && allowedLanguages.indexOf(storageLanguage) > -1) {
                setLangActive(storageLanguage)
            }
        }
    }, []);

    const [menuOpen, setMenuOpen] = useState(false);
    const [menusubOpen, setMenusubOpen] = useState({ view: true, id: menuactive });

    const handleMenusubChange = (state, id) => {
        if (menusubOpen.id === id) {
            setMenusubOpen({ view: !menusubOpen.view, id: id })
            console.log(!menusubOpen.view)
            if(!menusubOpen.view){
                localStorage.setItem('language', 'en');
                i18n.changeLanguage('en');
                setActive('en')
            }
        } else {
            setMenusubOpen({ view: true, id: id })
            localStorage.setItem('language', 'en');
            i18n.changeLanguage('en');
            setActive('en')
        }

    }

    const [Active, setActive] = useState(localStorage.getItem('language'));
    const handleSetmenu = (e) => {
        if(e === 'th'){
            setMenusubOpen({ view: false, id: 0 })
        }
        localStorage.setItem('language', e);
        i18n.changeLanguage(e);
        setActive(e)
    }

    // data.datafc();

    // const refreshdata = () => {
    //     alert(555)
    // }



    return (
        <>

            <aside className="main-sidebar bg-default scroll-custom">
                <div className='pt-4'>
                    <div className='box-logo-size-admin d-inline-block text-start' style={{ marginTop: "0px", verticalAlign: "middle" }}>
                        {/* <img src={logo} alt="" className="w-100" /> */}
                        {databranch.logo ?
                            <img src={process.env.REACT_APP_API_URL + "/uploads/branch/" + databranch.logo} alt="" className="w-100" />
                            :
                            <img src={noimg} alt="" className="w-100" />
                        }
                    </div>
                    <div className='d-inline-block ms-3 text-start mt-1 box-title-admin' style={{ verticalAlign: "middle" }}>
                        <div className='text-white font-14'>
                            {memberdata.position ? memberdata.name : memberdata.username}
                        </div>
                        {/* <div className='text-gray font-12 mb-1'>{memberdata.position} ผู้จัดการร้าน</div> */}
                        <div className='text-gray font-12 mb-1'>
                            {memberdata.position === '0' ? 'ผู้จัดการร้าน' : memberdata.position === '1' ? 'แคชเชียร์' : memberdata.position === '2' ? 'Checker' : memberdata.position === '3' ? 'พนักงานครัว' : 'Super Admin'}
                        </div>
                    </div>

                </div>
                <div className="sidebar">
                    <div className='user-panel'>
                        <div className='info'>
                            <div className='text-white mt-3'>{databranch ? databranch.name : null}</div>
                        </div>
                    </div>
                    <nav className="text-start mt-4">
                        {memberdata.position && linklogin ?
                            <>
                                <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                                    {JSON.parse(memberdata.access).filter(val => val === 5).length ? <>
                                        <li className="nav-item has-treeview">
                                            <Link to="/table/manage" transition="default-fade" className={menuactive == "table" ? "nav-link active" : "nav-link"}>
                                                <img src={calendar} alt="" className="nav-icon" style={{ width: "16px" }} />
                                                <p>จัดการโต๊ะ</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item has-treeview">
                                            <Link to="/menu/manage" transition="default-fade" className={menuactive == "menu" ? "nav-link active" : "nav-link"}>
                                                <img src={menu} alt="" className="nav-icon" style={{ width: "16px" }} />
                                                <p>เมนู / โปรโมชั่น</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item has-treeview">
                                            <Link to="/order/ordermenu" transition="default-fade" className={menuactive == "order" ? "nav-link active" : "nav-link"}>
                                                <img src={checkout} alt="" className="nav-icon" style={{ width: "16px" }} />
                                                <p>สั่งและชำระเงิน</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item has-treeview">
                                            <Link to="/order/orderopen" transition="default-fade" className={menuactive == "menuopen" ? "nav-link active" : "nav-link"}>
                                                <img src={orderopen} alt="" className="nav-icon" style={{ width: "16px" }} />
                                                <p>ออเดอร์ที่เปิดอยู่ <span className='span-orang-alert'>{dataordertotal.order_total}</span></p>
                                            </Link>
                                        </li>
                                        <li className="nav-item has-treeview">
                                            <Link to="#" transition="default-fade" className="nav-link">
                                                <img src={dalivery} alt="" className="nav-icon" style={{ width: "16px" }} />
                                                <p>เดลิเวอรี่</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item has-treeview">
                                            <Link to="/member/member" transition="default-fade" className={menuactive == "member" ? "nav-link active" : "nav-link"}>
                                                <img src={member} alt="" className="nav-icon" style={{ width: "16px" }} />
                                                <p>สมาชิก</p>
                                            </Link>
                                        </li>
                                    </> :
                                        <>
                                            {/* <li className="nav-item has-treeview">
                                                <Link to="/table/manage" transition="default-fade" className={menuactive == "table" ? "nav-link active" : "nav-link"}>
                                                    <img src={calendar} alt="" className="nav-icon" style={{ width: "16px" }} />
                                                    <p>จัดการโต๊ะ</p>
                                                </Link>
                                            </li>
                                            <li className="nav-item has-treeview">
                                                <Link to="/menu/manage" transition="default-fade" className={menuactive == "menu" ? "nav-link active" : "nav-link"}>
                                                    <img src={menu} alt="" className="nav-icon" style={{ width: "16px" }} />
                                                    <p>เมนู / โปรโมชั่น</p>
                                                </Link>
                                            </li> */}
                                            <li className="nav-item has-treeview">
                                                <Link to="/order/ordermenu" transition="default-fade" className={menuactive == "order" ? "nav-link active" : "nav-link"}>
                                                    <img src={checkout} alt="" className="nav-icon" style={{ width: "16px" }} />
                                                    <p>สั่งและชำระเงิน</p>
                                                </Link>
                                            </li>
                                            <li className="nav-item has-treeview">
                                                <Link to="/order/orderopen" transition="default-fade" className={menuactive == "menuopen" ? "nav-link active" : "nav-link"}>
                                                    <img src={orderopen} alt="" className="nav-icon" style={{ width: "16px" }} />
                                                    <p>ออเดอร์ที่เปิดอยู่ <span className='span-orang-alert'>{dataordertotal.order_total}</span></p>
                                                </Link>
                                            </li>
                                            {/* <li className="nav-item has-treeview">
                                                <Link to="#" transition="default-fade" className="nav-link">
                                                    <img src={dalivery} alt="" className="nav-icon" style={{ width: "16px" }} />
                                                    <p>เดลิเวอรี่</p>
                                                </Link>
                                            </li> */}
                                        </>
                                    }
                                    {JSON.parse(memberdata.access).filter(val => val === 1).length ? <>
                                        <li className="nav-header">ห้องครัว</li>
                                        <li className="nav-item has-treeview">
                                            <Link to="/kitchen/kitchen" transition="default-fade" className={menuactive == "kitchen" ? "nav-link active" : "nav-link"}>
                                                <img src={orderto} alt="" className="nav-icon" style={{ width: "16px" }} />
                                                <p>ออเดอร์เข้าครัว <span className='span-orang-alert'>{dataordertotal.total_kit}</span></p>
                                            </Link>
                                        </li>
                                        <li className="nav-item has-treeview">
                                            <Link to="/bar/bar" transition="default-fade" className={menuactive == "bar" ? "nav-link active" : "nav-link"}>
                                                <img src={orderto} alt="" className="nav-icon" style={{ width: "16px" }} />
                                                <p>ออเดอร์เข้าบาร์ <span className='span-orang-alert'>{dataordertotal.total_bar}</span></p>
                                            </Link>
                                        </li>
                                    </> : null}

                                    {JSON.parse(memberdata.access).filter(val => val === 2).length ?
                                        <li className="nav-item has-treeview">
                                            <Link to="/report/waste" transition="default-fade" className={menuactive == "waste" ? "nav-link active" : "nav-link"}>
                                                <img src={waste} alt="" className="nav-icon" style={{ width: "16px" }} />
                                                <p>รายการของเสีย</p>
                                            </Link>
                                        </li>
                                        : null}

                                    {JSON.parse(memberdata.access).filter(val => val === 3).length ? <>
                                        <li className="nav-header">คลังสินค้า</li>
                                        <li className={menusubOpen.id === 'warehouse' ? menusubOpen.view ? "nav-item has-submenu menu-open show" : "nav-item has-submenu" : "nav-item has-submenu"}>
                                            {/* <Link to="#" transition="default-fade" onClick={(state) => handleMenusubChange(state, 1)} className="nav-link"> */}
                                            <a className="nav-link" href="#" onClick={(state) => handleMenusubChange(state, 'warehouse')}>
                                                <img src={raw} alt="" className="nav-icon" style={{ width: "16px" }} />
                                                <p>คลังเครื่องดื่ม</p>
                                            </a>
                                            {/* </Link> */}
                                            <ul className={menusubOpen.id === 'warehouse' ? menusubOpen.view ? "submenu collapse show" : "submenu collapse" : "submenu collapse"}>
                                                <li>
                                                    <Link to="/warehouse/warehouse" transition='default-fade' className={data.sub === 'warehouse' ? "btn-link-default sub-a active" : "btn-link-default sub-a"}>
                                                        <i className="fa fa-circle-o"></i> เพิ่มชื่อสินค้า
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/warehouse/warehousecreate" transition='default-fade' className={data.sub === 'warehousecreate' ? "btn-link-default sub-a active" : "btn-link-default sub-a"}>
                                                        <i className="fa fa-circle-o"></i> เพิ่มรายการสินค้า
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/warehouse/warehousewithdraw" transition='default-fade' className={data.sub === 'warehousewithdraw' ? "btn-link-default sub-a active" : "btn-link-default sub-a"}>
                                                        <i className="fa fa-circle-o"></i> เบิกคลังสินค้า
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/warehouse/warehousemove" transition='default-fade' className={data.sub === 'warehousemove' ? "btn-link-default sub-a active" : "btn-link-default sub-a"}>
                                                        <i className="fa fa-circle-o"></i> จำนวนคงเหลือต่อวัน
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/warehouse/warehousereport" transition='default-fade' className={data.sub === 'warehousereport' ? "btn-link-default sub-a active" : "btn-link-default sub-a"}>
                                                        <i className="fa fa-circle-o"></i> รายงาน
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                    </>
                                        : null}

                                    {JSON.parse(memberdata.access).filter(val => val === 4).length ? <>
                                        <li className="nav-header">ข้อมูลพนักงาน</li>
                                        <li className="nav-item has-treeview">
                                            <Link to="/member/table_member" transition="default-fade" className={menuactive == "tablemember" ? "nav-link active" : "nav-link"}>
                                                <img src={peple} alt="" className="nav-icon" style={{ width: "16px" }} />
                                                <p>ตารางการทำงาน</p>
                                            </Link>
                                        </li>
                                    </>
                                        : null}

                                    {memberdata.position && linklogin ? null : <>
                                        <li className="nav-header">การจัดการ</li>
                                        <li className="nav-item has-treeview">
                                            <Link to="/report/reportreceipt" transition="default-fade" className={menuactive == "receipt" ? "nav-link active" : "nav-link"}>
                                                <img src={historyi} alt="" className="nav-icon" style={{ width: "16px" }} />
                                                <p>ประวัติใบเสร็จ</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item has-treeview">
                                            <Link to="/setting/setting" transition="default-fade" className={menuactive == "setting" ? "nav-link active" : "nav-link"}>
                                                <img src={setting} alt="" className="nav-icon" style={{ width: "16px" }} />
                                                <p>ตั้งค่า</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item has-treeview">
                                            <Link to="/report/report" transition="default-fade" className={menuactive == "report" ? "nav-link active" : "nav-link"}>
                                                <img src={report} alt="" className="nav-icon" style={{ width: "16px" }} />
                                                <p>รายงาน</p>
                                            </Link>
                                        </li>
                                    </>}

                                </ul>
                                <div className='mt-5 mb-4 px-3'>
                                    <Link to={"/logoutmember/" + linklogin} transition="default-fade" className="w-100 btn btn-bggray text-default py-2">
                                        ออกจากระบบ
                                    </Link>
                                </div>
                            </>
                            :
                            <>
                                {/* super admin */}
                                <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                                    <li className="nav-item has-treeview">
                                        <Link to="/table/manage" transition="default-fade" className={menuactive == "table" ? "nav-link active" : "nav-link"}>
                                            <img src={calendar} alt="" className="nav-icon" style={{ width: "16px" }} />
                                            <p>จัดการโต๊ะ</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item has-treeview">
                                        <Link to="/menu/manage" transition="default-fade" className={menuactive == "menu" ? "nav-link active" : "nav-link"}>
                                            <img src={menu} alt="" className="nav-icon" style={{ width: "16px" }} />
                                            <p>เมนู / โปรโมชั่น</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item has-treeview">
                                        <Link to="/order/ordermenu" transition="default-fade" className={menuactive == "order" ? "nav-link active" : "nav-link"}>
                                            <img src={checkout} alt="" className="nav-icon" style={{ width: "16px" }} />
                                            <p>สั่งและชำระเงิน</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item has-treeview">
                                        <Link to="/order/orderopen" transition="default-fade" className={menuactive == "menuopen" ? "nav-link active" : "nav-link"}>
                                            <img src={orderopen} alt="" className="nav-icon" style={{ width: "16px" }} />
                                            <p>ออเดอร์ที่เปิดอยู่ <span className='span-orang-alert'>{dataordertotal.order_total}</span></p>
                                        </Link>
                                    </li>
                                    <li className="nav-item has-treeview">
                                        <Link to="#" transition="default-fade" className="nav-link">
                                            <img src={dalivery} alt="" className="nav-icon" style={{ width: "16px" }} />
                                            <p>เดลิเวอรี่</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item has-treeview">
                                        <Link to="/member/member" transition="default-fade" className={menuactive == "member" ? "nav-link active" : "nav-link"}>
                                            <img src={member} alt="" className="nav-icon" style={{ width: "16px" }} />
                                            <p>สมาชิก</p>
                                        </Link>
                                    </li>
                                    <li className="nav-header">ห้องครัว</li>
                                    <li className="nav-item has-treeview">
                                        <Link to="/kitchen/kitchen" transition="default-fade" className={menuactive == "kitchen" ? "nav-link active" : "nav-link"}>
                                            <img src={orderto} alt="" className="nav-icon" style={{ width: "16px" }} />
                                            <p>ออเดอร์เข้าครัว <span className='span-orang-alert'>{dataordertotal.total_kit}</span></p>
                                        </Link>
                                    </li>
                                    <li className="nav-item has-treeview">
                                        <Link to="/bar/bar" transition="default-fade" className={menuactive == "bar" ? "nav-link active" : "nav-link"}>
                                            <img src={orderto} alt="" className="nav-icon" style={{ width: "16px" }} />
                                            <p>ออเดอร์เข้าบาร์ <span className='span-orang-alert'>{dataordertotal.total_bar}</span></p>
                                        </Link>
                                    </li>
                                    <li className="nav-item has-treeview">
                                        <Link to="/report/waste" transition="default-fade" className={menuactive == "waste" ? "nav-link active" : "nav-link"}>
                                            <img src={waste} alt="" className="nav-icon" style={{ width: "16px" }} />
                                            <p>รายการของเสีย</p>
                                        </Link>
                                    </li>


                                    <li className="nav-header">คลังสินค้า</li>
                                    <li className={menusubOpen.id === 'warehouse' ? menusubOpen.view ? "nav-item has-submenu menu-open show" : "nav-item has-submenu" : "nav-item has-submenu"}>
                                        {/* <Link to="#" transition="default-fade" onClick={(state) => handleMenusubChange(state, 1)} className="nav-link"> */}
                                        <a className="nav-link" href="#" onClick={(state) => handleMenusubChange(state, 'warehouse')}>
                                            <img src={raw} alt="" className="nav-icon" style={{ width: "16px" }} />
                                            <p>คลังเครื่องดื่ม</p>
                                        </a>
                                        {/* </Link> */}
                                        <ul className={menusubOpen.id === 'warehouse' ? menusubOpen.view ? "submenu collapse show" : "submenu collapse" : "submenu collapse"}>
                                            <li>
                                                <Link to="/warehouse/warehouse" transition='default-fade' className={data.sub === 'warehouse' ? "btn-link-default sub-a active" : "btn-link-default sub-a"}>
                                                    <i className="fa fa-circle-o"></i> เพิ่มชื่อสินค้า
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/warehouse/warehousecreate" transition='default-fade' className={data.sub === 'warehousecreate' ? "btn-link-default sub-a active" : "btn-link-default sub-a"}>
                                                    <i className="fa fa-circle-o"></i> เพิ่มรายการสินค้า
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/warehouse/warehousewithdraw" transition='default-fade' className={data.sub === 'warehousewithdraw' ? "btn-link-default sub-a active" : "btn-link-default sub-a"}>
                                                    <i className="fa fa-circle-o"></i> เบิกคลังสินค้า
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/warehouse/warehousemove" transition='default-fade' className={data.sub === 'warehousemove' ? "btn-link-default sub-a active" : "btn-link-default sub-a"}>
                                                    <i className="fa fa-circle-o"></i> จำนวนคงเหลือต่อวัน
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/warehouse/warehousereport" transition='default-fade' className={data.sub === 'warehousereport' ? "btn-link-default sub-a active" : "btn-link-default sub-a"}>
                                                    <i className="fa fa-circle-o"></i> รายงาน
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="nav-header">ข้อมูลพนักงาน</li>
                                    <li className="nav-item has-treeview">
                                        <Link to="/member/table_member" transition="default-fade" className={menuactive == "tablemember" ? "nav-link active" : "nav-link"}>
                                            <img src={peple} alt="" className="nav-icon" style={{ width: "16px" }} />
                                            <p>ตารางการทำงาน</p>
                                        </Link>
                                    </li>

                                    <li className="nav-header">การจัดการ</li>
                                    <li className="nav-item has-treeview">
                                        <Link to="/report/reportreceipt" transition="default-fade" className={menuactive == "receipt" ? "nav-link active" : "nav-link"}>
                                            <img src={historyi} alt="" className="nav-icon" style={{ width: "16px" }} />
                                            <p>ประวัติใบเสร็จ</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item has-treeview">
                                        <Link to="/setting/setting" transition="default-fade" className={menuactive == "setting" ? "nav-link active" : "nav-link"}>
                                            <img src={setting} alt="" className="nav-icon" style={{ width: "16px" }} />
                                            <p>ตั้งค่า</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item has-treeview">
                                        <Link to="/report/report" transition="default-fade" className={menuactive == "report" ? "nav-link active" : "nav-link"}>
                                            <img src={report} alt="" className="nav-icon" style={{ width: "16px" }} />
                                            <p>รายงาน</p>
                                        </Link>
                                    </li>
                                </ul>
                                <div className='mt-5 mb-4 box-log-out'>
                                    <Link to="/branch" transition="default-fade" className="w-100 btn btn-bggray text-default py-2">
                                        <img src={logout} alt="" className="nav-icon" style={{ width: "16px" }} />
                                        <span className='title-logout'>ออกจากระบบ</span>
                                    </Link>
                                </div>
                            </>
                        }
                    </nav>
                </div>
                <div className='position-absolute text-start' style={{ right: "-40px", top: "20px", zIndex: '999' }}>
                    <img src={menuIcon} className='cursor-pointer' onClick={e => handleSetmenu(Active === 'th' ? 'en' : 'th')} style={{ width: "25px" }} />
                </div>
            </aside>


        </>
    );
};

export default Menuleft;