import React, { useState, useCallback, useEffect, useRef } from 'react';
// import axios from 'axios';
import { useNavigate, useParams,Link } from "react-router-dom";
import { compareAsc, format } from 'date-fns'

import AuthService from "../../components/services_member/auth.service";
import PostService from "../../components/services_member/post.service";

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import '../../assets/css/respornsive.css';

import noimg from '../../assets/img/no-img-product.svg';
import close from '../../assets/img/close-w.svg';
import cplus from '../../assets/img/c-plus-m.svg';
import clob from '../../assets/img/c-lob-m.svg';


import smenu from '../../assets/img/s-menu.svg';
import Menuleft from "../../layout/pagesadmin/menuleft";
import Tabcontent from "../order/order_menu_tab";
import Ordertableview from "../order/order_table_view";


import { Form, Row, Col, DropdownButton, Dropdown, ButtonGroup, Button } from "react-bootstrap";

import Modalmenuselecttable from "../../layout/modal/modalMenuselecttable";
import Swal from 'sweetalert2'

const Orderfooddetail = (dataf) => {

    let history = useNavigate();

    const { decode, id, type } = useParams();

    const [dateday, setDateday] = useState(format(new Date(), 'dd/MM/yyyy'));
    const [state, setState] = useState({ count: 1 });
    const [checked, setChecked] = useState([]);
    const [checkedState, setCheckedState] = useState([]);
    const [validated, setValidated] = useState(false);
    const [dataproduct, setDataproduct] = useState();

    const formRef = useRef(null);
    // console.log(data)

    const fetchData2 = useCallback(async (dd, id) => {

        // const branch = JSON.parse(localStorage.getItem("branch_data"));

        PostService.getReserveonedetail(id, dd, type).then(
            (response) => {
                if (response.data.status === 200) {
                    // setDatareserve(response.data.reserve)
                    // setDatacat(response.data.data_cat)
                    // if(datacat.length === 4){
                    //     setDatacat(datacat => [...datacat, ...response.data.data_cat])
                    // }
                    // setValue(value => [value,...response.data.data_cat])
                    setDataproduct(response.data.data_product[0])
                    // setDproduct(response.data.data_product)/

                    // fetchData(response.data.reserve.branch_id);
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: '',
                        text: "ไม่มีรายการเลือกโต๊ะอาหาร",
                        showConfirmButton: false,
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        )
    });

    useEffect(() => {
        fetchData2(dateday, id);

        // if (tableview) {
        //     const tableorder = JSON.parse(localStorage.getItem("table_order"));
        //     fetchData2(tableorder.date, tableview.reserve);
        // }

    }, []);

    const handleSubmit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const data2 = new FormData(event.target);

        data2.append("idProduct", dataproduct.id);
        data2.append("idOrder", id);
        data2.append("nameProduct", dataproduct.name);
        data2.append("price", dataproduct.price);
        data2.append("kitchen_id", dataproduct.kitchen_id);

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

        } else {
            AuthService.updateOrderlist(data2).then(
                (res) => {
                    if (res.status === 200) {
                        // dataf.resetdetail();
                        setValidated(false);
                        setState({ count: 1 });

                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: '',
                            text: "เพิ่มรายการเรียบร้อยแล้ว",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(function () {
                            history('/orderfood/' + decode);
                        });
                    } else {
                        console.log(res.message)
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        }
        setValidated(true);
    };

    const incrementCount = () => {
        setState({
            count: state.count + 1
        });
    }
    const decrementCount = () => {
        if (state.count > 1) {
            setState({
                count: state.count - 1
            });
        }
    }

    const onCheckChange = (position, event, number) => {

        var updatedList = [...checked];
        var updatedList2 = [...checkedState];
        if (event.target.checked) {
            if (checked.length < number) {
                updatedList = [...checked, event.target.value];
                updatedList2 = [...checkedState, position];
            }
        } else {
            updatedList.splice(checked.indexOf(event.target.value), 1);
            updatedList2.splice(checkedState.indexOf(position), 1);
        }

        setChecked(updatedList);
        setCheckedState(updatedList2);
    }

    const reset = () => {
        dataf.resetdetail();
    }

    return (
        <>
            {dataproduct ?
                <div>
                    <Form noValidate validated={validated} onSubmit={handleSubmit} ref={formRef}>
                        <div className='position-relative'>
                            <Link to={"/orderfood/" + decode} className='position-absolute' style={{
                                top: "10px", left: "10px", background: " rgba(0,0,0,.5)", padding: "5px",
                                borderRadius: "3px"
                            }}>
                                <img src={close} alt="" className="rounded" style={{ width: '25px' }} />
                            </Link>
                            {
                                dataproduct.img ?
                                    <img src={process.env.REACT_APP_API_URL + "/uploads/product/" + dataproduct.img} alt="" className="w-100 rounded" />
                                    :
                                    <img src={noimg} alt="" className="w-100 rounded" />
                            }
                        </div>
                        <div className='text-start px-3 mt-3'>

                            <div>
                                <div className='font-16 mt-2'><b>{dataproduct.name} <span className='float-end'>{dataproduct.price}</span> </b></div>
                                <div className='font-14 mt-2'>{dataproduct.detail}</div>
                            </div>
                            <hr />
                            <div>
                                <div className="">
                                    {dataproduct.choice ? <>
                                        <div className='font-18 weight-500'>

                                            {
                                                JSON.parse(dataproduct.choice).topic
                                            }
                                            <span className="font-12 text-gray">
                                                {JSON.parse(dataproduct.choice).more_n === "1" ? " จำเป็นต้องระบุ" : " ไม่จำเป็นต้องระบุ"}
                                                {JSON.parse(dataproduct.choice).more_c === "0" ? "" : " เลือกได้ไม่เกิน " +(JSON.parse(dataproduct.choice).more_number) }
                                            </span>
                                        </div>

                                        {JSON.parse(dataproduct.choice).more_n === "1" ?
                                            <div className='mt-3'>
                                                {(JSON.parse(dataproduct.choice).more_data).filter(valb => valb.more_status === "yes").map((val, index) => (
                                                    <div key={index}>
                                                        {JSON.parse(dataproduct.choice).more_c === "0" ? <>
                                                            <div className="ex-radio mb-1" >
                                                                <input onChange={(e) => onCheckChange(index, e, JSON.parse(dataproduct.choice).more_number)} required={checked.length ? false : true} type="radio" className='form-control-checkbox' id="radio_one"
                                                                    name="choice[]" value={val.more_name + "price" + val.more_price} />
                                                                <span className="radio_one mb-2 ps-4 text-gray" style={{ width: "100%" }} >
                                                                    {val.more_name}
                                                                    <span className='float-end'>+ {val.more_price}</span>
                                                                </span>
                                                            </div>
                                                        </> :
                                                            <>
                                                                <div className="ex-checkbox mb-3">
                                                                    <input type="checkbox" name="choice[]" className='form-control-checkbox' onChange={(e) => onCheckChange(index, e, JSON.parse(dataproduct.choice).more_number)}
                                                                        required={checked.length ? false : true} value={val.more_name + "price" + val.more_price}
                                                                        checked={checkedState.filter(v => v === index).length ? true : false}
                                                                    />
                                                                    <label className="radio_one mb-2 ps-4 text-gray" style={{ width: "100%" }} >
                                                                        {val.more_name} {checked[val.more_name + "price" + val.more_price]}
                                                                        <span className='float-end'>+ {val.more_price}</span>
                                                                    </label>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>

                                                ))}
                                                {checked.length ? null :
                                                    <div className='valid-checkbox'>
                                                        <span className="font-12 text-red">
                                                            จำเป็นต้องระบุ
                                                        </span>
                                                    </div>
                                                }
                                            </div>
                                            :
                                            <div className='mt-3'>
                                                {(JSON.parse(dataproduct.choice).more_data).filter(valb => valb.more_status === "yes").map((val, index) => (
                                                    <div key={index}>
                                                        {JSON.parse(dataproduct.choice).more_c === "0" ? <>
                                                            <div className="ex-radio mb-1" >
                                                                <input onChange={(e) => onCheckChange(index, e, JSON.parse(dataproduct.choice).more_number)} type="radio" className='form-control-checkbox'
                                                                    name="choice[]" value={val.more_name + "price" + val.more_price} />
                                                                <span className="radio_one mb-2 ps-4 text-gray" style={{ width: "100%" }} >
                                                                    {val.more_name}
                                                                    <span className='float-end'>+ {val.more_price}</span>
                                                                </span>
                                                            </div>
                                                        </> :
                                                            <>
                                                                <div className="ex-checkbox mb-3">
                                                                    <input type="checkbox" name="choice[]" className='form-control-checkbox' onChange={(e) => onCheckChange(index, e, JSON.parse(dataproduct.choice).more_number)} 
                                                                        value={val.more_name + "price" + val.more_price} checked={checkedState.filter(v => v === index).length ? true : false} />
                                                                    <label className="radio_one mb-2 ps-4 text-gray" style={{ width: "100%" }} >
                                                                        {val.more_name}
                                                                        <span className='float-end'>+ {val.more_price}</span>
                                                                    </label>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                ))}

                                            </div>
                                        }
                                    </> : null}
                                    <div>
                                        <Row className="mt-4 mb-3">
                                            <Form.Group as={Col} md="12" className="position-relative" controlId="validationCustom01">
                                                <Form.Label className="font-16 weight-500 mb-0">หมายเหตุเพิ่มเติม <span className='font-12 text-gray'>ไม่จำเป็นต้องระบุ</span></Form.Label>
                                                <textarea name='note' className="form-control font-14 mt-2" rows="3" placeholder='ระบุรายละเอียดคำขอ'></textarea>
                                            </Form.Group>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                            <div className='pb-5'>
                                <div className='row'>
                                    <div className="col-6 col-md-6 col-lg-6 pe-1">
                                        <div className='text-start'>
                                            <span className='btn-lob-m rounded' onClick={decrementCount}>
                                                <img src={clob} style={{ marginBottom: "-14px", width: "15px" }} />
                                            </span>
                                            <span className='mx-2 d-inline-block text-center position-relative' style={{ width: "30px", verticalAlign: "sub" }}>
                                                <div className='position-center position-absolute'>{state.count}</div>
                                                <input type="hidden" defaultValue={state.count} name="qty" />
                                            </span>
                                            <span className='btn-lob-m rounded' onClick={incrementCount}>
                                                <img src={cplus} style={{ marginBottom: "-14px", width: "15px" }} />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-6 col-lg-6 ps-1">
                                        <button type='submit' className="w-100 btn btn-submit-mem text-white py-2">
                                            สั่งอาหาร
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
                : null}
        </>
    );
};

export default Orderfooddetail;