import React, { useRef } from 'react';
import { Modal, Button } from "react-bootstrap";
import { compareAsc, format } from 'date-fns'
import { useNavigate } from "react-router-dom";
import ReactToPrint from 'react-to-print';

import ComponentToqrcode from '../../components/ComponentToqrcode';

const Modalqrcode = (datas) => {

    const branch = JSON.parse(localStorage.getItem("branch_data"));

    const componentRef = useRef(null);
    // const handlePrint = useReactToPrint({
    //     content: () => componentRef.current,
    // });
    const printSuccess = (e) => {
        // alert(e)
        // console.log(e)
        datas.onHide()
    }

    return (
        <>
            <Modal {...datas} className="modal-custom" size="md" backdrop="static" keyboard={false}>
                <Modal.Header closeButton className="btn-close-white ">

                </Modal.Header>
                <Modal.Body className="p-0 bg-white">
                    <div className="d-block w-100 overflow-hidden">

                        <div className='pt-3 pb-4 bg-white px-4 text-center'>
                            <div>
                                <img src={process.env.REACT_APP_API_URL + "/uploads/branch/" + branch.logo} alt="" className="w-25 px-3" />
                            </div>
                            <div className='font-18 mt-3'><b> {datas.zone} : {datas.table} </b></div>
                            <div className='font-16 weight-500 mt-1'>Order #{datas.no}</div>
                            <div className='font-14 weight-400 mt-1 text-gray'>{datas.date}</div>
                            <div className='my-3'>
                                <img src={process.env.REACT_APP_API_URL + "/qrcode/order/" + datas.id + "-qrcode.png"} alt="" className="w-50 px-4" />
                            </div>
                            <div className='font-14'>
                                QR code สำหรับดูเมนูและสั่งอาหาร<br />
                                ท่านสามารถใช้ Smartphone ในการสแกน <br />
                                เพื่อทำการสั่งอาหารได้เลย
                            </div>
                            <div className='font-12 mt-4'>
                                วิธีใช้ : เปิดกล้องโทรศัพท์ของท่าน<br />
                                และสแกน QR Code นี่ได้ทันที
                            </div>
                            <div className='mt-3'>
                                <ReactToPrint
                                    trigger={() => <button className="btn btn-blue text-white py-2 mt-2 me-3 px-5">พิมพ์ QR Code</button>}
                                    content={() => componentRef.current}
                                    documenttTitle='name document'
                                    onAfterPrint={() => printSuccess('yes')}
                                />                                
                                    <div className='position-absolute d-none'>
                                        <div className="ticket" ref={componentRef} >
                                            <div className="" />
                                            <ComponentToqrcode
                                                logo={branch.logo}
                                                zone={datas.zone}
                                                table={datas.table}
                                                no={datas.no}
                                                date={datas.date}
                                                id={datas.id}
                                            />
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    );
};

export default Modalqrcode;