import React, { useState, useCallback, useEffect } from 'react';
// import axios from 'axios';
import { useNavigate,Link } from "react-router-dom";
import { compareAsc, format } from 'date-fns'

import AuthService from "../../components/services_member/auth.service";
import PostService from "../../components/services_member/post.service";

import help from '../../assets/img/help.svg';
import smenu from '../../assets/img/s-menu.svg';

import Menuleft from "../../layout/pagesadmin/menuleft";
import Tabcontent from "../order/order_menu_tab";
import Ordertableview from "../order/order_table_view";

// import { Link } from "react-tiger-transition";
import { Form, Tab, Nav, DropdownButton, Dropdown, ButtonGroup, Button } from "react-bootstrap";

import Modalmenuselecttable from "../../layout/modal/modalMenuselecttable";
import { useTranslation } from 'react-i18next';

const Ordermenu = () => {

    const { t } = useTranslation();
    const tableorder = JSON.parse(localStorage.getItem("table_order"));

    let history = useNavigate();
    const user = AuthService.getCurrentUser();
    const branch = AuthService.getCurrentBranch();

    // localStorage.removeItem("table_order");

    if (!user) {
        history("/");
    } else {
        if (!branch) {
            history("/branch");
        }
    }

    const [datacat, setDatacat] = useState([]);
    const [dataproduct, setDataproduct] = useState([]);
    const [datapromotion, setDatapromotion] = useState([]);
    const [modalselecttable, setModalselecttable] = useState({ view: false, id: "0" });

    const [datareserve, setDatareserve] = useState([]);

    const [dateday, setDateday] = useState(format(new Date(), 'dd/MM/yyyy'));

    const [tableview, setTableview] = useState(tableorder);

    const [showDropdown, setShowDropdown] = useState(false);

    const fetchData = useCallback(async () => {

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        PostService.getMenuall(branch.id).then(
            (response) => {
                if (response.data.status === 200) {
                    setDatacat(response.data.data_cat);

                    setDataproduct(response.data.data_product);
                    setDatapromotion(response.data.data_promotion);
                }
            },
            (error) => {
                console.log(error);
            }
        )
    });

    const fetchData2 = useCallback(async (dd, td) => {

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        PostService.getReserveall(branch.id, dd, td).then(
            (response) => {
                if (response.data.status === 200) {
                    setDatareserve(response.data.reserve)
                }
            },
            (error) => {
                console.log(error);
            }
        )
    });

    useEffect(() => {
        fetchData();
        if (tableview) {
            const tableorder = JSON.parse(localStorage.getItem("table_order"));
            fetchData2(tableorder.date, tableview.reserve);
        }

    }, []);

    // console.log(tableview)

    const handlerefresh = (event) => {
        const tableorder = JSON.parse(localStorage.getItem("table_order"));
        if (tableorder) {
            setTableview(tableorder);
            fetchData();
            fetchData2(tableorder.date, tableorder.reserve);
        }
    }

    const clickrefresh = (ee) => {

        const tableorder = JSON.parse(localStorage.getItem("table_order"));

        if (tableorder) {
            setTableview(tableorder);
            fetchData2(tableorder.date, ee);
        }
        setModalselecttable({ view: false, id: 0 });
        // data.handlerefresh()
    }

    // console.log(dateday)

    const handlemodalordertable = () => {
        setModalselecttable({ view: true, id: 1 })
    }

    const toggleDropdown = () => {
        if (showDropdown) {
            setShowDropdown(false);
        } else {
            setShowDropdown(true);
        }
    }


    const handledrivethutable = () => {

        const dataf = new FormData();
        const branch = JSON.parse(localStorage.getItem("branch_data"));
        dataf.append("id", 0);
        dataf.append("branch_id", branch.id);
        dataf.append("zone", 0);
        dataf.append("table_reserve", 0);

        AuthService.updateReserveorder(dataf).then(
            (res) => {
                if (res.status === 200) {
                    localStorage.setItem('table_order', JSON.stringify({ id: 0, zone: 0, table_id: 0, table: 0, reserve: res.data.id, date: dateday }));
                    clickrefresh(res.data.id);

                    toggleDropdown();

                } else {
                    console.log(res.message)
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    const [dataKey, setDataKey] = useState('อาหารทั้งหมด');
    const handleSelect = (key) => {
        setDataKey(key)
    }
    return (
        <>
            <div className={!t('menu-a') ? "sidebar-mini sidebar-collapse" : ""} >
                <div className='wrapper-admin layout-fixed'>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="food_all">
                        <nav className="main-header navbar bg-white shadow-sm" style={{ height: "60px" }}>
                            <Nav as="ul" variant="tabs" className="nav nav-tabs overflow-hidden ul-tabcustom">
                                <Nav.Item as="li">
                                    <Nav.Link eventKey="promotion_all">โปรโมชั่น</Nav.Link>
                                </Nav.Item>
                                {/* <Nav.Item as="li">
                                <Nav.Link eventKey="food_all">อาหารทั้งหมด</Nav.Link>
                            </Nav.Item>
                            {datacat.sort((a, b) => a.position - b.position).map((value, index) => (
                                <Nav.Item as="li" key={index}>
                                    <Nav.Link eventKey={value.id}>{value.category}</Nav.Link>
                                </Nav.Item>
                            ))} */}
                                <Nav.Item as="li">
                                    <Dropdown className='menu-top-dd'>
                                        <Dropdown.Toggle variant="menu-tab" id="dropdown-basic">
                                            {dataKey}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item eventKey="food_all" onSelect={(e) => handleSelect("อาหารทั้งหมด")}>อาหารทั้งหมด</Dropdown.Item>
                                            {datacat.sort((a, b) => a.position - b.position).map((value, index) => (
                                                <Dropdown.Item eventKey={value.id} key={index} onSelect={(e) => handleSelect(value.category)}>{value.category}</Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Nav.Item>
                            </Nav>
                            <ul className="navbar-nav ml-auto" style={{ marginTop: "-20px" }}>
                                <li className="">
                                    <div className='text-gray font-12'>
                                        <img src={help} alt="" className="me-1" style={{ width: "14px" }} /> ขอความช่วยเหลือ
                                    </div>
                                </li>
                            </ul>
                        </nav>


                        <Menuleft type="order" />

                        <div className="content-wrapper">
                            <section className="content scroll-custom">
                                <div className="row">
                                    <div className="col-12 col-md-8 col-lg-8 ">
                                        <Tabcontent datacat={datacat} dataproduct={dataproduct} datapromotion={datapromotion} tableview={tableview} datareserve={datareserve} modalshow={handlemodalordertable} handlerefresh={handlerefresh} />
                                    </div>

                                    <div className="col-12 col-md-4 col-lg-4 ps-0">

                                        <div className='w-100 mt-3 ps-2 mb-2'>
                                            <div className="w-100 bg-blue text-white py-3">
                                                <DropdownButton show={showDropdown} onClick={() => toggleDropdown()} id="dropdown-item-button" className='w-100'
                                                    title={tableview ? tableview.id === 0 ? "Drive Thru" : "ทานที่ร้าน" : "ทานที่ร้าน"}>
                                                    <Dropdown className='px-3 py-1 cursor' onClick={handlemodalordertable}>ทานที่ร้าน</Dropdown>
                                                    <Dropdown className='px-3 py-1 cursor' onClick={handledrivethutable}>Drive Thru</Dropdown>
                                                </DropdownButton>
                                            </div>
                                        </div>

                                        {tableview ?
                                            <Ordertableview ordertable={tableview} datapromotion={datapromotion} tableorder={handlemodalordertable} reloaddata={handlerefresh} datareserve={datareserve} />
                                            :
                                            <div className="bg-white rounded-3 ms-2 mt-2 h-100per-right-d position-relative">
                                                <div className='position-absolute position-center'>
                                                    <img src={smenu} />
                                                    <div className='weight-500 font-18 mt-3'>เลือกเมนูเพื่อเริ่มสั่งอาหาร</div>
                                                </div>
                                            </div>
                                        }

                                        {/* {reserveview.detail ?
                                        <Reserdetail datatableall={datatable} datatable={datatableone} detailreserve={datareserveone} />
                                        : null} */}
                                    </div>
                                </div>
                            </section>

                        </div>
                    </Tab.Container>
                </div>
            </div>
            {modalselecttable.view ?
                <Modalmenuselecttable show={modalselecttable.view} onHide={clickrefresh} id={modalselecttable.id} />
                : null}
        </>
    );
};

export default Ordermenu;