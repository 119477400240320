import React, { useState, useEffect, useCallback } from 'react';
// import axios from 'axios';
import { Link } from "react-router-dom";
import { compareAsc, format } from 'date-fns';
// import DatePicker from "react-datepicker";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import help from '../../assets/img/help.svg';
import calendar from '../../assets/img/calendar.svg';
import ipdf from '../../assets/img/i-pdf.svg';
import iexcel from '../../assets/img/i-excel.svg';

import Menuleft from "../../layout/pagesadmin/menuleft";
import Menureport from "../../components/report/report_menu";

import { Form, Tab, Nav } from "react-bootstrap";
// import { Form, Col, Row, InputGroup, Button } from "react-bootstrap";
// import Modalreportsummary from "../../layout/modal/modalReportsummary";

import PostService from "../../components/services_member/post.service";

import {
    Chart as ChartJS,
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Doughnut, Line } from 'react-chartjs-2';

import DatePicker from "react-datepicker";
// var DatePicker = require("react-datepicker");
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next';

ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const Reportsummary = () => {
    const { t } = useTranslation();
    const [todayDate, setTodayDate] = useState(format(new Date(), 'yyyy-MM-dd'));
    const [todayDateend, setTodayDateend] = useState(format(new Date('2023-04-01'), 'yyyy-MM-dd'));
    const [startDate, setStartDate] = useState(new Date());

    const [loadingpage, setLoadingpage] = useState(true);

    console.log(todayDate === todayDateend ? 'yes' : 'no')

    // const dateoption = {
    //     locale: {
    //         format: 'MM/YYYY'
    //     },
    //     // startDate: todayDate,
    //     singleDatePicker: true,
    //     endDate: startDate,
    //     autoApply: true,
    // };

    const [datestart, setDatestart] = useState(format(new Date(), 'MM/yyyy'));
    // const [dateend, setDateend] = useState(format(new Date(), 'dd/MM/yyyy'));

    const [modalreportsummary, setModalreportsummary] = useState({ view: false, id: 0 });

    const [dataDonut, setDataDonut] = useState({ cash: 0, credit: 0, qrcode: 0 });

    const [validated, setValidated] = useState(false);

    const fetchData = useCallback(async (dd) => {

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        PostService.getSummaryallmonth(branch.id, dd).then(
            (response) => {

                setLoadingpage(false)

                if (response.status === 200) {

                    setModalreportsummary({ view: true, datasummary: response.reserve });

                    setDataDonut({
                        cash: sumall((response.reserve).filter(val => val.data_type == 1), 10),
                        credit: sumall((response.reserve).filter(val => val.data_type == 3), 10),
                        qrcode: sumall((response.reserve).filter(val => val.data_type == 2), 10)
                    })

                } else {
                    setModalreportsummary([]);
                }
            },
            (error) => {
                console.log(error);
            }
        )

    });

    useEffect(() => {
        fetchData(datestart)
    }, []);

    const handledateday = (event, picker) => {
        setLoadingpage(true)
        // setDatestart(event);
        // fetchData(event)
        setDatestart(format(new Date(event), 'MM/yyyy'));
        setTodayDate(format(new Date(event), 'yyyy-MM-dd'));
        setStartDate(event);
        fetchData(format(new Date(event), 'MM/yyyy'))
        // setDateend(format(new Date(picker.endDate._d), 'MM/yyyy'));
    }

    const sumall = (content, type) => {

        var sumprice = 0;
        var summore = 0;
        var sumservice = 0;
        var sumvat = 0;
        var sumpromo = 0;
        var sumcut = 0;

        // console.log(content)
        if (content) {
            if (content.length) {
                sumprice = content.reduce((prev, current) => prev + +current.data_price, 0)
                summore = content.reduce((prev, current) => prev + +current.data_pricemore, 0)
                sumservice = content.reduce((prev, current) => prev + +current.data_service, 0)
                sumvat = content.reduce((prev, current) => prev + +current.data_vat, 0)
                sumpromo = content.reduce((prev, current) => prev + +current.data_promotion, 0)
                sumcut = content.reduce((prev, current) => prev + +current.totalCut, 0)
            }

            if (type === 1) {
                return (((sumprice + summore + sumservice + sumvat) - sumpromo) - sumcut).toLocaleString();
            } else if (type === 2) {
                return (sumprice + summore + sumservice + sumvat).toLocaleString();
            } else if (type === 3) {
                return (sumpromo).toLocaleString();
            } else if (type === 4) {
                return (sumservice).toLocaleString();
            } else if (type === 5) {
                return (sumvat).toLocaleString();
            } else if (type === 6) {
                return (sumprice + summore).toLocaleString();
            } else if (type === 7) {
                return (sumprice + summore - sumpromo).toLocaleString();
            } else if (type === 8) {
                return (sumcut).toLocaleString();
            } else if (type === 9) { //sum cash
                return (sumcut).toLocaleString();
            } else if (type === 10) { //sum all no string
                return (((sumprice + summore + sumservice + sumvat) - sumpromo) - sumcut);
            } else if (type === 0) {
                return (sumprice + summore).toLocaleString();
            }
        }
    }

    const datatest = {
        labels: ['Cash ' + dataDonut.cash.toLocaleString() + '฿', 'Credit Card ' + dataDonut.credit.toLocaleString() + '฿', 'QR Code ' + dataDonut.qrcode.toLocaleString() + '฿'],
        datasets: [
            {
                data: [
                    ((dataDonut.cash / sumall(modalreportsummary.datasummary, 10)) * 100).toLocaleString(),
                    ((dataDonut.credit / sumall(modalreportsummary.datasummary, 10)) * 100).toLocaleString(),
                    ((dataDonut.qrcode / sumall(modalreportsummary.datasummary, 10)) * 100).toLocaleString()],
                backgroundColor: [
                    'rgba(0, 131, 25, 1)',
                    'rgba(236, 184, 48, 1)',
                    'rgba(170, 17, 17, 1)',
                ],
            },
        ],
    };

    const options = {
        cutout: 70,
        plugins: {
            legend: {
                // labels: {
                //     render: 'percentage',
                //     precision: 2,
                //     // padding: 30,
                //     // boxWidth: "40",
                //     boxHeight: "15",
                //     // color: 'rgb(255, 99, 132)
                // },
                labels: {
                    pointStyle: "<div>9999</div>",
                    usePointStyle: true
                },
                display: true,
                position: "bottom",
                align: "start",
            },
        },
        height: 100,
        radius: 80,
        rotation: 215,
    }

    const [clssLoading, setClssLoading] = useState(false);
    const [clssLoadingVat, setClssLoadingVat] = useState({ a: false, b: false, c: false });
    const [clssLoadingb, setClssLoadingb] = useState(false);

    const exportExcel = () => {

        setClssLoading(true);

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        PostService.getWarehouseExportMonth(branch.id, datestart).then(
            (response) => {
                if (response.data.status === 200) {

                    // console.log(response.data.redirect)

                    window.location.href = response.data.redirect;

                    setClssLoading(false);
                } else {
                    // setDatawaste([]);

                    setClssLoading(false);
                }
            },
            (error) => {
                console.log(error);
            }
        )
    }


    const exportExcelb = () => {

        setClssLoadingb(true);

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        PostService.getWarehouseExportMonthb(branch.id, datestart).then(
            (response) => {
                if (response.data.status === 200) {

                    // console.log(response.data.redirect)

                    window.location.href = response.data.redirect;

                    setClssLoadingb(false);
                } else {
                    // setDatawaste([]);

                    setClssLoadingb(false);
                }
            },
            (error) => {
                console.log(error);
            }
        )
    }

    const exportExcelVat = (type) => {

        if (type === 1) {
            setClssLoadingVat({ a: true, b: false, c: false });
        } else if (type === 2) {
            setClssLoadingVat({ a: false, b: true, c: false });
        } else {
            setClssLoadingVat({ a: false, b: false, c: true });
        }


        const branch = JSON.parse(localStorage.getItem("branch_data"));

        PostService.getVatMonth(branch.id, datestart, type).then(
            (response) => {
                if (response.data.status === 200) {

                    // console.log(response.data.redirect)

                    window.location.href = response.data.redirect;

                    setClssLoadingVat({ a: false, b: false, c: false });
                } else {
                    // setDatawaste([]);

                    setClssLoadingVat({ a: false, b: false, c: false });
                }
            },
            (error) => {
                console.log(error);
            }
        )
    }

    return (
        <>
            <div className={!t('menu-a') ? "sidebar-mini sidebar-collapse" : ""} >
                <div className='wrapper-admin layout-fixed'>
                    <nav className="main-header navbar bg-white shadow-sm" style={{ height: "60px" }}>
                        <Nav as="ul" variant="tabs" className="nav nav-tabs overflow-hidden ul-tabcustom">

                        </Nav>
                        <ul className="navbar-nav ml-auto" style={{ marginTop: "-20px" }}>
                            <li className="">
                                <div className='text-gray font-12'><img src={help} alt="" className="me-1" style={{ width: "14px" }} /> ขอความช่วยเหลือ</div>
                            </li>
                        </ul>
                    </nav>

                    <Menuleft type="report" />

                    <div className="content-wrapper">
                        <section className="content scroll-custom">
                            <div className="row">
                                <div className="col-12 col-md-4 col-lg-4">
                                    <div className='text-start box-setting bg-white rounded-3 mt-3 h-100per'>
                                        <Menureport type="summary" sub="month" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-8 col-lg-8 ps-0">
                                    <div className="bg-white rounded-3 ms-2 mt-3 h-100per position-relative">

                                        <div className='px-4'>

                                            <div className='text-start py-3 weight-500'>สรุปยอดขายรายเดือน</div>
                                            <div className='position-relative'>
                                                <div className="row">
                                                    <div className="col-4">
                                                        <Form.Group controlId="dob" className='position-relative'>
                                                            <DatePicker
                                                                className='form-control py-2'
                                                                selected={startDate}
                                                                onChange={(e) => (handledateday(e))}
                                                                dateFormat="MM/yyyy"
                                                                showMonthYearPicker
                                                            >
                                                                <input type="text" className="form-control px-2 py-1" name="dateday" />
                                                            </DatePicker>
                                                            <div className='position-absolute' style={{ top: "11px", right: "10px" }}>
                                                                <img src={calendar} alt="" className="me-1" style={{ width: "18px" }} />
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-8">
                                                        <div className='font-16 text-end'>

                                                            <button onClick={() => exportExcel()} className="btn btn-submit text-white ms-2 font-12 py-1">
                                                                {clssLoading ? <i className="fa fa-spinner fa-spin" /> : <i className="fa fa-file-excel-o" aria-hidden="true"></i>} <span> รายงานยอดขาย</span>
                                                            </button>
                                                            <button onClick={() => exportExcelb()} className="btn btn-blue text-white ms-2 font-12 py-1">
                                                                {clssLoadingb ? <i className="fa fa-spinner fa-spin" /> : <i className="fa fa-file-excel-o" aria-hidden="true"></i>} <span> รายงานรายได้</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {loadingpage ?
                                                <div className='pt-5 mt-5'>
                                                    <i className="fa fa-spinner fa-spin" />
                                                    <div>Loading</div>
                                                </div>
                                                :

                                                <div className='row mt-3 pt-1 '>
                                                    <div className="col-12 col-md-6 col-lg-5 bg-white p-3 rounded-1">
                                                        <div className='text-start'>
                                                            <div>ยอดขายสุทธิ</div>
                                                            <div className='font-24 mt-2 mb-3'>
                                                                <b>฿{sumall(modalreportsummary.datasummary, 1)}</b><br />
                                                            </div>
                                                            <div className='mt-3'>
                                                                <div className='row'>
                                                                    <div className="col-8 col-md-8 col-lg-8">
                                                                        <div className='font-14' style={{ lineHeight: "1.5" }}>
                                                                            ยอดรวม <br />
                                                                            ลดราคาโปรโมชั่น <br />
                                                                            ลดราคาอื่นๆ <br />
                                                                            ยอดขายหลังลดราคา <br />
                                                                            ค่าบริการ <br />
                                                                            ภาษีมูลค่าเพิ่ม (VAT) <br />
                                                                            {/* ลดราคาท้ายบิล <br />
                                                                        ยอดรวมปัดเศษ <br /> */}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4 col-md-4 col-lg-4 text-end">
                                                                        <div className='font-14' style={{ lineHeight: "1.5" }}>
                                                                            {sumall(modalreportsummary.datasummary, 0)} <br />
                                                                            - {sumall(modalreportsummary.datasummary, 3)} <br />
                                                                            - {sumall(modalreportsummary.datasummary, 8)} <br />
                                                                            {sumall(modalreportsummary.datasummary, 7)} <br />
                                                                            {sumall(modalreportsummary.datasummary, 4)} <br />
                                                                            {sumall(modalreportsummary.datasummary, 5)} <br />
                                                                            {/* 00.00 <br />
                                                                        00.00 <br /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* {new Date(todayDateend)} */}

                                                            {todayDate >= todayDateend ?
                                                                <div className='mt-5'>
                                                                    <button onClick={() => exportExcelVat(1)} className="btn btn-submit text-white font-12 py-1 d-block">
                                                                        {clssLoadingVat.a ? <i className="fa fa-spinner fa-spin" /> : <i className="fa fa-file-excel-o" aria-hidden="true"></i>} <span> รายงานใบกำกับภาษี</span>
                                                                    </button>
                                                                    <button onClick={() => exportExcelVat(2)} className="btn btn-submit text-white mt-2 font-12 py-1 d-block">
                                                                        {clssLoadingVat.b ? <i className="fa fa-spinner fa-spin" /> : <i className="fa fa-file-excel-o" aria-hidden="true"></i>} <span> รายงานใบกำกับภาษีอย่างย่อ</span>
                                                                    </button>
                                                                    <button onClick={() => exportExcelVat(3)} className="btn btn-submit text-white mt-2 font-12 py-1 d-block">
                                                                        {clssLoadingVat.c ? <i className="fa fa-spinner fa-spin" /> : <i className="fa fa-file-excel-o" aria-hidden="true"></i>} <span> รายงานบิลเงินสด</span>
                                                                    </button>
                                                                </div>
                                                                : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-7 pe-0 rounded-1">
                                                        <div className='bg-white p-3 rounded-1'>
                                                            <div className='text-center font-14'>แบ่งยอดขายตามประเภทการชำระเงิน (%)</div>
                                                            <div className='px-5'>
                                                                <Doughnut data={datatest} options={options} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {/* <div className='row mt-2 pt-1 pb-2'>
                                            <div className="col-12 col-md-12 col-lg-12 bg-white p-3 rounded-1">
                                                <div className='my-3 rounded-1 bg-white'>
                                                    <div className='text-gray font-12 ps-5'>จำนวนเงิน (บาท)</div>
                                                    <Line options={optionsline} data={dataline} />
                                                </div>
                                            </div>
                                        </div> */}
                                        </div>


                                    </div>
                                    {/* <div className='w-100 ps-2 mt-2'>
                                        <button type="submit" className="w-100 btn btn-bg text-white py-2">
                                            ดูรายงานยอดขาย
                                        </button>
                                    </div> */}
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            {/* {modalreportsummary.view ?
                <Modalreportsummary show={modalreportsummary.view} onHide={() => setModalreportsummary({ view: false })} datasum={modalreportsummary.datasummary} datestart={datestart} dateend={dateend} />
                : null} */}
        </>
    );
};

export default Reportsummary;