import React, { useState, useCallback, useEffect } from 'react';
// import axios from 'axios';
import { useNavigate } from "react-router-dom";
import Moment from 'moment';
import { compareAsc, format } from 'date-fns';

import '../assets/css/print.css';

// import PostService from "./services_member/post.service";
// import AuthService from "./services_member/auth.service";

// const getToken = () => {
//     const tokenString = localStorage.getItem("login_member");
//     const userToken = JSON.parse(tokenString);
//     return userToken?.token
// }

const ComponentToqrcode = (data) => {

    let history = useNavigate();

    // const getPageMargins = () => {
    //     return `@page { margin: 0 !important; }`;
    // };
    // console.log(data.datareceipt.logo)
    return (
        <>

            <div className="ticket mb-3" >

                <div className='mt-1 font-12 text-center'>
                    <div>
                        <img src={process.env.REACT_APP_API_URL + "/uploads/branch/" + data.logo} alt="" className="w-50 px-3" />
                    </div>
                    <div className='font-14 mt-3'>{data.zone} : {data.table}</div>
                    <div className='font-12 mt-1'>Order #{data.no}</div>
                    <div className='font-12 mt-1 '>{data.date}</div>
                    <div className='my-3'>
                        <img src={process.env.REACT_APP_API_URL + "/qrcode/order/" + data.id + "-qrcode.png"} alt="" className="w-100 px-4" />
                    </div>
                    <div className='font-10'>
                        QR code สำหรับดูเมนูและสั่งอาหาร <br />
                        ท่านสามารถใช้ Smartphone ในการสแกน <br />
                        เพื่อทำการสั่งอาหารได้เลย
                    </div>
                    <div className='font-9 mt-4'>
                        วิธีใช้ : เปิดกล้องโทรศัพท์ของท่าน<br />
                        และสแกน QR Code นี่ได้ทันที
                    </div>
                </div>

            </div>

        </>
    );
};

export default ComponentToqrcode;