import React, { useState } from 'react';

import { useNavigate } from "react-router-dom";

import AuthService from "../components/services_member/auth.service";

const Logoutmember = () => {

    let history = useNavigate();

    // const { t } = useTranslation();

    // const [login, setLogin] = useState(localStorage.getItem('login_member'));

    // const storageLogin = localStorage.getItem('login_member');
    // if (!login) {
    //     history("/");
    // }

    const logout = code => e => {

        // localStorage.removeItem("login_member");
        history("/");
        AuthService.logout();
        window.location.reload();     
    }

    return (
        <>     
        <button onClick={logout()} className="w-100 btn btn-bg text-white px-5">ออกจากระบบ</button>      
        </>
    );
};

export default Logoutmember;