import React, { useState, useRef } from 'react';
import { Modal, Button } from "react-bootstrap";
import { compareAsc, format } from 'date-fns'
import { useNavigate } from "react-router-dom";

import AuthService from "../../components/services_member/auth.service";
// import PostService from "../../components/services_member/post.service";

import chold from '../../assets/img/confirm-hold.svg';

import ReactToPrint from 'react-to-print';

import ComponentToqrcode from '../../components/ComponentToqrcode';

const Modalcreateqrcode = (datas) => {

    let history = useNavigate();
    const [dateday, setDateday] = useState(format(new Date(), 'dd/MM/yyyy - HH:mm'));
    const [dateday2, setDateday2] = useState(format(new Date(), 'dd/MM/yyyy'));

    const [viewqr, setViewqr] = useState({ view: false });

    const branch = JSON.parse(localStorage.getItem("branch_data"));

    const submithold = (id, type) => {

        const dataf = new FormData();
        const branch = JSON.parse(localStorage.getItem("branch_data"));
        dataf.append("id", 0);
        dataf.append("branch_id", branch.id);
        dataf.append("zone", datas.id);
        dataf.append("table_reserve", datas.table_id);

        AuthService.updateReserveorder(dataf).then(
            (res) => {
                if (res.status === 200) {
                    localStorage.setItem('table_order', JSON.stringify({ id: datas.id, zone: datas.zone, table_id: datas.table_id, table: datas.table, reserve: res.data.id, date: dateday2 }));

                    if (type === 'qrcode') {
                        setViewqr({ view: true, id: res.data.id, no: res.data.no })
                    } else {
                        history("/order/ordermenu");
                    }
                } else {
                    console.log(res.message)
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    const componentRef = useRef(null);
    // const handlePrint = useReactToPrint({
    //     content: () => componentRef.current,
    // });
    const printSuccess = (e) => {
        // alert(e)
        // console.log(e)
        datas.onHide()
    }

    return (
        <>
            <Modal {...datas} className="modal-custom" size="md" backdrop="static" keyboard={false}>
                <Modal.Header closeButton className="btn-close-white ">

                </Modal.Header>
                <Modal.Body className="p-0 bg-white">
                    <div className="d-block w-100 overflow-hidden">
                        {viewqr.view ?
                            <div className='pt-3 pb-4 bg-white px-4 text-center'>
                                <div>
                                    <img src={process.env.REACT_APP_API_URL + "/uploads/branch/" + branch.logo} alt="" className="w-25 px-3" />
                                </div>
                                <div className='font-18 mt-3'><b> {datas.zone} : {datas.table} </b></div>
                                <div className='font-16 weight-500 mt-1'>Order #{viewqr.no}</div>
                                <div className='font-14 weight-400 mt-1 text-gray'>{dateday}</div>
                                <div className='my-3'>
                                    <img src={process.env.REACT_APP_API_URL + "/qrcode/order/" + viewqr.id + "-qrcode.png"} alt="" className="w-50 px-4" />
                                </div>
                                <div className='font-14'>
                                    QR code สำหรับดูเมนูและสั่งอาหาร 1<br />
                                    ท่านสามารถใช้ Smartphone ในการสแกน <br />
                                    เพื่อทำการสั่งอาหารได้เลย
                                </div>
                                <div className='font-12 mt-4'>
                                    วิธีใช้ : เปิดกล้องโทรศัพท์ของท่าน<br />
                                    และสแกน QR Code นี่ได้ทันที
                                </div>
                                <div className='mt-3'>
                                    <ReactToPrint
                                        trigger={() => <button className="btn btn-blue text-white py-2 mt-2 me-3 px-5">พิมพ์ QR Code</button>}
                                        content={() => componentRef.current}
                                        documenttTitle='name document'
                                        onAfterPrint={() => printSuccess('yes')}
                                    />
                                    <div className='position-absolute d-none'>
                                        <div className="ticket" ref={componentRef} >
                                            <div className="" />
                                            <ComponentToqrcode
                                                logo={branch.logo}
                                                zone={datas.zone}
                                                table={datas.table}
                                                no={viewqr.no}
                                                date={dateday}
                                                id={viewqr.id}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <>
                                <div className='pt-5 pb-4 bg-white px-4 text-center'>
                                    <div className='font-18 weight-500 mt-4'>สร้าง QR Code สำหรับสั่งอาหาร </div>
                                    <div className='font-16 weight-500 mt-1'>{datas.zone} : {datas.table}</div>
                                    <div className='font-14 weight-400 mt-1 text-gray'>{dateday}</div>
                                    <div className='text-center px-3 mt-5'>
                                        <div className='row'>
                                            <div className="col-12 col-md-6 col-lg-6 pe-1">
                                                <button className="w-100 btn btn-blue text-white py-2 me-3" onClick={() => submithold(datas.id, 'qrcode')}>
                                                    พิมพ์ QR Code
                                                </button>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 ps-1">
                                                <button type='submit' onClick={() => submithold(datas.id, 'menu')} className="w-100 btn btn-submit text-white py-2 me-3">
                                                    สั่งอาหาร
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                    </div>
                </Modal.Body>
            </Modal>

        </>
    );
};

export default Modalcreateqrcode;