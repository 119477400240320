import React, { useState } from 'react';
import { Form, Col, Row } from "react-bootstrap";

import AuthService from "../components/services_member/auth.service";
// import '../assets/css/style.css';

import { useNavigate,Link } from "react-router-dom";

// import { Link } from "react-tiger-transition";

import imglogin from '../assets/img/img-login-2.jpg';
import eye from '../assets/img/eye.svg';
import loading from '../assets/img/loading_icon.gif';

// state = { open: false };

// toggleMenu = () => {
//     this.setState({ open: !this.state.open });
//     console.log(this.state.open);
// };

const Home = () => {

    localStorage.removeItem("member");
    localStorage.removeItem("member_data");
    localStorage.removeItem("branch_data");
    localStorage.removeItem("position_data");
    localStorage.removeItem("more_data");

    let history = useNavigate();

    const [form, setForm] = useState({})
    const [errors, setErrors] = useState(false)

    const [validated, setValidated] = useState(false);
    const [clssLoading, setClssLoading] = useState(false);


    const handleSubmit = async (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const data = new FormData(event.target);

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            setClssLoading(true);

            try {
                AuthService.login(data.get('email'), data.get('password')).then(
                    (res) => {
                        if (res.status === 200) {
                            // history("/business");                              
                            // setClssLoading(false);
                            history("/branch");
                            // window.location.reload(); 
                        } else {
                            setErrors(true);
                            setClssLoading(false);
                        }
                    },
                    (error) => {
                        console.log(error);
                        setClssLoading(false);
                        // setErrors(true)
                    }
                );
            } catch (err) {
                console.log(err);
                // setErrors(true)
            }
        }
        setValidated(true);
    };

    const [passwordShown, setPasswordShown] = useState(false);

    // Password toggle handler
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    // console.log(passwordShown)

    return (
        <>
            {/* box header  */}
            {/* <Header /> */}

            <div className="wrapper" style={{ backgroundColor: "#fff", zIndex: "1" }}>
                <section className="" >
                    <div className="container-fluid">
                        <div className="row justify-content-center align-items-center">
                            {/* box feed */}
                            <div className="col-12 col-md-6 col-lg-6 text-start px-md-0 px-lg-0 px-xl-5">
                                <div className='px-md-0 px-xl-5'>
                                    <div className='px-md-0 px-xl-5'>
                                        <Form noValidate validated={validated} onSubmit={handleSubmit} className="px-5">
                                            <h2 className='mb-3'>เข้าสู่ระบบ</h2>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md="12" className="position-relative" controlId="validationCustom01">
                                                    <Form.Label className='text-gray mb-1'>อีเมล</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="email"
                                                        className=""
                                                        placeholder="Your Email"
                                                        name="email"
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="12" controlId="validationCustom02" className="position-relative mt-3">
                                                    <Form.Label className='text-gray mb-1'>รหัสผ่าน</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type={passwordShown ? "text" : "password"}
                                                        placeholder="Password"
                                                        className=""
                                                        name="password"
                                                        minLength={6}
                                                    />
                                                    <span className="sh-pass cursor-pointer" onClick={togglePassword}>
                                                        { passwordShown ? <><span className='hide-password'></span>
                                                            <img src={eye} alt="" className="" /> 
                                                            </>: 
                                                            <img src={eye} alt="" className="" />
                                                        }
                                                    </span>
                                                </Form.Group>
                                                <Form.Group as={Col} md="12" className="position-relative" controlId="validationCustom01">
                                                    <div className="mt-2 text-end w-100">
                                                        {errors ? (
                                                            <div className='position-absolute font-14' style={{ color: "#dc3545" }}>อีเมลหรือรหัสผ่านไม่ถูกต้อง !</div>
                                                        ) : ""}
                                                        <Link to="/forgotpassword" transition="default-fade" className="mt-1 text-gray text-decoration-none">ลืมรหัสผ่าน</Link>
                                                    </div>
                                                </Form.Group>
                                            </Row>


                                            <button type="submit" className="w-100 btn btn-bg text-white mt-2">
                                                {clssLoading ? <span><i className="fa fa-spinner fa-spin" /></span> : <span>เข้าสู่ระบบ</span>} 
                                            </button>
                                            <Link to="/register" transition="default-fade" className="w-100 btn btn-default mt-2">ลงทะเบียน</Link>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                            {/* end box feed */}
                            <div className="col-12 col-md-6 col-lg-6 h-100vh bg-cover" style={{ backgroundImage: `url(${imglogin})` }}>
                                {/* <img src={imglogin} className="h-100 w-100"/> */}
                            </div>
                        </div>
                    </div>
                </section>
            </div >

            {/* <Footer /> */}

        </>
    );
};

export default Home;