import React, { useState, useCallback, useEffect } from 'react';

import { useNavigate,Link } from "react-router-dom";

import PostService from "../../components/services_member/post.service";
import AuthService from "../../components/services_member/auth.service";

import logo from '../../assets/img/no-img.svg';
import plus from '../../assets/img/icon-plus.svg';

// import { Link } from "react-tiger-transition";

// import Checklogin from "../../components/check_login";
import Logout from "../../components/logout_member";
import Loadingpage from "../../components/loading_page";



const Business = () => {

    
    let history = useNavigate();

    localStorage.removeItem("business_data");
    localStorage.removeItem("businessdata");

    const [databusiness, setDatabusiness] = useState([]);

    // const getMemid = (respone) => {
    //     if(respone){
    //         setMemberid(respone.data.profile);
    //     }
    // }
    // console.log(memberid)
    const fetchData = useCallback(async () => {

        const user = AuthService.getCurrentUser();

        if (user) {
            PostService.getAllBusinessPosts().then(
                (response) => {
                    if (response.data.status === 200) {
                        setDatabusiness(response.data.data);
                        localStorage.setItem("member_data", JSON.stringify(response.data.member));
                    } else {
                        AuthService.logout();
                        history("/");
                        window.location.reload();
                        localStorage.removeItem("member_data");
                    }
                },
                (error) => {
                    // console.log("Private page", error.response);
                    // Invalid token
                    if (error.response && error.response.status === 403) {
                        history("/");
                        window.location.reload();
                        localStorage.removeItem("member_data");
                    }
                }
            )
        } else {
            history("/");
        }
    });    

    useEffect(() => {
        let timer1 = setTimeout(() => fetchData(), 2000);            
        return () => {
            clearTimeout(timer1);
          };
    }, []);

    const handleBusiness = (e) => {
        AuthService.getBusiness(e).then(
            (response) => {
                if (response.status === 200) {
                    localStorage.setItem("business_data", JSON.stringify(response.data));
                    history("/businesspininput");  
                    window.location.reload(); 
                } 
            },
            (error) => {
                if (error.response && error.response.status === 403) {
                    history("/business");
                    window.location.reload();
                    localStorage.removeItem("business_data");
                }
            }
        )
    }

    // console.log(databusiness)

    return (
        <>
        
            {/* <Checklogin memid={getMemid}/> */}

            <Loadingpage timeout="2000"/>

            <div className="wrapper" style={{ backgroundColor: "#EAEAEA", zIndex: "1" }}>
                <section className="" >
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-12 col-xl-12 text-start h-100vh position-relative">
                                <div className='position-absolute w-100 position-center px-3 py-5'>
                                    <div className="row">
                                        <div className="col-12 col-md-12 col-xl-12 text-center position-relative mb-4">
                                            <h2>เลือกกิจการ </h2>
                                        </div>
                                        {
                                        databusiness.map((e, i) => (
                                        <div className="col-6 col-md-3 col-xl-3 text-start mb-3" key={i}>
                                            <button onClick={() => handleBusiness(e.id)} className="select-business p-0">
                                                <div className='box-shadow bg-white rounded-3 position-relative box-business'>
                                                    <div className="text-center position-absolute w-100 position-center" >
                                                        <div className='py-3'>
                                                            <div className='mb-3 px-3' style={{ height: "200px" }}>
                                                                { e.logo ?
                                                                <img src={ process.env.REACT_APP_API_URL+ "/uploads/business/" + e.logo } alt="" className="w-100 px-5" />
                                                                :
                                                                <img src={ logo } alt="" className="w-100 px-5" />
                                                                }
                                                            </div>
                                                            <h5>{ e.name }</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                        ))
                                        }
                                        <div className="col-6 col-md-3 col-xl-3 text-start ">
                                            <Link to="/addbusiness" transition="default-fade" className="">
                                                <div className='box-shadow bg-white rounded-3 position-relative box-business'>
                                                    <div className="text-center position-absolute w-100 position-center" >
                                                        <div className='py-3'>
                                                            <div className='mb-4 px-5'>
                                                                <img src={plus} alt="" className="w-75 px-5" />
                                                            </div>
                                                            <h5>เพิ่มกิจการ</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='position-absolute' style={{ bottom: "30px", left: "30px" }}>
                        <Logout />
                    </div>
                </section>
            </div >
        </>
    );
};

export default Business;