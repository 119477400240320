import React, { useState, useEffect, useCallback } from 'react';
// import axios from 'axios';
import { compareAsc, format } from 'date-fns';
// import DatePicker from "react-datepicker";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import help from '../../assets/img/help.svg';
import calendar from '../../assets/img/calendar.svg';

import Menuleft from "../../layout/pagesadmin/menuleft";
import Menureport from "../../components/report/report_menu";


import { Form, Row, Col, Nav } from "react-bootstrap";
// import { Form, Col, Row, InputGroup, Button } from "react-bootstrap";
import Modalreportemployee from "../../layout/modal/modalEmployeereport";

import PostService from "../../components/services_member/post.service";
import { useTranslation } from 'react-i18next';
const Reportemployee = () => {
    const { t } = useTranslation();
    const [todayDate, setTodayDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());

    const dateoption = {
        locale: {
            format: 'DD/MM/YYYY'
        },
        startDate: todayDate,
        endDate: startDate
    };

    const [datestart, setDatestart] = useState(format(new Date(), 'dd/MM/yyyy'));
    const [dateend, setDateend] = useState(format(new Date(), 'dd/MM/yyyy'));
    const [dataemployee, setDataemployee] = useState([]);
    const [dataemployeetable, setDataemployeetable] = useState([]);
    const [modalreportemployee, setModalreportemployee] = useState({ view: false });

    const fetchData = useCallback(async () => {

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        PostService.getEmployeeall(branch.id).then(
            (response) => {
                if (response.data.status === 200) {
                    setDataemployee(response.data.data);
                } else {
                    setDataemployee([]);
                }
            },
            (error) => {
                console.log(error);
            }
        )
    });

    useEffect(() => {
        fetchData();
    }, []);



    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const dataf = new FormData(event.target);

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        dataf.append("branch_id", branch.id);
        dataf.append("date_start", datestart);
        dataf.append("date_end", dateend);

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            PostService.getEmployeedataall(dataf).then(
                (response) => {

                    if (response.status === 200) {
                        setDataemployeetable(response.data);

                        setModalreportemployee({ view: true, dataem: response.data });

                    } else {
                        setDataemployeetable([]);
                    }
                },
                (error) => {
                    console.log(error);
                }
            )
        }
        setValidated(true);
    };

    const handledateday = (event, picker) => {
        setDatestart(format(new Date(picker.startDate._d), 'dd/MM/yyyy'));
        setDateend(format(new Date(picker.endDate._d), 'dd/MM/yyyy'));
    }

    // console.log(modalreportemployee)

    return (
        <>
            <div className={!t('menu-a') ? "sidebar-mini sidebar-collapse" : ""} >
                <div className='wrapper-admin layout-fixed'>
                    <nav className="main-header navbar bg-white shadow-sm" style={{ height: "60px" }}>
                        <Nav as="ul" variant="tabs" className="nav nav-tabs overflow-hidden ul-tabcustom">

                        </Nav>
                        <ul className="navbar-nav ml-auto" style={{ marginTop: "-20px" }}>
                            <li className="">
                                <div className='text-gray font-12'><img src={help} alt="" className="me-1" style={{ width: "14px" }} /> ขอความช่วยเหลือ</div>
                            </li>
                        </ul>
                    </nav>

                    <Menuleft type="report" />

                    <div className="content-wrapper">
                        <section className="content scroll-custom">
                            <div className="row">
                                <div className="col-12 col-md-5 col-lg-5">
                                    <div className='text-start box-setting bg-white rounded-3 mt-3 h-100per'>
                                        <Menureport type="employee" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-7 col-lg-7 ps-0">
                                    <Form noValidate validated={validated} onSubmit={handleSubmit} className="">
                                        <div className="bg-white rounded-3 ms-2 mt-3 h-100per-right position-relative">
                                            <div className='px-4'>
                                                <div className='text-start pt-3 pb-1 weight-500'>รายงานการทำงาน</div>
                                                <div className='position-relative'>
                                                    <Form.Group controlId="dob">
                                                        {/* <Form.Control type="date" name="dob" format="YYYY/MM/DD" required /> */}
                                                        <DateRangePicker initialSettings={dateoption} onEvent={handledateday}>
                                                            <input type="text" className="form-control" />
                                                        </DateRangePicker >
                                                        <div className='position-absolute' style={{ top: "12px", right: "12px" }}>
                                                            <img src={calendar} alt="" className="me-1" style={{ width: "18px" }} />
                                                        </div>
                                                    </Form.Group>

                                                    <Row className="align-items-end mb-3">
                                                        <Form.Group as={Col} md="12" className="position-relative" controlId="validationCustom01">
                                                            <div className='text-start pt-4 pb-1 weight-400 text-gray'>ชื่อพนักงาน</div>
                                                            <Form.Select aria-label="Default select example" className='font-14' name='employee' required>
                                                                <option value="0">ทั้งหมด</option>
                                                                {dataemployee.map((value, index) => (
                                                                    <option key={index} value={value.id}>{value.name}</option>
                                                                ))}
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 ps-2 mt-2'>
                                            <button type="submit" className="w-100 btn btn-bg text-white py-2">
                                                ดูรายงานการทำงาน
                                            </button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            {modalreportemployee.view ?
                <Modalreportemployee show={modalreportemployee.view} onHide={(e) => setModalreportemployee({ view: false })} dataemployee={modalreportemployee.dataem} datestart={datestart} dateend={dateend} />
                : null}
        </>
    );
};

export default Reportemployee;