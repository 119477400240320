import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button } from "react-bootstrap";

// import { Form, Tab, Nav, Row, Col, Accordion, ListGroup, Badge } from "react-bootstrap";

// import AuthService from "../../components/services_member/auth.service";
// import PostService from "../../components/services_member/post.service";

import corder from '../../assets/img/cancle-order.svg';

const Modalcancleorder = (data) => {


    return (
        <>
            <Modal {...data} className="modal-custom" size="md" backdrop="static" keyboard={false}>
                <Modal.Header closeButton className="btn-close-white ">

                </Modal.Header>
                <Modal.Body className="p-0 bg-white">
                    <div className="d-block w-100 overflow-hidden">

                        <div className='pt-5 pb-4 bg-white px-4 text-center'>

                            <div><img src={corder} alt="" className="" style={{ width: "50px" }} /></div>
                            <div className='font-16 weight-500 mt-4 text-gray'>#XXXXX</div>
                            <div className='font-18 weight-500 mt-1'>ยืนยันการยกเลิกคำสั่งซื้อ</div>
                            <div className='text-center px-3 mt-4'>
                                <div className='row'>
                                    <div className="col-12 col-md-6 col-lg-6 pe-1">
                                        <button className="w-100 btn btn-calcel text-white py-2 me-3" onClick={data.onHide}>
                                            ยกเลิก
                                        </button>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 ps-1">
                                        <button type='submit' className="w-100 btn btn-bg text-white py-2 me-3">
                                            ยืนยัน
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>                       
                    </div>
                </Modal.Body>
            </Modal>

        </>
    );
};

export default Modalcancleorder;