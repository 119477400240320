import React, { useState, useRef } from 'react';
import { Modal, Button } from "react-bootstrap";

import '../../assets/css/radioandcheckbox.css';
import { Form, Tab, Nav, Row, Col } from "react-bootstrap";

import AuthService from "../../components/services_member/auth.service";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Moment from 'moment';

import ReactToPrint from 'react-to-print';

import ComponentToorder from '../../components/ComponentToorder';

const Modalcancelproduct = (data) => {

    const MySwal = withReactContent(Swal);

    const [validated, setValidated] = useState(false);

    const [errorblob, setErrorblob] = useState();

    const [errordetail, setErrordetail] = useState(true);

    const [detailonly, setDetailonly] = useState(true);
    const [setPrint, setSetPrint] = useState(false);

    const handleSubmit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const data2 = new FormData(event.target);

        data2.append("id", data.data.product_id);
        data2.append("type", data.type);

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

        } else {

            AuthService.holdOrderlist(data2).then(
                (res) => {
                    //   console.log(res)   
                    if (res.status === 200) {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: '',
                            text: data.type === "cancle" ? "ยกเลิกรายการเรียบร้อยแล้ว" : "ส่งออเดอร์กำลังปรุงแล้ว",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(function () {
                            // window.location.reload()
                            data.onHide()
                        })

                    } else {
                        // console.log(res.message)
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        }
        setValidated(true);
    };

    const handleSubmitPrint = (event) => {

        // event.preventDefault();

        // const form = event.currentTarget;

        const data2 = new FormData();

        // data2.append("id", data.data.product_id);
        data2.append("id", JSON.stringify(data.data.dataselect));
        data2.append("type", data.type);
        // checked
        // console.log(data.data.dataselect)

        AuthService.holdOrderlist(data2).then(
            (res) => {
                //   console.log(res)   
                if (res.status === 200) {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: '',
                        text: data.type === "cancle" ? "ยกเลิกรายการเรียบร้อยแล้ว" : "ส่งออเดอร์กำลังปรุงแล้ว",
                        showConfirmButton: false,
                        timer: 1500
                    }).then(function () {
                        data.onHide()
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    };

    const setOnchang = (e) => {
        if (e.target.value === '4') {
            setErrordetail(true);
            setDetailonly(false)
        } else {
            setErrordetail(false)
            setDetailonly(true)
        }
    }
    // console.log(data.data)
    const componentRef = useRef(null);
    // const handlePrint = useReactToPrint({
    //     content: () => componentRef.current,
    // });

    const [checked, setChecked] = useState([]);

    const handleCheckbox = (e) => {
        var updatedList = [...checked];
        if (e.target.checked) {
            updatedList = [...checked, e.target.value];
        } else {
            updatedList.splice(checked.indexOf(e.target.value), 1);
        }
        // setChecked(updatedList);
        setChecked(updatedList)
    }

    const printSuccess = (e) => {
        // alert(e)
        // console.log(e)
        // datas.onHide()
        handleSubmitPrint()
    }

    return (
        <>
            <Modal {...data} className="modal-custom" size="md" backdrop="static" keyboard={false}>
                <Modal.Header closeButton className="btn-close-white ">

                </Modal.Header>
                <Modal.Body className="p-0 bg-white">
                    {data.type === "cancle" ?
                        <Form noValidate validated={validated} onSubmit={handleSubmit} className="">
                            <div className="d-block w-100 py-3 px-4">
                                <Modal.Title className='pb-3'>
                                    <div className='text-default font-22'>ยกเลิกรายการนี้</div>
                                    <div className='text-gray font-14 weight-400'>#{data.data.order_no}</div>
                                    <div className='text-gray font-14 weight-400'>{data.data.product}</div>
                                    <div className='text-gray font-14 weight-400'>
                                        {data.data.order_zone === 0 ? "Drive Thru" : data.data.order_zone + ":" + data.data.order_table}
                                    </div>
                                </Modal.Title>
                                <div className="ex-radio4 mb-2">
                                    <input type="radio" id="radio_one" name="checkboxt" value="วัตถุดิบหมด" onChange={(e) => setOnchang(e)} required />
                                    <label className="radio_one mb-2 ps-4 ms-2" style={{ width: "100%" }} > วัตถุดิบหมด</label>
                                </div>
                                <div className="ex-radio4 mb-2">
                                    <input type="radio" id="radio_one" name="checkboxt" value="ลูกค้าขอยกเลิก" onChange={(e) => setOnchang(e)} required />
                                    <label className="radio_one mb-2 ps-4 ms-2" style={{ width: "100%" }} >
                                        ลูกค้าขอยกเลิก
                                    </label>
                                </div>
                                <div className="ex-radio4 mb-2">
                                    <input type="radio" id="radio_one" name="checkboxt" value="4" onChange={(e) => setOnchang(e)} required />
                                    <label className="radio_one mb-2 ps-4 ms-2" style={{ width: "100%" }} >
                                        อื่นๆ ระบุ
                                    </label>
                                    <div className='font-12 weight-300' style={{ color: "red" }}>{errorblob}</div>
                                </div>
                                <Row className="mt-2 px-3 mx-1 mb-5">
                                    <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                        <Form.Control type="text" name="detail_cancle" className='font-14 input-custom' placeholder='พิมพ์ละเอียด...' readOnly={detailonly ? true : null} required={errordetail ? true : false} />
                                    </Form.Group>
                                </Row>
                                <div className='text-center mb-3'>
                                    <button type='submit' className="w-50 btn btn-submit text-white py-2 me-3">
                                        ยืนยัน
                                    </button>
                                </div>
                            </div>
                        </Form>
                        :
                        <div className="d-block w-100 py-3 px-4">
                            <Modal.Title className='pb-3'>
                                <div className='text-default font-22'>ส่งออเดอร์กำลังปรุง</div>
                                {(data.data.dataselect).map((value2, index2) => (
                                    <div key={index2} className="mt-3">
                                        <div className='text-gray font-14 weight-400'>
                                            #{data.data.dataorder.filter(vls => vls.id === (data.data.dataplang.filter(vls => vls.id === value2)[0].id_order))[0].no}
                                        </div>
                                        <div className='text-gray font-14 weight-400'>
                                            {/* {data.data.product}  */}
                                            {data.data.dataplang.filter(vls => vls.id === value2)[0].name_product}
                                            <span className='ps-3'>X {data.data.dataplang.filter(vls => vls.id === value2)[0].qty}</span>
                                            { data.data.dataplang.filter(vls => vls.id === value2)[0].note ? <div className='font-14'>หมายเหตุ : {data.data.dataplang.filter(vls => vls.id === value2)[0].note}</div> : null }

                                            <span>
                                                {data.data.dataplang.filter(vls => vls.id === value2)[0].choice ? (JSON.parse(data.data.dataplang.filter(vls => vls.id === value2)[0].choice)).map((valsc, indesc) => (
                                                    <div className='ms-3 pe-5' key={indesc}>
                                                        <div className='font-13'>- {valsc.more_name}</div>
                                                    </div>
                                                ))
                                                    : null}
                                            </span>
                                        </div>
                                        <div className='text-gray font-14 weight-400'>
                                            {data.data.dataorder.filter(vls => vls.id === (data.data.dataplang.filter(vls => vls.id === value2)[0].id_order))[0].zone === '0' ?
                                                "Drive Thru" :
                                                data.data.dataorder.filter(vls => vls.id === (data.data.dataplang.filter(vls => vls.id === value2)[0].id_order))[0].zone_name + ":" + data.data.dataorder.filter(vls => vls.id === (data.data.dataplang.filter(vls => vls.id === value2)[0].id_order))[0].table_name}
                                        </div>
                                        <div className='text-gray font-14 weight-400'>เวลา {Moment(data.data.dataplang.filter(vls => vls.id === value2)[0].date_kitchen).format('HH:mm')} น.</div>
                                        <div className="ex-checkbox4 mb-2">
                                            <input type="checkbox" id="checkbox_one" name="checkboxt" value={value2} onChange={handleCheckbox} />
                                            <label className="checkbox_one mb-2 ps-4" style={{ width: "100%" }} > พิมพ์รายการนี้</label>
                                        </div>
                                    </div>
                                ))}
                            </Modal.Title>
                            <div className='text-center mb-3 mt-5'>
                                {checked.length ?
                                    <div className='mt-3'>
                                        <ReactToPrint
                                            trigger={() => <button className="w-50 btn btn-submit text-white py-2 me-3">ยืนยัน</button>}
                                            content={() => componentRef.current}
                                            documenttTitle='name document'
                                            onAfterPrint={() => printSuccess('yes')}
                                        />
                                        <div className='position-absolute d-none'>
                                            <div className="ticket" ref={componentRef} >
                                                <div className="" />
                                                <ComponentToorder
                                                    plang={data.data.dataplang}
                                                    order={data.data.dataorder}
                                                    select={checked}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className='mt-3'>
                                        <button onClick={() => printSuccess('yes')} className="w-50 btn btn-submit text-white py-2 me-3">ยืนยัน</button>
                                    </div>

                                }
                            </div>
                        </div>
                    }
                </Modal.Body>
            </Modal>

        </>
    );
};

export default Modalcancelproduct;