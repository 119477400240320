import React, { useState, useCallback } from 'react';

import { useNavigate,Link } from "react-router-dom";

import { Form, Col, Row } from "react-bootstrap";

import axios from 'axios';

import AuthService from "../../components/services_member/auth.service";

import Modalcropbranch from "../../layout/modal/modalCropbranch";
// import ImageCropper from '../../components/ImageCropper';

// import logo from '../../assets/img/ryuden-logo.svg';
import back from '../../assets/img/icon-back-blue.svg';
import noimg from '../../assets/img/no-img.svg';

// import { Link } from "react-tiger-transition";

const Editbranch = () => {

    let history = useNavigate();

    const user = AuthService.getCurrentUser();
    const branch = AuthService.getCurrentBranch();

    if (!user) {
        history("/");
        window.location.reload();
    } else {
        if (!branch) {
            history("/branch");
            window.location.reload();
        }
    }

    const [blob, setBlob] = useState(null)
    const [inputImg, setInputImg] = useState('');
    const [modalcrop, setModalcrop] = useState({ view: false, id: 0 });

    const getBlob = (blob) => {
        // pass blob up from the ImageCropper component
        setBlob(blob);
        setModalcrop({ view: false });
    }

    const onInputChange = (e) => {
        // convert image file to base64 string
        const file = e.target.files[0]
        const reader = new FileReader();

        reader.addEventListener('load', () => {
            setInputImg(reader.result)
            setModalcrop({ view: true, id: 1, getblob: getBlob, inputImg: reader.result })
        }, false)

        if (file) {
            reader.readAsDataURL(file)
        }
    }

    // console.log(blob)
    // let history = useNavigate();
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const data = new FormData(event.target);

        data.append("id", branch.id);
        data.append("blob", JSON.stringify(blob));
        data.append("type", "edit");

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

        } else {
            AuthService.editbranch(data).then(
                (res) => {
                    //   console.log(res)   
                    if (res.status === 200) {
                        history("/branch");
                        window.location.reload();
                    } else {
                    }
                }, (error) => {
                    console.log(error);
                }
            );
        }

        setValidated(true);
    };

    return (
        <>
            <div className="wrapper" style={{ backgroundColor: "#fff", zIndex: "1" }}>
                <Form noValidate validated={validated} onSubmit={handleSubmit} className="">
                    <section className="" >
                        <div className="container">
                            <div className="row align-items-center ">
                                <div className="col-12 col-md-12 col-xl-12 text-start h-100vh position-relative">
                                    <div className='position-absolute w-100 position-center px-3'>
                                        <div className="row justify-content-center">
                                            <div className="col-6 col-md-3 col-xl-3 text-center ">
                                                <div className='bg-white rounded-3 position-relative mx-auto'>
                                                    <div className="text-start position-absolute w-100 position-center" >
                                                        <div className='pb-3'>
                                                            <div className='weight-500 mb-1'>เลือกโปรไฟล์สาขาของคุณ</div>
                                                            <div className='font-12 text-gray'>ขนาดไฟล์ไม่เกิน 20 MB.</div>
                                                            <div className='pb-3 pe-5 py-3' >
                                                                <label htmlFor="upload" className='cursor'>
                                                                    {
                                                                        blob ? (
                                                                            <img src={blob} alt="" className="w-75" />
                                                                        ) :
                                                                            branch.logo ?
                                                                                <img src={process.env.REACT_APP_API_URL + "/uploads/branch/" + branch.logo} alt="" className="w-75" />
                                                                                :
                                                                                <img src={noimg} alt="" className="w-75" />
                                                                    }
                                                                </label>
                                                                <input type='file' name="file" id="upload" accept='image/*' onChange={onInputChange} hidden />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <Row className="mb-3">
                                                                <div className='weight-500 mb-1'>ตั้งค่ารหัส PIN</div>
                                                                <Form.Group as={Col} md="12" className="position-relative" controlId="validationCustom01"> 
                                                                    <Form.Control
                                                                        required
                                                                        type="password"
                                                                        className="py-2 bg-white font-15"
                                                                        placeholder="กรอกรหัส PIN เพื่อเข้าสู่ระบบ"
                                                                        name="pin" 
                                                                        defaultValue={branch.pin}                                                                     
                                                                        maxLength={4}
                                                                    />
                                                                </Form.Group>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='position-absolute' style={{ top: "30px", right: "30px" }}>
                            <div className='text-end'>{branch.name}</div>
                            <div className='text-gray font-14 text-end'>{branch.date_create}</div>
                        </div>
                        <div className='position-absolute' style={{ top: "30px", left: "30px" }}>
                            <h4 className='weight-500 m-0'>
                                <Link to="/branchpininput" transition="default-fade" className="text-decoration-none">
                                    <img src={back} alt="" className="me-3 mb-1" style={{ height: "20px" }} />
                                </Link>
                                ปรับแต่งสาขา
                            </h4>
                        </div>
                        <div className='position-absolute' style={{ bottom: "30px", right: "30px" }}>
                            <button type='submit' className="w-100 btn btn-bg text-white px-5">บันทึก</button>
                        </div>
                    </section>
                </Form>
            </div >

            <Modalcropbranch show={modalcrop.view} onHide={() => setModalcrop({ view: false })} id={modalcrop.id} getblob={getBlob} inputimg={modalcrop.inputImg} />
        </>
    );
};

export default Editbranch;