import React, { useState, useEffect } from 'react';
// import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { Form, Col, Row } from "react-bootstrap";

import AuthService from "../../components/services_member/auth.service";

import logo from '../../assets/img/ryuden-logo.svg';
import pen from '../../assets/img/icon-pen.svg';
import backspace from '../../assets/img/backspace.svg';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

// import { Link } from "react-tiger-transition";

const Branchaddpin = () => {

    let history = useNavigate();

    const user = AuthService.getCurrentUser();
    // const branch = AuthService.getCurrentBranch();

    if (!user) {
        history("/");
    }

    const [inputpin, setInputpin] = useState([]);
    const [inputpin2, setInputpin2] = useState([]);
    const [datapin, setDatapin] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9]);

    const [pina, setPina] = useState();

    const [pinerr, setPinerr] = useState(false);

    const handleAddItem = (e) => {
        setInputpin((oldArray) => [...oldArray, e])
    }

    const handleAddItem2 = (e) => {
        setInputpin2((oldArray) => [...oldArray, e])
    }

    const newArray = inputpin.join('');
    const newArray2 = inputpin2.join('');

    useEffect(() => {

        if (inputpin.length === 4) {
            // if(pina.length === 4){
            setPina(newArray)
            setInputpin([])
            // }

            // if (newArray === branch.pin) {
            //     setTimeout(function () {
            //         history("/table/manage");
            //     }, 1000)
            // } else {
            //     setPinerr(true)
            // }
            setInputpin2([])
        }

        if (inputpin2.length === 4) {

            // setPina(newArray)
            if (newArray2 === pina) {

                // setTimeout(function () {
                //     history("/branch");
                // }, 1000)

                const dataf = new FormData();

                // const member_id = JSON.parse(localStorage.getItem("business_data"));
                const branchdata = JSON.parse(localStorage.getItem("branchdata"));

                dataf.append("id", branchdata.id);
                dataf.append("type", "pin");
                dataf.append("pin", newArray2);

                AuthService.editbranch(dataf).then(
                    (res) => {
                        //   console.log(res)   
                        if (res.status === 200) {

                            // localStorage.setItem('branchdata', JSON.stringify(res.data));
                            Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: '',
                                text: "ตั้งค่ารหัส PIN เรียบร้อยแล้ว",
                                showConfirmButton: false,
                                timer: 1500
                            }).then(function () {
                                history("/branch");
                            })                          
                            // history("/branchaddpin"); 
                            // window.location.reload(); 
                        } else {
                            // setErrors({email:"This email cannot be used.!"})
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );


                // alert('yse')
            } else {
                setPinerr(true)
            }
        }
    });

    const handleRemoveItem = () => {
        const items = inputpin;
        if (items.length > 0) {
            const lastIndex = items.length - 1;
            setInputpin(items.filter((item, index) => index !== lastIndex));
        }
        setPinerr(false)
    };

    const handleRemoveItem2 = () => {
        const items = inputpin2;
        if (items.length > 0) {
            const lastIndex = items.length - 1;
            setInputpin2(items.filter((item, index) => index !== lastIndex));
        }
        setPinerr(false)
    };

    // const newArray = inputpin.toString('-');
    console.log(pina);

    return (
        <>
            <div className="wrapper" style={{ backgroundColor: "#fff", zIndex: "1" }}>
                <section className="" >
                    <div className="container">
                        <div className="row align-items-center ">
                            <div className="col-12 col-md-12 col-xl-12 text-start h-100vh position-relative">
                                <div className='position-absolute w-100 position-center px-3'>
                                    <div className="row justify-content-center">
                                        <div className="col-6 col-md-3 col-xl-3 text-center ">
                                            <div className='bg-white rounded-3 position-relative mx-auto' style={{ width: "220px" }}>
                                                <div className="text-center position-absolute w-100 position-center" >
                                                    {pina ?

                                                        <div className='pb-3'>
                                                            <div className='px-5 pb-3' >
                                                                ยืนยันรหัส PIN
                                                            </div>
                                                            <div>
                                                                <div className='mt-3 mb-2'>
                                                                    <span className={newArray2.length === 2 || newArray2.length === 1 || newArray2.length === 3 || newArray2.length === 4 ? "opt-input active" : "opt-input"}></span>
                                                                    <span className={newArray2.length === 2 || newArray2.length === 3 || newArray2.length === 4 ? "opt-input active" : "opt-input"}></span>
                                                                    <span className={newArray2.length === 3 || newArray2.length === 4 ? "opt-input active" : "opt-input"}></span>
                                                                    <span className={newArray2.length === 4 ? "opt-input active" : "opt-input"}></span>
                                                                    {pinerr ? <div className='mt-3 text-red font-14'>รหัสไม่ถูกต้อง</div> : null}
                                                                </div>
                                                                <Row className="mb-3">
                                                                    <Form.Group as={Col} md="12" className="position-relative" controlId="validationCustom01">
                                                                        <Form.Control
                                                                            required
                                                                            type="hidden"
                                                                            className="text-center py-3 bg-white font-15"
                                                                            placeholder="กรอกรหัส PIN เพื่อเข้าสู่ระบบ"
                                                                            name="email"
                                                                            value={newArray2}
                                                                            maxLength={4}
                                                                            disabled
                                                                        />
                                                                    </Form.Group>
                                                                </Row>
                                                            </div>

                                                            <div style={{ margin: "0 auto", width: "200px" }}>
                                                                <div className='row justify-content-end'>
                                                                    {
                                                                        datapin.map((e, i) => (
                                                                            <div className="col-4 col-md-4 col-xl-4 text-center p-1" key={i}>
                                                                                <div className='keybord-number position-relative' onClick={() => handleAddItem2(e)}>
                                                                                    <div className='position-absolute position-center'><h3 className='m-0'>{e}</h3></div>
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                    <div className="col-4 col-md-4 col-xl-4 text-center p-1">
                                                                        <div className='keybord-number position-relative' onClick={() => handleAddItem2(0)}>
                                                                            <div className='position-absolute position-center'><h3 className='m-0'>0</h3></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4 col-md-4 col-xl-4 text-center p-1">
                                                                        <div className='position-relative cursor' onClick={() => handleRemoveItem2()}>
                                                                            <div className='ps-4 pt-3 cors '>
                                                                                <img src={backspace} alt="" className="w-100 " />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        :
                                                        <div className='pb-3'>
                                                            <div className='px-5 pb-3' >
                                                                ตั้งรหัส PIN
                                                            </div>
                                                            <div>
                                                                <div className='mt-3 mb-2'>
                                                                    <span className={newArray.length === 2 || newArray.length === 1 || newArray.length === 3 || newArray.length === 4 ? "opt-input active" : "opt-input"}></span>
                                                                    <span className={newArray.length === 2 || newArray.length === 3 || newArray.length === 4 ? "opt-input active" : "opt-input"}></span>
                                                                    <span className={newArray.length === 3 || newArray.length === 4 ? "opt-input active" : "opt-input"}></span>
                                                                    <span className={newArray.length === 4 ? "opt-input active" : "opt-input"}></span>
                                                                    {pinerr ? <div className='mt-3 text-red font-14'>รหัสไม่ถูกต้อง</div> : null}
                                                                </div>
                                                                <Row className="mb-3">
                                                                    <Form.Group as={Col} md="12" className="position-relative" controlId="validationCustom01">
                                                                        <Form.Control
                                                                            required
                                                                            type="hidden"
                                                                            className="text-center py-3 bg-white font-15"
                                                                            placeholder="กรอกรหัส PIN เพื่อเข้าสู่ระบบ"
                                                                            name="email"
                                                                            value={newArray}
                                                                            maxLength={4}
                                                                            disabled
                                                                        />
                                                                    </Form.Group>
                                                                </Row>
                                                            </div>

                                                            <div style={{ margin: "0 auto", width: "200px" }}>
                                                                <div className='row justify-content-end'>
                                                                    {
                                                                        datapin.map((e, i) => (
                                                                            <div className="col-4 col-md-4 col-xl-4 text-center p-1" key={i}>
                                                                                <div className='keybord-number position-relative' onClick={() => handleAddItem(e)}>
                                                                                    <div className='position-absolute position-center'><h3 className='m-0'>{e}</h3></div>
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                    <div className="col-4 col-md-4 col-xl-4 text-center p-1">
                                                                        <div className='keybord-number position-relative' onClick={() => handleAddItem(0)}>
                                                                            <div className='position-absolute position-center'><h3 className='m-0'>0</h3></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4 col-md-4 col-xl-4 text-center p-1">
                                                                        <div className='position-relative cursor' onClick={() => handleRemoveItem()}>
                                                                            <div className='ps-4 pt-3 cors '>
                                                                                <img src={backspace} alt="" className="w-100 " />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className='position-absolute' style={{ top: "30px", right: "30px" }}>
                        <div className='text-end'>{branch.name}</div>
                        <div className='text-gray font-14 text-end'>{branch.date_create}</div>
                    </div> */}
                    {/* <div className='position-absolute' style={{ bottom: "30px", left: "30px" }}>
                        <Link to="/branch" transition="default-fade" className="w-100 btn btn-bggray text-default px-5">ย้อนกลับ</Link>
                    </div>
                    <div className='position-absolute' style={{ bottom: "30px", right: "30px" }}>
                        <Link to="/editbranch" transition="default-fade" className="w-100 btn btn-link-default text-default p-0">
                            <img src={pen} alt="" className="pe-2" /> ปรับแต่งสาขา
                        </Link>
                    </div> */}
                </section>
            </div >
        </>
    );
};

export default Branchaddpin;