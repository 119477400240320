import React, { useState, useEffect } from 'react';

const Loadingpage = (data) => {

    const [loadingpage, setLoadingpage] = useState(true);

    useEffect(() => {
        let timer1 = setTimeout(() => setLoadingpage(false), data.timeout);            
        return () => {
            clearTimeout(timer1);
          };
    }, []);

    return (
        <>
        { loadingpage ? (
        <div className=' position-fixed bg-loading' style={{  }}>
            <div className='loader position-center position-absolute'></div>
        </div>
         ) : "" } 
        
        </>
    );
};

export default Loadingpage;