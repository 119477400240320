import React, { useState } from 'react';
// import axios from 'axios';
import { useNavigate,Link } from "react-router-dom";

import success from '../../assets/img/success.svg';


const Registersuccess = () => {

    // let history = useNavigate();

    return (
        <>
            <div className="wrapper" style={{ backgroundColor: "#EAEAEA", zIndex: "1" }}>
                <section className="" >
                    <div className="container-fluid">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-12 col-md-7 col-xl-4 text-start h-100vh position-relative">
                                <div className='position-absolute w-100 position-center px-3'>
                                    <div className='box-shadow pb-4 bg-white'>
                                        <div className="px-5 mt-3 text-center">
                                            <div className='pt-3'>
                                                <div><img src={success} alt="" className="mt-5 pb-5" /></div>
                                                <h2>ลงทะเบียนสำเร็จ</h2>
                                                <div className='text-gray'>กรุณาลงชื่อเข้าสู่ระบบอีกครั้ง</div>
                                            </div>
                                            <Link to="/" className="w-100 btn btn-bg text-white my-5">เข้าสู่ระบบ</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        </>
    );
};

export default Registersuccess;