import React, { useState, useEffect } from 'react';
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import AuthService from "../../components/services_member/auth.service";
// import arrayMove from 'array-move';

// import axios from 'axios';
// import { Link } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
// import Rang from '../components/lang';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Form, Tab, Nav, Row, Col } from "react-bootstrap";

// import plus from '../../assets/img/icon-plus-white.svg';
// import iconpen from '../../assets/img/icon-pen.svg';
// import iconbin from '../../assets/img/icon-bin.svg';
// import iconlag from '../../assets/img/icon-lag.svg';

import noimg from '../../assets/img/no-img-product.svg';

import Modalcropbranch from "./modalCropbranch";
import Modalproductmore from "../../layout/modal/modalProductmore";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const Modaladdproductedit = (data) => {

    let history = useNavigate();

    let datatatb = data.datacat.datacat;
    // let datatatpro = data.datacat.datapro.filter(val => val.id === `${data.datacat.id}`);
    let datatatpro = data.datacat.datapro;
    let datach = ((datatatpro.choice));

    // console.log(datatatpro)

    const [validated, setValidated] = useState(false);

    const [state, setState] = useState({ items: [...datatatb] });
    // const [errorblob, setErrorblob] = useState(false);

    const [modalproductmore, setModalproductmore] = useState({ view: false, id: 0, getHide: null, topic: "", more_n: "", more_c: "", more_number: "", dataChoice: [] });

    const [swt, setSwt] = useState([]);
    const [swt2, setSwt2] = useState([]);

    const [blob, setBlob] = useState(null)
    const [inputImg, setInputImg] = useState('');
    const [modalcrop, setModalcrop] = useState({ view: false, id: 0 });
    const [kitchen, setKitchen] = useState(datatatpro.kitchen_id);

    const getBlob = (blob) => {
        setBlob(blob);
        setModalcrop({ view: false });
        // setErrorblob(false)
    }

    const onInputChange = (e) => {
        // convert image file to base64 string
        const file = e.target.files[0]
        const reader = new FileReader();

        reader.addEventListener('load', () => {
            setInputImg(reader.result)
            setModalcrop({ view: true, id: 1, getblob: getBlob, inputImg: reader.result })
        }, false)

        if (file) {
            reader.readAsDataURL(file)
        }
    }

    const handleSubmit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const dataf = new FormData(event.target);

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        dataf.append("id", datatatpro.id);
        dataf.append("branch_id", branch.id);
        dataf.append("blob", JSON.stringify(blob));
        dataf.append("status", swt.view);
        dataf.append("more", localStorage.getItem("more_data"));

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

            // if (!blob) {
            //     setErrorblob("Please select uploads file.")
            // }

        } else {

            // if (blob) {
            AuthService.updateProduct(dataf).then(
                (res) => {
                    //   console.log(res)   
                    if (res.status === 200) {
                        // window.location.reload();
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: '',
                            text: "บันทึกข้อมูลเรียบร้อยแล้ว",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(function () {
                            data.onHide('edit')
                        })
                    } else {
                        // console.log(res.message)
                        data.onHide('edit')
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
            // } else {
            //     setErrorblob("Please select uploads file. !")
            // }
        }
        setValidated(true);
    };

    const getHide = () => {
        setModalproductmore({ view: false, dataChoice: [] });
    }

    const getChoice = () => {
        // JSON.parse

        if (datatatpro.choice) {
            localStorage.setItem("more_data", datatatpro.choice);
            setModalproductmore({ view: true, getHide: null, topic: (JSON.parse(datatatpro.choice).topic), more_n: (JSON.parse(datatatpro.choice).more_n), more_c: (JSON.parse(datatatpro.choice).more_c), more_number: (JSON.parse(datatatpro.choice).more_number), dataChoice: JSON.parse(datatatpro.choice).more_data })
        } else {
            setModalproductmore({ view: true, getHide: null, topic: "", more_n: "",more_c: "",more_number: "", dataChoice: [{ "more_name": "", "more_price": "" }] })
        }

        // console.log(JSON.parse(datatatpro.choice).topic)
    }

    const handleSwt = (e) => {
        if (e === '1') {
            setSwt({ view: '0' });
            // data.append("status", "0");
        } else {
            setSwt({ view: '1' });
            // data.append("status", "1");
        }
    }

    const handleSwt2 = (e) => {
        if (e === '1') {
            setSwt2({ view: '0' });
        } else {
            setSwt2({ view: '1' });
        }
    }

    useEffect(() => {
        setState({ items: [...datatatb] });

        if (datatatpro.status === '0') {
            setSwt({ view: '0' })
        } else {
            setSwt({ view: '1' })
        }

        if (datatatpro.choice) {
            setSwt2({ view: '1' })
            
            localStorage.setItem("more_data", datatatpro.choice);
        } else {
            setSwt2({ view: '0' })
        }

    }, [datatatb]);

    // console.log(datatatpro.choice)
    // console.log(data.datacat.datawarehouse)

    // console.log(datatatpro.choice)
    // console.log(JSON.parse(datatatpro.choice).topic)
    // console.log(statepro.items.filter(val => val.id === `${data.datacat.id}`))
    return (
        <>
            <Modal {...data} className="modal-custom" size="lg" backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton className="btn-close-white ">

                </Modal.Header>
                <Modal.Body className="p-0">
                    <div className="d-block w-100 scroll-custom">
                        <div className='row'>
                            <div className="col-12 col-md-5 col-lg-5 bg-default px-4">
                                <div className='py-5 text-center' >

                                    <label htmlFor="upload" className='cursor px-1 d-block'>
                                        {
                                            blob ? (
                                                <img src={blob} alt="" className="w-50" />
                                            ) :
                                                datatatpro.img ?
                                                    <img src={process.env.REACT_APP_API_URL + "/uploads/product/" + datatatpro.img} alt="" className="w-50" />
                                                    :
                                                    <img src={noimg} alt="" className="w-50" />
                                        }
                                    </label>
                                    <input type='file' name="file" id="upload" accept='image/*' onChange={onInputChange} hidden />
                                    <div className='mt-2 font-14 text-gray'>อัพโหลดภาพเมนู</div>
                                </div>
                                <div className='text-white position-fixed font-14' style={{ bottom: "15px" }}>
                                    สถานะ
                                    <div className="form-check form-switch mt-2">
                                        <input className="form-check-input d-inline-block" type="checkbox" id="flexSwitchCheckChecked" name='vat_swit' value="1" onClick={() => handleSwt(swt.view)} defaultChecked={swt.view === '1' ? true : null} />
                                        <label className="form-check-label font-12 mt-1 pt-1 ps-2" >พร้อมจำหน่าย</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-7 col-lg-7 ps-0">

                                <Form noValidate validated={validated} onSubmit={handleSubmit} className="">
                                    <div className='h-100per-modal bg-white px-4 pt-4'>
                                        <div className='text-default font-18 weight-500'>
                                            {
                                                data.type === "add" ? "เพิ่มเมนู" : "แก้ไขเมนู"
                                            }
                                        </div>
                                        <div className='mt-1'>
                                            <Row className="align-items-end mt-3 mb-3">
                                                <Form.Group as={Col} md="6" className="position-relative" controlId="validationCustom01">
                                                    <Form.Label className="font-12 text-gray mb-2">หมวดหมู่</Form.Label>
                                                    <Form.Select as="select" className='font-14' name='cat_id' defaultValue={datatatpro.cat_id} >
                                                        {state.items.map((value, index) => (
                                                            <option value={`${value.id}`} key={index} >{value.category}</option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6" className="position-relative" controlId="validationCustom01">
                                                    <Form.Label className="font-12 text-gray mb-2">ครัว</Form.Label>
                                                    <Form.Select as="select" className='font-14' onChange={(e) => setKitchen(e.target.value)} name='kitchen_id' defaultValue={kitchen}>
                                                        <option value="1">ปิ้งย่าง</option>
                                                        <option value="2">ทอด</option>
                                                        <option value="3">ผัด</option>
                                                        <option value="4">ครัวเย็น</option>
                                                        <option value="5">เครื่องดื่ม</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Row>

                                            {kitchen === '5' ?
                                                <Row className="align-items-end mt-3 mb-3">
                                                    <Form.Group as={Col} md="12" className="position-relative" controlId="validationCustom01">
                                                        <Form.Label className="font-12 text-gray mb-2">คลังเครื่องดื่ม</Form.Label>
                                                        <Form.Select as="select" className='font-14' name='sku' defaultValue={datatatpro.sku}>
                                                            <option value="">เลือกชื่อสินค้า</option>
                                                            {data.datacat.datawarehouse.map((value2, index2) => (
                                                                <option key={index2} value={value2.id}>{value2.sku} - {value2.name} ({value2.unit})</option>
                                                            ))}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Row>
                                                : null}


                                            <Row className="mt-0">
                                                <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                                    <Form.Label className="font-12 text-gray mb-0">ชื่อเมนู</Form.Label>
                                                    <Form.Control type="text" name="name" defaultValue={datatatpro.name} className='font-14 input-custom' placeholder='xxxxx' required />
                                                </Form.Group>
                                            </Row>
                                            <Row className="mt-3">
                                                <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                                    <Form.Label className="font-12 text-gray mb-2">Recommend</Form.Label>
                                                    <div className="ex-checkbox mb-3">
                                                        <input type="checkbox" id="radio_one" name="recommend" value="1" defaultChecked={datatatpro.recommend === '0' ? false : true} />
                                                        <label className="radio_one mb-2 ps-4 text-gray font-12" style={{ width: "100%" }} >
                                                            Recommend
                                                        </label>
                                                    </div>
                                                </Form.Group>
                                            </Row>

                                            <Row className="align-items-end mb-3">
                                                <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                                    <Form.Label className="font-12 text-gray mb-0">คำอธิบาย</Form.Label>
                                                    <Form.Control type="text" name='detail' defaultValue={datatatpro.detail} className='font-14 input-custom' placeholder='' />
                                                </Form.Group>
                                            </Row>
                                            <Row className="align-items-end mt-3 mb-3">
                                                <Form.Group as={Col} md="4" className="position-relative pe-1" controlId="validationCustom01">
                                                    <Form.Label className="font-12 text-gray mb-0">ราคาขาย</Form.Label>
                                                    <Form.Control type="text" name='price' defaultValue={datatatpro.price} className='font-14 input-custom' placeholder='xxx' required />
                                                </Form.Group>
                                                <Form.Group as={Col} md="2" className="position-relative pe-1" controlId="validationCustom01">
                                                    <Form.Label className="mb-0">บาท</Form.Label>
                                                </Form.Group>
                                                <Form.Group as={Col} md="4" className="position-relative pe-1" controlId="validationCustom01">
                                                    <Form.Label className="font-12 text-gray mb-0">ราคาต้นทุน</Form.Label>
                                                    <Form.Control type="text" name='price_ton' defaultValue={datatatpro.price_ton} className='font-14 input-custom' placeholder='xxx' required />
                                                </Form.Group>
                                                <Form.Group as={Col} md="2" className="position-relative pe-1" controlId="validationCustom01">
                                                    <Form.Label className="mb-0">บาท</Form.Label>
                                                </Form.Group>
                                            </Row>
                                            <div className="ex-checkbox mb-3">
                                                <input type="checkbox" id="radio_one" name="tax" value="1" defaultChecked={datatatpro.tax === '0' ? false : true} />
                                                <label className="radio_one mb-2 ps-4 text-gray" style={{ width: "100%" }} >
                                                    ไม่คิดภาษี
                                                </label>
                                            </div>
                                            <div className='border-top pt-2'>
                                                <div className='d-inline-block mt-2'>เพิ่มตัวเลือก</div>
                                                <div className="form-check form-switch float-end">
                                                    <input className="form-check-input d-inline-block" type="checkbox" id="flexSwitchCheckChecked" name='choice_swit' value="1" onClick={() => handleSwt2(swt2.view)} defaultChecked={swt2.view === '1' ? true : null} />
                                                </div>
                                            </div>
                                            <div className='mt-3 mb-5'>
                                                {swt2.view === '1' ? (
                                                    <span onClick={() => getChoice()} className="btn btn-border-default py-2 px-5 me-3 weight-500 font-14">
                                                        จัดการตัวเลือก
                                                    </span>
                                                ) : ""
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-center py-3 px-3 bg-gray'>
                                        <div className='row'>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <button type='submit' className="w-100 btn btn-submit text-white py-2 me-3">
                                                    บันทึก
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modalcropbranch show={modalcrop.view} onHide={() => setModalcrop({ view: false })} id={modalcrop.id} getblob={getBlob} inputimg={modalcrop.inputImg} text="ภาพเมนู" />
            {modalproductmore.view ?
                <Modalproductmore show={modalproductmore.view} onHide={getHide} getHide={getHide} topic={modalproductmore.topic} more_n={modalproductmore.more_n} more_c={modalproductmore.more_c} more_number={modalproductmore.more_number} dataall={modalproductmore.dataChoice} />
                : null}
        </>
    );
};

export default Modaladdproductedit;