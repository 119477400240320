import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

// import { BrowserRouter as Router } from "react-router-dom";
// import axios from 'axios';
import AuthService from "../../components/services_member/auth.service";
// import { Link } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
// import Rang from '../components/lang';

import { Form, Row, Col } from "react-bootstrap";

import icondel from '../../assets/img/icon-del.svg';
import dellist from '../../assets/img/del-list.svg';
import addlist from '../../assets/img/add-list.svg';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const Modalproductmore = (datat) => {

    let history = useNavigate();

    const more = JSON.parse(localStorage.getItem("more_data"));
    // const modedata = JSON.parse(localStorage.getItem("more_data").more_data);
    let modedatarr = datat.dataall;

    // console.log(datat.dataall)

    // const [inputFields, setInputFields] = useState(more.more_data);
    const [inputFields, setInputFields] = useState(modedatarr);
    // const [inputFields, setInputFields] = useState({ items:[...modedata] });

    // console.log(inputFields)

    const handleFormChange = (index, event) => {
        // let data = [...inputFields];
        // data[index][event.target.more_name] = event.target.more_price;
        // setInputFields(data);
        // console.log(data[index])
    }

    const addFields = () => {
        let newfield = { more_name: '', more_price: '', more_status: 'yes' }
        setInputFields([...inputFields, newfield])
    }

    const removeFields = (index) => {
        let data = [...inputFields];
        data.splice(index, 1)
        setInputFields(data)
    }

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const data = new FormData(event.target);

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

        } else {
            // var val = { name: data.get('more_name[]') };
            AuthService.moreProduct(data).then(
                (res) => {
                    //   console.log(res)   
                    if (res.status === 200) {
                        // window.location.reload();
                        // console.log(res.data) 
                        localStorage.setItem("more_data", JSON.stringify(res.data));


                        // Swal.fire({
                        //     position: 'center',
                        //     icon: 'success',
                        //     title: '',
                        //     text: "บันทึกข้อมูลเรียบร้อยแล้ว",
                        //     showConfirmButton: false,
                        //     timer: 1500
                        // }).then(function () {
                        datat.getHide();
                        // }) 
                    } else {
                        // console.log(res.message)
                    }
                },
                (error) => {
                    console.log(error);
                }
            );


            // () => setModalproductmore({
            //     view: false
            //   })
            // datat.onHide;
            // console.log(datat.onHide)
            // localStorage.setItem("branch_data", JSON.stringify());
            // history("/setting/employee");
            // window.location.reload(true);
            // alert(data.get('service_charge'));
            // alert(555);

        }
        setValidated(true);
    };

    console.log(datat)

    // useEffect(() => {
    //     setInputFields({ items:[...datat.dataall]});
    // }, [datat.dataall]);

    return (
        <>
            <Modal {...datat} className="modal-custom" size="md" backdrop="static"
                keyboard={false} style={{ background: "rgba(0,0,0,0.7)" }}>
                {/* <Modal.Header closeButton className="btn-close-white ">

                </Modal.Header> */}
                <Modal.Body className="p-0">
                    <div className="d-block w-100 scroll-custom">
                        <Modal.Title className='py-3 bg-white px-4 shadow-sm position-fixed w-100' style={{ zIndex: "1" }}>
                            <div className='text-default font-22'>
                                เพิ่มตัวเลือก
                            </div>
                        </Modal.Title>
                        <Form noValidate validated={validated} onSubmit={handleSubmit} className="">
                            <div className='h-100per-modal bg-white px-4 pt-5'>

                                <div>
                                    <Row className="mt-5">
                                        <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                            <Form.Label className="font-12 text-gray mb-0">ชื่อหัวข้อ</Form.Label>
                                            <Form.Control type="text" name="topic" defaultValue={datat.topic} className='font-14 input-custom' placeholder='xxxxxxxx' required />
                                        </Form.Group>
                                    </Row>
                                    <div className='row mb-5'>
                                        <div className='col-6'>
                                            <div className="ex-radio mt-3">
                                                <input type="radio" id="radio_one" name="more_n" value="0" defaultChecked={datat.more_n === '0' ? true : false} />
                                                <span className="radio_one mb-2 ps-4 text-gray" style={{ width: "100%" }} >
                                                    ไม่จำเป็นต้องระบุ
                                                </span>
                                            </div>
                                            <div className="ex-radio mb-0">
                                                <input type="radio" id="radio_one" name="more_n" value="1" defaultChecked={datat.more_n === '1' ? true : false} />
                                                <span className="radio_one mb-2 ps-4 text-gray" style={{ width: "100%" }} >
                                                    จำเป็นต้องระบุ
                                                </span>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className="ex-radio mt-3">
                                                <input type="radio" id="radio_one" name="more_c" value="0" defaultChecked={datat.more_c === '0' ? true : false} />
                                                <span className="radio_one mb-2 ps-4 text-gray" style={{ width: "100%" }} >
                                                    เลือกได้แค่ 1
                                                </span>
                                            </div>
                                            <div className="ex-radio ">
                                                <input type="radio" id="radio_one" name="more_c" value="1" defaultChecked={datat.more_c === '1' ? true : false} />
                                                <span className="radio_one mb-2 ps-4 text-gray" style={{ width: "100%" }} >
                                                    ระบุจำนวน 
                                                    <Form.Control type="text" name="more_number" defaultValue={datat.more_number} className='font-14 input-custom'/>
                                                </span>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='mb-5 '>
                                        {inputFields.map((input, index) => index === 0 ?
                                            <Row className="align-items-end mb-3" key={index}>
                                                <Form.Group as={Col} md="5" className="position-relative" controlId="validationCustom01">
                                                    <Form.Label className="font-12 text-gray mb-0">ชื่อตัวเลือก</Form.Label>
                                                    <Form.Control type="text" name='more_name[]' defaultValue={input.more_name} className='font-14 input-custom' required />
                                                </Form.Group>
                                                <Form.Group as={Col} md="2" className="position-relative" controlId="validationCustom01">
                                                    <Form.Label className="font-12 text-gray mb-0">ราคา</Form.Label>
                                                    <Form.Control type="text" name='more_price[]' defaultValue={input.more_price} className='font-14 input-custom' required />
                                                </Form.Group>
                                                <Form.Group as={Col} md="2" className="position-relative" controlId="validationCustom01">
                                                    <Form.Label className="font-12 text-gray mb-0">Status</Form.Label>
                                                    <div className="form-check form-switch float-end">
                                                        <input className="form-check-input d-inline-block" type="checkbox" name={'more_status[' + index + ']'} value="yes" defaultChecked={input.more_status === 'yes' ? true : null} />
                                                        {/* <input id='testNameHidden' type='hidden' value='no' name='more_status[]'></input> */}
                                                    </div>
                                                </Form.Group>
                                                {inputFields.length === 1 ? (
                                                    <Form.Group as={Col} md="2" className="position-relative pifo-b" controlId="validationCustom01">
                                                        <button onClick={() => removeFields()} className="btn py-0 px-2" style={{ opacity: "0" }}>
                                                            <img src={dellist} alt="" className="" style={{ width: "30px" }} />
                                                        </button>
                                                        <span className='cursor' onClick={addFields}>
                                                            <img src={addlist} alt="" className="" style={{ width: "30px" }} />
                                                        </span>
                                                    </Form.Group>
                                                ) : null}
                                            </Row>
                                            : null
                                        )}
                                        {inputFields.map((input, index) => (
                                            index !== 0 ?
                                                <Row className="align-items-end mt-3 mb-3 pifo" key={index}>
                                                    <Form.Group as={Col} md="5" className="position-relative" controlId="validationCustom01">
                                                        <Form.Control type="text" name='more_name[]' defaultValue={input.more_name} className='font-14 input-custom' required onChange={event => handleFormChange(index, event)} />
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="2" className="position-relative" controlId="validationCustom01">
                                                        <Form.Control type="text" name='more_price[]' defaultValue={input.more_price} className='font-14 input-custom' required onChange={event => handleFormChange(index, event)} />
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="2" className="position-relative" controlId="validationCustom01">
                                                        {/* <Form.Control type="text" name='more_price[]' defaultValue={input.more_price} className='font-14 input-custom' required onChange={event => handleFormChange(index, event)} /> */}
                                                        <div className="form-check form-switch float-end">
                                                            <input className="form-check-input d-inline-block" type="checkbox" name={'more_status[' + index + ']'} value="yes" defaultChecked={input.more_status === 'yes' ? true : null} onChange={event => handleFormChange(index, event)} />
                                                            {/* <input id='testNameHidden' type='hidden' value='no' name='more_status[]'></input> */}
                                                        </div>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="3" className="position-relative pifo-b" controlId="validationCustom01">
                                                        <button onClick={() => removeFields(index)} className="btn py-0 px-2">
                                                            <img src={dellist} alt="" className="" style={{ width: "30px" }} />
                                                        </button>
                                                        <span className='cursor' onClick={addFields}>
                                                            <img src={addlist} alt="" className="" style={{ width: "30px" }} />
                                                        </span>
                                                    </Form.Group>
                                                </Row>
                                                : null
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className='text-center py-3 px-3 bg-gray'>
                                <div className='row'>
                                    <div className="col-12 col-md-6 col-lg-6 pe-1">
                                        <span className="w-100 btn btn-calcel text-white py-2 me-3" onClick={datat.onHide}>
                                            ยกเลิก
                                        </span>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 ps-1">
                                        <button type='submit' className="w-100 btn btn-submit text-white py-2 me-3">
                                            บันทึก
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    );
};

export default Modalproductmore;