import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button } from "react-bootstrap";

import '../../assets/css/radioandcheckbox.css';
import { Form, Tab, Nav, Row, Col } from "react-bootstrap";

import AuthService from "../../components/services_member/auth.service";

import cplus from '../../assets/img/c-plus.svg';
import clob from '../../assets/img/c-lob.svg';
import Swal from 'sweetalert2'

const Modalmenuorder = (data) => {

    const [state, setState] = useState({ count: 1 });
    const [checked, setChecked] = useState([]);
    const [checkedState, setCheckedState] = useState([]);
    const [validated, setValidated] = useState(false);
    const [validated2, setValidated2] = useState(false);

    const formRef = useRef(null);
    const formRef2 = useRef(null);
    // console.log(data)

    const handleSubmit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const data2 = new FormData(event.target);

        data2.append("idProduct", data.id);
        data2.append("idOrder", data.idreserve);
        data2.append("nameProduct", data.dataone[0].name);
        data2.append("price", data.dataone[0].price);
        data2.append("kitchen_id", data.dataone[0].kitchen_id);

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

        } else {
            // updateOrderlistupdate
            AuthService.updateOrderlist(data2).then(
                (res) => {
                    if (res.status === 200) {
                        // window.location.reload();
                        data.onHide();
                        setValidated(false);
                        setState({ count: 1 });

                        // formRef.current.reset();
                    } else {
                        console.log(res.message)
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        }
        setValidated(true);
    };

    const handleSubmitedit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const data2 = new FormData(event.target);

        data2.append("id", data.id);
        data2.append("qty", state.count);
        // data2.append("idOrder", data.idreserve);
        // data2.append("nameProduct", data.dataone[0].name);
        // data2.append("price", data.dataone[0].price);
        // data2.append("kitchen_id", data.dataone[0].kitchen_id);

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

        } else {
            // 
            AuthService.updateOrderlistupdate(data2).then(
                (res) => {
                    if (res.status === 200) {
                        // window.location.reload();
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: '',
                            text: "แก้ไขเรียบร้อยแล้ว",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(function () {
                            data.onHide();
                            // formRef2.current.reset();
                            setValidated2(false);
                            setState({ count: 1 });
                        })

                    } else {
                        console.log(res.message)
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        }
        setValidated2(true);
    };

    useEffect(() => {
        if (data.type === "edit") {
            setState({ count: parseFloat(data.dataone[0].qty) })
        }
    }, [data.dataone[0]]);
    // handlerefresh
    // console.log(swt.view)

    const incrementCount = () => {
        setState({
            count: state.count + 1
        });
    }
    const decrementCount = () => {
        if (state.count > 1) {
            setState({
                count: state.count - 1
            });
        }
    }

    // const onCheckChange = (event) => {
    //     var updatedList = [...checked];
    //     if (event.target.checked) {
    //         updatedList = [...checked, event.target.value];
    //     } else {
    //         updatedList.splice(checked.indexOf(event.target.value), 1);
    //     }
    //     setChecked(updatedList);
    // }
    const onCheckChange = (position, event, number) => {

        var updatedList = [...checked];
        var updatedList2 = [...checkedState];
        if (event.target.checked) {
            if (checked.length < number) {
                updatedList = [...checked, event.target.value];
                updatedList2 = [...checkedState, position];
            }
        } else {
            updatedList.splice(checked.indexOf(event.target.value), 1);
            updatedList2.splice(checkedState.indexOf(position), 1);
        }

        setChecked(updatedList);
        setCheckedState(updatedList2);
    }




    return (
        <>
            <Modal {...data} className="modal-custom" size="md" backdrop="static" keyboard={false}>
                <Modal.Header closeButton className="btn-close-white ">

                </Modal.Header>
                <Modal.Body className="p-0 bg-white">

                    <div className="d-block w-100 overflow-hidden">
                        {data.type === "edit" ?
                            data.dataone[0] ? <>
                                <Form noValidate validated={validated2} onSubmit={handleSubmitedit} ref={formRef2}>
                                    <Modal.Title className='py-3 mb-3'>
                                        <div className='font-22 mt-3 weight-500 text-center'>{data.dataone[0].name_product}</div>
                                        <div className='font-26 mt-2 mb-4 weight-500 text-center'>
                                            <b>{(parseFloat(data.dataone[0].price)).toLocaleString('en-US', { maximumFractionDigits: 2 })}</b>
                                        </div>
                                        <div className='text-center'>
                                            <span className='btn-lob rounded' onClick={decrementCount}>
                                                <img src={clob} style={{ marginBottom: "4px", width: "15px" }} />
                                            </span>
                                            <span className='mx-5 d-inline-block' style={{ width: "30px" }}>
                                                {state.count}
                                                <input type="hidden" defaultValue={state.count} name="qty" />
                                            </span>
                                            <span className='btn-lob rounded' onClick={incrementCount}>
                                                <img src={cplus} style={{ marginBottom: "4px", width: "15px" }} />
                                            </span>
                                        </div>
                                        {/* <div className='font-14 weight-400 px-3 mb-3'>{data.dataone[0].detail}</div> */}
                                    </Modal.Title>
                                    {/* <Modal.Body className='px-4 scroll-custom'>
                                        <div className="h-100per-modal-b">
                                        </div>
                                    </Modal.Body> */}
                                    <Modal.Footer style={{ backgroundColor: "#EAEAEA" }}>
                                        <div className='w-100 mt-2'>
                                            <div className='row'>
                                                <div className="col-12 col-md-6 col-lg-6 pe-1">
                                                    <span onClick={data.onHide} transition="default-fade" className="w-100 btn btn-calcel text-white py-2">
                                                        ยกเลิก
                                                    </span>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6 ps-1">
                                                    <button type='submit' className="w-100 btn btn-submit text-white py-2">
                                                        เพิ่ม
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal.Footer>
                                </Form>
                            </> : null
                            :

                            data.dataone[0] ? <>
                                <Form noValidate validated={validated} onSubmit={handleSubmit} ref={formRef}>

                                    <Modal.Title className='py-3 mb-3' style={{ boxShadow: "0px 0 15px -5px #2E384D" }}>
                                        <div className='font-22 mt-3 weight-500 text-center'>{data.dataone[0].name}</div>
                                        <div className='font-26 mt-2 mb-4 weight-500 text-center'>
                                            <b>{(parseFloat(data.dataone[0].price)).toLocaleString('en-US', { maximumFractionDigits: 2 })}</b>
                                        </div>
                                        <div className='font-14 weight-400 px-3 mb-3'>{data.dataone[0].detail}</div>
                                        <div className='text-center'>
                                            <span className='btn-lob rounded' onClick={decrementCount}>
                                                <img src={clob} style={{ marginBottom: "4px", width: "15px" }} />
                                            </span>
                                            <span className='mx-5 d-inline-block' style={{ width: "30px" }}>
                                                {state.count}
                                                <input type="hidden" defaultValue={state.count} name="qty" />
                                            </span>
                                            <span className='btn-lob rounded' onClick={incrementCount}>
                                                <img src={cplus} style={{ marginBottom: "4px", width: "15px" }} />
                                            </span>
                                        </div>
                                    </Modal.Title>
                                    <Modal.Body className='px-4 scroll-custom'>
                                        <div className="h-100per-modal-b">
                                            {data.dataone[0].choice ? <>
                                                <div className='font-18 weight-500'>

                                                    {
                                                        JSON.parse(data.dataone[0].choice).topic
                                                    }
                                                    <span className="font-12 text-gray">
                                                        {JSON.parse(data.dataone[0].choice).more_n === "1" ? " จำเป็นต้องระบุ" : " ไม่จำเป็นต้องระบุ"}
                                                        {JSON.parse(data.dataone[0].choice).more_c === "0" ? "" : " เลือกได้ไม่เกิน " +(JSON.parse(data.dataone[0].choice).more_number) }
                                                    </span>

                                                </div>

                                                {JSON.parse(data.dataone[0].choice).more_n === "1" ?
                                                    <div className='mt-3'>
                                                        {(JSON.parse(data.dataone[0].choice).more_data).map((val, index) => (
                                                            <div key={index}>
                                                                {JSON.parse(data.dataone[0].choice).more_c === "0" ? <>
                                                                    <div className="ex-radio mb-1" >
                                                                        <input onChange={(e) => onCheckChange(index, e, JSON.parse(data.dataone[0].choice).more_number)} required={checked.length ? false : true} type="radio" className='form-control-checkbox' id="radio_one"
                                                                            name="choice[]" value={val.more_name + "price" + val.more_price} />
                                                                        <span className="radio_one mb-2 ps-4 text-gray" style={{ width: "100%" }} >
                                                                            {val.more_name}
                                                                            <span className='float-end'>+{val.more_price}</span>
                                                                        </span>
                                                                    </div>
                                                                </>
                                                                    :
                                                                    <>
                                                                        <div className="ex-checkbox mb-3">
                                                                            <input type="checkbox" name="choice[]" className='form-control-checkbox' onChange={(e) => onCheckChange(index, e, JSON.parse(data.dataone[0].choice).more_number)}
                                                                                required={checked.length ? false : true} value={val.more_name + "price" + val.more_price}
                                                                                checked={checkedState.filter(v => v === index).length ? true : false}
                                                                            />
                                                                            <label className="radio_one mb-2 ps-4 text-gray" style={{ width: "100%" }} >
                                                                                {val.more_name} {checked[val.more_name + "price" + val.more_price]}
                                                                                <span className='float-end'>+ {val.more_price}</span>
                                                                            </label>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                        ))}
                                                        {checked.length ? null :
                                                            <div className='valid-checkbox'>
                                                                <span className="font-12 text-red">
                                                                    จำเป็นต้องระบุ
                                                                </span>
                                                            </div>
                                                        }
                                                    </div>
                                                    :
                                                    <div className='mt-3'>
                                                        {(JSON.parse(data.dataone[0].choice).more_data).map((val, index) => (
                                                            <div key={index}>
                                                                {JSON.parse(data.dataone[0].choice).more_c === "0" ? <>
                                                                    <div className="ex-radio mb-1" >
                                                                        <input onChange={(e) => onCheckChange(index, e, JSON.parse(data.dataone[0].choice).more_number)} type="radio"
                                                                            className='form-control-checkbox' name="choice[]" value={val.more_name + "price" + val.more_price} />
                                                                        <span className="radio_one mb-2 ps-4 text-gray" style={{ width: "100%" }} >
                                                                            {val.more_name}
                                                                            <span className='float-end'>+{val.more_price}</span>
                                                                        </span>
                                                                    </div>
                                                                </>
                                                                    : <>
                                                                        <div className="ex-checkbox mb-3">
                                                                            <input type="checkbox" name="choice[]" className='form-control-checkbox' onChange={(e) => onCheckChange(index, e, JSON.parse(data.dataone[0].choice).more_number)}
                                                                                value={val.more_name + "price" + val.more_price}
                                                                                checked={checkedState.filter(v => v === index).length ? true : false}
                                                                            />
                                                                            <label className="radio_one mb-2 ps-4 text-gray" style={{ width: "100%" }} >
                                                                                {val.more_name} {checked[val.more_name + "price" + val.more_price]}
                                                                                <span className='float-end'>+ {val.more_price}</span>
                                                                            </label>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                        ))}

                                                    </div>
                                                }
                                            </> : null}
                                            <div>
                                                <Row className="mt-4 mb-3">
                                                    <Form.Group as={Col} md="12" className="position-relative" controlId="validationCustom01">
                                                        <Form.Label className="font-16 weight-500 mb-0">หมายเหตุเพิ่มเติม <span className='font-12 text-gray'>ไม่จำเป็นต้องระบุ</span></Form.Label>
                                                        <textarea name='note' className="form-control font-14 mt-2" rows="3" placeholder='ระบุรายละเอียดคำขอ'></textarea>
                                                    </Form.Group>
                                                </Row>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer style={{ backgroundColor: "#EAEAEA" }}>
                                        <div className='w-100 mt-2'>
                                            <div className='row'>
                                                <div className="col-12 col-md-6 col-lg-6 pe-1">
                                                    <span onClick={data.onHide} transition="default-fade" className="w-100 btn btn-calcel text-white py-2">
                                                        ยกเลิก
                                                    </span>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6 ps-1">
                                                    <button type='submit' className="w-100 btn btn-submit text-white py-2">
                                                        เพิ่ม
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal.Footer>
                                </Form>
                            </> : null
                        }
                    </div>

                </Modal.Body>
            </Modal>

        </>
    );
};

export default Modalmenuorder;