import React, { useState, useCallback, useEffect } from 'react';
// import axios from 'axios';
import { useNavigate,Link } from "react-router-dom";

import { format } from 'date-fns'

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import AuthService from "../../components/services_member/auth.service";
import PostService from "../../components/services_member/post.service";

import help from '../../assets/img/help.svg';
// import chairgreen from '../../assets/img/chair-green.svg';
// import chairorange from '../../assets/img/chair-orange.svg';
// import chairred from '../../assets/img/chair-red.svg';
import calendar from '../../assets/img/calendar.svg';

import Menuleft from "../../layout/pagesadmin/menuleft";
import Tabcontent from "../../pages/table/table_tab_content";
import Reserveview from "../../pages/table/table_reserve";
import Reserdetail from "../../pages/table/table_detail_reserve";

// import { Link } from "react-tiger-transition";
import { Form, Tab, Nav } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

const Tablemanage = () => {

    localStorage.removeItem("table_order");

    const { t } = useTranslation();

    let history = useNavigate();
    const user = AuthService.getCurrentUser();
    const branch = AuthService.getCurrentBranch();

    if (!user) {
        history("/");
    } else {
        if (!branch) {
            history("/branch");
        }
    }

    const [todayDate, setTodayDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    const [datatable, setDatatable] = useState([]);
    const [datareserve, setDatareserve] = useState([]);
    const [datatableone, setDatatableone] = useState([]);
    const [datareserveone, setDatareserveone] = useState([]);

    const [dateday, setDateday] = useState(format(new Date(), 'dd/MM/yyyy'));

    const [reserveview, setReserveview] = useState({ search: true, reserve: false, detail: false });

    const dateoption = {
        locale: {
            format: 'DD/MM/YYYY'
        },
        minDate: todayDate,
        startDate: todayDate,
        endDate: startDate,
        singleDatePicker: true,
        // autoApply: true,
    };

    const [validated, setValidated] = useState(false);

    // const [dataarray1, setDataarray1] = useState({ items: [] });

    const [dataarray, setDataarray] = useState([]);

    const fetchData = useCallback(async (ee) => {

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        PostService.getTableall(branch.id, ee).then(
            (response) => {
                // console.log(response.data.status)
                if (response.data.status === 200) {
                    setDatatable(response.data.data);
                    setDatareserve(response.data.reserve);

                    const fourHiArray = [];

                    (response.data.data).map((value, index) => {

                        // fourHi[index] = value.id;

                        (JSON.parse(value.detail)).map((val, ind) => {

                            (response.data.reserve).filter(valb => valb.zone === value.id).filter(valv => valv.table_reserve === `${val.id}`).filter(valv => valv.status === '1').length ?

                                // (response.data.reserve).filter(valb => valb.zone === value.id).filter(valv => valv.table_reserve === `${val.id}`).map((val2, ind2) => {
                                fourHiArray.push({ zone: value.id, table_number: val.table_number, status: '1' })
                                // }
                                :
                                (response.data.reserve).filter(valb => valb.zone === value.id).filter(valv => valv.table_reserve === `${val.id}`).filter(valv => valv.status === '2').length ?
                                    fourHiArray.push({ zone: value.id, table_number: val.table_number, status: '2' })
                                    :

                                    fourHiArray.push({ zone: value.id, table_number: val.table_number, status: '0' })

                        })

                    })
                    setDataarray(fourHiArray);
                }
            },
            (error) => {
                console.log(error);
            }
        )
    });

    // console.log(datatable)

    useEffect(() => {
        fetchData(dateday)
    }, []);

    const handledateday = (event) => {
        fetchData(event.target.value)
        setDateday(event.target.value)
    }

    const handleBackmanage = (event) => {
        setReserveview({ search: true, reserve: false, detail: false })
        fetchData(format(new Date(), 'dd/MM/yyyy'))
    }

    const handlereserve = (event, ee) => {
        // console.log(event,ee)
        setReserveview({ search: false, reserve: false, detail: true })
        setDatatableone(datatable.filter(valb => valb.id === event)[0])
        setDatareserveone(datareserve.filter(valb => valb.zone === event).filter(valv => valv.table_reserve === `${ee}`)[0])
        // fetchData(format(new Date(), 'dd/MM/yyyy'))
        // console.log(datatable)
    }

    const refreshp = () => {
        fetchData(dateday)
    }

    return (
        <>
            {/* sidebar-mini sidebar-collapse */}
            <div className={!t('menu-a') ? "sidebar-mini sidebar-collapse" : ""} >
                <div className='wrapper-admin layout-fixed'>

                    <Tab.Container id="left-tabs-example" defaultActiveKey="zone_all">
                    <nav className="main-header navbar bg-white shadow-sm" style={{ height: "60px" }}>
                        <Nav as="ul" variant="tabs" className="nav nav-tabs overflow-hidden ul-tabcustom">
                            <Nav.Item as="li">
                                <Nav.Link eventKey="zone_all">ทั้งหมด</Nav.Link>
                            </Nav.Item>
                            {datatable.map((value, index) => (
                                <Nav.Item as="li" key={index}>
                                    <Nav.Link eventKey={value.id}>{value.zone}</Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                        <ul className="navbar-nav ml-auto" style={{ marginTop: "-20px" }}>
                            <li className="">
                                <div className='text-gray font-12'><img src={help} alt="" className="me-1" style={{ width: "14px" }} /> ขอความช่วยเหลือ</div>
                            </li>
                        </ul>
                    </nav>

                    <Menuleft type="table" />

                    <div className="content-wrapper">
                        <section className="content scroll-custom">
                            <div className="row">
                                <div className="col-12 col-md-8 col-lg-8 ">
                                    <Tabcontent datatable={datatable} datareserve={datareserve} nadlereserve={handlereserve} dataarr={dataarray} refresh={refreshp} />
                                </div>

                                <div className="col-12 col-md-4 col-lg-4 ps-0">
                                    {reserveview.search ?
                                        <>
                                            <div className="bg-white rounded-3 ms-2 mt-3 h-100per-right position-relative">
                                                <div className='px-4'>
                                                    <div className='text-start py-3 weight-500'>ค้นหาวันที่</div>
                                                    <div className='position-relative'>
                                                        <Form.Group controlId="dob">
                                                            <DateRangePicker initialSettings={dateoption} onApply={(e) => (handledateday(e))}>
                                                                <input type="text" className="form-control" name="dateday" />
                                                            </DateRangePicker >
                                                            <div className='position-absolute' style={{ top: "12px", right: "12px" }}>
                                                                <img src={calendar} alt="" className="me-1" style={{ width: "18px" }} />
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w-100 ps-2 mt-2'>
                                                <span onClick={() => setReserveview({ search: false, reserve: true })} transition="default-fade" className="w-100 btn btn-bg text-white py-2">
                                                    จองโต๊ะ
                                                </span>
                                            </div>
                                        </>
                                        : null}

                                    {reserveview.reserve ?
                                        <Reserveview canclef={handleBackmanage} datatable={datatable} dateday={dateday} datareserve={datareserve} selectdate={handledateday} />
                                        : null}

                                    {reserveview.detail ?
                                        <Reserdetail datatableall={datatable} datatable={datatableone} detailreserve={datareserveone} />
                                        : null}
                                </div>
                            </div>
                        </section>

                    </div>
                    </Tab.Container>
                </div>
            </div>
        </>
    );
};

export default Tablemanage;