import React, { useState } from 'react';

import { useNavigate,Link } from "react-router-dom";

import { Form, Col, Row } from "react-bootstrap";

import AuthService from "../../components/services_member/auth.service";

import Modalcrop from "../../layout/modal/modalCrop";
// import ImageCropper from '../../components/ImageCropper';

// import logo from '../../assets/img/ryuden-logo.svg';
import back from '../../assets/img/icon-back-blue.svg';
import noimg from '../../assets/img/no-img.svg';

// import { Link } from "react-tiger-transition";

const Editbusiness = () => {

    let history = useNavigate();

    const user = AuthService.getCurrentUser();
    const business = AuthService.getCurrentBusiness();

    if (!user) {
        history("/");
    }else{
        if(!business){
            history("/business");
        }
    }  

    const [blob, setBlob] = useState(null)
    // const [inputImg, setInputImg] = useState('');
    const [modalcrop, setModalcrop] = useState({ view: false, id: 0, getblobb:false });

    const getBlob = (blobrespone) => {
        // pass blob up from the ImageCropper component
        // setBlob(blob);
        
        if(blobrespone){
            setBlob(blobrespone);
            setModalcrop({ view: false });
        }
    }

    const onInputChange = (e) => {
        // convert image file to base64 string
        const file = e.target.files[0]
        const reader = new FileReader();

        reader.addEventListener('load', () => {
            // setInputImg(reader.result)
            setModalcrop({ view: true, id: 1, inputImg: reader.result })
        }, false)

        if (file) {
            reader.readAsDataURL(file)
        }        
    }    
    
    // console.log(modalcrop)

    // let history = useNavigate();
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const data = new FormData(event.target);

        // const blobconvert = new Blob(blob, { 'type': 'jpg/png; codecs=opus' });

        // data.append("blob", JSON.stringify(blobconvert));
        const bu_id = JSON.parse(localStorage.getItem("business_data"));

        data.append("id", bu_id.id);
        data.append("blob", JSON.stringify(blob));

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

        } else {

            AuthService.editbusiness(data).then(
                (res) => {                   
                //   console.log(res)   
                  if (res.status === 200) {
                        history("/business"); 
                        window.location.reload(); 
                  } else {
                  }                                  
                },(error) => {
                  console.log(error);
                }
              );
        }

        setValidated(true);
    };

    return (
        <>
            <div className="wrapper" style={{ backgroundColor: "#fff", zIndex: "1" }}>
                <Form noValidate validated={validated} onSubmit={handleSubmit} className="">
                    <section className="" >
                        <div className="container">
                            <div className="row align-items-center ">
                                <div className="col-12 col-md-12 col-xl-12 text-start h-100vh position-relative">
                                    <div className='position-absolute w-100 position-center px-3'>
                                        <div className="row justify-content-center">
                                            <div className="col-6 col-md-3 col-xl-3 text-center ">
                                                <div className='bg-white rounded-3 position-relative mx-auto'>
                                                    <div className="text-start position-absolute w-100 position-center" >
                                                        <div className='pb-3'>
                                                            <div className='weight-500 mb-1'>เลือกโปรไฟล์กิจการของคุณ</div>
                                                            <div className='font-12 text-gray'>ขนาดไฟล์ไม่เกิน 20 MB. { business.logo }</div>
                                                            <div className='pb-3 pe-5 py-3' >
                                                                <label htmlFor="upload" className='cursor'>
                                                                    {  
                                                                        blob ? (
                                                                            <img src={blob} alt="" className="w-75" />
                                                                        ) : 
                                                                        business.logo ?
                                                                            <img src={process.env.REACT_APP_API_URL+ "/uploads/business/" + business.logo} alt="" className="w-75" />
                                                                        :
                                                                            <img src={noimg} alt="" className="w-75" />
                                                                    }
                                                                </label>
                                                                <input type='file' name="file" id="upload" accept='image/*'  required onChange={onInputChange} hidden />
                                                            </div>
                                                            <div className='weight-500 mb-1 mt-3'>เลือกธีมสีกิจการของคุณ</div>
                                                            <div className='mt-2'>
                                                                <Row className="mb-3">
                                                                    <Form.Group as={Col} md="12" className="position-relative" controlId="validationCustom01">
                                                                        <Form.Control
                                                                            required
                                                                            type="color"
                                                                            className="text-center form-control-color p-0 rounded-circle border-0"
                                                                            name="color"
                                                                            defaultValue="#2E384D"
                                                                            style={{ height: "39px", width: "39px", overflow: "hidden", fontSize: "30px" }}
                                                                        />
                                                                    </Form.Group>
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='position-absolute' style={{ top: "30px", right: "30px" }}>
                            <div className='text-end'>บริษัท ริวเด็น จำกัด</div>
                            <div className='text-gray font-14 text-end'>29/11/2021  12:00</div>
                        </div>
                        <div className='position-absolute' style={{ top: "30px", left: "30px" }}>
                            <h4 className='weight-500 m-0'>
                                <Link to="/businesspininput" transition="default-fade" className="text-decoration-none">
                                    <img src={back} alt="" className="me-3 mb-1" style={{ height: "20px" }} />
                                </Link>
                                ปรับแต่งกิจการ
                            </h4>
                        </div>
                        <div className='position-absolute' style={{ bottom: "30px", right: "30px" }}>
                            <button type='submit' className="w-100 btn btn-bg text-white px-5">บันทึก</button>
                        </div>
                    </section>
                </Form>
            </div >

            <Modalcrop show={modalcrop.view} onHide={() => setModalcrop({ view: false })} id={modalcrop.id} getblobb={getBlob} inputimg={modalcrop.inputImg} />
            {/* <Modalcrop show={modalcrop.view} id={modalcrop.id} onHide={() => setModalcrop({ view: false })} onhideb={() => setModalcrop({ view: false })} inputimg={modalcrop.inputImg} /> */}
        </>
    );
};

export default Editbusiness;