import React, { useState, useEffect } from 'react';
// import axios from 'axios';
import { useNavigate,Link } from "react-router-dom";
import { Form, Col, Row } from "react-bootstrap";

import AuthService from "../../components/services_member/auth.service";

import logo from '../../assets/img/ryuden-logo.svg';
import pen from '../../assets/img/icon-pen.svg';
import backspace from '../../assets/img/backspace.svg';
import noimg from '../../assets/img/no-img.svg';

// import { Link } from "react-tiger-transition";

const Branchpininput = () => {

    let history = useNavigate();

    const user = AuthService.getCurrentUser();
    const branch = AuthService.getCurrentBranch();

    if (!user) {
        history("/");
    } else {
        if (!branch) {
            history("/branch");
        }
    }

    const [inputpin, setInputpin] = useState([]);
    const [datapin, setDatapin] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9]);

    const [pinerr, setPinerr] = useState(false);

    const handleAddItem = (e) => {
        setInputpin((oldArray) => [...oldArray, e])
    }
    const newArray = inputpin.join('');

    useEffect(() => {

        if (inputpin.length === 4) {
            if (newArray === branch.pin) {
                setTimeout(function () {
                    history("/table/manage");
                }, 1000)
            } else {
                setPinerr(true)
            }
        }
    });

    const handleRemoveItem = () => {
        const items = inputpin;
        if (items.length > 0) {
            const lastIndex = items.length - 1;
            setInputpin(items.filter((item, index) => index !== lastIndex));
        }
        setPinerr(false)
    };

    // const newArray = inputpin.toString('-');
    // console.log(newArray);

    return (
        <>
            <div className="wrapper" style={{ backgroundColor: "#fff", zIndex: "1" }}>
                <section className="" >
                    <div className="container">
                        <div className="row align-items-center ">
                            <div className="col-12 col-md-12 col-xl-12 text-start h-100vh position-relative">
                                <div className='position-absolute w-100 position-center px-3'>
                                    <div className="row justify-content-center">
                                        <div className="col-6 col-md-3 col-xl-3 text-center ">
                                            <div className='bg-white rounded-3 position-relative mx-auto' style={{ width: "220px" }}>
                                                <div className="text-center position-absolute w-100 position-center" >
                                                    <div className='pb-3'>
                                                        <div className='px-5 pb-3' >
                                                            {/* <img src={logo} alt="" className="w-100 " /> */}
                                                            {branch.logo ?
                                                                <img src={process.env.REACT_APP_API_URL + "/uploads/branch/" + branch.logo} alt="" className="w-100" />
                                                                :
                                                                <img src={noimg} alt="" className="w-100" />
                                                            }
                                                        </div>
                                                        <div>
                                                            <div className='mt-3 mb-2'>
                                                                <span className={newArray.length === 2 || newArray.length === 1 || newArray.length === 3 || newArray.length === 4 ? "opt-input active" : "opt-input"}></span>
                                                                <span className={newArray.length === 2 || newArray.length === 3 || newArray.length === 4 ? "opt-input active" : "opt-input"}></span>
                                                                <span className={newArray.length === 3 || newArray.length === 4 ? "opt-input active" : "opt-input"}></span>
                                                                <span className={newArray.length === 4 ? "opt-input active" : "opt-input"}></span>
                                                                {pinerr ? <div className='mt-3 text-red font-14'>รหัสไม่ถูกต้อง</div> : null}
                                                            </div>
                                                            <Row className="mb-3">
                                                                <Form.Group as={Col} md="12" className="position-relative" controlId="validationCustom01">
                                                                    <Form.Control
                                                                        required
                                                                        type="hidden"
                                                                        className="text-center py-3 bg-white font-15"
                                                                        placeholder="กรอกรหัส PIN เพื่อเข้าสู่ระบบ"
                                                                        name="email"
                                                                        value={newArray}
                                                                        maxLength={4}
                                                                        disabled
                                                                    />
                                                                </Form.Group>
                                                            </Row>
                                                            {/* <input className='form-control text-center py-3 bg-white' disabled placeholder='กรอกรหัส PIN เพื่อเข้าสู่ระบบ' value={inputpin.val} maxLength="4" /> */}
                                                            {/* {inputpin.val.length} */}
                                                        </div>
                                                        <div style={{ margin: "0 auto", width: "200px" }}>
                                                            <div className='row justify-content-end'>
                                                                {
                                                                    datapin.map((e, i) => (
                                                                        <div className="col-4 col-md-4 col-xl-4 text-center p-1" key={i}>
                                                                            <div className='keybord-number position-relative' onClick={() => handleAddItem(e)}>
                                                                                <div className='position-absolute position-center'><h3 className='m-0'>{e}</h3></div>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }
                                                                <div className="col-4 col-md-4 col-xl-4 text-center p-1">
                                                                    <div className='keybord-number position-relative' onClick={() => handleAddItem(0)}>
                                                                        <div className='position-absolute position-center'><h3 className='m-0'>0</h3></div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-4 col-md-4 col-xl-4 text-center p-1">
                                                                    <div className='position-relative cursor' onClick={() => handleRemoveItem()}>
                                                                        <div className='ps-4 pt-3 cors '>
                                                                            <img src={backspace} alt="" className="w-100 " />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='position-absolute' style={{ top: "30px", right: "30px" }}>
                        <div className='text-end'>{branch.name}</div>
                        <div className='text-gray font-14 text-end'>{branch.date_create}</div>
                    </div>
                    <div className='position-absolute' style={{ bottom: "30px", left: "30px" }}>
                        <Link to="/branch" transition="default-fade" className="w-100 btn btn-bggray text-default px-5">ย้อนกลับ</Link>
                    </div>
                    <div className='position-absolute' style={{ bottom: "30px", right: "30px" }}>
                        <Link to="/editbranch" transition="default-fade" className="w-100 btn btn-link-default text-default p-0">
                            <img src={pen} alt="" className="pe-2" /> ปรับแต่งสาขา
                        </Link>
                    </div>
                </section>
            </div >
        </>
    );
};

export default Branchpininput;