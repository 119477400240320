import React, { useState, useCallback, useEffect, useRef } from 'react';
// import axios from 'axios';
import { useNavigate,Link } from "react-router-dom";
import { compareAsc, format } from 'date-fns';

import { Form, Tab, Nav, Row, Col, Table } from "react-bootstrap";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import DateRangePicker from 'react-bootstrap-daterangepicker';
// import 'bootstrap-daterangepicker/daterangepicker.css';
// import DatepickerComponent from "../../components/datepicker.component";

import AuthService from "../../components/services_member/auth.service";
import PostService from "../../components/services_member/post.service";

import Menuleft from "../../layout/pagesadmin/menuleft";

import help from '../../assets/img/help.svg';
import btnremove from '../../assets/img/btn-remove.svg';
import calendar from '../../assets/img/calendar.svg';
import iconedit from '../../assets/img/icon-edit.svg';
import icondel from '../../assets/img/icon-del.svg';

import Modalconfirmwaste from "../../layout/modal/modalConfirwarehousecreate";

import DatePicker from "react-datepicker";
// var DatePicker = require("react-datepicker");
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next';
const Warehousereport = () => {
    const { t } = useTranslation();
    const MySwal = withReactContent(Swal)

    localStorage.removeItem("table_order");

    let history = useNavigate();
    const user = AuthService.getCurrentUser();
    const branch = AuthService.getCurrentBranch();

    if (!user) {
        history("/");
    } else {
        if (!branch) {
            history("/branch");
        }
    }

    const [dateday, setDateday] = useState(format(new Date(), 'MM/yyyy'));
    // const [datemonth, setDatemonth] = useState(format(new Date(), 'MM/yyyy'));

    const [startDate, setStartDate] = useState(new Date());

    const [datawaste, setDatawaste] = useState([]);
    const [dataSum, setDataSum] = useState({ sum: 0, total: 0 });

    const [modalconfirmwaste, setModalconfirmwaste] = useState({ view: false, id: 0 });

    const fetchData = useCallback(async (dd) => {

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        PostService.getWarehousereport(branch.id, dd, 1).then(
            (response) => {
                if (response.data.status === 200) {

                    // console.log(response.data)
                    setDatawaste(response.data.waste_data);
                    // setDatawastereport(response.data.waste_report);
                    // setDatacat(response.data.cat);
                    // setDataproduct(response.data.data_product);
                    // setDatapromotion(response.data.data_promotion);
                } else {
                    setDatawaste([]);
                }
            },
            (error) => {
                console.log(error);
            }
        )
    });

    useEffect(() => {
        fetchData(dateday);
    }, []);

    const handledateday = (event) => {

        fetchData(format(new Date(event), 'MM/yyyy'))
        setDateday(format(new Date(event), 'MM/yyyy'))

        setStartDate(event)

        // setStartDate(format(new Date(event), 'dd/MM/yyyy'))
        // setOrderview()
        // console.log(format(new Date(event), 'MM/yyyy'))
    }

    const handlehide = (event) => {
        setModalconfirmwaste({ view: false, dataform: false })
    }

    const handlerefresh = (event) => {
        setModalconfirmwaste({ view: false, dataform: false })
        fetchData(dateday);
    }

    const MoveAC = (id, day) => {
        // จำนวนที่นับได้ก่อนเปิดร้าน
        let move = datawaste.filter(vls => vls.id === id)[0];
        return <span>{(move.data_move).filter(vls => vls.day == day).length ?
            (move.data_move).filter(vls => vls.day == day)[0].qty_b ? (move.data_move).filter(vls => vls.day == day)[0].qty_b : "-" : "-"}
        </span>
        // console.log(move)
        // let dayy = day - 1;
        // let move = datawaste.filter(vls => vls.id === id)[0];
        // let suB = (move.data_move).filter(vls => vls.day == dayy).length ? (move.data_move).filter(vls => vls.day == dayy)[0].qty : 0;
        // let suC = (move.data_add_del).filter(vls => vls.day == dayy).filter(vls => vls.type == 0).length ? (move.data_add_del).filter(vls => vls.day == dayy).filter(vls => vls.type == 0)[0].qty : 0;
        // return <span>{ parseInt(suB) + parseInt(suC) ? parseInt(suB) + parseInt(suC) : '-' }</span>
    }
    const MoveA = (id, day) => {
        // ขายไปเท่าไหร่
        // type
        // 2 = ขาย
        let move = datawaste.filter(vls => vls.id === id)[0];
        let arraynea = [];
        let sumTotal = 0;

        if ((move.data_add_del).filter(vls => vls.day == day).filter(vls => vls.type != 0).length) {
            arraynea = (move.data_add_del).filter(vls => vls.day == day).filter(vls => vls.type != 0);
            sumTotal = arraynea.reduce((acc, curr) => acc + parseInt(curr.qty, 10), 0)

        } else {
            sumTotal = "-";
        }
        return <span>{sumTotal}
            {/* {(move.data_add_del).filter(vls => vls.day == day).filter(vls => vls.type != 0).length ? 
            (move.data_add_del).filter(vls => vls.day == day).filter(vls => vls.type != 0)[0].qty 
            : "-"} */}
        </span>
    }
    const MoveB = (id, day) => {
        // จำนวนเหลือหลังขาย(ปิดร้าน)
        let move = datawaste.filter(vls => vls.id === id)[0];
        return <span>{(move.data_move).filter(vls => vls.day == day).length ? (move.data_move).filter(vls => vls.day == day)[0].qty : "-"}</span>
    }
    const MoveC = (id, day) => {
        // ยอดเติมเข้าเท่าไหร่
        // type
        // 0 = เพิ่ม
        let move = datawaste.filter(vls => vls.id === id)[0];
        return <span>{(move.data_add_del).filter(vls => vls.day == day).filter(vls => vls.type == 0).length ? (move.data_add_del).filter(vls => vls.day == day).filter(vls => vls.type == 0)[0].qty : "-"}</span>
    }

    const [clssLoading, setClssLoading] = useState(false);

    const exportExcel = () => {

        setClssLoading(true);

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        PostService.getWarehouseExport(branch.id, dateday, 1).then(
            (response) => {
                if (response.data.status === 200) {

                    console.log(response.data.redirect)

                    window.location.href = response.data.redirect;

                    setClssLoading(false);
                } else {
                    // setDatawaste([]);

                    setClssLoading(false);
                }
            },
            (error) => {
                console.log(error);
            }
        )
    }

    // ช่อง1 จำนวนที่นับได้ก่อนเปิดร้าน 3+4ของเมื่อวาน
    // ช่อง2 ขายไปเท่าไหร่
    // ช่อง3 จำนวนเหลือหลังขาย(ปิดร้าน)
    // ช่อง4 เติมเข้าเท่าไหร่

    // วันถัดไป
    // ช่อง1 จำนวนที่นับได้ต้องเท่ากับช่อง3+4ของเมื่อวาน

    return (
        <>
            <div className={!t('menu-a') ? "sidebar-mini sidebar-collapse" : ""} >
                <div className='wrapper-admin layout-fixed'>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="food_all">
                        <nav className="main-header navbar bg-white shadow-sm" style={{ height: "60px" }}>
                            <Nav as="ul" className="" style={{ marginTop: "-4px" }}>
                                <li>
                                    <div className="bg-white rounded-3 ms-2 position-relative">
                                        <div className=''>
                                            <div className='text-start py-3 weight-500 d-inline-block me-3'>ประจำเดือน</div>
                                            <div className='position-relative d-inline-block'>
                                                <Form.Group controlId="dob">
                                                    {/* <DateRangePicker initialSettings={dateoption} showMonthYearPicker onApply={(e) => (handledateday(e))}>
                                                    <input type="text" className="form-control px-2 py-2" name="dateday" />
                                                </DateRangePicker > */}
                                                    <DatePicker
                                                        className='form-control py-2'
                                                        selected={startDate}
                                                        onChange={(e) => (handledateday(e))}
                                                        dateFormat="MM/yyyy"
                                                        showMonthYearPicker
                                                    >
                                                        <input type="text" className="form-control px-2 py-1" name="dateday" />
                                                    </DatePicker>

                                                    <div className='position-absolute' style={{ top: "10px", right: "12px" }}>
                                                        <img src={calendar} alt="" className="" style={{ width: "17px" }} />
                                                    </div>
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <button onClick={() => exportExcel()} className="w-100 btn btn-submit text-white mt-2 ms-3">
                                        {clssLoading ? <span><i className="fa fa-spinner fa-spin" /></span> : <span>Export</span>}
                                    </button>
                                </li>
                            </Nav>
                            <ul className="navbar-nav ml-auto" style={{ marginTop: "-20px" }}>
                                <li className="">
                                    <div className='text-gray font-12'>
                                        <img src={help} alt="" className="me-1" style={{ width: "14px" }} /> ขอความช่วยเหลือ
                                    </div>
                                </li>
                            </ul>
                        </nav>
                        <Menuleft type="warehouse" sub="warehousereport" />
                        <div className="content-wrapper">
                            <section className="content scroll-custom">
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <div className="bg-white rounded-3 mt-3 h-100per">
                                            <div className='text-start p-2 cuss scroll-custom'>
                                                <Table bordered className='report-table'>
                                                    <thead>
                                                        <tr style={{ background: "#F9CB9C" }}>
                                                            <th className=''>SKU</th>
                                                            <th className='' style={{ width: "300px" }}>รายการ</th>
                                                            {[...Array(31)].map((x, i) => (
                                                                <th className='' key={x} colSpan={4}>
                                                                    {i + 1}
                                                                </th>
                                                            ))}
                                                            <th>Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            datawaste.map((value2, index2) => (
                                                                <tr key={index2}>
                                                                    <td>{value2.sku}</td>
                                                                    <td>{value2.name} ({value2.unit})</td>
                                                                    {[...Array(31)].map((x, i) => (<>
                                                                        <td className='text-center' style={{ background: "#fff" }}>{MoveAC(value2.id, i + 1)}</td>
                                                                        <td className='text-center' style={{ background: "#FFF2CC" }}>{MoveA(value2.id, i + 1)}</td>
                                                                        <td className='text-center' style={{ background: "#fff" }}>
                                                                            {MoveB(value2.id, i + 1)}
                                                                        </td>
                                                                        <td className='text-center' style={{ background: "#E5F1E5" }}>{MoveC(value2.id, i + 1)}</td>
                                                                    </>
                                                                    ))}
                                                                    <td className='text-center' style={{ fontWeight: 500 }}>
                                                                        {value2.data_sum}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr style={{ background: "#FCE5CD" }}>
                                                            <td></td>
                                                            <td>Name</td>
                                                            <td colSpan={124}></td>
                                                            <td className='text-center'>
                                                                {datawaste.reduce((acc, curr) => acc + parseInt(curr.data_sum, 10), 0) ?
                                                                    datawaste.reduce((acc, curr) => acc + parseInt(curr.data_sum, 10), 0)
                                                                    : null}

                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </Table>
                                            </div>
                                            <div className='text-start mt-3 px-3 font-14'>
                                                *ช่อง 1 จำนวนที่นับได้ก่อนเปิดร้าน <br />
                                                *ช่อง 2 ขายไปเท่าไหร่ (ขาย+เบิก)<br />
                                                *ช่อง 3 จำนวนเหลือหลังขาย (ปิดร้าน)<br />
                                                *ช่อง 4 เติมเข้าเท่าไหร่
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </section>

                        </div>
                    </Tab.Container>
                </div>
            </div>

            {modalconfirmwaste.view ?
                <Modalconfirmwaste show={modalconfirmwaste.view} onHide={handlehide} onSubmit={handlerefresh} dataf={modalconfirmwaste.dataform} datawaste={datawaste} dataem={modalconfirmwaste.dataem} type={modalconfirmwaste.type} dateday={dateday} typeb={1} dataware={modalconfirmwaste.dataware} title={modalconfirmwaste.title} />
                : null}

        </>
    );
};

export default Warehousereport;