import React, { useState, useRef, useEffect } from 'react';
// import axios from 'axios';
import { Link } from "react-router-dom";
// import DatePicker from "react-datepicker";
// import help from '../../assets/img/help.svg';

import { compareAsc, format } from 'date-fns';
// import calendar from '../../assets/img/calendar.svg';

// import Menuleft from "../../layout/pagesadmin/menuleft";

// import { Link } from "react-tiger-transition";
import { Form, Tab, Table, DropdownButton, Dropdown } from "react-bootstrap";

import AuthService from "../../components/services_member/auth.service";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import Modalcancleproduct from "../../layout/modal/modalCancleproduct";
import Moment from 'moment';

import ReactToPrint from 'react-to-print';
import ComponentToorder from '../../components/ComponentToorder';

const Kitchentab = (data) => {

    Moment.locale('en');

    const [modalcancleproduct, setModalcancleproduct] = useState({ view: false, id: 0 });

    // const clickrefresh = () => {
    //     setModalmenudetail({ view: false, id: 0, data: [] });
    //     data.handlerefresh()
    // }
    // setIsLoading((prevState) => ({ ...prevState, donut: false }));
    // console.log(data.dataplang && data.dataplang.length ? data.dataplang.filter(vls => vls.status === '2') : [])

    const [checkedAll, setCheckedAll] = useState(false);
    const [checkedAllB, setCheckedAllB] = useState(false);
    const [checked, setChecked] = useState(data.dataplang && data.dataplang.length ? data.dataplang.filter(vls => vls.status === '2') : []);

    const [selectdata, setSelectdata] = useState([]);
    const [selectdataB, setSelectdataB] = useState([]);
    const [kitchendata, setKitchendata] = useState({ id: '0', label: 'ออเดอร์ทั้งหมด' });
    const [showDropdown, setShowDropdown] = useState(false);

    const componentRef = useRef(null);
    const formRef = useRef(null);

    const checktablename = (id, type, id_order) => {

        // alert(id_order)

        if (type === 'table') {
            return <span>
                {
                    data.dataorder.filter(vls => vls.id === id)[0] ?
                        data.dataorder.filter(vls => vls.id === id)[0].zone_name !== 0 ? (
                            <> {data.dataorder.filter(vls => vls.id === id)[0].zone_name} : {data.dataorder.filter(vls => vls.id === id)[0].table_name}</>
                        ) : (<div className='d-inline-block'>Drive Thru : คิวที่ {data.dataorder.filter(vls => vls.id === id)[0].table_reserve}</div>)
                        : null
                }
            </span>
        } else if (type === 'tableProduct') {
            return <span>
                {
                    data.dataProduct.filter(vls => vls.id === id)[0] ?
                        data.dataProduct.filter(vls => vls.id === id)[0].zone_name !== 0 ? (
                            <> {data.dataProduct.filter(vls => vls.id === id)[0].zone_name} : {data.dataProduct.filter(vls => vls.id === id)[0].table_name}</>
                        ) : (<div className='d-inline-block'>Drive Thru : คิวที่ {data.dataProduct.filter(vls => vls.id === id)[0].table_reserve}</div>)
                        : null
                }
            </span>
        } else {
            return <div>
                {
                    data.dataorder.filter(vls => vls.id === id)[0] ?
                        <>
                            <span className='font-20'><b>
                                {
                                    data.dataorder.filter(vls => vls.id === id)[0].zone_name !== 0 ? <>
                                        {data.dataorder.filter(vls => vls.id === id)[0].zone_name} :
                                        {data.dataorder.filter(vls => vls.id === id)[0].table_name}
                                    </>
                                        : (<div>Drive Thru : คิวที่ {data.dataorder.filter(vls => vls.id === id)[0].table_reserve}</div>)
                                }
                            </b></span>
                            <div className='weight-500 mt-2 font-14'>
                                Order #{data.dataorder.filter(vls => vls.id === id)[0].no}
                            </div>
                            <div className='weight-400 mt-1 text-gray font-14'>
                                วันที่ {format(new Date(data.dataorder.filter(vls => vls.id === id)[0].date_reserve), 'dd/MM/yyyy')}
                                - {data.dataorder.filter(vls => vls.id === id)[0].time_update}
                            </div>
                            <hr className='hr-custom' />
                            <div className='weight-500 mt-1'>รายการอาหาร</div>
                            <div className='mt-2 mb-4'>
                                <div className='mb-2'>
                                    <span className='font-14 pe-5'>
                                        {(data.dataorder.filter(vls => vls.id === id)[0].data_order).filter(vlss => vlss.id === id_order)[0].name_product}
                                        <span className='font-12 ms-3'>X</span> {(data.dataorder.filter(vls => vls.id === id)[0].data_order).filter(vlss => vlss.id === id_order)[0].qty}
                                    </span>

                                    {(data.dataorder.filter(vls => vls.id === id)[0].data_order).filter(vlss => vlss.id === id_order)[0].choice ?
                                        (JSON.parse((data.dataorder.filter(vls => vls.id === id)[0].data_order).filter(vlss => vlss.id === id_order)[0].choice)).map((val, inde) => (
                                            <div className='ms-3 pe-5' key={inde}>
                                                <div className='font-13'>- {val.more_name}</div>
                                            </div>
                                        ))
                                        : null}
                                </div>
                            </div>
                        </>
                        : null
                }
            </div>
        }

    }

    const Test = (datab) => {

        // console.log(datab)


        const output = [];

        // console.log(kitchendata.id)

        if (kitchendata && kitchendata.id !== '0') {

            for (let i = 0; i < data.dataorder.length; i++) {
                output.push(...data.dataorder[i].data_order.filter(vls => vls.kitchen_id === kitchendata.id))
            }
        } else {
            for (let i = 0; i < data.dataorder.length; i++) {
                output.push(...data.dataorder[i].data_order)
            }
        }

        return <>
            <Table striped bordered className='table-custom font-14'>
                <thead>
                    <tr>
                        <th style={{ width: "65px" }}>ลำดับที่ </th>
                        <th style={{ width: "80px" }}>เวลา </th>
                        <th><span className='border-left-table me-2'></span>นำส่ง</th>
                        <th><span className='border-left-table me-2'></span>รายการอาหาร</th>
                        {
                            datab.typeid === '3' ?
                                <>
                                    <th><span className='border-left-table me-2'></span>
                                        <div className="ex-checkbox4 d-inline-block">
                                            <input type="checkbox" id="checkbox_one" onChange={(event) => selectAllB(event.target.checked)} checked={checkedAllB} />
                                            <label className="checkbox_one mb-2 ps-4" style={{ width: "100%" }} >
                                                <span style={{ opacity: "1" }}>เลือกทั้งหมด</span>
                                            </label>
                                        </div>
                                    </th>
                                </>
                                :
                                <>
                                    <th><span className='border-left-table me-2'></span>เลือกส่งปรุง</th>
                                </>
                        }
                    </tr>
                </thead>
                {/* {console.log(output.filter(vls => vls.kitchen_id !== '5').filter(vls => vls.status === datab.typeid))} */}
                <tbody>
                    {

                        datab.typeid === '3' ? output.filter(vls => vls.kitchen_id !== '5').filter(vls => vls.status === datab.typeid).sort((a, b) => Moment(a.date_kitchen) - Moment(b.date_kitchen)).map((value2, index2) => (
                            <tr key={index2}>
                                <td>{index2 + 1}</td>
                                <td>{Moment(value2.date_kitchen).format('HH:mm')} น.</td>
                                <td>
                                    <span className='border-left-table me-2'></span>
                                    {checktablename(value2.id_order, 'table', 0)}
                                </td>
                                <td>
                                    <span className='border-left-table me-2'></span>
                                    {value2.name_product} <span className='font-12 ms-3'>X</span> {value2.qty}
                                    <span>
                                        {value2.choice ? (JSON.parse(value2.choice)).map((val, inde) => (
                                            <div className='ms-3 pe-5' key={inde}>
                                                <div className='font-13'>- {val.more_name}</div>
                                            </div>
                                        ))
                                            : null}
                                    </span>
                                    {value2.note ? <div className='ms-2 font-12 mt-2'>หมายเหตุ : {value2.note}</div> : null}
                                </td>
                                <td><span className='border-left-table me-2'></span>
                                    <div className="ex-checkbox4 d-inline-block">
                                        <input type="checkbox" id="checkbox_one" name="checkserve[]" value={value2.id} onChange={handleCheckboxB} checked={selectdataB.filter(vls => vls === value2.id).length ? true : false} required />
                                        <label className="checkbox_one mb-2 ps-4" style={{ width: "100%" }} >
                                            <span style={{ opacity: "0" }}>{value2.id}</span>
                                        </label>
                                    </div>
                                </td>
                            </tr>
                        ))
                            :
                            kitchendata.id === '0' ?
                                output.filter(vls => vls.kitchen_id !== '5').filter(vls => vls.status === datab.typeid).map((value2, index2) => (
                                    <tr key={index2} className=''>
                                        <td className='cursor-pointer' onClick={() => setSelectdata({ id: value2.id, id_order: value2.id_order })}>{index2 + 1}</td>
                                        <td>{Moment(value2.date_kitchen).format('HH:mm')} น.</td>
                                        <td>
                                            <span className='border-left-table me-2'></span>{checktablename(value2.id_order, 'table', 0)}
                                        </td>
                                        <td>
                                            <span className='border-left-table me-2'></span>99
                                            {value2.name_product} <span className='font-12 ms-3'>X</span> {value2.qty}
                                            <span>
                                                {value2.choice ? (JSON.parse(value2.choice)).map((val, inde) => (
                                                    <div className='ms-3 pe-5' key={inde}>
                                                        <div className='font-13'>- {val.more_name}</div>
                                                    </div>
                                                ))
                                                    : null}
                                            </span>
                                            {value2.note ? <div className='ms-2 font-12 mt-2'>หมายเหตุ : {value2.note}</div> : null}
                                        </td>
                                        <td><span className='border-left-table me-2'></span>
                                            <div className="ex-checkbox4 d-inline-block">
                                                <input type="checkbox" id="checkbox_one" name="checkserve[]" value={value2.id}
                                                    onChange={handleCheckbox} checked={selectdata.filter(vls => vls === value2.id).length ? true : false}/>
                                                <label className="checkbox_one mb-2 ps-4" style={{ width: "100%" }} >
                                                    <span style={{ opacity: "0" }}>{value2.id}</span>
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                                :
                                // id_order
                                output.filter(vls => vls.kitchen_id !== '5').filter(vls => vls.status === datab.typeid).filter(vls => vls.kitchen_id === kitchendata.id).map((value2, index2) => (
                                    <tr key={index2} className='cursor-pointer'>
                                        <td>{index2 + 1}</td>
                                        <td>{Moment(value2.date_kitchen).format('HH:mm')} น.</td>
                                        <td>
                                            <span className='border-left-table me-2'></span>{checktablename(value2.id_order, 'table', 0)}
                                        </td>
                                        <td>
                                            <span className='border-left-table me-2'></span>
                                            {value2.name_product} <span className='font-12 ms-3'>X</span> {value2.qty}
                                            <span>
                                                {value2.choice ? (JSON.parse(value2.choice)).map((val, inde) => (
                                                    <div className='ms-3 pe-5' key={inde}>
                                                        <div className='font-13'>- {val.more_name}</div>
                                                    </div>
                                                ))
                                                    : null}
                                            </span>
                                            {value2.note ? <div className='ms-2 font-12 mt-2'>หมายเหตุ : {value2.note}</div> : null}
                                        </td>
                                    </tr>

                                ))
                    }
                </tbody>
            </Table>
        </>
    }

    const clickrefresh = () => {

        setModalcancleproduct({ view: false, dataid: [] });
        setSelectdata([]);
        data.refresh();
    }

    const handlemodal = (type) => {
        // console.log(type)
        // const dataorder_no = data.dataorder.filter(vls => vls.id === selectdata.id_order)[0].no;
        // const dataorder_zone = data.dataorder.filter(vls => vls.id === selectdata.id_order)[0].zone_name;
        // const dataorder_table = data.dataorder.filter(vls => vls.id === selectdata.id_order)[0].table_name;
        // const dataproduct_name = data.dataorder.filter(vls => vls.id === selectdata.id_order)[0].data_order.filter(vlsb => vlsb.id === selectdata.id)[0].name_product;
        // const dataproduct_date = data.dataorder.filter(vls => vls.id === selectdata.id_order)[0].data_order.filter(vlsb => vlsb.id === selectdata.id)[0].date_kitchen;
        // const dataproduct_id = data.dataorder.filter(vls => vls.id === selectdata.id_order)[0].data_order.filter(vlsb => vlsb.id === selectdata.id)[0].id;
        // const dataproduct_qty = data.dataorder.filter(vls => vls.id === selectdata.id_order)[0].data_order.filter(vlss => vlss.id === selectdata.id)[0].qty;

        // setModalcancleproduct({ view: true, type: type, dataid: 
        //         {order_no: dataorder_no, 
        //         order_zone: dataorder_zone, 
        //         order_table: dataorder_table, 
        //         product: dataproduct_name, 
        //         product_id: dataproduct_id, 
        //         date_time: dataproduct_date, 
        //         qty: dataproduct_qty} })
        setModalcancleproduct({ view: true, type: type, dataid: { dataselect: selectdata, dataplang: data.dataplang, dataorder: data.dataorder } })

    }

    const handleSubmit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const dataf = new FormData(event.target);

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        // data2.append("id", data.data.product_id);
        dataf.append("type", "serve");

        AuthService.holdOrderlist(dataf).then(
            (res) => {
                //   console.log(res)   
                if (res.status === 200) {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: '',
                        text: "นำเสิร์ฟเรียบร้อยแล้ว",
                        showConfirmButton: false,
                        timer: 1500
                    }).then(function () {
                        data.refresh();
                        setCheckedAll();
                        setSelectdata([]);
                        setCheckedAllB();
                        setSelectdataB([]);
                    })
                } else {
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    const handlemodalordertable = (id) => {
        // alert(id)
        setCheckedAll();
        setSelectdata([]);
        setCheckedAllB();
        setSelectdataB([]);


        if (id === '0') {
            setKitchendata({ id: id, label: 'ออเดอร์ทั้งหมด' })
        } else if (id === '1') {
            setKitchendata({ id: id, label: 'ปิ้งย่าง' })
        } else if (id === '2') {
            setKitchendata({ id: id, label: 'ทอด' })
        } else if (id === '3') {
            setKitchendata({ id: id, label: 'ผัด' })
        } else if (id === '4') {
            setKitchendata({ id: id, label: 'ครัวเย็น' })
        } else if (id === '5') {
            setKitchendata({ id: id, label: 'เครื่องดื่ม' })
        }

    }

    const toggleDropdown = () => {
        if (showDropdown) {
            setShowDropdown(false);
        } else {
            setShowDropdown(true);
        }
    }

    // const [checked, setChecked] = useState([]);
    const handleCheckbox = (e) => {
        var updatedList = [...selectdata];
        if (e.target.checked) {
            updatedList = [...selectdata, e.target.value];
        } else {
            updatedList.splice(selectdata.indexOf(e.target.value), 1);
        }
        // setChecked(updatedList);
        setSelectdata(updatedList)
    }
    const handleCheckboxB = (e) => {
        var updatedList = [...selectdataB];
        if (e.target.checked) {
            updatedList = [...selectdataB, e.target.value];
        } else {
            updatedList.splice(selectdataB.indexOf(e.target.value), 1);
        }
        // setChecked(updatedList);
        setSelectdataB(updatedList)
    }

    const handleSubmitPrint = (event) => {

        // event.preventDefault();

        // const form = event.currentTarget;

        const data2 = new FormData();

        data2.append("id", JSON.stringify(selectdata));
        data2.append("type", 'print');

        AuthService.holdOrderlist(data2).then(
            (res) => {
                //   console.log(res)   
                if (res.status === 200) {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: '',
                        text: "ส่งออเดอร์กำลังปรุงแล้ว",
                        showConfirmButton: false,
                        timer: 1500
                    }).then(function () {
                        clickrefresh()
                        setCheckedAll();
                        setSelectdata([]);
                        setCheckedAllB();
                        setSelectdataB([]);
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    };

    const printSuccess = (e) => {
        // alert(e)
        // console.log(e)
        // datas.onHide()
        handleSubmitPrint()
    }

    const selectAll = (value) => {
        setCheckedAll(value);
        // setChecked((prevState) => {
        //   const newState = { ...prevState };
        //   for (const inputName in newState) {
        //     newState[inputName] = value;
        //   }
        //   return newState;
        // });

        // data.dataplang.filter(vls => vls.status === '2').map(())
        let arrayAll = []
        if (value) {
            // value ? 
            if (kitchendata.id === '0') {
                const listItems = data.dataplang.filter(vls => vls.status === '2').map((number) =>
                    arrayAll.push(number['id'])
                )
            } else {
                const listItems = data.dataplang.filter(vls => vls.status === '2').filter(vls => vls.kitchen_id === kitchendata.id).map((number) =>
                    arrayAll.push(number['id'])
                )
            }

            setSelectdata(arrayAll)
        } else {
            setSelectdata([])
        }

        // console.log(arrayAll)
    };

    const selectAllB = (value) => {
        setCheckedAllB(value);

        let arrayAll = []
        if (value) {
            // value ? 
            if (kitchendata.id === '0') {
                const listItems = data.dataplang.filter(vls => vls.status === '3').map((number) =>
                    arrayAll.push(number['id'])
                )
            } else {
                const listItems = data.dataplang.filter(vls => vls.status === '3').filter(vls => vls.kitchen_id === kitchendata.id).map((number) =>
                    arrayAll.push(number['id'])
                )
            }

            setSelectdataB(arrayAll)
        } else {
            setSelectdataB([])
        }
    };

    // console.log(selectdataB)

    return (
        <>
            <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                    <Tab.Content className="mt-3">
                        <Tab.Pane eventKey="kitchen_all">
                            <div className='border_hr ' >
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12 text-start" >
                                        <div className='w-100 mb-2'>
                                            <div className="w-100 text-white py-3">
                                                {/* <DropdownButton show={showDropdown} onClick={() => toggleDropdown()} id="dropdown-item-button" className='w-100' title={kitchendata.label}>
                                                    <Dropdown onClick={(e) => handlemodalordertable('0')} className='px-3 py-1 cursor' >ออเดอร์ทั้งหมด</Dropdown>
                                                    <Dropdown onClick={(e) => handlemodalordertable('1')} className='px-3 py-1 cursor' >ปิ้งย่าง</Dropdown>
                                                    <Dropdown onClick={(e) => handlemodalordertable('2')} className='px-3 py-1 cursor' >ทอด</Dropdown>
                                                    <Dropdown onClick={(e) => handlemodalordertable('3')} className='px-3 py-1 cursor' >ผัด</Dropdown>
                                                    <Dropdown onClick={(e) => handlemodalordertable('4')} className='px-3 py-1 cursor' >ครัวเย็น</Dropdown>
                                                </DropdownButton> */}
                                                <div>
                                                    <div className="ex-radio d-inline-block">
                                                        <input type="radio" onChange={(e) => handlemodalordertable('0')} checked={kitchendata.id === '0' && true} />
                                                        <span className="radio_one mb-2 ps-0" style={{ width: "100%" }} >
                                                            <span style={{ opacity: "1", color: '#2E384D', fontWeight: "500" }}>ทั้งหมด</span>
                                                        </span>
                                                    </div>
                                                    <div className="ex-radio d-inline-block ms-4">
                                                        <input type="radio" onChange={(e) => handlemodalordertable('1')} checked={kitchendata.id === '1' && true} />
                                                        <span className="radio_one mb-2 ps-0" style={{ width: "100%" }} >
                                                            <span style={{ opacity: "1", color: '#000000', fontWeight: "500" }}>ปิ้งย่าง</span>
                                                        </span>
                                                    </div>
                                                    <div className="ex-radio kitfry d-inline-block ms-4">
                                                        <input type="radio" onChange={(e) => handlemodalordertable('2')} checked={kitchendata.id === '2' && true} />
                                                        <span className="radio_one mb-2 ps-0" style={{ width: "100%" }} >
                                                            <span style={{ opacity: "1", color: '#E0484C', fontWeight: "500" }}>ทอด</span>
                                                        </span>
                                                    </div>
                                                    <div className="ex-radio puff d-inline-block ms-4">
                                                        <input type="radio" onChange={(e) => handlemodalordertable('3')} checked={kitchendata.id === '3' && true} />
                                                        <span className="radio_one mb-2 ps-0" style={{ width: "100%" }} >
                                                            <span style={{ opacity: "1", color: '#7BD676', fontWeight: "500" }}>ผัด</span>
                                                        </span>
                                                    </div>
                                                    <div className="ex-radio kitcool d-inline-block ms-4">
                                                        <input type="radio" onChange={(e) => handlemodalordertable('4')} checked={kitchendata.id === '4' && true} />
                                                        <span className="radio_one mb-2 ps-0" style={{ width: "100%" }} >
                                                            <span style={{ opacity: "1", color: '#3A7BEF', fontWeight: "500" }}>ครัวเย็น</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='h-100per-right-ff bg-white rounded-3 '>
                                            <div className='m-2'>
                                                {/* <Test typeid="2" /> */}
                                                <Table striped bordered className='table-custom font-14'>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: "65px" }}>ลำดับที่ </th>
                                                            <th style={{ width: "80px" }}>เวลา </th>
                                                            <th><span className='border-left-table me-2'></span>นำส่ง</th>
                                                            <th><span className='border-left-table me-2'></span>รายการอาหาร</th>
                                                            <th><span className='border-left-table me-2'></span>
                                                                <div className="ex-checkbox4 d-inline-block">
                                                                    <input type="checkbox" id="checkbox_one" onChange={(event) => selectAll(event.target.checked)} checked={checkedAll} />
                                                                    <label className="checkbox_one mb-2 ps-4" style={{ width: "100%" }} >
                                                                        <span style={{ opacity: "1" }}>เลือกทั้งหมด</span>
                                                                    </label>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {/* {console.log(data.dataplang)} */}
                                                        {data.dataplang ? data.dataplang.length ?

                                                            kitchendata.id === '0' ?
                                                                data.dataplang.filter(vls => vls.status === '2').sort((a, b) => Moment(a.date_kitchen) - Moment(b.date_kitchen)).map((value2, index2) => (
                                                                    <tr key={index2} className=''>
                                                                        <td className='cursor-pointer' onClick={() => setSelectdata({ id: value2.id, id_order: value2.id_order })}>{index2 + 1}</td>
                                                                        <td>{Moment(value2.date_kitchen).format('HH:mm')} น.</td>
                                                                        <td>
                                                                            <span className='border-left-table me-2'></span>{checktablename(value2.id_order, 'table', 0)}
                                                                        </td>
                                                                        <td>
                                                                            <span className='border-left-table me-2'></span>
                                                                            {value2.name_product} <span className='font-12 ms-3'>X</span> {value2.qty}
                                                                            <span>
                                                                                {value2.choice ? (JSON.parse(value2.choice)).map((val, inde) => (
                                                                                    <div className='ms-3 pe-5' key={inde}>
                                                                                        <div className='font-13'>- {val.more_name}</div>
                                                                                    </div>
                                                                                ))
                                                                                    : null}
                                                                            </span>
                                                                            {value2.note ? <div className='ms-2 font-12 mt-2'>หมายเหตุ : {value2.note}</div> : null}
                                                                        </td>
                                                                        <td><span className='border-left-table me-2'></span>
                                                                            <div className="ex-checkbox4 d-inline-block">
                                                                                <input type="checkbox" id="checkbox_one" name="checkserve[]" value={value2.id}
                                                                                    onChange={handleCheckbox} checked={selectdata.filter(vls => vls === value2.id).length ? true : false} />
                                                                                <label className="checkbox_one mb-2 ps-4" style={{ width: "100%" }} >
                                                                                    <span style={{ opacity: "0" }}>1</span>
                                                                                </label>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                                :
                                                                data.dataplang.filter(vls => vls.status === '2').filter(vls => vls.kitchen_id === kitchendata.id).sort((a, b) => Moment(a.date_kitchen) - Moment(b.date_kitchen)).map((value2, index2) => (
                                                                    <tr key={index2} className='cursor-pointer'>
                                                                        <td>{index2 + 1}</td>
                                                                        <td>{Moment(value2.date_kitchen).format('HH:mm')} น.</td>
                                                                        <td>
                                                                            <span className='border-left-table me-2'></span>{checktablename(value2.id_order, 'table', 0)}
                                                                        </td>
                                                                        <td>
                                                                            <span className='border-left-table me-2'></span>
                                                                            {value2.name_product} <span className='font-12 ms-3'>X</span> {value2.qty}
                                                                            <span>
                                                                                {value2.choice ? (JSON.parse(value2.choice)).map((val, inde) => (
                                                                                    <div className='ms-3 pe-5' key={inde}>
                                                                                        <div className='font-13'>- {val.more_name}</div>
                                                                                    </div>
                                                                                ))
                                                                                    : null}
                                                                            </span>
                                                                            {value2.note ? <div className='ms-2 font-12 mt-2'>หมายเหตุ : {value2.note}</div> : null}
                                                                        </td>
                                                                        <td><span className='border-left-table me-2'></span>
                                                                            <div className="ex-checkbox4 d-inline-block">
                                                                                <input type="checkbox" id="checkbox_one" name="checkserve[]" value={value2.id}
                                                                                    onChange={handleCheckbox} checked={selectdata.filter(vls => vls === value2.id).length ? true : false} />
                                                                                <label className="checkbox_one mb-2 ps-4" style={{ width: "100%" }} >
                                                                                    <span style={{ opacity: "0" }}>1</span>
                                                                                </label>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                                ))
                                                            : <tr>
                                                                <td colSpan={5} className="text-center">
                                                                    <div>-- ไม่มีออเดอร์เข้าครัว --</div>
                                                                </td>
                                                            </tr>
                                                            : <tr>
                                                                <td colSpan={5} className="text-center">
                                                                    <div>-- Loading --</div>
                                                                </td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                        <div className='w-100 ps-2 mt-3'>
                                            <div className='row'>
                                                <div className="col-12 col-md-12 col-lg-12 text-end">
                                                    <ReactToPrint
                                                        trigger={() => <button className="btn btn-blue text-white py-2 px-5" disabled={selectdata.length ? false : true}>ส่งออเดอร์กำลังปรุง</button>}
                                                        content={() => componentRef.current}
                                                        documenttTitle='name document'
                                                        onAfterPrint={() => printSuccess('yes')}
                                                    />
                                                    <div className='position-absolute d-none'>
                                                        <div className="ticket" ref={componentRef} >
                                                            <div className="" />
                                                            <ComponentToorder
                                                                plang={data.dataplang}
                                                                order={data.dataorder}
                                                                select={selectdata}
                                                            />
                                                        </div>
                                                    </div>

                                                    {/* <button onClick={() => handlemodal("print")} disabled={selectdata.length ? false : true} className="btn btn-blue text-white py-2 px-5">
                                                        ส่งออเดอร์กำลังปรุง
                                                    </button> */}
                                                    {/* <button onClick={() => handlemodal("cancle")} disabled={selectdata.length ? false : true} className="btn btn-border-red py-2 ms-3" >
                                                            ยกเลิกออเดอร์
                                                        </button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* 
                                    <div className="col-12 col-md-4 col-lg-4 ps-0">
                                        <div className="bg-white rounded-3 ms-2 h-100per-right-f position-relative">
                                            <div className='px-4'>
                                                <div className='position-relative'>
                                                    <div className='text-start mt-4'>

                                                        {selectdata ? checktablename(selectdata.id_order, 'right', selectdata.id) : null}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                     */}
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="serve_all">
                            <Form noValidate onSubmit={handleSubmit} className="" ref={formRef}>
                                <div className='border_hr ' >
                                    <div className="row">
                                        <div className="col-12 col-md-12 col-lg-12 text-start" >
                                            <div className='w-100 mb-2'>
                                                <div className="w-100 text-white py-3">
                                                    {/* <DropdownButton show={showDropdown} onClick={() => toggleDropdown()} id="dropdown-item-button" className='w-100' title={kitchendata.label}>
                                                        <Dropdown onClick={(e) => handlemodalordertable('0')} className='px-3 py-1 cursor' >ออเดอร์ทั้งหมด</Dropdown>
                                                        <Dropdown onClick={(e) => handlemodalordertable('1')} className='px-3 py-1 cursor' >ปิ้งย่าง</Dropdown>
                                                        <Dropdown onClick={(e) => handlemodalordertable('2')} className='px-3 py-1 cursor' >ทอด</Dropdown>
                                                        <Dropdown onClick={(e) => handlemodalordertable('3')} className='px-3 py-1 cursor' >ผัด</Dropdown>
                                                        <Dropdown onClick={(e) => handlemodalordertable('4')} className='px-3 py-1 cursor' >ครัวเย็น</Dropdown>
                                                        
                                                    </DropdownButton> */}
                                                    <div>
                                                        <div className="ex-radio d-inline-block">
                                                            <input type="radio" onChange={(e) => handlemodalordertable('0')} checked={kitchendata.id === '0' && true} />
                                                            <span className="radio_one mb-2 ps-0" style={{ width: "100%" }} >
                                                                <span style={{ opacity: "1", color: '#2E384D', fontWeight: "500" }}>ทั้งหมด</span>
                                                            </span>
                                                        </div>
                                                        <div className="ex-radio d-inline-block ms-4">
                                                            <input type="radio" onChange={(e) => handlemodalordertable('1')} checked={kitchendata.id === '1' && true} />
                                                            <span className="radio_one mb-2 ps-0" style={{ width: "100%" }} >
                                                                <span style={{ opacity: "1", color: '#000000', fontWeight: "500" }}>ปิ้งย่าง</span>
                                                            </span>
                                                        </div>
                                                        <div className="ex-radio kitfry d-inline-block ms-4">
                                                            <input type="radio" onChange={(e) => handlemodalordertable('2')} checked={kitchendata.id === '2' && true} />
                                                            <span className="radio_one mb-2 ps-0" style={{ width: "100%" }} >
                                                                <span style={{ opacity: "1", color: '#E0484C', fontWeight: "500" }}>ทอด</span>
                                                            </span>
                                                        </div>
                                                        <div className="ex-radio puff d-inline-block ms-4">
                                                            <input type="radio" onChange={(e) => handlemodalordertable('3')} checked={kitchendata.id === '3' && true} />
                                                            <span className="radio_one mb-2 ps-0" style={{ width: "100%" }} >
                                                                <span style={{ opacity: "1", color: '#7BD676', fontWeight: "500" }}>ผัด</span>
                                                            </span>
                                                        </div>
                                                        <div className="ex-radio kitcool d-inline-block ms-4">
                                                            <input type="radio" onChange={(e) => handlemodalordertable('4')} checked={kitchendata.id === '4' && true} />
                                                            <span className="radio_one mb-2 ps-0" style={{ width: "100%" }} >
                                                                <span style={{ opacity: "1", color: '#3A7BEF', fontWeight: "500" }}>ครัวเย็น</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='h-100per-right-ff bg-white rounded-3 '>
                                                <div className='m-2'>
                                                    <Test typeid="3" />
                                                </div>
                                            </div>
                                            <div className='w-100 ps-2 mt-2'>
                                                <div className='row justify-content-end'>
                                                    <div className="col-4 col-md-4 col-lg-4">
                                                        <button className="w-100 btn btn-submit text-white py-2 " >
                                                            เสิร์ฟ
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </Tab.Pane>
                        <Tab.Pane eventKey="order_all">
                            <div className='border_hr ' >
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12 text-start" >
                                        <div className='h-100per bg-white rounded-3 '>
                                            <div className='m-2'>
                                                {/* <Test typeid="2" /> */}
                                                <Table striped bordered className='table-custom font-14'>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: "65px" }}>ลำดับที่ </th>
                                                            <th style={{ width: "80px" }}>เวลา </th>
                                                            <th><span className='border-left-table me-2'></span>นำส่ง</th>
                                                            <th><span className='border-left-table me-2'></span>รายการอาหาร</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            data.dataProduct.filter(vls => vls.kitchen_id !== '5').sort((a, b) => Moment(b.date_serve) - Moment(a.date_serve)).map((value2, index2) => (
                                                                <tr key={index2} className=''>
                                                                    <td className='cursor-pointer' onClick={() => setSelectdata({ id: value2.id, id_order: value2.id_order })}>{index2 + 1}</td>
                                                                    <td>{Moment(value2.date_serve).format('HH:mm')} น.</td>
                                                                    <td>
                                                                        <span className='border-left-table me-2'></span>{checktablename(value2.id, 'tableProduct', 0)}
                                                                    </td>
                                                                    <td>
                                                                        <span className='border-left-table me-2'></span>
                                                                        {value2.name_product} <span className='font-12 ms-3'>X</span> {value2.qty}
                                                                        <span>
                                                                            {value2.choice ? (JSON.parse(value2.choice)).map((val, inde) => (
                                                                                <div className='ms-3 pe-5' key={inde}>
                                                                                    <div className='font-13'>- {val.more_name}</div>
                                                                                </div>
                                                                            ))
                                                                                : null}
                                                                        </span>
                                                                        {value2.note ? <div className='ms-2 font-12 mt-2'>หมายเหตุ : {value2.note}</div> : null}
                                                                    </td>
                                                                </tr>
                                                            ))

                                                        }
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </div>
                {/* {console.log(modalcancleproduct)} */}

            </div>
            {modalcancleproduct.view ?
                <Modalcancleproduct show={modalcancleproduct.view} onHide={clickrefresh} type={modalcancleproduct.type} data={modalcancleproduct.dataid} />
                : null}
        </>
    );
};

export default Kitchentab;