import React, { useState, useRef } from 'react';
import { Table, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { compareAsc, format } from 'date-fns';

// import { BrowserRouter as Router } from "react-router-dom";
// import axios from 'axios';
// import { Link } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
// import Rang from '../components/lang';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";


import ipdf from '../../assets/img/i-pdf.svg';
import iexcel from '../../assets/img/i-excel.svg';


const Modalwastereport = (datas) => {

    let history = useNavigate();

    const toHHMMSS = (time) => {
        var sec_num = parseInt(time, 10); // don't forget the second param
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);

        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        if (seconds < 10) { seconds = "0" + seconds; }
        // return hours+':'+minutes+':'+seconds;
        return hours + ':' + minutes;
    }

    const sumOt = (content) => {
        return content.reduce((prev, current) => prev + +current.ot_sum, 0)
    };

    console.log((datas.datawaste))

    return (
        <>
            <Modal {...datas} className="modal-custom" size="lg" backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton className="btn-close-white ">

                </Modal.Header>
                <Modal.Body className="p-0">
                    <div className="d-block w-100 scroll-custom">

                        <Modal.Title className='py-3 bg-white px-4 shadow-sm position-fixed w-100' style={{ zIndex: "1" }}>
                            <div className='text-default font-22'>
                                รายงานการทำของเสีย
                            </div>
                            <div className='font-14 text-gray weight-300'>
                                {(datas.datawaste).length === 1 ? datas.datawaste[0].name + " |" : null}  {datas.datestart} - {datas.dateend}
                            </div>

                            <div className='position-absolute font-16' style={{ right: "15px", top: "25px" }}>
                                นำออกเป็น
                                <span className='ms-2 me-1'>
                                    <img src={ipdf} />
                                </span>
                                <span>
                                    <img src={iexcel} />
                                </span>
                            </div>
                        </Modal.Title>
                        <div className='h-100per-modal bg-white px-4 py-5'>
                            <div className='mt-5 pt-3'>

                                <Table bordered className='table-report'>
                                    <thead>
                                        <tr>
                                            <th rowSpan={2} style={{verticalAlign:"middle"}}>ชื่อพนักงาน</th>
                                            <th colSpan={7} className="py-3">รายละเอียด</th>
                                        </tr>
                                        <tr>
                                            <th className="py-3">วันที่</th>
                                            <th className="py-3">ประเภท</th>
                                            <th className="py-3">หมวดหมู่</th>
                                            <th className="py-3">รายการ</th>
                                            <th className="py-3">จำนวน</th>
                                            <th className="py-3">หน่วย</th>
                                            <th className="py-3">เหตุผล</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {datas.datawaste.filter(valv => valv.table_waste.length).map((val, index) => (
                                            <tr key={index}>
                                                <td>
                                                    {val.name}
                                                </td>
                                                <td>
                                                    {
                                                        val.table_waste.length ? val.table_waste.map((val2, index2) => (
                                                            <div key={index2} className="mb-1">
                                                                {format(new Date(val2.date_create), 'dd/MM/yyyy')}
                                                            </div>
                                                        ))
                                                            : <>-</>
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        val.table_waste.length ? val.table_waste.map((val2, index2) => (
                                                            <div key={index2} className="mb-1">
                                                                {(val2.type_product)}
                                                            </div>
                                                        ))
                                                            : <>-</>
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        val.table_waste.length ? val.table_waste.map((val2, index2) => (
                                                            <div key={index2} className="mb-1">
                                                                {(val2.cat_product)}
                                                            </div>
                                                        ))
                                                            : <>-</>
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        val.table_waste.length ? val.table_waste.map((val2, index2) => (
                                                            <div key={index2} className="mb-1">
                                                                {val2.name_product}
                                                            </div>
                                                        ))
                                                            : <>-</>
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        val.table_waste.length ? val.table_waste.map((val2, index2) => (
                                                            <div key={index2} className="mb-1">
                                                                {val2.qty}
                                                            </div>
                                                        ))
                                                            : <>-</>
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        val.table_waste.length ? val.table_waste.map((val2, index2) => (
                                                            <div key={index2} className="mb-1">
                                                                {val2.unit}
                                                            </div>
                                                        ))
                                                            : <>-</>
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        val.table_waste.length ? val.table_waste.map((val2, index2) => (
                                                            <div key={index2} className="mb-1">
                                                                {val2.note}
                                                            </div>
                                                        ))
                                                            : <>-</>
                                                    }
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                            </div>
                        </div>
                        {/* <div className='bg-white px-4 py-3 text-end'>
                            <div className='font-12 text-gray weight-300'>
                                {(datas.datawaste).length === 1 ? datas.datawaste[0].name + " |" : null}  {datas.datestart} - {datas.dateend}
                            </div>
                            <div className='font-14 weight-400 my-1'>
                                รวมเวลางาน <span className='d-inline-block' style={{ width: "100px" }}>70 ชั่วโมง</span>
                            </div>
                            <div className='font-14 weight-400'>

                                รวม OT <span className='d-inline-block' style={{ width: "100px" }}>{sumOt(datas.datawaste)} ชั่วโมง</span>
                            </div>
                        </div> */}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Modalwastereport;