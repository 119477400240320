import React, { useState,useEffect,useCallback } from 'react';
import { Form, Col, Row } from "react-bootstrap";

import { useNavigate,Link } from "react-router-dom";

import AuthService from "../../components/services_member/auth.service";
import PostService from "../../components/services_member/post.service";

import back from '../../assets/img/icon-back.svg';

import Loadingpage from "../../components/loading_page";

const Addbusiness = () => {

    let history = useNavigate();

    const [validated, setValidated] = useState(false);
    const [loadingpage, setLoadingpage] = useState(false);

    const [dataprovince, setDataprovince] = useState([]);

    // const getMemid = (respone) => {
    //     if(respone){
    //         setMemberid(respone.data.profile);
    //     }
    // }
    const user = AuthService.getCurrentUser();

    if (!user) {
        history("/");
    }

    const fetchData = useCallback(async () => {
        PostService.getAllProvince().then(
            (response) => {
                if (response.data.status === 200) {
                    setDataprovince(response.data.data);
                }
            },
            (error) => {
                console.log(error.response)
            }
        )
    });

    useEffect(() => {
        fetchData()
    }, []);

    const handleSubmit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const data = new FormData(event.target);

        const member_id = JSON.parse(localStorage.getItem("member_data"));

        data.append("member_idi", member_id.id);
        data.append("email", member_id.email);

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

        } else {
            setLoadingpage(true);

            AuthService.registerbusiness(data).then(
                (res) => {
                    //   console.log(res)   
                    if (res.status === 200) {
                        // localStorage.removeItem("register");

                        localStorage.setItem('businessdata', JSON.stringify(res.data));

                        history("/businesspin");
                        window.location.reload();
                    } else {
                        // setErrors({email:"This email cannot be used.!"})
                    }
                },
                (error) => {
                    console.log(error);
                    // setErrors(true)
                }
            );
        }

        setValidated(true);
    };

    // console.log(memberid.data)

    return (
        <>
             { loadingpage ?
                <Loadingpage timeout="10000"/>
                : "" 
            }
            <div className="wrapper" style={{ backgroundColor: "#EAEAEA", zIndex: "1" }}>
                <section className="" >
                    <div className="container-fluid">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-12 col-md-7 col-xl-4 text-start h-100vh position-relative">
                                <div className='position-absolute w-100 position-center px-3'>
                                    <div className='box-shadow pb-4 bg-white'>
                                        <div className='bg-topic text-white p-3'>
                                            <h4 className='weight-400 m-0'>
                                                <Link to="/business" transition="default-fade" className="text-decoration-none">
                                                    <img src={back} alt="" className="me-3" />
                                                </Link>
                                                เพิ่มกิจการ
                                            </h4>
                                        </div>
                                        <Form noValidate validated={validated} onSubmit={handleSubmit} className="px-5 mt-4">
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md="12" className="position-relative mt-3" controlId="validationCustom01">
                                                    <Form.Label className='text-gray mb-1 font-14'>ชื่อบริษัท</Form.Label>
                                                    <Form.Control required type="text" className="" placeholder="xxxxxxxx" name="name" />
                                                </Form.Group>
                                                <Form.Group as={Col} md="12" className="position-relative mt-3" controlId="validationCustom01">
                                                    <Form.Label className='text-gray mb-1 font-14'>เลขประจำตัวผู้เสียภาษี</Form.Label>
                                                    <Form.Control required type="text" className="" placeholder="xxxxxxxxxxxxx" name="idcard_number"
                                                        maxLength={13} pattern="([0-9]){13,}"
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="12" className="position-relative mt-3" controlId="validationCustom01">
                                                    <Form.Label className='text-gray mb-1 font-14'>ที่ตั้ง</Form.Label>
                                                    <Form.Select id="disabledSelect" name="location" required>
                                                        { dataprovince.map((e, i) => (
                                                        <option value={e.id} key={i}>{e.name_th}</option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Row>
                                            <button type="submit" className="w-100 btn btn-bg text-white mt-5">ถัดไป</button>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        </>
    );
};

export default Addbusiness;