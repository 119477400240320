import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button } from "react-bootstrap";

// import { Form, Tab, Nav, Row, Col, Accordion, ListGroup, Badge } from "react-bootstrap";

import AuthService from "../../components/services_member/auth.service";
// import PostService from "../../components/services_member/post.service";

import chold from '../../assets/img/confirm-hold.svg';

const Modalconfirmhold = (datas) => {

    // console.log(datas.id)

    const submithold = (id) => {
        // alert(e)
        const data2 = new FormData();

        data2.append("id", id);
        data2.append("type", "hold");

        AuthService.holdOrderlist(data2).then(
            (res) => {
                if (res.status === 200) {
                    // window.location.reload();
                    datas.onHide();
                } else {
                    console.log(res.message)
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    return (
        <>
            <Modal {...datas} className="modal-custom" size="md" backdrop="static" keyboard={false}>
                <Modal.Header closeButton className="btn-close-white ">

                </Modal.Header>
                <Modal.Body className="p-0 bg-white">
                    <div className="d-block w-100 overflow-hidden">

                        <div className='pt-5 pb-4 bg-white px-4 text-center'>

                            <div><img src={chold} alt="" className="" style={{ width: "50px" }} /></div>
                            <div className='font-16 weight-500 mt-4 text-gray'>#{datas.no}</div>
                            <div className='font-18 weight-500 mt-1'>ยืนยันการสั่งค้างไว้</div>
                            <div className='text-center px-3 mt-4'>
                                <div className='row'>
                                    <div className="col-12 col-md-6 col-lg-6 pe-1">
                                        <button className="w-100 btn btn-calcel text-white py-2 me-3" onClick={datas.onHide}>
                                            ยกเลิก
                                        </button>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 ps-1">
                                        <button type='submit' onClick={() => submithold(datas.id)} className="w-100 btn btn-bg text-white py-2 me-3">
                                            ยืนยัน
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>                       
                    </div>
                </Modal.Body>
            </Modal>

        </>
    );
};

export default Modalconfirmhold;