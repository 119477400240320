import React, { useState } from 'react';
// import axios from 'axios';


import help from '../../assets/img/help.svg';
import setting from '../../assets/img/report.svg';

import Menuleft from "../../layout/pagesadmin/menuleft";
import Menureport from "../../components/report/report_menu";


import { Nav } from "react-bootstrap";
// import { Form, Col, Row, InputGroup, Button } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
const Report = () => {
    const { t } = useTranslation();
    localStorage.removeItem("table_order");

    return (
        <>
        <div className={!t('menu-a') ? "sidebar-mini sidebar-collapse" : ""} >
            <div className='wrapper-admin layout-fixed'>
                <nav className="main-header navbar bg-white shadow-sm" style={{ height: "60px" }}>
                    <Nav as="ul" variant="tabs" className="nav nav-tabs overflow-hidden ul-tabcustom">

                    </Nav>
                    <ul className="navbar-nav ml-auto" style={{ marginTop: "-20px" }}>
                        <li className="">
                            <div className='text-gray font-12'><img src={help} alt="" className="me-1" style={{ width: "14px" }} /> ขอความช่วยเหลือ</div>
                        </li>
                    </ul>
                </nav>

                <Menuleft type="report" />

                <div className="content-wrapper">
                    <section className="content scroll-custom">
                        <div className="row">
                            <div className="col-12 col-md-5 col-lg-5">
                                <div className='text-start box-setting bg-white rounded-3 mt-3 h-100per'>
                                    <Menureport type="report"/>
                                </div>
                            </div>
                            <div className="col-12 col-md-7 col-lg-7 ps-0">
                                <div className="bg-white rounded-3 ms-2 mt-3 h-100per position-relative">
                                    <div className='position-absolute w-100 px-4 pb-3 position-center'>
                                        <img src={setting} alt="" className="" />
                                        <div className='mt-3 font-18 weight-500'>เริ่มต้นการดูรายงาน</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            </div>
        </>
    );
};

export default Report;