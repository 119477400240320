import React, { useState, useCallback, useEffect, useRef } from 'react';
// import axios from 'axios';
import { useNavigate,Link } from "react-router-dom";
import { compareAsc, format } from 'date-fns';

import { Form, Tab, Nav, Row, Col, Table } from "react-bootstrap";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import AuthService from "../../components/services_member/auth.service";
import PostService from "../../components/services_member/post.service";

import Menuleft from "../../layout/pagesadmin/menuleft";

import help from '../../assets/img/help.svg';
import btnremove from '../../assets/img/btn-remove.svg';
import calendar from '../../assets/img/calendar.svg';
import iconedit from '../../assets/img/icon-edit.svg';
import icondel from '../../assets/img/icon-del.svg';

import Modalconfirmwaste from "../../layout/modal/modalConfirwarehousecreate";
import { useTranslation } from 'react-i18next';
const Warehousemove = () => {
    const { t } = useTranslation();
    const MySwal = withReactContent(Swal)

    localStorage.removeItem("table_order");

    let history = useNavigate();
    const user = AuthService.getCurrentUser();
    const branch = AuthService.getCurrentBranch();

    if (!user) {
        history("/");
    } else {
        if (!branch) {
            history("/branch");
        }
    }

    const [dateday, setDateday] = useState(format(new Date(), 'dd/MM/yyyy'));

    const [datawaste, setDatawaste] = useState([]);
    // const [datawasteadd, setDatawasteadd] = useState([]);
    const [datawastemove, setDatawastemove] = useState([]);
    // const [datacat, setDatacat] = useState([]);
    // const [dataemployee, setDataemployee] = useState([]);

    const [modalconfirmwaste, setModalconfirmwaste] = useState({ view: false, id: 0 });

    const fetchData = useCallback(async (dd) => {

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        PostService.getWarehouseall(branch.id, dd).then(
            (response) => {
                if (response.data.status === 200) {

                    // console.log(response.data)
                    setDatawaste(response.data.waste);
                    setDatawastemove(response.data.waste_move);
                    // setDatacat(response.data.cat);
                    // setDataemployee(response.data.employee)
                    // setDataproduct(response.data.data_product);
                    // setDatapromotion(response.data.data_promotion);
                } else {
                    setDatawaste([]);
                }
            },
            (error) => {
                console.log(error);
            }
        )
    });

    useEffect(() => {
        fetchData(dateday);
    }, []);

    const dateoption = {
        locale: {
            format: 'DD/MM/YYYY'
        },
        startDate: dateday,
        maxDate: dateday,
        singleDatePicker: true,
        autoApply: true,
    };

    const handledateday = (event) => {
        fetchData(event.target.value)
        setDateday(event.target.value)
        formRef.current.reset();
        // setOrderview()
        // handlerefresh()
        // console.log(event.target.value)
    }

    const handlerefresh = (event) => {

        setModalconfirmwaste({ view: false, dataform: false })
        fetchData(dateday);
        formRef.current.reset();
        setValidated(false);
    }

    const [validated, setValidated] = useState(false);
    const formRef = useRef(null);

    const handleSubmit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const data = new FormData(event.target);

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        data.append("branch_id", branch.id);
        data.append("date_create", dateday);
        data.append("type", "add");

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

        } else {
            // setModalconfirmwaste({ view: true, dataform: data, type: "add", title: "ยืนยันการเพิ่มสินค้า" });
            event.preventDefault();

            AuthService.updateWarehousemove(data).then(
                (res) => {
                    if (res.status === 200) {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: '',
                            text: "อัพเดทรายการเรียบร้อยแล้ว",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(function () {
                            handlerefresh()
                        })

                    } else {
                        console.log(res.message)
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        }
        setValidated(true);
    };

    console.log(datawaste)

    return (
        <>
            <div className={!t('menu-a') ? "sidebar-mini sidebar-collapse" : ""} >
                <div className='wrapper-admin layout-fixed'>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="food_all">
                        <nav className="main-header navbar bg-white shadow-sm" style={{ height: "60px" }}>
                            <Nav as="ul" className="nav overflow-hidden " style={{ marginTop: "-4px" }}>
                                <li>
                                    <div className="bg-white rounded-3 ms-2 position-relative">
                                        <div className=''>
                                            <div className='text-start py-3 weight-500 d-inline-block me-3'>วันที่</div>
                                            <div className='position-relative d-inline-block'>
                                                <Form.Group controlId="dob">
                                                    <DateRangePicker initialSettings={dateoption} onApply={(e) => (handledateday(e))}>
                                                        <input type="text" defaultValue={dateday} className="form-control px-2 py-2" name="dateday" />
                                                    </DateRangePicker >
                                                    <div className='position-absolute' style={{ top: "10px", right: "12px" }}>
                                                        <img src={calendar} alt="" className="" style={{ width: "17px" }} />
                                                    </div>
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </Nav>
                            <ul className="navbar-nav ml-auto" style={{ marginTop: "-20px" }}>
                                <li className="">
                                    <div className='text-gray font-12'>
                                        <img src={help} alt="" className="me-1" style={{ width: "14px" }} /> ขอความช่วยเหลือ
                                    </div>
                                </li>
                            </ul>
                        </nav>
                        <Menuleft type="warehouse" sub="warehousemove" />
                        <div className="content-wrapper">
                            <section className="content scroll-custom">
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <Form noValidate validated={validated} onSubmit={handleSubmit} ref={formRef}>
                                            <div className="bg-white rounded-3 mt-3 h-100per-right position-relative">
                                                <div className='text-start p-2'>
                                                    <Table striped bordered className='table-custom'>
                                                        <thead>
                                                            <tr>
                                                                <th className='font-16' style={{ width: "100px" }}>SKU</th>
                                                                <th className='font-16'><span className='border-left-table me-2'></span>รายการสินค้า</th>
                                                                {/* <th className='font-18'><span className='border-left-table me-2'></span>จำนวน</th> */}
                                                                <th className='font-16' ><span className='border-left-table me-2'></span>หน่วย</th>
                                                                <th className='font-16' style={{ width: "135px" }}><span className='border-left-table me-2'></span>นับก่อนเปิดร้าน</th>
                                                                <th className='font-16' style={{ width: "130px" }}><span className='border-left-table me-2'></span>นับหลังปิดร้าน</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                datawaste.sort((a, b) => a.sku - b.sku).map((value2, index2) => (
                                                                    <tr key={index2} className="font-14">
                                                                        <td style={{ verticalAlign: "middle" }}>{value2.sku}</td>
                                                                        <td style={{ verticalAlign: "middle" }}><span className='border-left-table me-2'></span>{value2.name}</td>
                                                                        {/* <td style={{ verticalAlign: "middle" }}>
                                                                        <span className='border-left-table me-2'></span>
                                                                        {value2.waste_add_move}
                                                                    </td> */}
                                                                        <td style={{ verticalAlign: "middle" }}>
                                                                            <span className='border-left-table me-2'></span>{value2.unit}
                                                                            {/* <span className='float-end'>
                                                                        <span className='button-edit me-2' onClick={() => handledetail(value2.id,"edit")}><img src={iconedit} alt="" className="" /></span>
                                                                        <span className='button-del' onClick={() => handledetail(value2.id,"del")}><img src={icondel} alt="" className="" /></span>
                                                                    </span> */}
                                                                        </td>
                                                                        <td style={{ verticalAlign: "middle" }}>
                                                                            <input type="hidden" name='id[]' defaultValue={value2.id} />
                                                                            <Form.Control type="number" min="0" name='qty_b[]' defaultValue={value2.waste_move_b} className='input-custom font-14' style={{ padding: "3px 0px", background: "unset" }} />
                                                                        </td>
                                                                        <td style={{ verticalAlign: "middle" }}>
                                                                            <Form.Control type="number" min="0" name='qty[]' defaultValue={value2.waste_move} className='input-custom font-14' style={{ padding: "3px 0px", background: "unset" }} />
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                        </tbody>
                                                    </Table>
                                                </div>

                                            </div>
                                            <div className='mt-3 text-end'>
                                                <button type='submit' className="btn btn-submit text-white py-2 px-5" >
                                                    อัพเดทรายการ
                                                </button>
                                            </div>
                                        </Form>
                                    </div>
                                    {/* 
                                <div className="col-12 col-md-4 col-lg-4 ps-0">
                                    <Form noValidate validated={validated} onSubmit={handleSubmit} ref={formRef}>
                                        <div className='w-100 mt-3 ps-2 mb-2'>
                                            <div className="bg-white rounded-3 ms-2 mt-2 h-100per-right position-relative">
                                                <div className='text-start p-3'>
                                                    <div className='font-22 py-3 weight-500'>เพิ่มรายการสินค้า</div>
                                                    <div className='position-relative'>
                                                        <Row className="align-items-end mb-3">
                                                            <Form.Group as={Col} md="12" className="position-relative" controlId="validationCustom01">
                                                                <Form.Label className="text-gray mb-2 font-14">เลือกชื่อสินค้า</Form.Label>
                                                                <Form.Select aria-label="Default select example" className='font-14' name='warehouse_id' required>
                                                                    <option value="">เลือกชื่อสินค้า</option>
                                                                    {datawaste.map((value2, index2) => (
                                                                        <option key={index2} value={value2.id}>{value2.name}</option>
                                                                    ))}
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </Row>
                                                        <Row className="align-items-end mt-3 mb-3">
                                                            <Form.Group as={Col} md="6" className="position-relative pe-1" controlId="validationCustom01">
                                                                <Form.Label className="text-gray mb-2 font-14">จำนวน</Form.Label>
                                                                <Form.Control type="text" name='qty' className='input-custom font-14' style={{ padding: "10px 0px" }} required />
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="6" className="position-relative" controlId="validationCustom01">
                                                                <Form.Label className="text-gray mb-2 font-14">หน่วย</Form.Label>
                                                                <Form.Select aria-label="Default select example" className='font-14' name='unit' required>
                                                                    <option value="">เลือกหน่วย</option>
                                                                    <option value="กรัม">กรัม</option>
                                                                    <option value="กิโลกรัม">กิโลกรัม</option>
                                                                    <option value="ถุง">ถุง</option>
                                                                    <option value="ขวด">ขวด</option>
                                                                    <option value="ใบ">ใบ</option>
                                                                    <option value="ชิ้น">ชิ้น</option>
                                                                    <option value="ลัง">ลัง</option>
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </Row>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w-100 ps-2 mt-2'>
                                                <div className='row'>
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <button type='submit' className="w-100 btn btn-submit text-white py-2">
                                                            เพิ่มรายการ
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </div> */}
                                </div>
                            </section>

                        </div>
                    </Tab.Container>
                </div>
            </div>

            {/* {modalconfirmwaste.view ?
                <Modalconfirmwaste show={modalconfirmwaste.view} onHide={handlehide} onSubmit={handlerefresh} dataf={modalconfirmwaste.dataform} datawaste={datawaste} dataem={modalconfirmwaste.dataem} type={modalconfirmwaste.type} dateday={dateday} title={modalconfirmwaste.title} />
                : null} */}

        </>
    );
};

export default Warehousemove;