import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const signup = (data) => {
  return axios
    .post(API_URL + "/register/signup", data)
    .then((response) => {
    //   if (response.data.accessToken) {
    //     localStorage.setItem("member", JSON.stringify(response.data));
    //   }
      return response.data;
    });
};

const checkemail = (data) => {
  return axios
    .post(API_URL + "/register/checkemail", data)
    .then((response) => {
    //   if (response.data.accessToken) {
    //     localStorage.setItem("member", JSON.stringify(response.data));
    //   }
      return response.data;
    });
};
const resetpass = (data) => {
  return axios
    .post(API_URL + "/register/resetpass", data)
    .then((response) => {
    //   if (response.data.accessToken) {
    //     localStorage.setItem("member", JSON.stringify(response.data));
    //   }
      return response.data;
    });
};

const login = (email, password) => {
  return axios
    .post(API_URL + "/register/login", {
      email,
      password,
    })
    .then((response) => {
      if (response.data.status === 200) {
        localStorage.setItem("member", JSON.stringify(response.data.data));
      }
      return response.data;
      
    });
};

const loginmember = (username, password) => {
  return axios
    .post(API_URL + "/register/loginmember", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.status === 200) {
        localStorage.setItem("member", JSON.stringify(response.data.data));
      }
      return response.data;
      
    });
};

const logout = () => {
  localStorage.removeItem("member");
  localStorage.removeItem("member_data");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("member"));
};
const getCurrentBusiness = () => {
  return JSON.parse(localStorage.getItem("business_data"));
};
const getCurrentBranch = () => {
  return JSON.parse(localStorage.getItem("branch_data"));
};


const registerbusiness = (data) => {
  return axios
    .post(API_URL + "/business/registerbusiness", data)
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("businesspin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};
const registerbranch = (data) => {
  return axios
    .post(API_URL + "/branch/registerbranch", data)
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("branchpin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};

const getBusiness = (id) => {
  return axios
    .post(API_URL + "/business/business_one", {id})
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("businesspin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};

const getBranch = (id) => {
  return axios
    .post(API_URL + "/branch/branch_one", {id})
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("businesspin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};

const editbusiness = (data) => {
  return axios
    .post(API_URL + "/business/editbusiness", data)
    .then((response) => {
      return response.data;
    });
};

const editbranch = (data) => {
  return axios
    .post(API_URL + "/branch/editbranch", data)
    .then((response) => {
      return response.data;
    });
};

const createTable = (data) => {
  return axios
    .post(API_URL + "/settingtable/createtable", data)
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("branchpin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};

const deleteTable = (data) => {
  return axios
    .post(API_URL + "/settingtable/delete_table", data)
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("branchpin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};

const updateTable = (data) => {
  return axios
    .post(API_URL + "/settingtable/updatetable", data)
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("branchpin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};

const updatePayment = (data) => {
  return axios
    .post(API_URL + "/settingtable/updatepayment", data)
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("branchpin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};

const updateCategory = (data) => {
  return axios
    .post(API_URL + "/settingtable/updatecategory", data)
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("branchpin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};

const addCategory = (data) => {
  return axios
    .post(API_URL + "/settingtable/addcategory", data)
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("branchpin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};

const updateProduct = (data) => {
  return axios
    .post(API_URL + "/settingtable/updateproduct", data)
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("branchpin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};

const delCategory = (id,type) => {
  return axios
    .post(API_URL + "/settingtable/delete_category", {id,type})
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("businesspin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};

const delProduct = (id,type) => {
  return axios
    .post(API_URL + "/settingtable/delete_product", {id,type})
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("businesspin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};

const delEmployee = (id,type) => {
  return axios
    .post(API_URL + "/settingtable/delete_employee", {id,type})
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("businesspin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};
const delBanner = (id,type) => {
  return axios
    .post(API_URL + "/settingtable/delete_banner", {id,type})
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("businesspin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};
const delWarehouse = (id,type) => {
  return axios
    .post(API_URL + "/waste/delete_warehouse", {id,type})
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("businesspin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};
const delWarehouseadd = (id,type) => {
  return axios
    .post(API_URL + "/waste/delete_warehouse_add", {id,type})
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("businesspin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};

const delPromotion = (id,type) => {
  return axios
    .post(API_URL + "/settingtable/delete_promotion", {id,type})
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("businesspin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};

const moreProduct = (data) => {
  return axios
    .post(API_URL + "/settingtable/more_product", data)
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("businesspin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};
const positionProduct = (data) => {
  return axios
    .post(API_URL + "/settingtable/position_product", data)
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("businesspin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};

const updateReceipt = (data) => {
  return axios
    .post(API_URL + "/settingtable/updatereceipt", data)
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("branchpin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};

const updateEmployee = (data) => {
  return axios
    .post(API_URL + "/settingtable/updateemployee", data)
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("branchpin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};

const updateBanner = (data) => {
  return axios
    .post(API_URL + "/settingtable/updatebanner", data)
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("branchpin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};

const updatePromotion = (data) => {
  return axios
    .post(API_URL + "/settingtable/updatepromotion", data)
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("branchpin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};

const positionPromotion = (data) => {
  return axios
    .post(API_URL + "/settingtable/position_promotion", data)
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("businesspin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};

const updateReserve = (data) => {
  return axios
    .post(API_URL + "/reserve/updatereserve", data)
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("branchpin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};

const updateReserveorder = (data) => {
  return axios
    .post(API_URL + "/reserve/updatereserveorder", data)
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("branchpin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};


const moveReserveorder = (data) => {
  return axios
    .post(API_URL + "/reserve/movereserveorder", data)
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("branchpin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};

const updateReservestatus = (data) => {
  return axios
    .post(API_URL + "/reserve/updatereservestatus", data)
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("branchpin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};

const updateMenustatus = (data) => {
  return axios
    .post(API_URL + "/settingtable/update_menustatus", data)
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("branchpin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};

const updateOrderlist = (data) => {
  return axios
    .post(API_URL + "/reserve/add_order_list", data)
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("branchpin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};
const updateOrderlistupdate = (data) => {
  return axios
    .post(API_URL + "/reserve/update_order_list", data)
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("branchpin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};

const removeOrder = (data) => {
  return axios
    .post(API_URL + "/reserve/remove_order", data)
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("branchpin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};

const removeOrderlist = (data) => {
  return axios
    .post(API_URL + "/reserve/remove_order_list", data)
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("branchpin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};

const promotionOrderlist = (data) => {
  return axios
    .post(API_URL + "/reserve/promotion_order_list", data)
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("branchpin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};
const holdOrderlist = (data) => {
  return axios
    .post(API_URL + "/reserve/hold_order_list", data)
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("branchpin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};
const updateWaste = (data) => {
  return axios
    .post(API_URL + "/waste/add_waste", data)
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("branchpin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};
const updateWarehouse = (data) => {
  return axios
    .post(API_URL + "/waste/add_warehouse", data)
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("branchpin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};
const updateWarehousecreate = (data) => {
  return axios
    .post(API_URL + "/waste/add_warehouse_create", data)
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("branchpin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};
const updateWarehousemove = (data) => {
  return axios
    .post(API_URL + "/waste/add_warehouse_move", data)
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("branchpin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};
const updateMember = (data) => {
  return axios
    .post(API_URL + "/waste/add_member", data)
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("branchpin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};

const delMember = (id,type) => {
  return axios
    .post(API_URL + "/waste/del_member", {id,type})
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("businesspin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};

const updateTablemember = (data) => {
  return axios
    .post(API_URL + "/waste/table_member", data)
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("branchpin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};
const updateConfirmpayment = (data) => {
  return axios
    .post(API_URL + "/reserve/updatereservePayment", data)
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("branchpin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};


const authService = {
  signup,
  checkemail,
  resetpass,
  login,
  loginmember,
  logout,
  getCurrentUser,
  registerbusiness,
  getCurrentBusiness,
  getBusiness,
  getBranch,
  editbusiness,
  editbranch,
  getCurrentBranch,
  registerbranch,
  createTable,
  deleteTable,
  updateTable,
  updatePayment,
  updateCategory,
  addCategory,
  updateProduct,
  delProduct,
  moreProduct,
  positionProduct,
  updateReceipt,
  updateEmployee,
  updateBanner,
  delEmployee,
  delBanner,
  delCategory,
  delPromotion,
  updatePromotion,
  positionPromotion,
  updateReserve,
  updateReservestatus,
  updateMenustatus,
  updateReserveorder,
  moveReserveorder,
  updateOrderlist,
  removeOrder,
  removeOrderlist,
  promotionOrderlist,
  holdOrderlist,
  updateWaste,
  updateMember,
  delMember,
  updateTablemember,
  updateConfirmpayment,
  updateWarehouse,
  updateWarehousecreate,
  delWarehouse,
  delWarehouseadd,
  updateWarehousemove,
  updateOrderlistupdate
};

export default authService;