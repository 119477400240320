import React, { useState } from 'react';
// import axios from 'axios';
// import { useNavigate } from "react-router-dom";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { compareAsc, format } from 'date-fns'
// import help from '../../assets/img/help.svg';
import chairgreen from '../../assets/img/chair-green.svg';
import chairorange from '../../assets/img/chair-orange.svg';
import chairred from '../../assets/img/chair-red.svg';
// import calendar from '../../assets/img/calendar.svg';

import Modalqrcode from "../../layout/modal/modalCreateqrcode";

import { Tab } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
// import { Form, Col, Row, InputGroup, Button } from "react-bootstrap";



// import "react-datepicker/dist/react-datepicker.css";

const Tabletabcontent = (data) => {
    // console.log(JSON.parse((data.datatable.detail)))
    let dataarray = data.dataarr;

    const { t } = useTranslation();

    // console.log(data)

    function range(start, end, val, type) {

        // console.log(end - start + 1)

        return Array(end - start + 1).fill().map((_, idx) => (
            <span key={idx}>
                {idx > end - 1 ?
                    <img src={chairgreen} alt="" className="me-1" style={{ width: "30px" }} />
                    :
                    type === '1' ?
                        <img src={chairred} alt="" className="me-1" style={{ width: "30px" }} />

                        : type === '2' ?
                            <img src={chairorange} alt="" className="me-1" style={{ width: "30px" }} />
                            :
                            <img src={chairgreen} alt="" className="me-1" style={{ width: "30px" }} />
                }
            </span>
        ))

        // return Array(end - start + 1).fill().map((_, idx) => (
        //     <span key={idx}>
        //         {idx > val - 1 ?
        //             <img src={chairgreen} alt="" className="me-1" style={{ width: "30px" }} />
        //             :
        //             type === '1' ?
        //                 <img src={chairred} alt="" className="me-1" style={{ width: "30px" }} />

        //             : type === '2' ?
        //                 <img src={chairorange} alt="" className="me-1" style={{ width: "30px" }} />
        //             :
        //                 <img src={chairgreen} alt="" className="me-1" style={{ width: "30px" }} />   
        //         }
        //     </span>
        // ))
    }

    const [dataactive, setDataactive] = useState({ zone: '', table: '' });

    const [modalqrcode, setModalqrcode] = useState({ view: false, id: 0 });

    const selectlist = (zone, table) => {
        data.nadlereserve(zone, table)
        setDataactive({ zone: zone, table: table })
    }

    const setmodalqrcode = (id,zone,table,table_id) => {

        setModalqrcode({view:true,id:id,zone:zone,table:table,table_id:table_id})
        // alert(5555)
    }

    const handlehid = () => {
        setModalqrcode({view:false})
        data.refresh()
    }

    // console.log(modalqrcode)

    return (
        <>
            <Tab.Content className="bg-white rounded-3 mt-3 h-100per">
                <Tab.Pane eventKey="zone_all">
                    <div className='text-start shadow-sm p-3'>
                        <span className='weight-500'>สถานะ :</span>
                        <span className='ms-3'>ว่าง
                            <span className='status-number bg-green ms-1'>
                                {dataarray.filter(vls => vls.status === '0').length}
                            </span>
                        </span>
                        <span className='ms-3 px-3 border-r-l'>ไม่ว่าง
                            <span className='status-number bg-orange ms-1'>
                                {dataarray.filter(vls => vls.status === '2').length}
                            </span>
                        </span>
                        <span className='ms-3'>ติดจอง
                            <span className='status-number bg-red ms-1'>
                                {dataarray.filter(vls => vls.status === '1').length}
                            </span>
                        </span>
                    </div>
                    {data.datatable.map((value, index) => (
                        <div key={index}>

                            {(JSON.parse(value.detail)).map((val, ind) => (
                                <div className='border_hr' key={ind}>

                                    {data.datareserve.filter(valb => valb.zone === value.id).filter(valv => valv.table_reserve === `${val.id}`).length ?

                                        data.datareserve.filter(valb => valb.zone === value.id).filter(valb => valb.status !== '0').filter(valv => valv.table_reserve === `${val.id}`).map((val2, ind2) => (

                                            <span onClick={() => selectlist(val2.zone, val2.table_reserve)} className={dataactive.zone === val2.zone ? dataactive.table === val2.table_reserve ? "a-list-table p-5 cursor active" : "a-list-table p-5 cursor" : "a-list-table p-5 cursor"} key={ind2}>
                                                <div className="row" >
                                                    <div className="col-6 col-md-5 col-lg-5 text-start">
                                                        <div>
                                                            {range(1, val.number, val2.number_seats, val2.status)}
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-md-7 col-lg-7 text-start">
                                                        <div className='font-18'><b>{value.zone} : {val.table_number}</b></div>
                                                        <div>
                                                            <div className='font-14 mt-2'>จำนวนที่นั่ง :  {val2.number_seats}</div>
                                                            <div className='font-14 mt-1'>วัน - เวลา : {format(new Date(val2.date_reserve), 'dd/MM/yyyy')} - {val2.time_reserve}</div>
                                                            <div className='font-14 mt-1'>ชื่อลูกค้า : {val2.name_member}</div>
                                                            <div className='font-14 mt-1'>เบอร์โทร : {val2.tel_member}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </span>)
                                        )
                                        : (
                                            <div className='p-5 cursor' onClick={(e) => setmodalqrcode(value.id,value.zone,val.table_number,val.id)}>
                                                <div className="row">
                                                    <div className="col-6 col-md-5 col-lg-5 text-start">
                                                        <div>
                                                            {range(1, val.number, 0, '0')}
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-md-7 col-lg-7 text-start">
                                                        <div className='font-18'><b>{value.zone} : {val.table_number}</b></div>
                                                        <div>
                                                            <div className='font-14 mt-2'>จำนวนที่นั่ง :  -</div>
                                                            <div className='font-14 mt-1'>วัน - เวลา : -</div>
                                                            <div className='font-14 mt-1'>ชื่อลูกค้า : -</div>
                                                            <div className='font-14 mt-1'>เบอร์โทร : -</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            ))}
                        </div>
                    ))}
                </Tab.Pane>
                {data.datatable.map((value, index) => (
                    <Tab.Pane eventKey={value.id} key={index}>

                        <div className='text-start shadow-sm p-3'>
                            <span className='weight-500'>สถานะ :</span>
                            <span className='ms-3'>ว่าง
                                <span className='status-number bg-green ms-1'>
                                    {dataarray.filter(vls => vls.status === '0').filter(vls2 => vls2.zone === value.id).length}
                                </span>
                            </span>
                            <span className='ms-3 px-3 border-r-l'>ไม่ว่าง
                                <span className='status-number bg-orange ms-1'>
                                    {dataarray.filter(vls => vls.status === '2').filter(vls2 => vls2.zone === value.id).length}
                                </span>
                            </span>
                            <span className='ms-3'>ติดจอง
                                <span className='status-number bg-red ms-1'>
                                    {dataarray.filter(vls => vls.status === '1').filter(vls2 => vls2.zone === value.id).length}
                                </span>
                            </span>
                        </div>

                        {(JSON.parse(value.detail)).map((val, ind) => (
                            <div className='border_hr' key={ind}>
                                {data.datareserve.filter(valb => valb.zone === value.id).filter(valv => valv.table_reserve === `${val.id}`).length ?

                                    data.datareserve.filter(valb => valb.zone === value.id).filter(valv => valv.table_reserve === `${val.id}`).map((val2, ind2) => (
                                        // <span to="/table/detail_reserve" className="a-list-table p-5" key={ind2}>
                                        <span onClick={() => selectlist(val2.zone, val2.table_reserve)} className={dataactive.zone === val2.zone ? dataactive.table === val2.table_reserve ? "a-list-table p-5 cursor active" : "a-list-table p-5 cursor" : "a-list-table p-5 cursor"} key={ind2}>

                                            <div className="row" >
                                                <div className="col-6 col-md-5 col-lg-5 text-start">
                                                    <div>
                                                        {range(1, val.number, val2.number_seats, val2.status)}
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-7 col-lg-7 text-start">
                                                    <div className='font-18'><b>{value.zone} : {val.table_number}</b></div>
                                                    <div>
                                                        <div className='font-14 mt-2'>จำนวนที่นั่ง :  {val2.number_seats}</div>
                                                        <div className='font-14 mt-1'>วัน - เวลา : {format(new Date(val2.date_reserve), 'dd/MM/yyyy')} - {val2.time_reserve}</div>
                                                        <div className='font-14 mt-1'>ชื่อลูกค้า : {val2.name_member}</div>
                                                        <div className='font-14 mt-1'>เบอร์โทร : {val2.tel_member}</div>
                                                    </div>

                                                </div>
                                            </div>
                                        </span>
                                    ))
                                    : (
                                        <div className='p-5 cursor' onClick={(e) => setmodalqrcode(value.id,value.zone,val.table_number,val.id)}>
                                            <div className="row">
                                                <div className="col-6 col-md-5 col-lg-5 text-start">
                                                    <div>
                                                        {range(1, val.number, 0, 0)}
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-7 col-lg-7 text-start">
                                                    <div className='font-18'><b>{value.zone} : {val.table_number}</b></div>
                                                    <div>
                                                        <div className='font-14 mt-2'>จำนวนที่นั่ง :  -</div>
                                                        <div className='font-14 mt-1'>วัน - เวลา : -</div>
                                                        <div className='font-14 mt-1'>ชื่อลูกค้า : -</div>
                                                        <div className='font-14 mt-1'>เบอร์โทร : -</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        ))}

                        {/* <div className='m-3 border_hr py-4'>
                            <div className="row">
                                <div className="col-6 col-md-5 col-lg-5 text-start">
                                    <div>
                                        <img src={chairgreen} alt="" className="me-1" style={{ width: "30px" }} />
                                        <img src={chairgreen} alt="" className="me-1" style={{ width: "30px" }} />
                                    </div>
                                </div>
                                <div className="col-6 col-md-7 col-lg-7 text-start">
                                    <div className='font-18'><b>โซน A : B02</b></div>
                                    <div className='font-14 mt-2'>จำนวนที่นั่ง : 2</div>
                                    <div className='font-14 mt-1'>วัน - เวลา : -</div>
                                    <div className='font-14 mt-1'>ชื่อลูกค้า : -</div>
                                    <div className='font-14 mt-1'>เบอร์โทร : -</div>
                                </div>
                            </div>
                        </div> */}
                    </Tab.Pane>
                ))}
            </Tab.Content>

            {modalqrcode.view ?
                <Modalqrcode show={modalqrcode.view} onHide={handlehid} id={modalqrcode.id} zone={modalqrcode.zone} table={modalqrcode.table} table_id={modalqrcode.table_id} />
                : null}
        </>
    );
};

export default Tabletabcontent;