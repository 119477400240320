import React, { useState, useCallback, useEffect, useRef } from 'react';
// import axios from 'axios';
import { useNavigate, useParams, Link } from "react-router-dom";
import { compareAsc, format } from 'date-fns'

import AuthService from "../../components/services_member/auth.service";
import PostService from "../../components/services_member/post.service";

import ReactToPrint from 'react-to-print';
import ComponentToPrint from '../../components/ComponentToPrint';

import help from '../../assets/img/help.svg';
import smenu from '../../assets/img/s-menu.svg';

import Menuleft from "../../layout/pagesadmin/menuleft";
import Tabcontent from "../order/order_menu_tab";
import Ordertableview from "../order/order_table_view";

import { Form, Tab, Nav, DropdownButton, Dropdown, ButtonGroup, Button } from "react-bootstrap";

// import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';

import Modalmenuselecttable from "../../layout/modal/modalMenuselecttable";
import Modalconfirmpayment from "../../layout/modal/modalConfirmpayment";
import AutocompleteMember from "../../layout/auTocomplete/member";

// import cmore from '../../assets/img/icon-more.svg';
// import search from '../../assets/img/search.svg';
import backspace from '../../assets/img/backspace.svg';
import { useTranslation } from 'react-i18next';

const Payment = () => {
    const { t } = useTranslation();
    const tableorder = JSON.parse(localStorage.getItem("table_order"));

    let history = useNavigate();

    const { id } = useParams();

    const user = AuthService.getCurrentUser();
    const branch = AuthService.getCurrentBranch();

    // localStorage.removeItem("table_order");

    if (!user) {
        history("/");
    } else {
        if (!branch) {
            history("/branch");
        }
    }
    const [modalselecttable, setModalselecttable] = useState({ view: false, id: "0" });
    const [modalconfirmpayment, setModalconfirmpayment] = useState({ view: false, id: "0" });
    const [radio, setRadio] = useState(1);

    const [datareserve, setDatareserve] = useState(false);
    const [memberone, setMemberone] = useState(false);
    const [dateday, setDateday] = useState(format(new Date(), 'dd/MM/yyyy'));
    const [activePrice, setActivePrice] = useState();
    const [datamember, setDatamember] = useState([]);

    const [dataorder, setDataorder] = useState([]);
    const [dataorderlist, setDataorderlist] = useState([]);
    const [datapromotion, setDatapromotion] = useState([]);
    const [datareceipt, setDatareceipt] = useState([]);
    const [datapayment, setDatapayment] = useState();
    const [dataactive, setDataactive] = useState({ order: '' });
    const [orderview, setOrderview] = useState([]);

    const fetchData2 = useCallback(async (dd, td) => {

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        PostService.getReserveallpayment(branch.id, dd, td).then(
            (response) => {
                if (response.data.status === 200) {
                    setDatareserve(response.data.reserve)
                }
            },
            (error) => {
                console.log(error);
            }
        )

        PostService.getMemberdataall(branch.id, dd).then(
            (response) => {
                if (response.data.status === 200) {
                    setDatamember(response.data.memberdata);
                } else {
                    setDatamember([]);
                }
            },
            (error) => {
                console.log(error);
            }
        )

        PostService.getReceiptone(branch.id, dd, id).then(
            (response) => {
                if (response.data.status === 200) {

                    setDataorder(response.data.reserve);
                    setDataorderlist(response.data.reserve);
                    setDatareceipt(response.data.receipt)

                    setDataactive({ order: id })
                    setOrderview(response.data.reserve[0])
                    setDatapromotion(response.data.reserve[0].promotion_data ? JSON.parse(response.data.reserve[0].promotion_data) : null);
                    setDatapayment(response.data.reserve[0].payment_data ? JSON.parse(response.data.reserve[0].payment_data) : null);

                } else {
                    setDataorder([]);
                    setDataorderlist([]);
                    setDatareceipt();
                }
            },
            (error) => {
                console.log(error);
            }
        )
    });

    useEffect(() => {
        fetchData2(dateday, id);
    }, []);

    // const clickrefresh = (ee) => {
    // }

    const sumAll = () => {
        var sum = 0;
        var total = 0;
        if (datareserve) {
            datareserve.data_order.filter(valb => valb.status !== '5').map((val, index) => (
                <>
                    {sum = sum + (parseFloat(val.price) * val.qty)}

                    {val.choice ? (JSON.parse(val.choice)).map((vall, indel) =>
                        total = total + (parseFloat(vall.more_price * val.qty))
                    ) : null}
                </>
            )
            )
            return parseFloat(sum + total)
        }
    }

    const sumAllFood = () => {
        var sum = 0;
        var total = 0;
        if (datareserve) {
            datareserve.data_order.filter(valb => valb.status !== '5').filter(valb => valb.kitchen_id !== '5').map((val, index) => (
                <>
                    {sum = sum + (parseFloat(val.price) * val.qty)}

                    {val.choice ? (JSON.parse(val.choice)).map((vall, indel) =>
                        total = total + (parseFloat(vall.more_price * val.qty))
                    ) : null}
                </>
            )
            )
            return parseFloat(sum + total)
        }
    }

    const sumAllDrink = () => {
        var sum = 0;
        var total = 0;
        if (datareserve) {
            datareserve.data_order.filter(valb => valb.status !== '5').filter(valb => valb.kitchen_id === '5').map((val, index) => (
                <>
                    {sum = sum + (parseFloat(val.price) * val.qty)}

                    {val.choice ? (JSON.parse(val.choice)).map((vall, indel) =>
                        total = total + (parseFloat(vall.more_price * val.qty))
                    ) : null}
                </>
            )
            )
            return parseFloat(sum + total)
        }
    }

    // console.log(datareserve)

    const sumTotal = (sum, sumpromotion) => {

        var sumservice = 0;
        var sumvat = 0;
        var sumall = sum - sumpromotion;

        if (datareserve.payment_data) {

            let datapayment = JSON.parse(datareserve.payment_data)

            if (datapayment) {
                if (datapayment.service_charge === '1') {
                    var sumservice = parseFloat(sumall * (datapayment.service_rate) / 100).toFixed(2)
                }
                if (datapayment.vat === '1') {
                    var sumvat = parseFloat(sumall * (datapayment.vat_rate) / 100).toFixed(2)
                }
            }
        }
        return parseFloat((sum - parseFloat(sumpromotion)) + parseFloat(sumservice) + parseFloat(sumvat)).toFixed(2)
    }

    const sumPromotion = (sumall) => {
        if (datareserve.promotion_id !== '0') {
            if (datareserve.promotion_data) {
                if (JSON.parse(datareserve.promotion_data).method_reduction === '1') {
                    if (JSON.parse(datareserve.promotion_data).type_reduction) {
                        if (JSON.parse(datareserve.promotion_data).type_reduction === '0') {
                            return parseFloat(sumall * JSON.parse(datareserve.promotion_data).price / 100)
                        } else if (JSON.parse(datareserve.promotion_data).type_reduction === '1') {
                            return parseFloat(sumAllFood() * JSON.parse(datareserve.promotion_data).price / 100)
                        } else {
                            return parseFloat(sumAllDrink() * JSON.parse(datareserve.promotion_data).price / 100)
                        }
                    } else {
                        return parseFloat(sumall * JSON.parse(datareserve.promotion_data).price / 100)
                    }

                } else {
                    if (JSON.parse(datareserve.promotion_data).type_reduction) {
                        if (JSON.parse(datareserve.promotion_data).type_reduction === '0') {
                            return parseFloat(JSON.parse(datareserve.promotion_data).price)
                        } else if (JSON.parse(datareserve.promotion_data).type_reduction === '1') {
                            if (sumAllFood() > 0) {
                                if (sumAllFood() < parseFloat(JSON.parse(datareserve.promotion_data).price)) {
                                    return sumAllFood()
                                } else {
                                    return parseFloat(JSON.parse(datareserve.promotion_data).price)
                                }
                            } else {
                                return 0;
                            }
                        } else {
                            if (sumAllDrink() > 0) {
                                if (sumAllDrink() < parseFloat(JSON.parse(datareserve.promotion_data).price)) {
                                    return sumAllDrink()
                                } else {
                                    return parseFloat(JSON.parse(datareserve.promotion_data).price)
                                }
                            } else {
                                return 0;
                            }
                        }
                    } else {
                        return parseFloat(JSON.parse(datareserve.promotion_data).price)
                    }
                }

            } else {
                return "00";
            }
        } else {
            return "00";
        }
        // return sumAllFood();
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        const dataf = new FormData(event.target);
        const keyword = dataf.get('search');
    }

    const [inputpin, setInputpin] = useState([]);
    const [datapin, setDatapin] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9]);

    const handleAddItem = (e) => {
        if (e === "0,0") {
            // console.log(e.split(","))
            setInputpin((oldArray) => [...oldArray, '0', '0'])
        } else {
            setInputpin((oldArray) => [...oldArray, e])
        }
    }

    const handleRemoveItem = () => {
        const items = inputpin;
        if (items.length > 0) {
            const lastIndex = items.length - 1;
            setInputpin(items.filter((item, index) => index !== lastIndex));
            setActivePrice()
        }
        // setPinerr(false)
    };
    const handleRemoveAllItem = () => {
        // const items = inputpin;
        setInputpin([])
        setActivePrice()
        // setPinerr(false)
    };

    const setPrice = (e) => {
        if (e === "20") {
            setInputpin(['2', '0'])
        } else if (e === "50") {
            setInputpin(['5', '0'])
        } else if (e === "100") {
            setInputpin(['1', '0', '0'])
        } else if (e === "500") {
            setInputpin(['5', '0', '0'])
        } else {
            setInputpin(['1', '0', '0', '0'])
        }
        setActivePrice(e);
    }

    const setMember = (e) => {
        setMemberone(e)
        // alert(e)
    }

    const sumMath = (price) => {

        if (datareserve.payment_data) {

            let datapayment = JSON.parse(datareserve.payment_data)

            if (datapayment) {
                if (datapayment.rounding === '0') {
                    return parseFloat(price)
                } else if (datapayment.rounding === '1') {
                    return parseFloat(Math.round(price))
                } else if (datapayment.rounding === '2') {
                    return parseFloat(Math.ceil(price))
                } else {
                    return parseFloat(Math.floor(price))
                }
            }
        }
    }

    const sumCut = (price) => {

        if (price) {
            return parseFloat(price).toFixed(2);
        } else {
            return parseFloat(0).toFixed(2)
        }
    }

    const onHideclick = () => {
        setModalconfirmpayment({ view: false });
        history("/table/manage");
    }

    const [inputpinCut, setInputpinCut] = useState(0);

    const handleAddItemCut = (e) => {
        setInputpinCut(e.target.value)
        // console.log(e.target.value)
    }

    const componentRef = useRef(null);
    // const handlePrint = useReactToPrint({
    //     content: () => componentRef.current,
    // });
    const printSuccess = (e) => {
        // alert(e)
    }
    // console.log(memberone)
    return (
        <>
            <div className={!t('menu-a') ? "sidebar-mini sidebar-collapse" : ""} >
                <div className='wrapper-admin layout-fixed'>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="food_all">
                        <nav className="main-header navbar bg-white shadow-sm" style={{ height: "60px" }}>
                            {/* <Nav as="ul" variant="tabs" className="nav nav-tabs overflow-hidden ul-tabcustom">
                            <Nav.Item as="li">
                                <Nav.Link eventKey="promotion_all">โปรโมชั่น</Nav.Link>
                            </Nav.Item>
                           
                            {datacat.sort((a, b) => a.position - b.position).map((value, index) => (
                                <Nav.Item as="li" key={index}>
                                    <Nav.Link eventKey={value.id}>{value.category}</Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav> */}
                            <ul className="nav nav-tabs overflow-hidden ul-tabcustom ps-2" style={{ marginTop: "-20px" }}>
                                <li>
                                    <div className='pt-1'>
                                        {datareserve.zone === "0" ? <>
                                            <span className='font-20'><b>Drive Thru : คิวที่ {datareserve.table_reserve}</b></span>
                                            {/* <span className='float-end cursor ms-3' style={{ marginTop: "3px" }}>
                                            <img src={cmore} style={{ height: "15px" }} />
                                        </span> */}
                                        </>
                                            : <>
                                                <span className='font-20'><b>{datareserve.zone_name} : {datareserve.table_reserve_name}</b></span>

                                                {/* <span className='float-end cursor ms-3' style={{ marginTop: "3px" }}>
                                                <img src={cmore} style={{ height: "15px" }} />
                                            </span> */}

                                            </>}
                                    </div>
                                </li>
                                <li className=''>
                                    <div className='ps-3 ms-3' style={{ borderLeft: "1px solid #F2F2F2" }}>
                                        <div className='weight-500 font-14 text-start'>Order #{datareserve.no}</div>
                                        <div className='weight-400 mt-1 text-gray font-12 text-start'>
                                            {datareserve.date_reserve ? <>
                                                {format(new Date(datareserve.date_reserve), 'dd/MM/yyyy')} - {datareserve.time_update}
                                            </>
                                                : null}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <ul className="navbar-nav ml-auto" style={{ marginTop: "-20px" }}>
                                <li className="">
                                    <div className='text-gray font-12'>
                                        <img src={help} alt="" className="me-1" style={{ width: "14px" }} /> ขอความช่วยเหลือ
                                    </div>
                                </li>
                            </ul>
                        </nav>
                        <Menuleft type="order" />
                        <div className="content-wrapper">
                            <section className="content scroll-custom">
                                <div className="row">

                                    <div className="col-12 col-md-6 col-lg-6 pe-2">

                                        <div className='w-100 mt-3 mb-2'>
                                            <div className="w-100 bg-blue text-white py-3">
                                                <div className='position-relative'>
                                                    <Form onSubmit={handleSubmit} className="">
                                                        <div className='position-relative'>
                                                            <Form.Group controlId="dob">
                                                                {/* <Form.Control type="text" name='search' className='font-14' placeholder='เบอร์โทรสมาชิก' /> */}
                                                                {datamember.length ?
                                                                    <AutocompleteMember datamember={datamember} fnsetMember={setMember} />

                                                                    : null}
                                                                {/* <div className='position-absolute' style={{ bottom: "6px", right: "3px" }}>
                                                                <button type="submit" className='btn p-0'>
                                                                    <img src={search} alt="" className="me-1" style={{ width: "30px" }} />
                                                                    เพิ่มสมาชิกใหม่
                                                                </button>
                                                            </div> */}
                                                            </Form.Group>
                                                        </div>
                                                    </Form>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bg-white rounded-3 mt-2 h-100per-top-240 position-relative">
                                            {/* {console.log(memberone)} */}
                                            {memberone ?
                                                <div className='text-end px-3 pt-2 font-12'>
                                                    ชื่อสมาชิก {memberone.name} เบอร์โทร {memberone.tel}
                                                </div>
                                                : null}
                                            <div className='text-start p-3 pt-2'>
                                                <div className='weight-500 mt-1'>รายการอาหาร</div>
                                                <div className='mt-2 mb-4'>

                                                    {datareserve.data_order ? datareserve.data_order.length ? (datareserve.data_order).filter(valb => valb.status !== '5').sort((a, b) => a.id - b.id).map((value, index) => (
                                                        <div className='mb-2' key={index}>
                                                            <div className='font-14 pe-5 position-relative cursor-pointer' >
                                                                {
                                                                    value.status === "2" ? <span className='span-blue me-2'></span> :
                                                                        value.status === "3" ? <span className='span-orang me-2'></span> :
                                                                            value.status === "4" ? <span className='span-green me-2'></span> :
                                                                                value.status === "5" ? <span className='span-red me-2'></span> : null
                                                                }
                                                                <span>{value.name_product} <span className='font-12 ms-3'>X</span> {value.qty}</span>

                                                                <span className='position-absolute' style={{ top: "0", right: "0" }}>
                                                                    {parseFloat(value.price * value.qty).toFixed(2)}
                                                                </span>
                                                            </div>

                                                            {value.choice ? (JSON.parse(value.choice)).map((val, inde) => (
                                                                <div className='ms-3 mt-1' key={inde}>
                                                                    <div className='font-13 position-relative'>
                                                                        <span>- {val.more_name}</span>
                                                                        <span className='position-absolute' style={{ top: "0", right: "0" }}>
                                                                            {parseFloat(val.more_price * value.qty).toFixed(2)}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            ))
                                                                : null}
                                                        </div>
                                                    ))
                                                        : <div className='text-center my-4 font-12 weight-300 text-gray'>- ยังไม่มีรายการ - </div> : null}
                                                </div>
                                                <hr className='hr-custom' />
                                                {datareserve.data_order ? datareserve.data_order.length ? (datareserve.data_order).filter(valb => valb.status === '5').length ?
                                                    <>
                                                        <div className='weight-500 mt-1'>รายการยกเลิก</div>
                                                        <div className='mt-2 mb-4'>

                                                            {datareserve.data_order ? datareserve.data_order.length ? (datareserve.data_order).filter(valb => valb.status === '5').sort((a, b) => a.id - b.id).map((value, index) => (
                                                                <div className='mb-2' key={index}>
                                                                    <div className='font-14 pe-5 position-relative'>
                                                                        {
                                                                            value.status === "2" ? <span className='span-blue me-2'></span> :
                                                                                value.status === "3" ? <span className='span-orang me-2'></span> :
                                                                                    value.status === "4" ? <span className='span-green me-2'></span> :
                                                                                        value.status === "5" ? <span className='span-red me-2'></span> : null
                                                                        }
                                                                        <span>{value.name_product} <span className='font-12 ms-3'>X</span> {value.qty}</span>

                                                                        <span className='position-absolute' style={{ top: "0", right: "0" }}>
                                                                            {parseFloat(value.price * value.qty).toFixed(2)}
                                                                        </span>
                                                                    </div>

                                                                    {value.choice ? (JSON.parse(value.choice)).map((val, inde) => (
                                                                        <div className='ms-3 mt-1' key={inde}>
                                                                            <div className='font-13 position-relative'>
                                                                                - {val.more_name}
                                                                                <span className='position-absolute' style={{ top: "0", right: "0" }}>
                                                                                    {parseFloat(val.more_price * value.qty).toFixed(2)}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                        : null}
                                                                </div>
                                                            ))
                                                                : <div className='text-center my-4 font-12 weight-300 text-gray'>- ยังไม่มีรายการ - </div> : null}
                                                        </div>
                                                        <hr className='hr-custom' />
                                                    </>
                                                    : null : null : null}

                                                <div className='weight-500 mt-1'>โปรโมชั่น</div>
                                                <div className='mt-2 mb-4'>
                                                    {datareserve.promotion_data ?
                                                        <div className='mb-2'>
                                                            <span className='font-14 pe-5'>{JSON.parse(datareserve.promotion_data).promotion}</span>
                                                            <span className='float-end'>
                                                                {JSON.parse(datareserve.promotion_data).method_reduction === '1' ?
                                                                    "-" + JSON.parse(datareserve.promotion_data).price + "%"
                                                                    : parseFloat(JSON.parse(datareserve.promotion_data).price).toFixed(2)
                                                                }
                                                            </span>
                                                        </div>
                                                        : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='text-white text-start px-3 py-4 rounded-bottom' style={{ background: "#2E384D" }}>
                                            <div className='font-14'>
                                                ราคาอาหาร
                                                <span className='float-end cursor' >
                                                    {sumAll() ? sumAll().toLocaleString() : null}
                                                </span>
                                            </div>
                                            <div className='font-12 mt-1'>
                                                {datareserve.payment_data ? <>
                                                    {JSON.parse(datareserve.payment_data).service_charge === '1' ?
                                                        <div className='mb-1 font-12'>
                                                            <span className=''>ค่าบริการ ({JSON.parse(datareserve.payment_data).service_rate}%)</span>
                                                            <span className='float-end'>{parseFloat((sumAll() - (parseFloat(sumPromotion(sumAll())) + parseFloat((sumCut(inputpinCut))))) * (JSON.parse(datareserve.payment_data).service_rate) / 100).toFixed(2)}</span>
                                                        </div>
                                                        : null}
                                                    {JSON.parse(datareserve.payment_data).vat === '1' ?
                                                        <div className='mb-1 font-12'>
                                                            <span className=''>Vat ({JSON.parse(datareserve.payment_data).vat_rate}%)</span>
                                                            <span className='float-end'>
                                                                {parseFloat((sumAll() - (parseFloat(sumPromotion(sumAll())) + parseFloat((sumCut(inputpinCut))))) * (JSON.parse(datareserve.payment_data).vat_rate) / 100).toFixed(2)} <br />
                                                            </span>
                                                        </div>
                                                        : null}
                                                </> : null}
                                            </div>

                                            <div className='font-12 mt-1'>
                                                ส่วนลดโปรโมชั่น
                                                <span className='float-end'><span>- </span>
                                                    {sumPromotion(sumAll())}
                                                </span>
                                            </div>
                                            <div className='font-12 mt-1'>
                                                ส่วนลดอื่นๆ
                                                <span className='float-end'><span>- </span>
                                                    {sumCut(inputpinCut)}
                                                </span>
                                            </div>
                                            <div className='mt-3'>
                                                <b>ยอดชำระ
                                                    <span className='float-end cursor' >
                                                        {sumMath(sumTotal(parseFloat(sumAll()), (parseFloat(sumPromotion(sumAll())) + parseFloat((sumCut(inputpinCut)))))) ?
                                                            (sumMath(sumTotal(parseFloat(sumAll()), (parseFloat(sumPromotion(sumAll())) + parseFloat((sumCut(inputpinCut)))))))
                                                            : null}
                                                    </span>
                                                </b>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className='bg-white rounded-3 mt-3 h-100per-right-f tab-content'>
                                            <div className='text-start'>
                                                <fieldset>
                                                    <div className="toggle">
                                                        <input type="radio" name="sizeBy" value="1" id="sizeWeight" onChange={() => setRadio(1)} defaultChecked={radio === 1 ? "checked" : null} />
                                                        <label htmlFor="sizeWeight">Cash</label>
                                                        <input type="radio" name="sizeBy" value="3" id="sizeDimensions" onChange={() => setRadio(3)} defaultChecked={radio === 3 ? "checked" : null} />
                                                        <label htmlFor="sizeDimensions" >Credit Card</label>
                                                        <input type="radio" name="sizeBy" value="2" id="sizeDimensions2" onChange={() => setRadio(2)} defaultChecked={radio === 2 ? "checked" : null} />
                                                        <label htmlFor="sizeDimensions2" >QR Code</label>
                                                    </div>
                                                </fieldset>
                                            </div>
                                            <div className='text-start px-3'>
                                                <div className='p-3 rounded' style={{ background: "#EAEAEA" }}>
                                                    <h2 className='m-0'><b>฿
                                                        <span className='float-end'>{inputpin ? inputpin.length === 0 ? "0.00" : inputpin.join('') : "0.00"}</span></b>
                                                    </h2>
                                                </div>
                                                <div className='mt-3'>
                                                    <div className='d-table w-100'>
                                                        <div className='d-table-cell'>
                                                            <div className={activePrice === '20' ? 'keybord-number-price text-center active' : 'keybord-number-price text-center'} onClick={() => setPrice('20')}>
                                                                20
                                                            </div>
                                                        </div>
                                                        <div className='d-table-cell px-1'>
                                                            <div className={activePrice === '50' ? 'keybord-number-price text-center active' : 'keybord-number-price text-center'} onClick={() => setPrice('50')}>
                                                                50
                                                            </div>
                                                        </div>
                                                        <div className='d-table-cell'>
                                                            <div className={activePrice === '100' ? 'keybord-number-price text-center active' : 'keybord-number-price text-center'} onClick={() => setPrice('100')}>
                                                                100
                                                            </div>
                                                        </div>
                                                        <div className='d-table-cell px-1'>
                                                            <div className={activePrice === '500' ? 'keybord-number-price text-center active' : 'keybord-number-price text-center'} onClick={() => setPrice('500')}>
                                                                500
                                                            </div>
                                                        </div>
                                                        <div className='d-table-cell'>
                                                            <div className={activePrice === '1000' ? 'keybord-number-price text-center active' : 'keybord-number-price text-center'} onClick={() => setPrice('1000')}>
                                                                1000
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='p-5 pt-3'>
                                                <div className='row justify-content-center'>
                                                    <div className="col-8 col-md-6 col-xl-6 text-center">
                                                        <div className='row justify-content-end'>
                                                            {
                                                                datapin.map((e, i) => (
                                                                    <div className="col-4 col-md-4 col-xl-4 text-center p-1" key={i}>
                                                                        <div className='keybord-number position-relative' onClick={() => handleAddItem(e)}>
                                                                            <div className='position-absolute position-center'><h3 className='m-0'>{e}</h3></div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }

                                                            <div className="col-4 col-md-4 col-xl-4 text-center p-1">
                                                                {inputpin.length === 0 ?
                                                                    <div className='keybord-number position-relative disible-key' >
                                                                        <div className='position-absolute position-center'><h3 className='m-0'>00</h3></div>
                                                                    </div>
                                                                    :
                                                                    <div className='keybord-number position-relative' onClick={(e) => handleAddItem('0,0')}>
                                                                        <div className='position-absolute position-center'><h3 className='m-0'>00</h3></div>
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="col-4 col-md-4 col-xl-4 text-center p-1">
                                                                {inputpin.length === 0 ?
                                                                    <div className='keybord-number position-relative disible-key'>
                                                                        <div className='position-absolute position-center'><h3 className='m-0'>0</h3></div>
                                                                    </div>
                                                                    :
                                                                    <div className='keybord-number position-relative' onClick={(e) => handleAddItem(0)}>
                                                                        <div className='position-absolute position-center'><h3 className='m-0'>0</h3></div>
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="col-4 col-md-4 col-xl-4 text-center p-1">
                                                                {inputpin.length === 0 ?
                                                                    <div className='keybord-number position-relative disible-key'>
                                                                        <div className='position-absolute position-center'><h3 className='m-0'>.</h3></div>
                                                                    </div>
                                                                    :
                                                                    inputpin.filter((item, index) => item === '.').length === 0 ?
                                                                        <div className='keybord-number position-relative' onClick={(e) => handleAddItem('.')}>
                                                                            <div className='position-absolute position-center'><h3 className='m-0'>.</h3></div>
                                                                        </div>
                                                                        : <div className='keybord-number position-relative disible-key'>
                                                                            <div className='position-absolute position-center'><h3 className='m-0'>.</h3></div>
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-4 col-md-3 col-xl-3 text-center p-1">
                                                        <div className='keybord-number cursor position-relative' style={{ height: "48.5%" }} onClick={() => handleRemoveItem()}>
                                                            <div className='position-absolute cors position-center w-100'>
                                                                <img src={backspace} alt="" className="w-50 " />
                                                            </div>
                                                        </div>
                                                        <div className='keybord-number cursor mt-2 position-relative' style={{ height: "49%" }} onClick={() => handleRemoveAllItem()}>
                                                            <div className='position-absolute cors position-center w-100'>
                                                                <h1>C</h1>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 mt-2'>
                                            <div className='row'>
                                                <div className="col-12 col-md-4 col-lg-4 pe-0">
                                                    <div>
                                                        <Form.Control type="number" name="username" min={0} onChange={handleAddItemCut} onKeyUp={handleAddItemCut} className='font-14 control mb-2' placeholder='ส่วนลด' />
                                                    </div>

                                                    <Link to="/order/ordermenu" className="w-100 btn btn-red text-white py-2">
                                                        ยกเลิก
                                                    </Link>
                                                </div>
                                                <div className="col-12 col-md-8 col-lg-8">
                                                    {/* <button className="w-100 btn btn-blue text-white py-2 mb-2">
                                                    พิมพ์สรุปยอด
                                                </button> */}
                                                    {orderview ?
                                                        <div>
                                                            <ReactToPrint
                                                                trigger={() => <span className="w-100 btn btn-blue text-white py-2 mb-2">พิมพ์สรุปยอด</span>}
                                                                content={() => componentRef.current}
                                                                documenttTitle='name document'
                                                                onAfterPrint={() => printSuccess('yes')}
                                                            />
                                                            {datareceipt ?
                                                                <div className='position-absolute d-none'>
                                                                    <div className="ticket" ref={componentRef} >
                                                                        <div className="" />
                                                                        <ComponentToPrint
                                                                            overview={orderview}
                                                                            datareceipt={datareceipt}
                                                                            datapromotion={datapromotion} dataactive={dataactive}
                                                                            dataorderlist={dataorderlist}
                                                                            datapayment={datapayment}
                                                                            sumAll={sumAll()}
                                                                            sumPromotion={sumPromotion(sumAll())}
                                                                            sumTotal={sumTotal(parseFloat(sumAll()), sumPromotion(sumAll()))}
                                                                            sumMath={sumMath(sumTotal(parseFloat(sumAll()), (parseFloat(sumPromotion(sumAll())) + parseFloat((sumCut(inputpinCut))))))}
                                                                            sumCut={sumCut(inputpinCut)}
                                                                            type="payment"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                : null}
                                                        </div>
                                                        : null}


                                                    {parseFloat(inputpin.join('')) ? parseFloat(inputpin.join('')) >= sumTotal(parseFloat(sumAll()), (sumPromotion(sumAll()) + parseFloat(sumCut(inputpinCut)))) ?
                                                        <button className="w-100 btn btn-submit text-white py-2"
                                                            onClick={() => setModalconfirmpayment({
                                                                view: true, id: datareserve.id, datatotal: {
                                                                    no: datareserve.no,
                                                                    price: parseFloat(inputpin.join('')),
                                                                    total_food: parseFloat(sumAll()).toFixed(2),
                                                                    total_promotion: sumPromotion(sumAll()),
                                                                    total: sumMath(sumTotal(parseFloat(sumAll()), (sumPromotion(sumAll()) + parseFloat(sumCut(inputpinCut))))),
                                                                    type: radio,
                                                                    datamember: memberone,
                                                                }
                                                            })}>
                                                            ชำระเงิน
                                                        </button>
                                                        : radio === 1 ?
                                                            <button className="w-100 btn btn-submit text-white py-2" disabled>
                                                                ชำระเงิน
                                                            </button>
                                                            : <button className="w-100 btn btn-submit text-white py-2"
                                                                onClick={() => setModalconfirmpayment({
                                                                    view: true, id: datareserve.id, datatotal: {
                                                                        no: datareserve.no,
                                                                        price: parseFloat(inputpin.join('')),
                                                                        total_food: parseFloat(sumAll()).toFixed(2),
                                                                        total_promotion: sumPromotion(sumAll()),
                                                                        total: sumMath(sumTotal(parseFloat(sumAll()), (sumPromotion(sumAll()) + parseFloat(sumCut(inputpinCut))))),
                                                                        type: radio,
                                                                        datamember: memberone,
                                                                    }
                                                                })}>
                                                                ชำระเงิน
                                                            </button>
                                                        :
                                                        radio === 1 ?
                                                            <button className="w-100 btn btn-submit text-white py-2" disabled>
                                                                ชำระเงิน
                                                            </button>
                                                            : <button className="w-100 btn btn-submit text-white py-2"
                                                                onClick={() => setModalconfirmpayment({
                                                                    view: true, id: datareserve.id, datatotal: {
                                                                        no: datareserve.no,
                                                                        price: parseFloat(inputpin.join('')),
                                                                        total_food: parseFloat(sumAll()).toFixed(2),
                                                                        total_promotion: sumPromotion(sumAll()),
                                                                        total: sumMath(sumTotal(parseFloat(sumAll()), (sumPromotion(sumAll()) + parseFloat(sumCut(inputpinCut))))),
                                                                        type: radio,
                                                                        datamember: memberone,
                                                                    }
                                                                })}>
                                                                ชำระเงิน
                                                            </button>
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </Tab.Container>
                </div>
            </div>

            {/* {modalselecttable.view ?
                <Modalmenuselecttable show={modalselecttable.view} onHide={clickrefresh} id={modalselecttable.id} />
                : null} */}

            {modalconfirmpayment.view ?
                <Modalconfirmpayment show={modalconfirmpayment.view} onHide={() => setModalconfirmpayment({ view: false })} onLocation={() => onHideclick()} id={modalconfirmpayment.id} datatotal={modalconfirmpayment.datatotal} datacut={sumCut(inputpinCut)} />
                : null}
        </>
    );
};

export default Payment;