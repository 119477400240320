import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Modal, Form, Tab, Nav, Row, Col, Table } from "react-bootstrap";
import { compareAsc, format } from 'date-fns';
// import { Form, Tab, Nav, Row, Col, Accordion, ListGroup, Badge } from "react-bootstrap";

import AuthService from "../../components/services_member/auth.service";
// import PostService from "../../components/services_member/post.service";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import chold from '../../assets/img/confirm-hold.svg';
import icondel from '../../assets/img/icon-del.svg';


const Modalconfirmwarehouse = (datas) => {

    // console.log(datas.id)

    const submithold = (id) => {
        AuthService.updateWarehouse(datas.dataf).then(
            (res) => {
                if (res.status === 200) {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: '',
                        text: "เพิ่มชื่อสินค้าเรียบร้อยแล้ว",
                        showConfirmButton: false,
                        timer: 1500
                    }).then(function () {
                        datas.onSubmit()
                    })

                } else {
                    console.log(res.message)
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    const [validated, setValidated] = useState(false);
    const formRef = useRef(null);

    const handleSubmit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const data = new FormData(event.target);
        const branch = JSON.parse(localStorage.getItem("branch_data"));

        data.append("branch_id", branch.id);
        data.append("id", datas.dataf.id);
        data.append("type", "edit");

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

        } else {
            // datas.dataf.id
            event.preventDefault();

            AuthService.updateWarehouse(data).then(
                (res) => {
                    if (res.status === 200) {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: '',
                            text: "แก้ไขชื่อสินค้าเรียบร้อยแล้ว",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(function () {
                            datas.onSubmit()
                        })

                    } else {
                        console.log(res.message)
                    }
                },
                (error) => {
                    console.log(error);
                }
            );

        }
        setValidated(true);
    };

    const handleSubmitdel = (event, idcat) => {
        AuthService.delWarehouse(event, 'warehouse').then(
            (response) => {
                // console.log(response.status)
                if (response.status === 200) {
                    // window.location.reload();                    
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: '',
                        text: "ลบข้อมูลเรียบร้อยแล้ว",
                        showConfirmButton: false,
                        timer: 1500
                    }).then(function () {
                        datas.onSubmit()
                    }) 
                }
            },
            (error) => {
                console.log(error);
            }
        )
    }

    console.log(datas.dataf)

    return (
        <>
            <Modal {...datas} className="modal-custom" size="md" backdrop="static" keyboard={false}>
                <Modal.Header closeButton className="btn-close-white ">

                </Modal.Header>
                <Modal.Body className="p-0 bg-white">
                    <div className="d-block w-100 overflow-hidden">
                        {datas.type === "add" ?
                            <div className='pt-5 pb-4 bg-white px-4 text-center'>
                                <div className='font-18 weight-500 mt-1'>{datas.title}</div>
                                <div className='text-center px-3 mt-4'>
                                    <div className='row'>
                                        <div className="col-12 col-md-6 col-lg-6 pe-1">
                                            <button className="w-100 btn btn-calcel text-white py-2 me-3" onClick={datas.onHide}>
                                                ยกเลิก
                                            </button>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-6 ps-1">
                                            <button type='submit' onClick={() => submithold()} className="w-100 btn btn-bg text-white py-2 me-3">
                                                ยืนยัน
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : datas.type === "edit" ?
                                <div className='pt-5 pb-4 mb-3 bg-white px-4 text-start font-14'>
                                    <Form noValidate validated={validated} onSubmit={handleSubmit} ref={formRef}>
                                        <div className='w-100 ps-2 mb-2'>
                                            <div className="bg-white rounded-3 ms-2 position-relative">
                                                <div className='text-start p-3'>
                                                    <div className='font-22 py-3 weight-500'>แก้ไขชื่อสินค้า</div>
                                                    <div className='position-relative'>
                                                        <Row className="align-items-end mb-3">
                                                            <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                                                <Form.Label className="text-gray mb-2 font-14">SKU</Form.Label>
                                                                <Form.Control type="text" name='sku' className='input-custom font-14' defaultValue={datas.dataf.sku} style={{ padding: "10px 0px" }} required />
                                                            </Form.Group>
                                                        </Row>
                                                        <Row className="align-items-end mt-3 mb-3">
                                                            <Form.Group as={Col} md="12" className="position-relative" controlId="validationCustom01">
                                                                <Form.Label className="text-gray mb-2 font-14">รายการ</Form.Label>
                                                                <Form.Control type="text" name='name' className='font-14 input-custom' defaultValue={datas.dataf.name} placeholder='' required />
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="12" className="position-relative mt-3" controlId="validationCustom01">
                                                                <Form.Label className="text-gray mb-2 font-14">หน่วย</Form.Label>
                                                                <Form.Select aria-label="Default select example" className='font-14' name='unit' defaultValue={datas.dataf.unit} required>
                                                                    <option value="">เลือกหน่วย</option>
                                                                    <option value="กรัม">กรัม</option>
                                                                    <option value="กิโลกรัม">กิโลกรัม</option>
                                                                    <option value="ถุง">ถุง</option>                                                                    
                                                                    <option value="ขวด">ขวด</option>
                                                                    <option value="ใบ">ใบ</option>
                                                                    <option value="ชิ้น">ชิ้น</option>
                                                                    <option value="ลัง">ลัง</option>
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w-100 ps-2 mt-2'>
                                                <div className='row'>
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <button type='submit' className="w-100 btn btn-submit text-white py-2">
                                                            แก้ไขชื่อสินค้า
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                                :
                                <div className='pt-5 pb-4 bg-white px-4 text-center'>
                                    <div><img src={icondel} alt="" className="" style={{ width: "50px" }} /></div>
                                    <div className='font-18 weight-500 mt-4'>ยืนยันการลบ {datas.dataf.name}</div>
                                    <div className='text-center px-3 mt-4'>
                                        <div className='row'>
                                            <div className="col-12 col-md-6 col-lg-6 pe-1">
                                                <span className="w-100 btn btn-calcel text-white py-2 me-3" onClick={datas.onHide}>
                                                    ยกเลิก
                                                </span>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 ps-1">
                                                <button type='submit' onClick={() => handleSubmitdel(datas.dataf.id)} className="w-100 btn btn-bg text-white py-2 me-3">
                                                    ยืนยัน
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>

        </>
    );
};

export default Modalconfirmwarehouse;