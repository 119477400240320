import React, { useState } from 'react';
import { Form, Col, Row } from "react-bootstrap";
// import axios from 'axios';
import { useNavigate,Link } from "react-router-dom";

import AuthService from "../../components/services_member/auth.service";

import back from '../../assets/img/icon-back.svg';
import eye from '../../assets/img/eye.svg';


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const Newpassword = () => {

    let history = useNavigate();

    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({})
    // const [errorsemail, setErrorsemail] = useState()

    const findFormErrors = () => {

        const { otp, password, password2 } = form

        const newErrors = {};

        // const emailval = errorsemail;

        // const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        
        const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[0-9])(?=.{6,})");
        // const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
        const otpRegex = /^[0-9\b]+$/;

        if (!otpRegex.test(otp)) {
            newErrors.otp = 'This otp cannot be used.!'
        }

        // if(emailval === email) {
        //     newErrors.email = 'This email cannot be used.!'
        // }

        if (!strongRegex.test(password)) {
            newErrors.password = '.'
        }

        if (password2 !== password) {
            newErrors.password2 = 'Confirm Password Not Match!'
        }
        return newErrors
    }

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        })
    }

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {

        event.preventDefault();

        const newErrors = findFormErrors()

        const form = event.currentTarget;

        const data = new FormData(event.target);

        // const { email,password,password2 } = event.target.elements;   
        // console.log(newErrors)
        // const registera = JSON.parse(localStorage.getItem("register"));

        // data.append("username", registera.name);
        // data.append("id_card", registera.idnumber);
        // data.append("tel", registera.tel);

        if (Object.keys(newErrors).length > 0) {
            // We got errors!
            setErrors(newErrors)
        } else {
            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            } else {
                // alert(55)
                AuthService.resetpass(data).then(
                    (res) => {     
                      console.log(res) 

                      if (res.status === 200) {
                            // localStorage.removeItem("register");
                            // history("/registersuccess"); 
                            // window.location.reload(); 
                            Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: '',
                                text: "ตั้งค่ารหัสผ่านใหม่เรียบร้อยแล้ว",
                                showConfirmButton: false,
                                timer: 1500
                            }).then(function () {
                                history("/"); 
                            }) 
                      } else {
                            setErrors({otp:"This OTP cannot be used.!"})
                      }                                  
                    },
                    (error) => {
                      console.log(error);
                      // setErrors(true)
                    }
                  );
            }
        }
        setValidated(true);
    };

    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordShown2, setPasswordShown2] = useState(false);

    // Password toggle handler
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };
    const togglePassword2 = () => {
        setPasswordShown2(!passwordShown2);
    };

    const autoTab2 = (obj) => {

        var obj_l = obj.target.value;

        const newErrors = findFormErrors()

        if (Object.keys(newErrors).length > 0) {
            // We got errors!
            setErrors(newErrors)
        } else {
            setErrors({})
        }        
    };

    return (
        <>
            <div className="wrapper" style={{ backgroundColor: "#EAEAEA", zIndex: "1" }}>
                <section className="" >
                    <div className="container-fluid">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-12 col-md-7 col-xl-4 text-start h-100vh position-relative">
                                <div className='position-absolute w-100 position-center px-3'>
                                    <div className='box-shadow pb-4 bg-white'>
                                        <div className='bg-topic text-white p-3'>
                                            <h4 className='weight-400 m-0'>                                                
                                                ตั้งรหัสผ่านใหม่
                                            </h4>
                                        </div>
                                        <Form noValidate validated={validated} onSubmit={handleSubmit} className="px-5 mt-4">
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md="12" className="position-relative mt-3" controlId="validationCustom01">
                                                    <Form.Label className='text-gray mb-1 font-14'>รหัส OTP </Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        className=""
                                                        placeholder="OTP"
                                                        maxLength={4}
                                                        name="otp"  
                                                        onKeyUp={autoTab2}
                                                        onChange={e => setField('otp', e.target.value)}  
                                                        isInvalid={ !!errors.otp}                                  
                                                    />      
                                                    <Form.Control.Feedback type='invalid' className='position-absolute text-end pe-4'>
                                                        {errors.otp}
                                                    </Form.Control.Feedback>                                              
                                                </Form.Group>
                                                <Form.Group as={Col} md="12" className="position-relative mt-3" controlId="validationCustom01">
                                                    <Form.Label className='text-gray mb-1 font-14'>รหัสผ่านใหม่</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type={passwordShown ? "text" : "password"}
                                                        placeholder="รหัสผ่านใหม่"
                                                        className=""
                                                        name="password"
                                                        minLength={6}
                                                        onKeyUp={autoTab2}
                                                        onChange={e => setField('password', e.target.value)}
                                                        isInvalid={!!errors.password}
                                                    />
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.password}
                                                    </Form.Control.Feedback>
                                                    <span className="sh-pass cursor-pointer" onClick={togglePassword}>
                                                        <img src={eye} alt="" className="" />
                                                        { passwordShown ? <span className='hide-password'></span> : null }
                                                    </span>
                                                </Form.Group>
                                                <Form.Group as={Col} md="12" className="position-relative mt-3" controlId="validationCustom01">
                                                    <Form.Label className='text-gray mb-1 font-14'>ยืนยันรหัสผ่านใหม่</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type={passwordShown2 ? "text" : "password"}
                                                        placeholder="ยืนยันรหัสผ่านใหม่"
                                                        className=""
                                                        name="password2"
                                                        minLength={6}
                                                        onKeyUp={autoTab2}
                                                        onChange={e => setField('password2', e.target.value)}
                                                        isInvalid={!!errors.password2}
                                                    />
                                                    <span className="sh-pass cursor-pointer" onClick={togglePassword2}>
                                                        <img src={eye} alt="" className="" />
                                                        { passwordShown2 ? <span className='hide-password'></span> : null }
                                                    </span>

                                                    <Form.Control.Feedback type='invalid' className='position-absolute text-end pe-4'>
                                                        {errors.password2}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <div className='mt-4 font-12 text-gray'>รหัสผ่านต้องไม่ต่ำกว่า 6 ตัว <br/> และประกอบด้วยตัวอักษร และตัวเลข</div>
                                            </Row>
                                            <button type="submit" className="w-100 btn btn-bg text-white mt-5">ถัดไป</button>
                                        </Form>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        </>
    );
};

export default Newpassword;