import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Table, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import AuthService from "../../components/services_member/auth.service";
import PostService from "../../components/services_member/post.service";

// import { BrowserRouter as Router } from "react-router-dom";
// import axios from 'axios';
// import { Link } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
// import Rang from '../components/lang';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import { Form, Tab, Nav, Row, Col } from "react-bootstrap";

import calendar from '../../assets/img/calendar.svg';

import ipdf from '../../assets/img/i-pdf.svg';
import iexcel from '../../assets/img/i-excel.svg';
import dellist from '../../assets/img/del-list.svg';
import addlist from '../../assets/img/add-list.svg';
import icondel from '../../assets/img/icon-del.svg';

import InputMask from 'react-input-mask';

import Swal from 'sweetalert2'

const Modalmemberadd = (datas) => {

    let history = useNavigate();

    let date = new Date();

    function addYears(date, years) {
        date.setFullYear(date.getFullYear() + years);
        return date;
    }

    let dateB = addYears(new Date(), 1);

    let datatatb = datas.type === "edit" ? JSON.parse(datas.datamember.add_vat) : [];

    const [inputFields, setInputFields] = useState(datatatb);

    const [todayDate, setTodayDate] = useState(new Date());

    const [method2, setMethod2] = useState(datas.type === "edit" ? datas.datamember.vat : []);

    const [dataOrder, setDataOrder] = useState([]);
    const [dateday, setDateday] = useState({ start: date, end: dateB });
    const [datedayB, setDatedayB] = useState({ start: datas.datamember && new Date(datas.datamember.date_active), end: datas.datamember && new Date(datas.datamember.date_exp) });
    // const [datedayB, setDatedayB] = useState({ start: '', end: '' });

    console.log(datas.datamember)

    const fetchData = useCallback(async (id) => {
        // const branch = JSON.parse(localStorage.getItem("branch_data"));

        PostService.getReserveallmember(id).then(
            (response) => {
                if (response.data.status === 200) {
                    setDataOrder(response.data.reserve);
                    // setProduct(response.data.memberdata);
                    // setDatedayB({start:datas.datamember.date_active})
                } else {
                    setDataOrder([]);
                }
            },
            (error) => {
                console.log(error);
            }
        )
    });

    useEffect(() => {

        if (datas.type === "detail") {
            fetchData(datas.datamember.id);
        }

    }, []);

    const dateoption = {
        locale: {
            format: 'DD/MM/YYYY'
        },
        startDate: dateday.start,
        maxDate: todayDate,
        singleDatePicker: true,
        // autoApply: true,
    };

    const dateoptionB = {
        locale: {
            format: 'DD/MM/YYYY'
        },
        startDate: dateday.end,
        // maxDate: todayDate,
        singleDatePicker: true,
        // autoApply: true,
    };
    const dateoptionC = {
        locale: {
            format: 'DD/MM/YYYY'
        },
        startDate: datedayB.start,
        // maxDate: todayDate,
        singleDatePicker: true,
        // autoApply: true,
    };
    const dateoptionD = {
        locale: {
            format: 'DD/MM/YYYY'
        },
        startDate: datedayB.end,
        // maxDate: todayDate,
        singleDatePicker: true,
        // autoApply: true,
    };

    const [validated, setValidated] = useState(false);
    const formRef = useRef(null);

    const addFields = () => {
        let newfield = { id: '', name_vat: '', number_vat: '', add_vat: '' }
        setInputFields([...inputFields, newfield])
    }

    const removeFields = (index) => {
        let datae = [...inputFields];
        datae.splice(index, 1)

        // console.log(datae)

        setInputFields(datae)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        const dataf = new FormData(event.target);
        const branch = JSON.parse(localStorage.getItem("branch_data"));

        dataf.append("branch_id", branch.id);
        dataf.append("type", "add");

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();
        } else {

            AuthService.updateMember(dataf).then(
                (res) => {
                    //   console.log(res)   
                    if (res.status === 200) {
                        // history("/setting/setting"); 
                        // window.location.reload();
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: '',
                            text: "บันทึกข้อมูลเรียบร้อยแล้ว",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(function () {
                            datas.onHide();
                            // formRef.current.reset();
                            setValidated(false);
                        })
                    } else {
                        // setErrors({email:"This email cannot be used.!"})
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        }
        setValidated(true);
    };

    const handleSubmitedit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        const dataf = new FormData(event.target);
        const branch = JSON.parse(localStorage.getItem("branch_data"));

        dataf.append("id", datas.datamember.id);
        dataf.append("branch_id", branch.id);
        dataf.append("type", "edit");

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();
        } else {

            AuthService.updateMember(dataf).then(
                (res) => {
                    //   console.log(res)   
                    if (res.status === 200) {
                        // window.location.reload();
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: '',
                            text: "บันทึกข้อมูลเรียบร้อยแล้ว",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(function () {
                            datas.onHide();
                            // formRef.current.reset();
                            setValidated(false);
                        })
                    } else {
                        datas.onHide();
                        // setErrors({email:"This email cannot be used.!"})
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        }
        setValidated(true);
    };

    const handleSubmitdel = (event, idcat) => {
        // alert(event)
        AuthService.delMember(event, 'member').then(
            (response) => {
                if (response.status === 200) {

                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: '',
                        text: "ลบข้อมูลเรียบร้อยแล้ว",
                        showConfirmButton: false,
                        timer: 1500
                    }).then(function () {
                        datas.onHide()
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        )
    }



    const handledateday = (event) => {
        // fetchData(event.target.value)
        setDateday(event.target.value)
        // {start:'',date:''}
        // setOrderview()
    }

    // console.log(datas.datamember)
    return (
        <>
            <Modal {...datas} className="modal-custom" size={datas.type === "detail" ? "lg" : "md"} backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton className="btn-close-white ">

                </Modal.Header>
                <Modal.Body className="p-0">
                    <div className="d-block w-100 scroll-custom">
                        {
                            datas.type === "detail" ? (<>
                                <Modal.Title className='py-3 bg-white px-4 shadow-sm position-fixed w-100' style={{ zIndex: "1" }}>
                                    <div className='text-default font-22'>
                                        ยอดสะสม
                                    </div>
                                    <div className='font-14 text-gray weight-400'>{datas.datamember.name}</div>
                                    {/* <div className='position-relative w-50 mt-2'>
                                        <Form.Group controlId="dob">
                                            <DateRangePicker initialSettings={dateoption} >
                                                <input type="text" className="form-control" name="dateday" />
                                            </DateRangePicker >
                                            <div className='position-absolute' style={{ top: "4px", right: "12px" }}>
                                                <img src={calendar} alt="" className="me-1" style={{ width: "18px" }} />
                                            </div>
                                        </Form.Group>
                                    </div> */}
                                    {/* <div className='position-absolute font-16' style={{ right: "15px", top: "25px" }}>
                                        นำออกเป็น
                                        <span className='ms-2 me-1'>
                                            <img src={ipdf} />
                                        </span>
                                        <span>
                                            <img src={iexcel} />
                                        </span>
                                    </div> */}
                                </Modal.Title>
                                <div className='h-100per-modal bg-white px-4 py-5'>
                                    <div className='mt-5 pt-3'>
                                        <Table bordered className='table-report'>
                                            <thead>
                                                <tr>
                                                    <th>ลำดับที่</th>
                                                    <th>วันที่</th>
                                                    <th>ยอดสะสม</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dataOrder.length ? dataOrder.sort((a, b) => (b.id) - (a.id)).map((val, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            {index + 1}
                                                        </td>
                                                        <td>
                                                            {val.date_bill}
                                                        </td>
                                                        <td>
                                                            {parseFloat(val.data_price_Total).toLocaleString()}
                                                        </td>
                                                    </tr>
                                                )) : null}
                                            </tbody>
                                        </Table>

                                    </div>
                                </div>
                            </>
                            ) :
                                datas.type === "edit" ? (
                                    <>
                                        <Modal.Title className='py-3 bg-white px-4 shadow-sm position-fixed w-100' style={{ zIndex: "1" }}>
                                            <div className='text-default font-22'>
                                                แก้ไขข้อมูล
                                            </div>
                                        </Modal.Title>
                                        <Form noValidate validated={validated} onSubmit={handleSubmitedit} className="">
                                            <div className='h-100per-modal bg-white px-4 py-5'>
                                                <div>
                                                    <Row className="mt-5">
                                                        <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                                            <Form.Label className="font-12 text-gray mb-0">ชื่อ-นามสกุล</Form.Label>
                                                            <Form.Control type="text" name="name" defaultValue={datas.datamember.name} className='font-14 input-custom' placeholder='xxxxxxxx' required />
                                                        </Form.Group>
                                                    </Row>
                                                    <Row className="align-items-end mt-3 mb-5">
                                                        <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                                            <Form.Label className="font-12 text-gray mb-0">เบอร์โทร</Form.Label>
                                                            <Form.Control type="text" name='tel' defaultValue={datas.datamember.tel} className='font-14 input-custom' placeholder='xxx-xxx-xxxx' required />
                                                            {/* <InputMask
                                                                mask="999-999-9999"
                                                                maskChar={null}
                                                                defaultValue={datas.datamember.tel}
                                                                required
                                                                className='font-14 input-custom form-control'
                                                                type="text"
                                                                placeholder="xxx-xxx-xxxx"
                                                                name="tel"
                                                                pattern="\d{3}[\-]\d{3}[\-]\d{4}"
                                                            /> */}
                                                        </Form.Group>
                                                    </Row>
                                                    <Row className="align-items-end mt-3 mb-3">
                                                        <Form.Group as={Col} md="6" className="position-relative pe-1" controlId="validationCustom01">
                                                            <Form.Label className="font-12 text-gray mb-0">วันที่สมัคร</Form.Label>
                                                            {/* <Form.Control type="text" name='add_vat' className='font-14 input-custom' placeholder='xxxxxxx' /> */}
                                                            <Form.Group controlId="dob" className='position-relative'>
                                                                <DateRangePicker selected={datas.datamember.date_active} initialSettings={dateoptionC} onApply={(e) => (handledateday(e, 'a'))}>
                                                                    <input type="text" className="font-14 form-control input-custom px-2 py-2" name="date_start" required />
                                                                </DateRangePicker >
                                                                <div className='position-absolute' style={{ top: "10px", right: "12px" }}>
                                                                    <img src={calendar} alt="" className="" style={{ width: "17px" }} />
                                                                </div>
                                                            </Form.Group>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6" className="position-relative pe-1" controlId="validationCustom01">
                                                            <Form.Label className="font-12 text-gray mb-0">วันที่สิ้นสุด </Form.Label>
                                                            {/* <Form.Control type="text" name='add_vat' className='font-14 input-custom' placeholder='xxxxxxx' /> */}
                                                            <Form.Group controlId="dob" className='position-relative'>
                                                                <DateRangePicker selected={datas.datamember.date_active} initialSettings={dateoptionD} onApply={(e) => (handledateday(e, 'b'))}>
                                                                    <input type="text" className="font-14 form-control input-custom px-2 py-2" name="date_end" required />
                                                                </DateRangePicker >
                                                                <div className='position-absolute' style={{ top: "10px", right: "12px" }}>
                                                                    <img src={calendar} alt="" className="" style={{ width: "17px" }} />
                                                                </div>
                                                            </Form.Group>
                                                        </Form.Group>
                                                    </Row>
                                                    <Row className="align-items-end mt-3 mb-3">
                                                        <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                                            <Form.Label className="font-12 text-gray mb-0">หมายเหตุ</Form.Label>
                                                            <Form.Control type="text" name='description' defaultValue={datas.datamember.description} className='font-14 input-custom' placeholder='xxxxxxx' />
                                                        </Form.Group>
                                                    </Row>
                                                    <div className=''>
                                                        <div className='text-default font-18 weight-500'>
                                                            ข้อมูลสำหรับออกใบกำกับภาษี
                                                        </div>
                                                    </div>
                                                    {/* {console.log(inputFields)} */}
                                                    {inputFields.map((input, index) => (
                                                        <div key={index} className="mb-3">
                                                            <div className="ex-radio mt-3">
                                                                <input type="radio" name="vat" value={index} onChange={() => setMethod2(input.id)} defaultChecked={input.id == datas.datamember.vat ? true : false} />
                                                                <span className="radio_one mb-2 ps-4 text-gray font-12" style={{ width: "100%", height: "auto" }} >
                                                                    <div className='ms-2'>
                                                                        <Row className="align-items-end mb-3">
                                                                            <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                                                                <Form.Label className="font-12 text-gray mb-0">ชื่อ </Form.Label>
                                                                                <Form.Control type="text" defaultValue={input.name_vat} name='name_vat[]' className='font-14 input-custom' placeholder='xxxxxxx' />
                                                                            </Form.Group>
                                                                        </Row>
                                                                        <Row className="align-items-end mt-3 mb-3">
                                                                            <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                                                                <Form.Label className="font-12 text-gray mb-0">เลขประจำตัวผู้เสียภาษี</Form.Label>
                                                                                <Form.Control type="text" defaultValue={input.number_vat} name='number_vat[]' className='font-14 input-custom' placeholder='xxxxxxxxxxxxx' />
                                                                            </Form.Group>
                                                                        </Row>
                                                                        <Row className="align-items-end mt-3 mb-3">
                                                                            <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                                                                <Form.Label className="font-12 text-gray mb-0">ที่อยู่</Form.Label>
                                                                                <Form.Control type="text" defaultValue={input.add_vat} name='add_vat[]' className='font-14 input-custom' placeholder='xxxxxxx' />
                                                                            </Form.Group>
                                                                        </Row>
                                                                    </div>
                                                                </span>
                                                            </div>

                                                        </div>
                                                    ))}
                                                    <div className='mt-2'>
                                                        <Form.Group as={Col} md="4" className="position-relative pifo-b" controlId="validationCustom01">
                                                            <span onClick={() => removeFields()} className="btn py-0 px-2" style={{ opacity: "1" }}>
                                                                <img src={dellist} alt="" className="" style={{ width: "30px" }} />
                                                            </span>
                                                            <span className='cursor' onClick={addFields}>
                                                                <img src={addlist} alt="" className="" style={{ width: "30px" }} />
                                                            </span>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='text-center py-3 px-3 bg-gray'>
                                                <div className='row'>
                                                    <div className="col-12 col-md-6 col-lg-6 pe-1">
                                                        <span className="w-100 btn btn-calcel text-white py-2 me-3" onClick={datas.onHide}>
                                                            ยกเลิก
                                                        </span>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6 ps-1">
                                                        <button type='submit' className="w-100 btn btn-submit text-white py-2 me-3">
                                                            บันทึก
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </>
                                ) : datas.type === "del" ? (
                                    <div className='pt-5 pb-4 bg-white px-4 text-center'>
                                        <div><img src={icondel} alt="" className="" style={{ width: "50px" }} /></div>
                                        <div className='font-18 weight-500 mt-4'>ยืนยันการลบสมาชิก {datas.datamember.name}</div>
                                        <div className='text-center px-3 mt-4'>
                                            <div className='row'>
                                                <div className="col-12 col-md-6 col-lg-6 pe-1">
                                                    <span className="w-100 btn btn-calcel text-white py-2 me-3" onClick={datas.onHide}>
                                                        ยกเลิก
                                                    </span>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6 ps-1">
                                                    <button type='submit' onClick={() => handleSubmitdel(datas.datamember.id)} className="w-100 btn btn-bg text-white py-2 me-3">
                                                        ยืนยัน
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <Modal.Title className='py-3 bg-white px-4 shadow-sm position-fixed w-100' style={{ zIndex: "1" }}>
                                            <div className='text-default font-22'>
                                                เพิ่มสมาชิก
                                            </div>
                                        </Modal.Title>
                                        <Form noValidate validated={validated} onSubmit={handleSubmit} className="">
                                            <div className='h-100per-modal bg-white px-4 py-5'>

                                                <div>
                                                    <Row className="mt-5">
                                                        <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                                            <Form.Label className="font-12 text-gray mb-0">ชื่อ-นามสกุล</Form.Label>
                                                            <Form.Control type="text" name="name" className='font-14 input-custom' placeholder='xxxxxxxx' required />
                                                            <Form.Control type="hidden" name="id" className='font-14 input-custom' />
                                                        </Form.Group>
                                                    </Row>
                                                    <Row className="align-items-end mt-3 mb-5">
                                                        <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                                            <Form.Label className="font-12 text-gray mb-0">เบอร์โทร</Form.Label>
                                                            <Form.Control type="text" name='tel' className='font-14 input-custom' placeholder='xxxxxxxxxx' required />
                                                            {/* <InputMask
                                                                mask="999-999-9999"
                                                                maskChar={null}
                                                                required
                                                                className='font-14 input-custom form-control'
                                                                type="text"
                                                                placeholder="xxxxxxxxxx"
                                                                name="tel"
                                                            /> */}
                                                        </Form.Group>
                                                    </Row>
                                                    <Row className="align-items-end mt-3 mb-3">
                                                        <Form.Group as={Col} md="6" className="position-relative pe-1" controlId="validationCustom01">
                                                            <Form.Label className="font-12 text-gray mb-0">วันที่สมัคร {console.log(dateday.end)}</Form.Label>
                                                            {/* <Form.Control type="text" name='add_vat' className='font-14 input-custom' placeholder='xxxxxxx' /> */}
                                                            <Form.Group controlId="dob" className='position-relative'>
                                                                <DateRangePicker selected={dateday.start} initialSettings={dateoption} onApply={(e) => (handledateday(e, 'a'))}>
                                                                    <input type="text" className="font-14 form-control input-custom px-2 py-2" name="date_start" required />
                                                                </DateRangePicker >
                                                                <div className='position-absolute' style={{ top: "10px", right: "12px" }}>
                                                                    <img src={calendar} alt="" className="" style={{ width: "17px" }} />
                                                                </div>
                                                            </Form.Group>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6" className="position-relative pe-1" controlId="validationCustom01">
                                                            <Form.Label className="font-12 text-gray mb-0">วันที่สิ้นสุด </Form.Label>
                                                            {/* <Form.Control type="text" name='add_vat' className='font-14 input-custom' placeholder='xxxxxxx' /> */}
                                                            <Form.Group controlId="dob" className='position-relative'>
                                                                <DateRangePicker selected={dateday.end} initialSettings={dateoptionB} onApply={(e) => (handledateday(e, 'b'))}>
                                                                    <input type="text" className="font-14 form-control input-custom px-2 py-2" name="date_end" required />
                                                                </DateRangePicker >
                                                                <div className='position-absolute' style={{ top: "10px", right: "12px" }}>
                                                                    <img src={calendar} alt="" className="" style={{ width: "17px" }} />
                                                                </div>
                                                            </Form.Group>
                                                        </Form.Group>
                                                    </Row>
                                                    <Row className="align-items-end mt-3 mb-3">
                                                        <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                                            <Form.Label className="font-12 text-gray mb-0">หมายเหตุ</Form.Label>
                                                            <Form.Control type="text" name='description' className='font-14 input-custom' placeholder='xxxxxxx' />
                                                        </Form.Group>
                                                    </Row>
                                                    <div className=''>
                                                        <div className='text-default font-18 weight-500'>
                                                            ข้อมูลสำหรับออกใบกำกับภาษี
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <Row className="align-items-end mt-3 mb-3">
                                                            <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                                                <Form.Label className="font-12 text-gray mb-0">ชื่อ</Form.Label>
                                                                <Form.Control type="text" name='name_vat' className='font-14 input-custom' placeholder='xxxxxxx' />
                                                            </Form.Group>
                                                        </Row>
                                                        <Row className="align-items-end mt-3 mb-3">
                                                            <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                                                <Form.Label className="font-12 text-gray mb-0">เลขประจำตัวผู้เสียภาษี</Form.Label>
                                                                <Form.Control type="text" name='number_vat' className='font-14 input-custom' placeholder='xxxxxxxxxxxxx' />
                                                            </Form.Group>
                                                        </Row>
                                                        <Row className="align-items-end mt-3 mb-3">
                                                            <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                                                <Form.Label className="font-12 text-gray mb-0">ที่อยู่</Form.Label>
                                                                <Form.Control type="text" name='add_vat' className='font-14 input-custom' placeholder='xxxxxxx' />
                                                            </Form.Group>
                                                        </Row>

                                                    </div>

                                                </div>
                                            </div>
                                            <div className='text-center py-3 px-3 bg-gray'>
                                                <div className='row'>
                                                    <div className="col-12 col-md-6 col-lg-6 pe-1">
                                                        <span className="w-100 btn btn-calcel text-white py-2 me-3" onClick={datas.onHide}>
                                                            ยกเลิก
                                                        </span>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6 ps-1">
                                                        <button type='submit' className="w-100 btn btn-submit text-white py-2 me-3">
                                                            บันทึก
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </>
                                )}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Modalmemberadd;