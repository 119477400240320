import React, { useState, useCallback, useEffect } from 'react';
// import axios from 'axios';
import { useNavigate,Link} from "react-router-dom";

import PostService from "../../components/services_member/post.service";
import AuthService from "../../components/services_member/auth.service";

import logo from '../../assets/img/ryuden-logo.svg';
import noimg from '../../assets/img/no-img.svg';
import plus from '../../assets/img/icon-plus.svg';
import back from '../../assets/img/icon-back-blue.svg';

import Loadingpage from "../../components/loading_page";

// import { Link } from "react-tiger-transition";

const Branch = () => {

    localStorage.removeItem("branch_data");
    localStorage.removeItem("branchdata");
    localStorage.removeItem("table_order");

    let history = useNavigate();

    const user = AuthService.getCurrentUser();
    // const business = AuthService.getCurrentBusiness();

    // if (!user) {
    //     history("/");
    //     window.location.reload();
    // }else{
    //     if(!business){
    //         history("/business");
    //         window.location.reload();
    //     }
    // }   
    if (!user) {
        history("/");
        window.location.reload();
    }

    const [databranch, setDatabranch] = useState([]);
    const fetchData = useCallback(async () => {
        const user = AuthService.getCurrentUser();
        if (user) {
            PostService.getAllBranchPosts().then(
                
                (response) => {

                    // console.log(response);

                    if (response.status === 200) {
                        setDatabranch(response.data);
                        localStorage.setItem("member_data", JSON.stringify(response.member));
                    } else {
                        AuthService.logout();
                        history("/");
                        window.location.reload();
                        localStorage.removeItem("member_data");
                    }
                },
                (error) => {
                    if (error.response && error.response.status === 403) {
                        history("/");
                        window.location.reload();
                        localStorage.removeItem("member_data");
                    }
                }
            )
        } else {
            history("/");
        }
    });

    useEffect(() => {
        let timer1 = setTimeout(() => fetchData(), 1000);
        return () => {
            clearTimeout(timer1);
        };
    }, []);

    const handleBranch = (e) => {
        AuthService.getBranch(e).then(
            (response) => {
                if (response.status === 200) {
                    localStorage.setItem("branch_data", JSON.stringify(response.data));
                    history("/branchpininput");
                    window.location.reload();
                }
            },
            (error) => {
                if (error.response && error.response.status === 403) {
                    history("/branch");
                    window.location.reload();
                    localStorage.removeItem("branch_data");
                }
            }
        )
    }

    return (
        <>
            <Loadingpage timeout="1000" />
            <div className="wrapper" style={{ backgroundColor: "#EAEAEA", zIndex: "1" }}>
                <section className="" >
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-12 col-xl-12 text-start h-100vh position-relative">
                                <div className='position-absolute w-100 position-center px-3'>
                                    <div className="row">
                                        <div className="col-12 col-md-12 col-xl-12 text-center position-relative mb-4">
                                            <h2>เลือกสาขา</h2>
                                        </div>
                                        {
                                            databranch.map((e, i) => (
                                                <div className="col-6 col-md-3 col-xl-3 text-start" key={i}>
                                                    <button onClick={() => handleBranch(e.id)} className="select-business">
                                                        <div className='box-shadow bg-white rounded-3 position-relative box-business'>
                                                            <div className="text-center position-absolute w-100 position-center" >
                                                                <div className='py-3'>
                                                                    <div className='mb-3 px-3' style={{ height: "200px" }}>
                                                                        {/* <img src={logo} alt="" className="w-100 px-5" /> */}
                                                                        {e.logo ?
                                                                            <img src={process.env.REACT_APP_API_URL + "/uploads/branch/" + e.logo} alt="" className="w-100 px-5" />
                                                                            :
                                                                            <img src={noimg} alt="" className="w-100 px-5" />
                                                                        }
                                                                    </div>
                                                                    <h5>{e.name}</h5>
                                                                    <div className='text-gray'>{e.detail}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </div>
                                            ))
                                        }
                                        <div className="col-6 col-md-3 col-xl-3 text-start ">
                                            <Link to="/addbranch" transition="default-fade" className="">
                                                <div className='box-shadow bg-white rounded-3 position-relative box-business'>
                                                    <div className="text-center position-absolute w-100 position-center" >
                                                        <div className='py-3'>
                                                            <div className='mb-4 px-5'>
                                                                <img src={plus} alt="" className="w-75 px-5" />
                                                            </div>
                                                            <h5>เพิ่มสาขา</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='position-fixed w-100 bg-topic text-start' style={{ top: "0px", left: "0px", height: "80px" }}>
                        <div className='d-inline-block ms-4'>
                            {/* <div className='box-logo-size d-inline-block' style={{ marginTop: "10px" }}>
                                { business.logo ? (
                                    <img src={ process.env.REACT_APP_API_URL+ "/uploads/business/" + business.logo } alt="" className="w-100" style={{ borderRadius:"50%" }} />
                                ):(
                                    <img src={noimg} alt="" className="w-100" style={{ borderRadius:"50%" }}/>
                                )}
                            </div>
                            <div className='d-inline-block ms-3' style={{ verticalAlign: "middle" }}>
                                <div className='text-white font-18 mb-1'>{business.name}</div>
                                <div className='text-white font-12'><span className='span text-default weight-500 me-2'>Premium</span>29/11/2021 - 29/02/2022</div>
                            </div> */}
                        </div>
                        <div className='d-inline-block float-end mt-4 me-4'>
                            <Link to="/" transition="default-fade" className="btn btn-bggray py-1">
                                ออกจากระบบ
                            </Link>
                        </div>
                    </div>
                    {/* <div className='position-absolute' style={{ top: "110px", left: "30px" }}>
                        <h4 className='weight-500 m-0'>
                            <Link to="/" transition="default-fade" className="text-decoration-none">
                                <img src={back} alt="" className="me-3 mb-1" style={{ height: "20px" }} />
                            </Link>
                            ย้อนกลับ
                        </h4>
                    </div> */}
                </section>
            </div >
        </>
    );
};

export default Branch;