import React, { useState, useCallback, useEffect } from 'react';
// import axios from 'axios';
import { useNavigate,Link } from "react-router-dom";

import AuthService from "../../components/services_member/auth.service";
import PostService from "../../components/services_member/post.service";

import help from '../../assets/img/help.svg';
import search from '../../assets/img/search.svg';

import Menuleft from "../../layout/pagesadmin/menuleft";
import Tabcontent from "../menu/menu_tab_content";

// import { Link } from "react-tiger-transition";
import { Form, Tab, Nav, Dropdown } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
const Menumanage = () => {

    const { t } = useTranslation();
    let history = useNavigate();
    const user = AuthService.getCurrentUser();
    const branch = AuthService.getCurrentBranch();

    localStorage.removeItem("table_order");

    if (!user) {
        history("/");
    } else {
        if (!branch) {
            history("/branch");
        }
    }

    const [datacat, setDatacat] = useState([]);
    const [product, setProduct] = useState([]);
    const [dataproduct, setDataproduct] = useState([]);
    const [promotion, setPromotion] = useState([]);
    const [datapromotion, setDatapromotion] = useState([]);

    const [reserveview, setReserveview] = useState({ search: true, reserve: false, detail: false });

    const fetchData = useCallback(async () => {

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        PostService.getMenuall(branch.id).then(
            (response) => {
                if (response.data.status === 200) {
                    setDatacat(response.data.data_cat);

                    setProduct(response.data.data_product);
                    setDataproduct(response.data.data_product);

                    setPromotion(response.data.data_promotion);
                    setDatapromotion(response.data.data_promotion);
                }
            },
            (error) => {
                console.log(error);
            }
        )
    });

    useEffect(() => {
        fetchData()
    }, []);

    const handlerefresh = (event) => {
        fetchData()
    }

    // const searchChange = (event) => {
    //     setDatasearch(event.target.value)
    // }

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        const data = new FormData(event.target);
        // console.log(data.get('search'))

        const keyword = data.get('search');

        if (keyword !== '') {
            const results = product.filter((product) => {
                return product.name.toLowerCase().startsWith(keyword.toLowerCase());
                // Use the toLowerCase() method to make it case-insensitive
            });
            setDataproduct(results);

            const results2 = promotion.filter((promotion) => {
                return promotion.promotion.toLowerCase().startsWith(keyword.toLowerCase());
            });
            setDatapromotion(results2);

        } else {
            setDataproduct(product);
            setDatapromotion(promotion);
        }
    }

    const [dataKey, setDataKey] = useState('อาหารทั้งหมด');

    const handleSelect = (key) => {
        // this.setState({
        //     key: key
        // })
        setDataKey(key)
        // alert(`selected ${key}`)
    }

    return (
        <>
        <div className={!t('menu-a') ? "sidebar-mini sidebar-collapse" : ""} >
            <div className='wrapper-admin layout-fixed'>
                <Tab.Container id="left-tabs-example" defaultActiveKey="food_all">
                    <nav className="main-header navbar bg-white shadow-sm" style={{ height: "60px" }}>
                        <Nav as="ul" variant="tabs" className="nav nav-tabs overflow-hidden ul-tabcustom">
                            <Nav.Item as="li">
                                <Nav.Link eventKey="promotion_all">โปรโมชั่น</Nav.Link>
                            </Nav.Item>
                            {/* <Nav.Item as="li">
                                <Nav.Link eventKey="food_all">อาหารทั้งหมด</Nav.Link>
                            </Nav.Item> */}
                            <Nav.Item as="li">
                                <Dropdown className='menu-top-dd'>
                                    <Dropdown.Toggle variant="menu-tab" id="dropdown-basic">
                                        {dataKey}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item eventKey="food_all" onSelect={(e) => handleSelect("อาหารทั้งหมด")}>อาหารทั้งหมด</Dropdown.Item>
                                        {datacat.sort((a, b) => a.position - b.position).map((value, index) => (
                                            <Dropdown.Item eventKey={value.id} key={index} onSelect={(e) => handleSelect(value.category)}>{value.category}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Nav.Item>
                            {/* {datacat.sort((a, b) => a.position - b.position).map((value, index) => (
                                <Nav.Item as="li" key={index}>

                                    <Nav.Link eventKey={value.id}>{value.category}</Nav.Link>
                                </Nav.Item>
                            ))} */}
                        </Nav>
                        <ul className="navbar-nav ml-auto" style={{ marginTop: "-20px" }}>
                            <li className="">
                                <div className='text-gray font-12'>
                                    <img src={help} alt="" className="me-1" style={{ width: "14px" }} /> ขอความช่วยเหลือ
                                </div>
                            </li>
                        </ul>
                    </nav>

                    <Menuleft type="menu" />

                    <div className="content-wrapper">
                        <section className="content scroll-custom">
                            <div className="row">
                                <div className="col-12 col-md-8 col-lg-8 ">
                                    <Tabcontent datacat={datacat} dataproduct={dataproduct} datapromotion={datapromotion} handlerefresh={handlerefresh} />
                                </div>

                                <div className="col-12 col-md-4 col-lg-4 ps-0">
                                    {reserveview.search ?
                                        <>
                                            <div className="bg-white rounded-3 ms-2 mt-3 h-100per position-relative">
                                                <div className='px-4'>
                                                    <div className='text-start py-3 weight-500'>ค้นหาเมนู / โปรโมชั่น</div>
                                                    <Form onSubmit={handleSubmit} className="">
                                                        <div className='position-relative'>
                                                            <Form.Group controlId="dob">
                                                                <Form.Control type="text" name='search' className='font-14' />
                                                                <div className='position-absolute' style={{ bottom: "6px", right: "3px" }}>
                                                                    <button type="submit" className='btn p-0'><img src={search} alt="" className="me-1" style={{ width: "30px" }} /></button>
                                                                </div>
                                                            </Form.Group>
                                                        </div>
                                                    </Form>
                                                </div>
                                            </div>
                                        </>
                                        : null}

                                    {/* {reserveview.reserve ?
                                        <Reserveview canclef={handleBackmanage} datatable={datatable} dateday={dateday} datareserve={datareserve} selectdate={handledateday} />
                                        : null}

                                    {reserveview.detail ?
                                        <Reserdetail datatableall={datatable} datatable={datatableone} detailreserve={datareserveone} />
                                        : null} */}
                                </div>
                            </div>
                        </section>

                    </div>
                </Tab.Container>
            </div>
            </div>
        </>
    );
};

export default Menumanage;