import i18n from "i18next";
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from "react-i18next";
// import LanguageDetector from 'i18next-browser-languagedetector';

// import translationEN from './public/locales/en/trans';
// import customData from '../public/locales/en/trans.json';

const allowedLanguages = ['en', 'th'];

const defaultLng = 'en';
let lng = defaultLng;

const storageLanguage = localStorage.getItem('language');
if (storageLanguage && allowedLanguages.indexOf(storageLanguage) > -1) {
  lng = storageLanguage;
}

i18n
.use(XHR)
.use(initReactI18next) // passes i18n down to react-i18next
.init({
  // the translations
  // (tip move them in a JSON file and import them,
  // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
  resources: {
    en: {
      translation: {
        "menu-a": true,
      }
    },
    th: {
      translation: {
        "menu-a": false,      
      }
    }
  },
  lng, // if you're using a language detector, do not define the lng option
  debug: true,
  keySeparator: false,
  interpolation: {
    escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
//   backend: {
//     loadPath: '/public/locales/th/trans.json',
//     allowMultiLoading: true
//   },
  react: {
      wait: true,
      useSuspense: true
    }
});
export default i18n