import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate,Link } from "react-router-dom";
import { compareAsc, format } from 'date-fns'

import cplus from '../../assets/img/c-plus.svg';
import cmore from '../../assets/img/icon-more.svg';
import iconqrcode from '../../assets/img/qrcode.jpg';

import Modalcancleorder from "../../layout/modal/modalCancleorder";
import Modalholdorder from "../../layout/modal/modalConfirmhold";

import AuthService from "../../components/services_member/auth.service";

import Modalmenuselectdrivethru from "../../layout/modal/modalMenuselectdrivethru";
import Modalqrcodemenu from "../../layout/modal/modalQrcodemenu";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Modalcancleproduct from "../../layout/modal/modalCancleproduct";
import Modalmenudetail from "../../layout/modal/modalMenuorder";

const Oordertableview = (datas) => {

    let history = useNavigate();

    const tableorder = JSON.parse(localStorage.getItem("table_order"));

    const [modalcancleorder, setModalcancleorder] = useState({ view: false, id: 0 });
    const [modalholdorder, setModalholdorder] = useState({ view: false, id: 0 });
    const [modalselectdrivethru, setModalselectdrivethru] = useState({ view: false, id: 0 });

    const [modalqrcodemenu, setModalqrcodemenu] = useState({ view: false, id: 0 });

    const incrementCount = (id) => {
        // alert(e)
        const data2 = new FormData();

        data2.append("id", id);

        AuthService.removeOrderlist(data2).then(
            (res) => {
                if (res.status === 200) {
                    // window.location.reload();
                    datas.reloaddata();
                } else {
                    console.log(res.message)
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    const delpromotion = (id) => {
        // setModalmenudetail({ view: false, id: 0, data: [] });
        // alert(id)
        const data2 = new FormData();

        data2.append("id", datas.datareserve.id);
        data2.append("idpromotion", id);
        data2.append("type", "del");

        AuthService.promotionOrderlist(data2).then(
            (res) => {
                if (res.status === 200) {
                    // window.location.reload();
                    datas.reloaddata();
                } else {
                    console.log(res.message)
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    const clickonhide = (e) => {
        setModalcancleorder({ view: false, id: 0 })
        setModalholdorder({ view: false, id: 0 })

        datas.reloaddata();

        history("/order/orderopen");
    }

    const onviewmodaltable = () => {
        datas.tableorder()
    }

    const onviewmodaldrivethru = (id) => {
        setModalselectdrivethru({ view: false, id: 0 })
        datas.reloaddata(id)
        // alert(id)
    }

    const handleTokitchen = () => {

        // alert(orderview.id)
        const data2 = new FormData();

        data2.append("id", datas.datareserve.id);
        data2.append("type", "kitchen");

        // console.log(datas.datareserve.id)

        AuthService.holdOrderlist(data2).then(
            (res) => {
                if (res.status === 200) {
                    // handlerefresh(orderview.id)
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: '',
                        text: "ออเดอร์เข้าครัวเรียบร้อยแล้ว",
                        showConfirmButton: false,
                        timer: 1500
                    }).then(function () {
                        datas.reloaddata();
                        // selectlist(orderview.id);
                        // setOrderview(dataorder.filter(vls => vls.id === orderview.id)[0]);

                    })
                } else {
                    console.log(res.message)
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    // var formattedDate = format(new Date("2016-01-04"), "dd/MM/yyyy");
    // var formattedDate = format(new Date(datas.datareserve.date_reserve), "dd/MM/yyyy");

    // console.log(formattedDate);

    // console.log(datas.datareserve)
    const StatusKang = (e) => {

        let view = true;

        if (datas.datareserve.data_order) {
            if (datas.datareserve.data_order.filter(valb => valb.status == 0).length) {
                view = false;
            }
        }

        return <button disabled={view}
            onClick={() => setModalholdorder({ view: true, id: datas.datareserve.id, no: datas.datareserve.no })}
            className="w-100 btn btn-border-blue py-2 me-3">
            สั่งค้างไว้
        </button>;
    }

    const StatusPayment = (e) => {
        // console.log(datas.datareserve.data_order)        
        let view = true;

        if (datas.datareserve.data_order) {
            if (datas.datareserve.data_order.length) {
                if (datas.datareserve.data_order.length === datas.datareserve.data_order.filter(valb => valb.status >= 4).length) {
                    view = false;
                }
            }
        } else {
            view = true;
        }

        return <Link to={"/payment/payment/" + datas.datareserve.id}
            className={view ? "w-100 btn btn-submit text-white py-2 mt-2 me-3 payment disabled" : "w-100 btn btn-submit text-white py-2 mt-2 me-3 payment"} >
            ชำระเงิน
        </Link>;
    }

    const SendtoKit = (e) => {

        let view = true;

        if (datas.datareserve.data_order) {
            if (datas.datareserve.data_order.length) {
                if (datas.datareserve.data_order.filter(valb => valb.status <= 1).length) {
                    view = false;
                    // console.log('yes')                    
                }
            }
        } else {
            view = true;
            // console.log('no')
        }

        // console.log(datas.datareserve.data_order.filter(valb => valb.status <= 1).length)

        return <button type='submit' onClick={() => handleTokitchen()} className="w-100 btn btn-blue text-white py-2 " disabled={view}>
            ส่งออเดอร์เข้าครัว
        </button>;
    }

    const [modalcancleproduct, setModalcancleproduct] = useState({ view: false, id: 0 });
    const [modalmenudetail, setModalmenudetail] = useState({ view: false, id: 0, data: [] });

    const handlemodal = (id) => {
        // console.log(data.dataorder)
        
        if (datas.datareserve.data_order) {
            if (datas.datareserve.data_order.length) {
                console.log((datas.datareserve))
                // console.log((datas.datareserve.data_order.filter(valb => valb.id == id)))

                const dataorder_no = datas.datareserve.no;
                const dataorder_zone = datas.datareserve.zone_name;
                const dataorder_table = datas.datareserve.table_reserve_name;
                const dataproduct_name = (datas.datareserve.data_order.filter(valb => valb.id == id))[0].name_product;
                const dataproduct_date = (datas.datareserve.data_order.filter(valb => valb.id == id))[0].date_kitchen;
                const dataproduct_id = (datas.datareserve.data_order.filter(valb => valb.id == id))[0].id;
                const dataproduct_qty = (datas.datareserve.data_order.filter(valb => valb.id == id))[0].qty;

                setModalcancleproduct({ view: true, type: "cancle", dataid: { order_no: dataorder_no, order_zone: dataorder_zone, order_table: dataorder_table, product: dataproduct_name, product_id: dataproduct_id, date_time: dataproduct_date, qty: dataproduct_qty } })
            }
        }
    }

    const handlemodalEdit = (id) => {
        // console.log(data.dataorder)
        if (datas.datareserve.data_order) {
            if (datas.datareserve.data_order.length) {
                setModalmenudetail({ view: true, type: "edit", id: id, dataone: (datas.datareserve.data_order.filter(valb => valb.id == id)) })
            }
        }
    }

    const clickrefresh = () => {
        setModalcancleproduct({ view: false, dataid: [] });
        setModalmenudetail({ view: false, dataid: [] });
        // setSelectdata();
        datas.reloaddata();
    }
    return (
        <>
            <div className="bg-white rounded-3 ms-2 mt-2 h-100per-right-z position-relative">
                <div className='text-start p-3'>

                    <div>
                        {tableorder.zone === 0 ? <>
                            <span className='font-20'><b>Drive Thru : คิวที่ {datas.datareserve.table_reserve}</b></span>
                            <span className='float-end cursor' onClick={() => setModalselectdrivethru({ view: true })}>
                                <img src={cmore} style={{ height: "15px" }} />
                            </span>
                        </>
                            : <>
                                <span className='font-20'><b>{tableorder.zone} : {tableorder.table}</b></span>

                                <span className='float-end cursor' onClick={onviewmodaltable}>
                                    <img src={cmore} style={{ height: "15px" }} />
                                </span>
                                <span className='me-2 float-end cursor' onClick={() => setModalqrcodemenu({ view: true, id: datas.datareserve.id, zone: tableorder.zone, table: tableorder.table, no: datas.datareserve.no })}>
                                    <img src={iconqrcode} style={{ width: '30px' }} />
                                    {/* [QR code] */}
                                </span>
                            </>}
                    </div>

                    <div className='weight-500 mt-2 font-14'>Order #{datas.datareserve.no}</div>
                    <div className='weight-400 mt-1 text-gray font-14'>
                        {datas.datareserve.date_reserve ? <>
                            วันที่ {format(new Date(datas.datareserve.date_reserve), 'dd/MM/yyyy')} - {datas.datareserve.time_update}
                        </>
                            : null}
                    </div>

                    <hr className='hr-custom' />
                    <div className='weight-500 mt-1'>รายการอาหาร</div>
                    <div className='mt-2 mb-4'>

                        {datas.datareserve.data_order ? datas.datareserve.data_order.length ? (datas.datareserve.data_order.filter(valb => valb.status !== '5')).sort((a, b) => a.id - b.id).map((value, index) => (

                            <div className='mb-2 cursor-pointer' key={index}>
                                <div className='font-14 pe-5 position-relative'  >
                                    {
                                        value.status === "2" ? <span className='span-blue me-2'></span> :

                                            value.status === "3" ? <span className='span-orang me-2'></span> :

                                                value.status === "4" ? <span className='span-green me-2'></span> :

                                                    value.status === "5" ? <span className='span-red me-2'></span> : null
                                    }
                                    <span onClick={() => handlemodalEdit(value.id)}>{value.name_product} <span className='font-12 ms-3'>X</span> {value.qty}</span>
                                    

                                    {value.status === "0" || value.status === "1" ?

                                        <span className='position-absolute' style={{ top: "0",right:"0" }}>
                                            <span className='btn-lob rounded' onClick={() => incrementCount(value.id)} style={{ verticalAlign: "middle", width: "21px", height: "21px" }}>
                                                <img src={cplus} style={{ width: "12px", transform: "rotate(45deg)" }} />
                                            </span>
                                        </span>
                                        :
                                        <span className='position-absolute' style={{ top: "0",right:"0" }}>
                                            <span className='btn-lob rounded font-12 p-1' onClick={() => handlemodal(value.id)} style={{ verticalAlign: "middle", width: "44px", height: "21px" }}>
                                                {/* <img src={cplus} style={{ width: "12px", transform: "rotate(45deg)" }} /> */}
                                                ยกเลิก
                                            </span>
                                        </span>
                                    }
                                </div>


                                {value.choice ? (JSON.parse(value.choice)).map((val, inde) => (
                                    <div className='ms-3 pe-5' key={inde}>
                                        <div className='font-13'>- {val.more_name}</div>
                                    </div>
                                ))
                                    : null}
                            </div>
                        ))
                            : <div className='text-center my-4 font-12 weight-300 text-gray'>- ยังไม่มีรายการ - </div> : null}
                    </div>
                    {datas.datareserve.data_order ? datas.datareserve.data_order.length ? (datas.datareserve.data_order.filter(valb => valb.status === '5')).length ?
                        <>
                            <hr className='hr-custom' />
                            <div className='weight-500 mt-1'>รายการยกเลิก</div>
                            <div className='mt-2 mb-4'>

                                {datas.datareserve.data_order ? datas.datareserve.data_order.length ? (datas.datareserve.data_order.filter(valb => valb.status === '5')).sort((a, b) => a.id - b.id).map((value, index) => (

                                    <div className='mb-2' key={index}>
                                        <div className='font-14 pe-5'>
                                            {
                                                value.status === "2" ? <span className='span-blue me-2'></span> :

                                                    value.status === "3" ? <span className='span-orang me-2'></span> :

                                                        value.status === "4" ? <span className='span-green me-2'></span> :

                                                            value.status === "5" ? <span className='span-red me-2'></span> : null
                                            }
                                            {value.name_product} <span className='font-12 ms-3'>X</span> {value.qty}
                                        </div>
                                        {value.status === "0" || value.status === "1" ?

                                            <span className='float-end'>
                                                <span className='btn-lob rounded' onClick={() => incrementCount(value.id)} style={{ verticalAlign: "middle", width: "21px", height: "21px" }}>
                                                    <img src={cplus} style={{ width: "12px", transform: "rotate(45deg)" }} />
                                                </span>
                                            </span>
                                            : null
                                        }

                                        {value.choice ? (JSON.parse(value.choice)).map((val, inde) => (
                                            <div className='ms-3 pe-5' key={inde}>
                                                <div className='font-13'>- {val.more_name}</div>
                                            </div>
                                        ))
                                            : null}
                                    </div>
                                ))
                                    : <div className='text-center my-4 font-12 weight-300 text-gray'>- ยังไม่มีรายการ - </div> : null}
                            </div>
                        </>

                        : null : null : null}

                    <hr className='hr-custom' />
                    <div className='weight-500 mt-1'>โปรโมชั่น</div>
                    <div className='mt-2 mb-4'>
                        {datas.datapromotion.filter(valb => valb.id === datas.datareserve.promotion_id).map((val, index) => (
                            <div className='mb-2' key={index}>
                                <span className='font-14 pe-5'>{val.promotion}</span>
                                <span className='float-end'>
                                    <span className='btn-lob rounded' onClick={() => delpromotion(val.id)} style={{ verticalAlign: "middle", width: "21px", height: "21px" }}>
                                        <img src={cplus} style={{ width: "12px", transform: "rotate(45deg)" }} />
                                    </span>
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='w-100 ps-2 mt-2'>
                <div className='row'>
                    <div className="col-12 col-md-12 col-lg-12 pe-1 pb-2">
                        <SendtoKit />
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 pe-1">
                        <button disabled={true} onClick={() => setModalcancleorder({ view: true, id: datas.datareserve.id })} className="w-100 btn btn-border-red py-2 me-3">
                            ยกเลิกคำสั่งซื้อ
                        </button>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 ps-1">
                        <StatusKang />
                    </div>
                    <div className="col-12 col-md-12 col-lg-12">
                        <StatusPayment />
                    </div>
                </div>
            </div>

            {modalcancleorder.view ?
                <Modalcancleorder show={modalcancleorder.view} onHide={clickonhide} id={modalcancleorder.id} />
                : null}
            {modalholdorder.view ?
                <Modalholdorder show={modalholdorder.view} onHide={clickonhide} id={modalholdorder.id} no={modalholdorder.no} />
                : null}

            {modalselectdrivethru.view ?
                <Modalmenuselectdrivethru show={modalselectdrivethru.view} onHide={onviewmodaldrivethru} id={modalselectdrivethru.id} />
                : null}
            {modalqrcodemenu.view ?
                <Modalqrcodemenu show={modalqrcodemenu.view} onHide={() => setModalqrcodemenu({ view: false })} id={modalqrcodemenu.id} zone={modalqrcodemenu.zone} table={modalqrcodemenu.table} no={modalqrcodemenu.no} date={format(new Date(datas.datareserve.date_reserve), 'dd/MM/yyyy') + " - " + datas.datareserve.time_update} />
                : null}

            {modalcancleproduct.view ?
                <Modalcancleproduct show={modalcancleproduct.view} onHide={clickrefresh} type={modalcancleproduct.type} data={modalcancleproduct.dataid} />
                : null}

            {modalmenudetail.view ?
                <Modalmenudetail show={modalmenudetail.view} onHide={clickrefresh} id={modalmenudetail.id} dataone={modalmenudetail.dataone} type={modalmenudetail.type} idreserve={datas.datareserve.id} />
                : null}
        </>
    );
};

export default Oordertableview;