import React, { useState, useCallback, useEffect } from 'react';
// import axios from 'axios';
import { useNavigate,Link } from "react-router-dom";
import { compareAsc, format } from 'date-fns';

import { Form, Tab, Nav, DropdownButton, Dropdown } from "react-bootstrap";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import AuthService from "../../components/services_member/auth.service";
import PostService from "../../components/services_member/post.service";

import Menuleft from "../../layout/pagesadmin/menuleft";

import help from '../../assets/img/help.svg';
import btnremove from '../../assets/img/btn-remove.svg';
import btnmove from '../../assets/img/btn-move.svg';
import calendar from '../../assets/img/calendar.svg';

import Modalremoveorder from "../../layout/modal/modalRemoveorder";
import Modalcancleproduct from "../../layout/modal/modalCancleproduct";
import Modalmenudetail from "../../layout/modal/modalMenuorder";
import { useTranslation } from 'react-i18next';


import Modalmenuselecttable from "../../layout/modal/modalTablemove";


const Orderopen = () => {
    const { t } = useTranslation();
    const MySwal = withReactContent(Swal)

    // localStorage.removeItem("table_order");

    let history = useNavigate();
    const user = AuthService.getCurrentUser();
    const branch = AuthService.getCurrentBranch();

    if (!user) {
        history("/");
    } else {
        if (!branch) {
            history("/branch");
        }
    }

    const [dataorder, setDataorder] = useState(false);

    const [dateday, setDateday] = useState(format(new Date(), 'dd/MM/yyyy'));

    const [dataactive, setDataactive] = useState({ order: '' });

    const [orderview, setOrderview] = useState();
    const [modalremoveorder, setModalremoveorder] = useState({ view: false, id: 0, no: 0 });

    const [modalselecttable, setModalselecttable] = useState({ view: false, id: "0" });

    const fetchData = useCallback(async (dd) => {

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        PostService.getOrderall(branch.id, dd).then(
            (response) => {
                if (response.data.status === 200) {

                    // console.log(response.data)
                    setDataorder(response.data.reserve);

                    // setDataproduct(response.data.data_product);
                    // setDatapromotion(response.data.data_promotion);
                } else {
                    setDataorder([]);
                }
            },
            (error) => {
                console.log(error);
            }
        )
    });

    useEffect(() => {
        fetchData(dateday);
    }, []);

    const dateoption = {
        locale: {
            format: 'DD/MM/YYYY'
        },
        startDate: dateday,
        // maxDate: dateday,
        singleDatePicker: true,
        // autoApply: true,
    };

    const handledateday = (event) => {
        fetchData(event.target.value)
        setDateday(event.target.value)
        setOrderview()
    }

    const handlerefresh = (id) => {
        fetchData(dateday);
    }

    const selectlist = (id) => {
        setDataactive({ order: id })
        setOrderview(dataorder.filter(vls => vls.id === id)[0])
    }


    const clickonhide = (e) => {
        setModalremoveorder({ view: false, id: 0, no: 0 })
        fetchData(dateday);
        setOrderview()
    }

    const handleTablesubmit = () => {

        localStorage.setItem('table_order', JSON.stringify({ id: orderview.zone, zone: orderview.zone_name, table_id: orderview.table_reserve, table: orderview.table_name, reserve: orderview.id, date: dateday }));
        history("/order/ordermenu");
    }

    const handleTokitchen = () => {

        // alert(orderview.id)
        const data2 = new FormData();

        data2.append("id", orderview.id);
        data2.append("type", "kitchen");

        AuthService.holdOrderlist(data2).then(
            (res) => {
                if (res.status === 200) {

                    handlerefresh(orderview.id)

                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: '',
                        text: "ออเดอร์เข้าครัวเรียบร้อยแล้ว",
                        showConfirmButton: false,
                        timer: 1500
                    }).then(function () {

                        selectlist(orderview.id);
                        setOrderview(dataorder.filter(vls => vls.id === orderview.id)[0]);

                    })
                } else {
                    console.log(res.message)
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    const [modalcancleproduct, setModalcancleproduct] = useState({ view: false, id: 0 });
    const [modalmenudetail, setModalmenudetail] = useState({ view: false, id: 0, data: [] });

    const handlemodal = (id) => {
        // dataorder.filter(vls => vls.id === dataactive.order)[0].data_order
        // console.log(dataorder.filter(vls => vls.id === dataactive.order)[0])
        // dataorder.filter(vls => vls.id === dataactive.order)[0].data_order
        if (dataorder.filter(vls => vls.id === dataactive.order)[0].data_order) {
            if (dataorder.filter(vls => vls.id === dataactive.order)[0].data_order.length) {
                //         console.log((datas.datareserve))

                const dataorder_no = dataorder.filter(vls => vls.id === dataactive.order)[0].no;
                const dataorder_zone = dataorder.filter(vls => vls.id === dataactive.order)[0].zone_name;
                const dataorder_table = dataorder.filter(vls => vls.id === dataactive.order)[0].table_name;
                const dataproduct_name = (dataorder.filter(vls => vls.id === dataactive.order)[0].data_order.filter(valb => valb.id == id))[0].name_product;
                const dataproduct_date = (dataorder.filter(vls => vls.id === dataactive.order)[0].data_order.filter(valb => valb.id == id))[0].date_kitchen;
                const dataproduct_id = (dataorder.filter(vls => vls.id === dataactive.order)[0].data_order.filter(valb => valb.id == id))[0].id;
                const dataproduct_qty = (dataorder.filter(vls => vls.id === dataactive.order)[0].data_order.filter(valb => valb.id == id))[0].qty;

                setModalcancleproduct({ view: true, type: "cancle", dataid: { order_no: dataorder_no, order_zone: dataorder_zone, order_table: dataorder_table, product: dataproduct_name, product_id: dataproduct_id, date_time: dataproduct_date, qty: dataproduct_qty } })
            }
        }
    }

    const handlemodalEdit = (id) => {
        // console.log(data.dataorder)
        if (dataorder.filter(vls => vls.id === dataactive.order)[0].data_order) {
            if (dataorder.filter(vls => vls.id === dataactive.order)[0].data_order.length) {
                setModalmenudetail({ view: true, type: "edit", id: id, dataone: (dataorder.filter(vls => vls.id === dataactive.order)[0].data_order.filter(valb => valb.id == id)) })
            }
        }
    }

    const clickrefresh = () => {
        setModalcancleproduct({ view: false, dataid: [] });
        setModalmenudetail({ view: false, dataid: [] });
        // setSelectdata();
        handlerefresh();

        setModalselecttable({ view: false, id: 0 });

        setOrderview()
    }

    // const handlemodalordertable = (id,no) => {
    //     setModalselecttable({ view: true, id: id,no:no })
    // }

    // console.log(dataorder)

    return (
        <>
            <div className={!t('menu-a') ? "sidebar-mini sidebar-collapse" : ""} >
                <div className='wrapper-admin layout-fixed'>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="food_all">
                        <nav className="main-header navbar bg-white shadow-sm" style={{ height: "60px" }}>
                            <Nav as="ul" className="nav overflow-hidden " style={{ marginTop: "-4px" }}>
                                <li>
                                    <div className="bg-white rounded-3 ms-2 position-relative">
                                        <div className=''>
                                            <div className='text-start py-3 weight-500 d-inline-block me-3'>ค้นหาวันที่</div>
                                            <div className='position-relative d-inline-block'>
                                                <Form.Group controlId="dob">
                                                    <DateRangePicker initialSettings={dateoption} onApply={(e) => (handledateday(e))}>
                                                        <input type="text" className="form-control px-2 py-2" name="dateday" />
                                                    </DateRangePicker >
                                                    <div className='position-absolute' style={{ top: "10px", right: "12px" }}>
                                                        <img src={calendar} alt="" className="" style={{ width: "17px" }} />
                                                    </div>
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className='ms-5'>
                                    <div className='d-flex-center'>
                                        <div className='d-inline-block weight-500 '>สถานะอาหาร : </div>
                                    </div>
                                </li>
                                <li className='ms-3'>
                                    <div className='d-flex-center'>
                                        <div className='d-inline-block span-blue me-2'></div>
                                        <div className='d-inline-block font-12'>ออเดอร์เข้าครัวแล้ว</div>
                                    </div>
                                </li>
                                <li className='ms-3'>
                                    <div className='d-flex-center'>
                                        <div className='d-inline-block span-orang me-2'></div>
                                        <div className='d-inline-block font-12'>กำลังปรุง</div>
                                    </div>
                                </li>
                                <li className='ms-3'>
                                    <div className='d-flex-center'>
                                        <div className='d-inline-block span-green me-2'></div>
                                        <div className='d-inline-block font-12'>ส่งเสิร์ฟแล้ว</div>
                                    </div>
                                </li>
                                <li className='ms-3'>
                                    <div className='d-flex-center'>
                                        <div className='d-inline-block span-red me-2'></div>
                                        <div className='d-inline-block font-12'>ยกเลิก</div>
                                    </div>
                                </li>
                            </Nav>
                            <ul className="navbar-nav ml-auto" style={{ marginTop: "-20px" }}>
                                <li className="">
                                    <div className='text-gray font-12'>
                                        <img src={help} alt="" className="me-1" style={{ width: "14px" }} /> ขอความช่วยเหลือ
                                    </div>
                                </li>
                            </ul>
                        </nav>
                        <Menuleft type="menuopen" />
                        <div className="content-wrapper">
                            <section className="content scroll-custom">
                                <div className="row">
                                    <div className="col-12 col-md-8 col-lg-8 ">
                                        <div className="bg-white rounded-3 mt-3 h-100per">
                                            <div className='text-start'> 
                                                {dataorder ? dataorder.length ? dataorder.sort((a, b) => b.id - a.id).map((val, ind) => (
                                                    <div className='border_hr' key={ind}>
                                                        {val.data_order.length ?

                                                            <span onClick={() => selectlist(val.id)} className={dataactive.order === val.id ? "a-list-table py-4 px-4 cursor active" : "a-list-table py-4 px-4 cursor"} >
                                                                <div className="row" >
                                                                    <div className="col-12 col-md-12 col-lg-12 text-start">
                                                                        <div className='float-end'>
                                                                            <button className='btn px-0' onClick={(e) => setModalselecttable({ view: true, id: val.id, no: val.no })}>
                                                                                <img src={btnmove} className="remove-order" style={{width:"40px"}}/>
                                                                            </button>
                                                                            <button className='btn' onClick={() => setModalremoveorder({ view: true, id: val.id, no: val.no })}>
                                                                                <img src={btnremove} className="remove-order" />
                                                                            </button>
                                                                        </div>
                                                                        <span className="badge bg-blue-b weight-400 font-16">
                                                                            {val.zone === '0' ? "Drive Thru" : "ทานที่ร้าน"}
                                                                        </span>
                                                                        <div className='weight-500 mt-2 font-16'>
                                                                            {val.zone === '0' ? "คิวที่ " + val.table_reserve : val.zone_name + " : " + val.table_name}
                                                                            <span className='mx-2'>-</span>Order #{val.no}
                                                                        </div>
                                                                        <div className='weight-400 mt-1 text-gray font-14'>
                                                                            {val.date_reserve ? <>
                                                                                วันที่ {format(new Date(val.date_reserve), 'dd/MM/yyyy')} - {val.time_update}
                                                                            </>
                                                                                : null}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </span>
                                                            : null}
                                                    </div>
                                                )) : <div className='text-gray font-14 text-center p-5'>-- ไม่มีออเดอร์ที่เปิดอยู่ --</div>
                                                    :
                                                    <div className="text-center p-5"><i className="fa fa-spinner fa-spin" /><div>Loading</div></div>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-4 col-lg-4 ps-0">
                                        <div className='w-100 mt-3 mb-2'>
                                            <div className="bg-white rounded-3 ms-2 mt-2 h-100per-right-f position-relative">
                                                <div className='text-start p-3'>
                                                    {orderview ?
                                                        <div>
                                                            <div className='mt-3 mb-3'>
                                                                <span className="badge bg-blue-b weight-400 font-16">
                                                                    {orderview.zone === '0' ? "Drive Thru" : "ทานที่ร้าน"}
                                                                </span>
                                                            </div>
                                                            <span className='font-20'><b>
                                                                {orderview.zone === '0' ? "คิวที่ " + orderview.table_reserve : orderview.zone_name + " : " + orderview.table_name}
                                                            </b></span>
                                                            <div className='weight-500 mt-2 font-14'>Order #{orderview.no}</div>
                                                            <div className='weight-400 mt-1 text-gray font-14'>
                                                                {orderview.date_reserve ? <>
                                                                    วันที่ {format(new Date(orderview.date_reserve), 'dd/MM/yyyy')} - {orderview.time_update}
                                                                </>
                                                                    : null}
                                                            </div>
                                                            <hr className='hr-custom' />
                                                            <div className='weight-500 mt-1'>รายการอาหาร</div>
                                                            <div className='mt-2 mb-4'>

                                                                {
                                                                    dataorder.filter(vls => vls.id === dataactive.order)[0].data_order ? dataorder.filter(vls => vls.id === dataactive.order)[0].data_order.length ? (dataorder.filter(vls => vls.id === dataactive.order)[0].data_order).filter(valb => valb.status !== '5').sort((a, b) => a.id - b.id).map((value, index) => (
                                                                        <div className='mb-2' key={index}>

                                                                            <div className='font-14 pe-5 position-relative cursor-pointer' >
                                                                                {
                                                                                    value.status === "2" ? <span className='span-blue me-2'></span> :

                                                                                        value.status === "3" ? <span className='span-orang me-2'></span> :

                                                                                            value.status === "4" ? <span className='span-green me-2'></span> :

                                                                                                value.status === "5" ? <span className='span-red me-2'></span> : null
                                                                                }
                                                                                <span onClick={() => handlemodalEdit(value.id)}>{value.name_product}<span className='font-12 ms-3'>X</span> {value.qty}</span>

                                                                                <span className='position-absolute' style={{ top: "0", right: "0" }}>
                                                                                    <span className='btn-lob rounded font-12 p-1' onClick={() => handlemodal(value.id)} style={{ verticalAlign: "middle", width: "44px", height: "21px" }}>
                                                                                        ยกเลิก
                                                                                    </span>
                                                                                </span>
                                                                            </div>



                                                                            {value.choice ? (JSON.parse(value.choice)).map((val, inde) => (
                                                                                <div className='ms-3 pe-5' key={inde}>
                                                                                    <div className='font-13'>- {val.more_name}</div>
                                                                                </div>
                                                                            ))
                                                                                : null}
                                                                        </div>
                                                                    ))
                                                                        : <div className='text-center my-4 font-12 weight-300 text-gray'>- ยังไม่มีรายการ - </div> : null}
                                                            </div>
                                                            {dataorder.filter(vls => vls.id === dataactive.order)[0].data_order ? dataorder.filter(vls => vls.id === dataactive.order)[0].data_order.length ? (dataorder.filter(vls => vls.id === dataactive.order)[0].data_order).filter(valb => valb.status === '5').length ?
                                                                <>
                                                                    <hr className='hr-custom' />
                                                                    <div className='weight-500 mt-1'>รายการยกเลิก</div>
                                                                    <div className='mt-2 mb-4'>

                                                                        {
                                                                            dataorder.filter(vls => vls.id === dataactive.order)[0].data_order ? dataorder.filter(vls => vls.id === dataactive.order)[0].data_order.length ? (dataorder.filter(vls => vls.id === dataactive.order)[0].data_order).filter(valb => valb.status === '5').sort((a, b) => a.id - b.id).map((value, index) => (
                                                                                <div className='mb-2' key={index}>

                                                                                    <span className='font-14 pe-5'>
                                                                                        {
                                                                                            value.status === "2" ? <span className='span-blue me-2'></span> :

                                                                                                value.status === "3" ? <span className='span-orang me-2'></span> :

                                                                                                    value.status === "4" ? <span className='span-green me-2'></span> :

                                                                                                        value.status === "5" ? <span className='span-red me-2'></span> : null
                                                                                        }

                                                                                        {value.name_product}<span className='font-12 ms-3'>X</span> {value.qty}
                                                                                    </span>



                                                                                    {value.choice ? (JSON.parse(value.choice)).map((val, inde) => (
                                                                                        <div className='ms-3 pe-5' key={inde}>
                                                                                            <div className='font-13'>- {val.more_name}</div>
                                                                                        </div>
                                                                                    ))
                                                                                        : null}
                                                                                </div>
                                                                            ))
                                                                                : <div className='text-center my-4 font-12 weight-300 text-gray'>- ยังไม่มีรายการ - </div> : null}
                                                                    </div>
                                                                </>
                                                                : null : null : null}
                                                        </div>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                            <div className='w-100 ps-2 mt-2'>
                                                <div className='row'>
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <button type='submit' onClick={() => handleTokitchen()} className="w-100 btn btn-submit text-white py-2 " disabled={orderview ? orderview.data_order.length ? orderview.data_order.filter(vls => vls.status === '1').length ? false : true : true : true}>
                                                            ส่งออเดอร์เข้าครัว
                                                        </button>
                                                    </div>
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <button onClick={() => handleTablesubmit()} disabled={orderview ? false : true} className="w-100 btn btn-blue text-white py-2 mt-2 me-3">
                                                            เปิดหน้ารายการ
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                        </div>
                    </Tab.Container>
                </div>
            </div>
            {modalremoveorder.view ?
                <Modalremoveorder show={modalremoveorder.view} onHide={clickonhide} id={modalremoveorder.id} no={modalremoveorder.no} />
                : null}

            {modalcancleproduct.view ?
                <Modalcancleproduct show={modalcancleproduct.view} onHide={clickrefresh} type={modalcancleproduct.type} data={modalcancleproduct.dataid} />
                : null}

            {modalmenudetail.view ?
                <Modalmenudetail show={modalmenudetail.view} onHide={clickrefresh} id={modalmenudetail.id} dataone={modalmenudetail.dataone} type={modalmenudetail.type} idreserve={dataactive.order} />
                : null}

            {modalselecttable.view ?
                <Modalmenuselecttable show={modalselecttable.view} onHide={clickrefresh} idOrder={modalselecttable.id} noOrder={modalselecttable.no} />
                : null}

        </>
    );
};

export default Orderopen;