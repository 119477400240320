import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button } from "react-bootstrap";
import { compareAsc, format } from 'date-fns';
// import { Form, Tab, Nav, Row, Col, Accordion, ListGroup, Badge } from "react-bootstrap";

import AuthService from "../../components/services_member/auth.service";
// import PostService from "../../components/services_member/post.service";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import chold from '../../assets/img/confirm-hold.svg';

const Modalconfirmwaste = (datas) => {

    // console.log(datas.id)

    const submithold = (id) => {
        AuthService.updateWaste(datas.dataf).then(
            (res) => {

                if (res.status === 200) {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: '',
                        text: "เพิ่มรายการของเสียเรียบร้อยแล้ว",
                        showConfirmButton: false,
                        timer: 1500
                    }).then(function () {
                        datas.onSubmit()
                    })

                } else {
                    console.log(res.message)
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    // console.log(datas.dataf)

    return (
        <>
            <Modal {...datas} className="modal-custom" size="md" backdrop="static" keyboard={false}>
                <Modal.Header closeButton className="btn-close-white ">

                </Modal.Header>
                <Modal.Body className="p-0 bg-white">
                    <div className="d-block w-100 overflow-hidden">
                        { datas.type === "add" ?
                        <div className='pt-5 pb-4 bg-white px-4 text-center'>
                            <div className='font-18 weight-500 mt-1'>{datas.title}</div>
                            <div className='text-center px-3 mt-4'>
                                <div className='row'>
                                    <div className="col-12 col-md-6 col-lg-6 pe-1">
                                        <button className="w-100 btn btn-calcel text-white py-2 me-3" onClick={datas.onHide}>
                                            ยกเลิก
                                        </button>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 ps-1">
                                        <button type='submit' onClick={() => submithold()} className="w-100 btn btn-bg text-white py-2 me-3">
                                            ยืนยัน
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>     
                        : 
                        <div className='pt-5 pb-4 mb-3 bg-white px-4 text-start font-14'>
                            <div className='font-18 weight-500 mt-1'>รายละเอียดของเสีย</div>
                            <div className='mt-4'>วันที่ : {format(new Date(datas.dataf.date_create), 'dd/MM/yyyy')}</div>
                            <div className='mt-1'>ประเภท : {datas.dataf.type_product}</div>
                            {/* <div className='mt-1'>หมวดหมู่ : {datas.dataf.cat_product}</div> */}
                            <div className='mt-1'>รายการ : {datas.dataf.name_product}</div>
                            <div className='mt-1'>จำนวน : {datas.dataf.qty}</div>
                            <div className='mt-1'>หน่วย : {datas.dataf.unit}</div>
                            <div className='mt-1'>
                                ชื่อพนักงาน : { datas.dataem.filter(vls => vls.id === datas.dataf.name_employee)[0].name }
                            </div>
                            <div className='mt-1'>หมายเหตุ : {datas.dataf.note}</div>
                        </div>
                        }                  
                    </div>
                </Modal.Body>
            </Modal>

        </>
    );
};

export default Modalconfirmwaste;