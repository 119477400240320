import React, { useState, useEffect } from 'react';
import { Modal, Button } from "react-bootstrap";

import '../../assets/css/radioandcheckbox.css';
import { Form, Tab, Nav, Row, Col } from "react-bootstrap";

import AuthService from "../../components/services_member/auth.service";


const Modalmenudetail = (data) => {

    const [swt, setSwt] = useState({ view: 0 });

    const handleSwt = (e) => {

        const data2 = new FormData();
        data2.append("id", data.id);
        data2.append("type", data.type);

        if (e === '1') {
            setSwt({ view: '0' });
            data2.append("status", '0');
        } else {
            setSwt({ view: '1' });
            data2.append("status", '1');
        }

        AuthService.updateMenustatus(data2).then(
            (res) => {
                if (res.status === 200) {

                } else {
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    useEffect(() => {
        if (data.dataone[0]) {
            if (data.dataone[0].status === '0') {
                setSwt({ view: '0' })
            } else {
                setSwt({ view: '1' })
            }
        }

    }, [data.dataone[0]]);
    // handlerefresh
    // console.log(swt.view)


    return (
        <>
            <Modal {...data} className="modal-custom" size="md" backdrop="static" keyboard={false}>
                <Modal.Header closeButton className="btn-close-white ">

                </Modal.Header>
                <Modal.Body className="p-0 bg-white">
                    <Form noValidate className="">
                        {data.type === "promotion" ?
                            <div className="d-block w-100 py-3 px-4">
                                {data.dataone[0] ? <>
                                    <Modal.Title className='pb-3'>
                                    </Modal.Title>

                                    <div>
                                        <div className='font-18 mt-3 weight-500'>{data.dataone[0].promotion}</div>                                        
                                        <div>
                                            <Row className="align-items-end mt-4 mb-3">
                                                <Form.Group as={Col} md="6" className="position-relative pe-1" controlId="validationCustom01">
                                                    <Form.Label className="font-12 text-gray mb-0">สถานะ</Form.Label>
                                                    <div className="form-check form-switch">
                                                        <input className="form-check-input d-inline-block" type="checkbox" id="flexSwitchCheckChecked" name='service_charge' value="1"
                                                            onClick={() => handleSwt(swt.view)} checked={swt.view === '1' ? true : false} />
                                                        <label className="form-check-label font-12 mt-1 pt-1 ps-2" >เปิดโปรโมชั่น</label>
                                                    </div>
                                                </Form.Group>
                                            </Row>
                                        </div>
                                    </div>
                                </> : null
                                }
                            </div>
                            :
                            <div className="d-block w-100 py-3 px-4">
                                {data.dataone[0] ? <>
                                    <Modal.Title className='pb-3'>
                                    </Modal.Title>

                                    <div>
                                        <div className='font-18 mt-3 weight-500'>{data.dataone[0].name}</div>
                                        <div className='font-22 mt-2 mb-4 weight-500'>
                                            <b>{(parseFloat(data.dataone[0].price)).toLocaleString('en-US', { maximumFractionDigits: 2 })}</b>
                                        </div>
                                        <div className='font-14'>
                                            รายละเอียดเมนู : {data.dataone[0].detail}
                                        </div>
                                        <div>
                                            <Row className="align-items-end mt-4 mb-3">
                                                <Form.Group as={Col} md="6" className="position-relative pe-1" controlId="validationCustom01">
                                                    <Form.Label className="font-12 text-gray mb-0">สถานะ</Form.Label>
                                                    <div className="form-check form-switch">                                                        
                                                        <input className="form-check-input d-inline-block" type="checkbox" id="flexSwitchCheckChecked" name='service_charge' value="1"
                                                                onClick={() => handleSwt(swt.view)} checked={swt.view === '1' ? true : false } />
                                                        <label className="form-check-label font-12 mt-1 pt-1 ps-2" >พร้อมจำหน่าย</label>
                                                    </div>
                                                </Form.Group>
                                            </Row>
                                        </div>
                                    </div>


                                </> : null
                                }
                            </div>
                        }
                    </Form>
                </Modal.Body>
            </Modal>

        </>
    );
};

export default Modalmenudetail;