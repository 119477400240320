import React, { useState, useEffect } from 'react';
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { SortableContainer, SortableElement, arrayMove, sortableHandle } from 'react-sortable-hoc';

import AuthService from "../../components/services_member/auth.service";
// import arrayMove from 'array-move';

// import axios from 'axios';
// import { Link } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
// import Rang from '../components/lang';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Form, Tab, Nav, Row, Col,Dropdown } from "react-bootstrap";

import plus from '../../assets/img/icon-plus-white.svg';
import iconpen from '../../assets/img/icon-pen.svg';
import iconbin from '../../assets/img/icon-bin.svg';
import iconlag from '../../assets/img/icon-lag.svg';

import Modalproductedit from "../../layout/modal/modalProductedit";
import Modaleditproduct from "../../layout/modal/modalProduct";
import Modalcategorydel from "../../layout/modal/modalCategorydel";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

//Drag handler
const DragHandle = sortableHandle(() => (
    <span className="position-absolute cursor-pointer" style={{ left: "10px", top: "12px" }}>
        <img src={iconlag} alt="" className="" style={{ width: "15px" }} />
    </span>
));

const Modalproductlist = (data) => {

    let history = useNavigate();

    // console.log(data.datacat);

    let datatatb = data.datacat.datacat;
    let dataproduct = data.datacat.dataproduct;
    let datapro = data.datacat.dataproductall;
    let datawarehouse = data.datacat.datawarehouse;

    const [validated, setValidated] = useState(false);

    // console.log(datapro)

    const [modalproductedit, setModalproductedit] = useState({ view: false, id: 0, datacat: [], datapro: [],datawarehouse:[] });
    const [modalcategorydel, setModalcategorydel] = useState({ view: false, id: 0 });

    const [source, setSource] = useState(data.datacat.catid);
    const [cate, setCate] = useState({ items: [...datatatb] });

    const [state, setState] = useState({ items: [...dataproduct] });
    const [dataall, setDataall] = useState({ items: [...datapro] });

    const [dataKey, setDataKey] = useState(cate.items[0].category);

    // console.log(dataproduct)

    useEffect(() => {
        setCate({ items: [...datatatb] })
        setState({ items: [...dataproduct] })
        setDataall({ items: [...datapro] })
    }, [datatatb, dataproduct, datapro]);

    const onSortEnd = ({ oldIndex, newIndex }) => {

        setState(({ items }) => ({
            items: arrayMove(items, oldIndex, newIndex),
        }));
    };

    // console.log()

    const SortableList = SortableContainer(({ items, idcat }) => {
        // .sort((a, b) => a.position - b.position)
        // console.log(idcat);
        if (idcat === source) {
            // alert(1)
            const data = new FormData();
            const branch = JSON.parse(localStorage.getItem("branch_data"));
            data.append("branch_id", branch.id);
            data.append("cat_id", source);
            data.append("dataposition", JSON.stringify(state.items.filter(val => val.cat_id === `${source}`)));
            // data.append("new", newIndex);

            AuthService.positionProduct(data).then(
                (response) => {
                    // console.log(response)
                    if (response.status === 200) {
                        // console.log(response.data)
                        setDataall({ items: response.data })
                    }
                },
                (error) => {
                    console.log(error);
                }
            )
        }

        return (
            <ul className='list-category'>
                {items.map((value, index) => (
                    <SortableItem key={index} index={index} value={value} />
                ))}
            </ul>
        );
    });

    const SortableItem = SortableElement(({ key, value }) =>

        <li tabIndex={key} key={key}>
            <Row className="mt-2" >
                <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <div className="position-relative" >
                        <DragHandle />
                        <Form.Control type="text" name="catagory[]" disabled={true} className='font-14 input-catrgory' defaultValue={value.name} required style={{ paddingLeft: "35px", paddingRight: "70px" }} />
                        <span onClick={() => setProduct(value.id)} className="icon-input cursor-pointer" style={{ right: "35px", top: "12px", borderRight: "1px solid #2e384d", paddingRight: "12px" }}>
                            <img src={iconpen} alt="" className="" style={{ width: "15px" }} />
                        </span>
                        <span className="icon-input cursor-pointer" onClick={() => setModalcategorydel({ view: true, id: value.id, text: value.name })} style={{ right: "10px", top: "12px" }}>
                            <img src={iconbin} alt="" className="" style={{ width: "15px" }} />
                        </span>
                    </div>
                </Form.Group>
            </Row>
        </li>
    );

    const deleteFile = (event) => {

        AuthService.delProduct(event, 'product').then(
            (response) => {
                if (response.status === 200) {
                    // console.log(response.data.status)
                    // window.location.reload();                   
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: '',
                        text: "บันทึกข้อมูลเรียบร้อยแล้ว",
                        showConfirmButton: false,
                        timer: 1500
                    }).then(function () {
                        data.onHide(event);
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        )
        const s = state.items.filter(item => item.id !== event);
        setState({ items: s });
        setModalcategorydel({ view: false })
    }

    
    const clicksetSource = (event,key) => {
        setSource(event)
        setState({ items: dataall.items.filter(val => val.cat_id === `${event}`) })
        setDataKey(key)
    }

    const setProduct = (e) => {
        // alert(modalproductedit.view)
        if (cate.items) {
            setModalproductedit({ view: true, id: e, datacat: cate.items, datapro: datapro.filter(val => val.id === `${e}`)[0],datawarehouse:datawarehouse })
        } else {
            setModalproductedit({ view: true, id: 0, datacat: [], datapro: [],datawarehouse:[] })
        }
    }

    const setProductonhide = (e) => {
        // alert(modalproductedit.view)
        data.onHide(e);

        setModalproductedit({ view: false, id: 0, datacat: [], datapro: [] })
        setModalcategorydel({ view: false })
    }

    // console.log()
    

    return (
        <>
            <Modal {...data} className="modal-custom" size="md" backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton className="btn-close-white "></Modal.Header>
                <Modal.Body className="p-0" >
                    <Tab.Container id="left-tabs-example" defaultActiveKey={data.datacat.catid}>
                        <div className="d-block w-100 scroll-custom">
                            <Modal.Title className='py-3 bg-white px-0 shadow-sm position-fixed w-100' style={{ zIndex: "1" }}>
                                <div className='text-default font-14'>
                                    <nav className="navbar bg-white ">
                                        <Nav as="ul" variant="tabs" className="nav nav-tabs overflow-hidden ul-tabcustom">
                                            {/* {cate.items.map((value, index) => (
                                                <Nav.Item as="li" key={index}>
                                                    <Nav.Link eventKey={value.id} onClick={() => clicksetSource(value.id)}>{value.category}</Nav.Link>
                                                </Nav.Item>
                                            ))} */}
                                            <Nav.Item as="li">
                                                <Dropdown className='menu-top-dd'>
                                                    <Dropdown.Toggle variant="menu-tab" id="dropdown-basic">
                                                        {dataKey}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>                                                        
                                                        {cate.items.map((value, index) => (
                                                            <Dropdown.Item eventKey={value.id} key={index} onClick={(e) => clicksetSource(value.id,value.category)}>{value.category}</Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Nav.Item>
                                        </Nav>
                                    </nav>
                                </div>
                            </Modal.Title>
                            <div className='h-100per-modal bg-white px-4 pt-5'>
                                <Tab.Content className="bg-white rounded-3 mt-3">
                                    {cate.items.map((value, index) => (
                                        <Tab.Pane eventKey={value.id} key={index}>
                                            <div className='mt-5'>
                                                <SortableList items={state.items} idcat={value.id} onSortEnd={onSortEnd} useDragHandle />
                                            </div>
                                        </Tab.Pane>
                                    ))}
                                </Tab.Content>
                            </div>
                        </div>
                    </Tab.Container>
                </Modal.Body>
            </Modal>
            {modalproductedit.view ?
                <Modalproductedit show={modalproductedit.view} onHide={(e) => setProductonhide(e)} type="edit" datacat={modalproductedit} />
                :
                null}
            <Modalcategorydel show={modalcategorydel.view} onHide={(e) => setProductonhide(e)} id={modalcategorydel.id} action={() => deleteFile(modalcategorydel.id)} text={"ยืนยันการลบเมนู " + modalcategorydel.text} />
        </>
    );
};

export default Modalproductlist;