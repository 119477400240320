import React, { useState, useCallback } from 'react';
import { Modal, Button } from "react-bootstrap";

// import { BrowserRouter as Router } from "react-router-dom";
// import axios from 'axios';
// import { Link } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
// import Rang from '../components/lang';

import noimg from '../../assets/img/no-img.svg';

import Cropper from 'react-easy-crop';
import getCroppedImg from '../../components/cropPng'

import '../../assets/css/crop.css';

const Modalcrop = (data) => {

    // console.log(data.inputImg);
    // const dogImg =
    // 'https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000'
    const dogImg = data.inputimg
    // const { t } = useTranslation();

    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)

    // const [modalcrop, setModalcrop] = useState({ view: false, id: 0, getblobb:false });

    // const [getBlob, setGetBlob] = useState()

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {

        data.onHide();
        try {

            const croppedImage = await getCroppedImg(
                dogImg,
                croppedAreaPixels,
            )
            // console.log('donee', croppedImage);
            setCroppedImage(croppedImage)

            if (data.getblobb) {
                data.getblobb(croppedImage); 
            }
            // data.onHide();
            // data.onHide(() => setModalcrop({ view: false, getblobb: croppedImage }));

        } catch (e) {
            console.error(e)
        }
        
        

    }, [croppedAreaPixels]);

    // console.log(croppedImage)
    // console.log(data.onHide)
    // console.log(data)

    return (
        <>
            <Modal {...data} className="slideimg" size="lg">
                <Modal.Header closeButton className="btn-close-dark ">
                    <Modal.Title>
                        <div className='text-default'>โปรไฟล์กิจการ</div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0 bg-white">
                    <div style={{ height: "70vh" }}>
                        <div className="crop-container">
                            <Cropper
                                image={dogImg}
                                crop={crop}
                                zoom={zoom}
                                aspect={1}
                                onCropChange={setCrop}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}
                                // cropSize={ width:"600", height: "600" }
                                // cropSize={{width: '400px', height: '400px'}}
                                objectFit="contain"
                            />
                        </div>
                      
                    </div>
                </Modal.Body>
                <Modal.Footer >
                    <div className="controls">
                        <h2 className='my-0 me-1'>-</h2>
                        <input
                            type="range"
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            aria-labelledby="Zoom"
                            onChange={(e) => {
                                setZoom(e.target.value)
                            }}
                            className="zoom-range"
                        />
                        <h2 className='my-0 ms-1'>+</h2>
                    </div>

                    <Button onClick={showCroppedImage} className="btn btn-bg border-0 py-2" >
                        <i className="fa fa-crop" aria-hidden="true"></i> ครอบตัดภาพ
                    </Button>
                    {/* <Button variant="primary">
                        Save Changes
                    </Button> */}
                </Modal.Footer>
            </Modal>

        </>
    );
};

export default Modalcrop;