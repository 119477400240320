import React, { useState, useCallback, useEffect, useRef } from 'react';
// import axios from 'axios';
import { useNavigate,Link } from "react-router-dom";
import { compareAsc, format } from 'date-fns';

// import { Link } from "react-tiger-transition";
import { Form, Tab, Nav, Row, Col, Table } from "react-bootstrap";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import DateRangePicker from 'react-bootstrap-daterangepicker';
import TimePicker from 'react-bootstrap-time-picker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import AuthService from "../../components/services_member/auth.service";
import PostService from "../../components/services_member/post.service";

import Menuleft from "../../layout/pagesadmin/menuleft";

import help from '../../assets/img/help.svg';
import iplus from '../../assets/img/icon-plus-white.svg';
import calendar from '../../assets/img/calendar.svg';
import icontime from '../../assets/img/icon-time.svg';

import { useTranslation } from 'react-i18next';
const Membertable = () => {
    const { t } = useTranslation();
    const MySwal = withReactContent(Swal)

    localStorage.removeItem("table_order");

    let history = useNavigate();
    const user = AuthService.getCurrentUser();
    const branch = AuthService.getCurrentBranch();

    if (!user) {
        history("/");
    } else {
        if (!branch) {
            history("/branch");
        }
    }

    const [dateday, setDateday] = useState(format(new Date(), 'dd/MM/yyyy'));
    const [datatime, setDatatime] = useState([{ val: 0, id: 0 }]);
    const [datatime2, setDatatime2] = useState([{ val: 0, id: 0 }]);

    const [datamember, setDatamember] = useState([]);

    const formRef = useRef(null);

    const dateoption = {
        locale: {
            format: 'DD/MM/YYYY'
        },
        startDate: dateday,
        // maxDate: dateday,
        singleDatePicker: true,
        // autoApply: true,
    };

    const handledateday = (event) => {
        fetchData(event.target.value)
        setDateday(event.target.value)
        // setOrderview()
    }

    const fetchData = useCallback(async (dd) => {

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        PostService.getEmployeeall(branch.id, dd).then(
            (response) => {
                if (response.data.status === 200) {

                    console.log(response.data)
                    setDatamember(response.data.data);
                    // setDatatime(response.data.memberdata);                    
                    response.data.data.map((val) => {
                        // console.log(val.table_member.attend,val.id)
                        if (val.table_member) {
                            setDatatime(arr => [...arr, { val: val.table_member.attend, id: val.id }])
                            setDatatime2(arr => [...arr, { val: val.table_member.finish, id: val.id }])
                        } else {
                            setDatatime([{ val: 0, id: 0 }])
                            setDatatime2([{ val: 0, id: 0 }])
                            formRef.current.reset();
                        }
                    })

                } else {
                    setDatamember([]);
                }
            },
            (error) => {
                console.log(error);
            }
        )
    });

    useEffect(() => {
        fetchData(dateday);
    }, []);

    const handleTimeChange = (time, id) => {
        const test = datatime.map(obj => {
            if (obj.id === id) {
                return { ...obj, val: time, id: id };
            }
            return obj;
        });
        datatime.filter(vls => vls.id === id).length ?
            setDatatime(test)
            : setDatatime(arr => [...arr, { val: time, id: id }])
    }

    const handleTimeChange2 = (time, id) => {
        const test = datatime2.map(obj => {
            if (obj.id === id) {
                return { ...obj, val: time, id: id };
            }
            return obj;
        });
        datatime2.filter(vls => vls.id === id).length ?
            setDatatime2(test)
            : setDatatime2(arr => [...arr, { val: time, id: id }])
    }

    const valuetime = (data, id) => {
        return (data.filter(vlss => vlss.id === id).length ? data.filter(vlss => vlss.id === id)[0].val : null)
    }


    const handleSubmit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const dataf = new FormData(event.target);

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        dataf.append("branch_id", branch.id);

        AuthService.updateTablemember(dataf).then(
            (res) => {
                //   console.log(res)   
                if (res.status === 200) {
                    // window.location.reload();
                    // data.onHide('edit')

                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: '',
                        text: "อัพเดทเรียบร้อยแล้ว",
                        showConfirmButton: false,
                        timer: 1500
                    }).then(function () {
                        fetchData(dateday);
                    })
                } else {
                    // console.log(res.message)
                    // data.onHide('edit')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    // console.log(datamember)

    return (
        <>
        <div className={!t('menu-a') ? "sidebar-mini sidebar-collapse" : ""} >
            <div className='wrapper-admin layout-fixed'>
                <Form noValidate onSubmit={handleSubmit} className="" ref={formRef}>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="food_all">
                        <nav className="main-header navbar bg-white shadow-sm" style={{ height: "60px" }}>
                            <Nav as="ul" className="nav overflow-hidden " style={{ marginTop: "-4px" }}>
                                <li>
                                    <div className="bg-white rounded-3 ms-2 position-relative">
                                        <div className=''>
                                            <div className='text-start py-3 weight-500 d-inline-block me-3'>ค้นหาวันที่</div>
                                            <div className='position-relative d-inline-block'>
                                                <Form.Group controlId="dob">
                                                    <DateRangePicker initialSettings={dateoption} onApply={(e) => (handledateday(e))}>
                                                        <input type="text" defaultValue={dateday} className="form-control px-2 py-2" name="dateday" />
                                                    </DateRangePicker >
                                                    <div className='position-absolute' style={{ top: "10px", right: "12px" }}>
                                                        <img src={calendar} alt="" className="" style={{ width: "17px" }} />
                                                    </div>
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className='ms-3'>
                                    <button type='submit' className="w-100 btn btn-submit text-white py-2 px-3 mt-1">
                                        <img src={iplus} /> อัพเดท
                                    </button>
                                </li>

                            </Nav>
                            <ul className="navbar-nav ml-auto" style={{ marginTop: "-20px" }}>
                                <li className="">
                                    <div className='text-gray font-12'>
                                        <img src={help} alt="" className="me-1" style={{ width: "14px" }} /> ขอความช่วยเหลือ
                                    </div>
                                </li>
                            </ul>
                        </nav>
                        <Menuleft type="tablemember" />
                        <div className="content-wrapper">
                            <section className="content scroll-custom">
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <div className="bg-white rounded-3 mt-3 h-100per">
                                            <div className='text-start p-2'>
                                                <Table striped bordered className='table-custom'>
                                                    <thead>
                                                        <tr>
                                                            <th className='font-18' style={{ width: "25%" }}>ชื่อ</th>
                                                            <th className='font-18' style={{ width: "150px" }}><span className='border-left-table me-2'></span>เข้างาน</th>
                                                            <th className='font-18' style={{ width: "150px" }}><span className='border-left-table me-2'></span>เลิกงาน</th>
                                                            <th className='font-18' style={{ width: "150px" }}><span className='border-left-table me-2'></span>OT</th>
                                                            <th className='font-18'><span className='border-left-table me-2'></span>หมายเหตุ</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            datamember.map((value2, index2) => (
                                                                <tr key={index2} >
                                                                    <td>{value2.name}</td>
                                                                    <td><span className='border-left-table me-2'></span>
                                                                        <div className="position-relative d-inline-block">
                                                                            <TimePicker onChange={(e) => handleTimeChange(e, value2.id)} name={`attend[${value2.id}]`}
                                                                                initialValue={valuetime(datatime, value2.id)} format={24} step={5} className="font-14" style={{ width: "110px" }} />
                                                                            <div className='position-absolute' style={{ bottom: "12px", right: "12px" }}>
                                                                                <img src={icontime} alt="" className="me-1" style={{ width: "18px" }} />
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td><span className='border-left-table me-2'></span>
                                                                        <div className="position-relative d-inline-block" >
                                                                            <TimePicker onChange={(e) => handleTimeChange2(e, value2.id)} name={`finish[${value2.id}]`}
                                                                                initialValue={valuetime(datatime2, value2.id)} format={24} step={5} className="font-14" style={{ width: "110px" }} />
                                                                            <div className='position-absolute' style={{ bottom: "12px", right: "12px" }}>
                                                                                <img src={icontime} alt="" className="me-1" style={{ width: "18px" }} />
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td><span className='border-left-table me-2'></span>
                                                                        <div className="position-relative pe-1 d-inline-block">
                                                                            <Form.Control type="number" defaultValue={value2.table_member ? value2.table_member.ot : 0} name={`ot[${value2.id}]`} min="0" step="0.5" style={{ width: "100px" }} className='font-14' required />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="position-relative pe-1">
                                                                            <Form.Control type="text" defaultValue={value2.table_member ? value2.table_member.note : ""} name={`note[${value2.id}]`} className='font-14 input-custom' style={{ background: "#F7F7F7" }} required />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </Table>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </section>

                        </div>
                    </Tab.Container>
                </Form>
            </div>
            </div>
        </>
    );
};

export default Membertable;