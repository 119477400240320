import React, { useState, useRef } from 'react';
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { Form, Tab, Nav, Row, Col } from "react-bootstrap";

import AuthService from "../../components/services_member/auth.service";
import Swal from 'sweetalert2'

const Modalcategoryadd = ({ actionAdd, ...datas }) => {

    let history = useNavigate();

    const formRef = useRef(null);

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        // alert(55)
        event.preventDefault();

        const form = event.currentTarget;

        const data = new FormData(event.target);

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        data.append("branch_id", branch.id);
        data.append("type_cat", datas.type);

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();
            // alert(55)
            // if (!blob) {           
            //     setErrorblob("Please select uploads file.")
            // }

        } else {

            // if(blob){

            AuthService.addCategory(data).then(
                (res) => {
                    //   console.log(res)   
                    if (res.status === 200) {
                        // history("/setting/setting"); 
                        // window.location.reload();
                        formRef.current.reset();

                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: '',
                            text: "บันทึกข้อมูลเรียบร้อยแล้ว",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(function () {
                            // datas.onHide();
                            actionAdd()
                            setValidated(false);
                        })
                    } else {
                        // setErrors({email:"This email cannot be used.!"})
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
            // } else {
            //     setErrorblob("Please select uploads file. !")

            // }
        }
        setValidated(true);
    };



    console.log(datas)
    return (
        <>
            <Modal {...datas} className="modal-custom" size="md" backdrop="static"
                keyboard={false} style={{ background: "rgba(0,0,0,0.7)" }}>
                <Modal.Title className='py-3 bg-white px-4 shadow-sm position-fixed w-100' style={{ zIndex: "1" }}>
                    <div className='text-default font-22'>
                        {datas.type === 'add' ? 'เพิ่มหมวดหมู่' : 'แก้ไขหมวดหมู่'}
                    </div>
                </Modal.Title>
                <Modal.Body className="p-0">
                    <Form noValidate validated={validated} onSubmit={handleSubmit} className="" ref={formRef}>
                        <div className='h-100per-modal bg-white px-4 pt-5'>
                            {datas.type === 'add' ?
                                <div>
                                    <Row className="mt-5">
                                        <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                            <Form.Label className="font-12 text-gray mb-0">ชื่อหมวดหมู่</Form.Label>
                                            <Form.Control type="text" name="category" className='font-14 input-custom' placeholder='xxxxxxxx' required />
                                        </Form.Group>
                                    </Row>
                                    <Row className="align-items-end mt-3 mb-3">
                                        <Form.Group as={Col} md="6" className="position-relative pe-1" controlId="validationCustom01">
                                            <Form.Label className="font-12 text-gray mb-2">ประเภทหมวดหมู่</Form.Label>
                                            <Form.Select aria-label="Default select example" className='font-14' name='type' required>
                                                <option value="">เลือกประเภทหมวดหมู่</option>
                                                <option value="0">Food</option>
                                                <option value="1">Drink</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Row>

                                </div>
                                :
                                <div>
                                    <Row className="mt-5">
                                        <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                            <Form.Label className="font-12 text-gray mb-0">ชื่อหมวดหมู่</Form.Label>
                                            <Form.Control type="text" name="category" className='font-14 input-custom' defaultValue={datas.text.category} placeholder='xxxxxxxx' required />
                                            <Form.Control type="hidden" name="id" className='font-14 input-custom' defaultValue={datas.text.id}/>
                                            <Form.Control type="hidden" name="type" className='font-14 input-custom' defaultValue={datas.type}/>
                                        </Form.Group>
                                    </Row>
                                    <Row className="align-items-end mt-3 mb-3">
                                        <Form.Group as={Col} md="6" className="position-relative pe-1" controlId="validationCustom01">
                                            <Form.Label className="font-12 text-gray mb-2">ประเภทหมวดหมู่</Form.Label>
                                            <Form.Select aria-label="Default select example" className='font-14' defaultValue={datas.text.type} name='type' required>
                                                <option value="">เลือกประเภทหมวดหมู่</option>
                                                <option value="0">Food</option>
                                                <option value="1">Drink</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Row>

                                </div>
                            }

                        </div>
                        <div className='text-center py-3 px-3 bg-gray'>
                            <div className='row'>
                                <div className="col-12 col-md-6 col-lg-6 pe-1">
                                    <span className="w-100 btn btn-calcel text-white py-2 me-3" onClick={datas.onHide}>
                                        ยกเลิก
                                    </span>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 ps-1">
                                    <button type='submit' className="w-100 btn btn-submit text-white py-2 me-3">
                                        บันทึก
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Form>


                </Modal.Body>
            </Modal>


        </>
    );
};

export default Modalcategoryadd;