import React, { useState, useEffect, useCallback } from 'react';
// import axios from 'axios';
import { useNavigate,Link } from "react-router-dom";
import AuthService from "../../components/services_member/auth.service";
import PostService from "../../components/services_member/post.service";

import help from '../../assets/img/help.svg';
import iconedit from '../../assets/img/icon-edit.svg';
import icondel from '../../assets/img/icon-del.svg';
import iconplus from '../../assets/img/icon-plus-white.svg';


import Menuleft from "../../layout/pagesadmin/menuleft";
import Menusetting from "../../components/setting/setting_menu";

import { Nav, ListGroup } from "react-bootstrap";

// import noimg from '../../assets/img/no-img.svg';

import Modaltable from "../../layout/modal/modalTable";
import { useTranslation } from 'react-i18next';

const Settingtable = () => {
    const { t } = useTranslation();
    let history = useNavigate();

    const user = AuthService.getCurrentUser();
    const branch = AuthService.getCurrentBranch();

    if (!user) {
        history("/");
    } else {
        if (!branch) {
            history("/branch");
        }
    }

    const [datatable, setDatatable] = useState([]);

    const fetchData = useCallback(async () => {
        PostService.getAllTable(branch.id).then(
            (response) => {
                if (response.data.status === 200) {
                    setDatatable(response.data.data);
                }
            },
            (error) => {
                console.log(error);
            }
        )
    });

    useEffect(() => {
        // let timer1 = setTimeout(() => fetchData(), 100);            
        // return () => {
        //     clearTimeout(timer1);
        //   };
        fetchData()
    }, []);

    const [modaltable, setModaltable] = useState({ view: false, id: 0, type: false });
    const [dtable, setDtable] = useState([]);
    const [nametable, setNametable] = useState();

    const setMo = (e, type) => {
        // alert(e)
        setModaltable({ view: true, id: e, type: type });

        setDtable(JSON.parse(datatable.filter((ev) => ev.id === e)[0].detail));

        setNametable(datatable.filter((ev) => ev.id === e)[0].zone);
    }

    const setReset = (e) => {
        setModaltable({ view: false });
        setDtable([]);
        fetchData();

    }

    // const refreshdata = () => {
    //     fetchData();
    //     setModaltable({ view: false })
    // }


    return (
        <>
            <div className={!t('menu-a') ? "sidebar-mini sidebar-collapse" : ""} >
                <div className='wrapper-admin layout-fixed'>
                    <nav className="main-header navbar bg-white shadow-sm" style={{ height: "60px" }}>
                        <Nav as="ul" variant="tabs" className="nav nav-tabs overflow-hidden ul-tabcustom">

                        </Nav>
                        <ul className="navbar-nav ml-auto" style={{ marginTop: "-20px" }}>
                            <li className="">
                                <div className='text-gray font-12'><img src={help} alt="" className="me-1" style={{ width: "14px" }} /> ขอความช่วยเหลือ</div>
                            </li>
                        </ul>
                    </nav>

                    <Menuleft type="setting" />

                    <div className="content-wrapper">
                        <section className="content scroll-custom">
                            <div className="row">
                                <div className="col-12 col-md-5 col-lg-5">
                                    <div className='text-start box-setting bg-white rounded-3 mt-3 h-100per'>
                                        <Menusetting type="table" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-7 col-lg-7 ps-0">
                                    <div className='bg-white rounded-3 ms-2 mt-3 border-bottom-none pt-1 pb-2'>
                                        <div className='text-start py-3 shadow-sm px-4'>
                                            <b>พื้นที่</b>
                                            <span className='float-end' style={{ marginTop: "-7px" }}>
                                                <button onClick={() => setModaltable({ view: true, type: "add" })} className="btn btn-submit text-white py-1 px-3 font-14">
                                                    <img src={iconplus} alt="" className="" /> สร้างพื้นที่ใหม่
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="bg-white rounded-3 ms-2 h-100per-right-c position-relative border-top-none">
                                        <div className='text-start box-employee' style={{ marginTop: "-5px" }}>
                                            <ListGroup>
                                                {datatable.map((e, i) => (
                                                    <ListGroup.Item key={i}>
                                                        <span onClick={() => setMo(e.id, 'detail')}>{e.zone}</span>
                                                        <span className='float-end'>
                                                            {/* <span className='button-edit me-2' onClick={() => setModaltable({ view: true, id:e.id, type: "edit" })}><img src={iconedit} alt="" className="" /></span> */}
                                                            <span className='button-edit me-2' onClick={() => setMo(e.id, 'edit')}><img src={iconedit} alt="" className="" /></span>
                                                            <span className='button-del' onClick={() => setMo(e.id, 'del')}><img src={icondel} alt="" className="" /></span>
                                                        </span>
                                                    </ListGroup.Item>
                                                ))}
                                            </ListGroup>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>


            {/* <Modaltable show={modaltable.view} onHide={() => setModaltable({ view: false })} id={modaltable.id} type={modaltable.type} datatable={dtable} /> */}
            <Modaltable show={modaltable.view} onHide={() => setReset()} id={modaltable.id} type={modaltable.type} datatable={dtable} zone={nametable} />
        </>
    );
};

export default Settingtable;