import React, { useState } from 'react';
import { Form, Col, Row } from "react-bootstrap";

// import axios from 'axios';
import { useNavigate ,Link} from "react-router-dom";

import back from '../../assets/img/icon-back.svg';

import InputMask from 'react-input-mask';

// state = { open: false };

// toggleMenu = () => {
//     this.setState({ open: !this.state.open });
//     console.log(this.state.open);
// };

const Register = () => {

    let history = useNavigate();

    const [validated, setValidated] = useState(false);
    const [valtel, setValtel] = useState();

    const handleSubmit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const data = new FormData(event.target);

        // console.log(data.get('name'));
        // const { email,password,password2 } = event.target.elements;   
        // var errors = [];

        // console.log(errors.push("tel"));

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

        } else {

            var val = { name: data.get('name'), idnumber: data.get('id_number'), tel: data.get('tel') };

            localStorage.setItem('register', JSON.stringify(val));

            history("/register_b");
        }

        setValidated(true);
    };

    // const onKeyUpValue = (event) => {

    //     console.log(event.target.value)

    // }

    const autoTab = (obj, typeCheck) => {
        var pattern = new String("___-___-____"); // กำหนดรูปแบบในนี้
        var pattern_ex = new String("-"); // กำหนดสัญลักษณ์หรือเครื่องหมายที่ใช้แบ่งในนี้
        var returnText = new String("");
        var obj_l = obj.target.value.length;
        var obj_l2 = obj_l - 1;
        var i = 0;
        for (i = 0; i < pattern.length; i++) {
            if (obj_l2 == i && pattern.charAt(i + 1) == pattern_ex) {
                returnText += obj.target.value + pattern_ex;
                obj.target.value = returnText;
            }
        }
        if (obj_l >= pattern.length) {
            obj.target.value = obj.target.value.substr(0, pattern.length);
        }
    };
    const autoTab2 = (obj) => {
        // console.log(obj.target.value.length);
        var pattern = new String("_-____-_____-__-_");// กำหนดรูปแบบในนี้
        var pattern_ex = new String("-"); // กำหนดสัญลักษณ์หรือเครื่องหมายที่ใช้แบ่งในนี้
        var returnText = new String("");
        var obj_l = obj.target.value.length;
        // console.log(obj_l)
        // console.log(pattern.length)
        var obj_l2 = obj_l - 1;
        var i = 0;
        for (i = 0; i < pattern.length; i++) {
            if (obj_l2 == i && pattern.charAt(i + 1) == pattern_ex) {
                returnText += obj.target.value + pattern_ex;
                obj.target.value = returnText;
                // obj.target.value = '-';
                console.log(returnText)
            }
        }
        if (obj_l >= pattern.length) {
            obj.target.value = obj.target.value.substr(0, pattern.length);
        }
        //  if (obj_l == 0) {
        //     obj.target.value = '';
        // }
    };

    return (
        <>
            <div className="wrapper" style={{ backgroundColor: "#EAEAEA", zIndex: "1" }}>
                <section className="" >
                    <div className="container-fluid">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-12 col-md-7 col-xl-4 text-start h-100vh position-relative">
                                <div className='position-absolute w-100 position-center px-3'>
                                    <div className='box-shadow pb-4 bg-white'>
                                        <div className='bg-topic text-white p-3'>
                                            <h4 className='weight-400 m-0'>
                                                <Link to="/" transition="default-fade" className="text-decoration-none">
                                                    <img src={back} alt="" className="me-3" />
                                                </Link>
                                                ลงทะเบียน
                                            </h4>
                                        </div>
                                        <Form noValidate validated={validated} onSubmit={handleSubmit} className="px-5 mt-4">
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md="12" className="position-relative mt-3" controlId="validationCustom01">
                                                    <Form.Label className='text-gray mb-1 font-14'>ชื่อผู้ใช้</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        className=""
                                                        placeholder="Name"
                                                        name="name"
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="12" className="position-relative mt-3" controlId="validationCustom01">
                                                    <Form.Label className='text-gray mb-1 font-14'>หมายเลขบัตรประชาชน หรือหนังสือเดินทาง</Form.Label>
                                                    {/* <Form.Control
                                                        required
                                                        type="text"
                                                        className=""
                                                        placeholder="x-xxxx-xxxxx-xx-x"
                                                        name="id_number"
                                                        pattern="\d{1}[\-]\d{4}[\-]\d{5}[\-]\d{2}[\-]\d{1}"
                                                        onKeyUp={autoTab2.bind(this)}
                                                    /> */}
                                                    <InputMask mask="9-9999-99999-99-9" placeholder="x-xxxx-xxxxx-xx-x" name="id_number" type="text"
                                                        className='form-control' maskChar={null} required
                                                        pattern="\d{1}[\-]\d{4}[\-]\d{5}[\-]\d{2}[\-]\d{1}" />

                                                </Form.Group>
                                                <Form.Group as={Col} md="12" className="position-relative mt-3" controlId="validationCustom01">
                                                    <Form.Label className='text-gray mb-1 font-14'>หมายเลขโทรศัพท์</Form.Label>
                                                    <InputMask
                                                        mask="999-999-9999"
                                                        maskChar={null}
                                                        required
                                                        className='form-control'
                                                        type="text"
                                                        placeholder="xxx-xxx-xxxx"
                                                        name="tel"
                                                        // value={valtel}
                                                        // onKeyUp={autoTab.bind(this)}

                                                        pattern="\d{3}[\-]\d{3}[\-]\d{4}"
                                                    // pattern="(?=.*\d)(?=.*[0-9]).{12,}"
                                                    />
                                                </Form.Group>
                                            </Row>
                                            <button type="submit" className="w-100 btn btn-bg text-white mt-5">ถัดไป</button>
                                        </Form>
                                    </div>
                                    <div className='w-100 d-inline-block text-center'>
                                        <ul className='timeline-cicle mt-3'>
                                            <li className=''><span className='text-white cicle active'>1</span></li>
                                            <li className=''><span className='text-white cicle'>2</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        </>
    );
};

export default Register;