import React, { useState } from 'react';
// import axios from 'axios';
import { Link } from "react-router-dom";

import sorn from '../../assets/img/sorn.svg';

// import { Link } from "react-tiger-transition";

import { ListGroup } from "react-bootstrap";

const Menusetting = (type) => {

    // let history = useHistory();
    const [menuactive, setMenuactive] = useState(type.type);

    return (
        <>

            <ListGroup>
                <ListGroup.Item>
                    <Link to="/setting/payment" transition="default-fade" className={menuactive == "payment" ? "active" : ""}>
                        การชำระเงิน
                        <span className='float-end'>
                            <img src={sorn} alt="" className="" />
                        </span>
                    </Link>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Link to="/setting/banner" transition="default-fade" className={menuactive == "banner" ? "active" : ""}>
                        Banner
                        <span className='float-end'>
                            <img src={sorn} alt="" className="" />
                        </span>
                    </Link>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Link to="/setting/employee" transition="default-fade" className={menuactive == "employee" ? "active" : ""}>
                        พนักงาน
                        <span className='float-end'>
                            <img src={sorn} alt="" className="" />
                        </span>
                    </Link>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Link to="/setting/product" transition="default-fade" className={menuactive == "product" ? "active" : ""}>
                        จัดการเมนู / โปรโมชั่น
                        <span className='float-end'>
                            <img src={sorn} alt="" className="" />
                        </span>
                    </Link>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Link to="/setting/table" transition="default-fade" className={menuactive == "table" ? "active" : ""}>
                        ตั้งค่าโต๊ะ
                        <span className='float-end'>
                            <img src={sorn} alt="" className="" />
                        </span>
                    </Link>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Link to="/setting/printer" transition="default-fade" className={menuactive == "printer" ? "active" : ""}>
                        เครื่องพิมพ์
                        <span className='float-end'>
                            <img src={sorn} alt="" className="" />
                        </span>
                    </Link>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Link to="/setting/invoice" transition="default-fade" className={menuactive == "invoice" ? "active" : ""}>
                        รูปแบบใบเสร็จ
                        <span className='float-end'>
                            <img src={sorn} alt="" className="" />
                        </span>
                    </Link>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Link to="/setting/linklogin" transition="default-fade" className={menuactive == "linklogin" ? "active" : ""}>
                        URL ล็อกอินสำหรับพนักงาน
                        <span className='float-end'>
                            <img src={sorn} alt="" className="" />
                        </span>
                    </Link>
                </ListGroup.Item>
            </ListGroup>

        </>
    );
};

export default Menusetting;