import React, { useState, useCallback, useEffect } from 'react';
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { SortableContainer, SortableElement, arrayMove, sortableHandle } from 'react-sortable-hoc';

import AuthService from "../../components/services_member/auth.service";
// import arrayMove from 'array-move';

// import axios from 'axios';
// import { Link } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
// import Rang from '../components/lang';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Form, Tab, Nav, Row, Col } from "react-bootstrap";

import plus from '../../assets/img/icon-plus-white.svg';
import iconpen from '../../assets/img/icon-pen.svg';
import iconbin from '../../assets/img/icon-bin.svg';
import iconlag from '../../assets/img/icon-lag.svg';

// import Modalproductlist from "../../layout/modal/modalProductlist";
import Modalpromotionadd from "./modalProductpromotionadd";
import Modalpromotionedit from "./modalProductpromotionedit";
import Modalcategorydel from "../../layout/modal/modalCategorydel";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

//Drag handler
const DragHandle = sortableHandle(() => (
    <span className="position-absolute cursor-pointer" style={{ left: "10px", top: "12px" }}>
        <img src={iconlag} alt="" className="" style={{ width: "15px" }} />
    </span>
));

const Modalproductpromotionlist = (data) => {

    // console.log(data.datapromotion);

    let history = useNavigate();

    let datatatb = data.datapromotion;

    const [validated, setValidated] = useState(false);

    const [modalpromotionedit, setModalpromotionedit] = useState({ view: false, id: 0 });
    const [modalcategorydel, setModalcategorydel] = useState({ view: false, id: 0 });

    // const [state, setState] = useState({ items: [{ name: 'test', focus: false }, { name: 'Item 2', focus: false }, { name: 'Item 3', focus: false }] });
    const [state, setState] = useState({ items: [...datatatb] });

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setState(({ items }) => ({
            items: arrayMove(items, oldIndex, newIndex),
        }));
    };

    // autoFocus={ source.active.active == value ? true : false }
    const SortableItem = SortableElement(({ key, value }) =>
        <li tabIndex={key} key={key}>
            <Row className="mt-2" >
                <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <div className="position-relative" >
                        <DragHandle />
                        <Form.Control type="text" name="catagory[]" disabled={true} className='font-14 input-catrgory' defaultValue={value.promotion} required style={{ paddingLeft: "35px", paddingRight: "70px" }} />
                        <span onClick={() => setModalpromotionedit({ view: true, id:value.id })} className="icon-input cursor-pointer" style={{ right: "35px", top: "12px", borderRight: "1px solid #2e384d", paddingRight: "12px" }}>
                            <img src={iconpen} alt="" className="" style={{ width: "15px" }} />
                        </span>
                        <span className="icon-input cursor-pointer" onClick={() => setModalcategorydel({ view: true, id: key, idpromotion:value.id, text: value.promotion })} style={{ right: "10px", top: "12px" }}>
                            <img src={iconbin} alt="" className="" style={{ width: "15px" }} />
                        </span>
                    </div>
                </Form.Group>
            </Row>
        </li>
    );

    const SortableList = SortableContainer(({ items }) => {

        const data = new FormData();
        const branch = JSON.parse(localStorage.getItem("branch_data"));
        data.append("branch_id", branch.id);
        data.append("dataposition", JSON.stringify(state.items));

        AuthService.positionPromotion(data).then(
            (response) => {
                if (response.status === 200) {
                    // setState({ items: response.data })
                }
            },
            (error) => {
                console.log(error);
            }
        )

        return (
            <ul className='list-category'>
                {items.map((value, index) => (
                    <SortableItem key={index} index={index} value={value} />
                ))}
            </ul>
        );
    });

    const deleteFile = (event,idpro) => {
        // alert(event)
        AuthService.delPromotion(idpro,'promotion').then(
            (response) => {
                if (response.status === 200) {
                    // console.log(response.data.status)
                    // window.location.reload();
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: '',
                        text: "บันทึกข้อมูลเรียบร้อยแล้ว",
                        showConfirmButton: false,
                        timer: 1500
                    }).then(function () {
                        // data.onHide()
                    }) 
                }
            },
            (error) => {
                console.log(error);
            }
        )
        const s = state.items.filter((item, index) => item.id !== idpro);
        setState({ items: s });
        setModalcategorydel({ view: false })
    }

    // useEffect(() => {
    //     setState({ items: [...datatatb] })
    // }, [datatatb]);

    return (
        <>
            <Modal {...data} className="modal-custom" size="md" backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton className="btn-close-white ">

                </Modal.Header>
                <Modal.Body className="p-0">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="zone_all">
                        <div className="d-block w-100 scroll-custom">
                            <Modal.Title className='py-3 bg-white px-0 shadow-sm position-fixed w-100' style={{ zIndex: "1" }}>
                                <div className='text-default font-14'>
                                    <nav className="navbar bg-white ">
                                        <Nav as="ul" variant="tabs" className="nav nav-tabs overflow-hidden ul-tabcustom">
                                            <Nav.Item as="li">
                                                <Nav.Link eventKey="zone_all">โปรโมชั่น</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </nav>
                                </div>
                            </Modal.Title>
                            <div className='h-100per-modal bg-white px-4 pt-5'>
                                {/* <div className='mt-5'>
                                    <SortableList items={state.items} onSortEnd={onSortEnd} useDragHandle />
                                </div> */}
                                <Tab.Content className="bg-white rounded-3 mt-3">
                                    <Tab.Pane eventKey="zone_all">
                                        <div className='mt-5'>
                                            <SortableList items={state.items} onSortEnd={onSortEnd} useDragHandle />
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </div>
                    </Tab.Container>
                </Modal.Body>
            </Modal>
            { modalpromotionedit.view ?
            <Modalpromotionedit show={modalpromotionedit.view} onHide={() => setModalpromotionedit({ view: false })} dataproone={(state.items.filter((item, index) => item.id === modalpromotionedit.id)[0])} id={modalpromotionedit.id} type="edit" />
            : null }
            <Modalcategorydel show={modalcategorydel.view} onHide={() => setModalcategorydel({ view: false })} id={modalcategorydel.id} action={() => deleteFile(modalcategorydel.id,modalcategorydel.idpromotion)} text={"ยืนยันการลบโปรโมชั่น " + modalcategorydel.text} />
        </>
    );
};

export default Modalproductpromotionlist;