import React, { useState, useCallback, useEffect, useRef } from 'react';
// import axios from 'axios';
import { useNavigate,Link } from "react-router-dom";
import { compareAsc, format } from 'date-fns';

// import { Link } from "react-tiger-transition";
import { Form, Tab, Nav, Row, Col, Table } from "react-bootstrap";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import AuthService from "../../components/services_member/auth.service";
import PostService from "../../components/services_member/post.service";

import Menuleft from "../../layout/pagesadmin/menuleft";

import help from '../../assets/img/help.svg';
import memedit from '../../assets/img/mem_edit.svg';
import memde from '../../assets/img/mem_detail.svg';
import iplus from '../../assets/img/icon-plus-white.svg';
import search from '../../assets/img/search.svg';
import icondel from '../../assets/img/icon-del.svg';

import Modalmemberadd from "../../layout/modal/modalMemberadd";
import { useTranslation } from 'react-i18next';

import * as moment from 'moment/moment'
import 'moment-duration-format'
import 'moment/locale/th'
// import 'moment/locale/he'

const Member = () => {
    const { t } = useTranslation();
    const MySwal = withReactContent(Swal)

    const [todayDate, setTodayDate] = useState(new Date());

    localStorage.removeItem("table_order");

    let history = useNavigate();
    const user = AuthService.getCurrentUser();
    const branch = AuthService.getCurrentBranch();

    if (!user) {
        history("/");
    } else {
        if (!branch) {
            history("/branch");
        }
    }

    const [dateday, setDateday] = useState(format(new Date(), 'dd/MM/yyyy'));

    const [datamember, setDatamember] = useState([]);
    const [product, setProduct] = useState([]);

    const [modalmemberadd, setModalMemberadd] = useState({ view: false, id: 0 });

    const fetchData = useCallback(async (dd) => {

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        PostService.getMemberdataall(branch.id, dd).then(
            (response) => {
                if (response.data.status === 200) {
                    // console.log(response.data)
                    setDatamember(response.data.memberdata);
                    setProduct(response.data.memberdata);
                } else {
                    setDatamember([]);
                }
            },
            (error) => {
                console.log(error);
            }
        )
    });

    const called = useRef(true)

    useEffect(() => {

        if (called.current) {
            fetchData(dateday);
        }

        return () => {
            called.current = false;
        }
        

    }, []);

    const handlehide = (event) => {
        setModalMemberadd({ view: false, type: "" });

        fetchData(dateday);

    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        const dataf = new FormData(event.target);
        // console.log(data.get('search'))

        const keyword = dataf.get('search');

        if (keyword !== '') {
            const results = product.filter((product) => {
                return product.tel.toLowerCase().startsWith(keyword.toLowerCase());
            });

            setDatamember(results);

        } else {
            setDatamember(product);
        }
    }

    const age = (d) => {
        let dateString = new Date(d)
        // const date = moment(dateString, 'YYYY-MM-DD')
        // const years = moment().diff(date, 'years')
        // const days = moment().diff(date.add(years, 'years'), 'days', false)

        var date = dateString
        var diff = moment(date).diff(moment(), 'milliseconds');
        var duration = moment.duration(diff);
// console.log(duration)
        return duration.format("y [ปี], M [เดือน], d [วัน]", {
            largest: 3
        }).replace("-","")
        // return duration.format().replace("-","")
    }

    const formatStatus = (d) => {
        const date = new Date();
        const end = new Date(d);

        let test

        if (date > end) {
            test = false
        } else {
            test = true
        }

        return <>

            {test ? <span className="text-success">Active</span> : <span className="text-danger">หมดอายุ</span>}
        </>
    }

    return (
        <>
            <div className={!t('menu-a') ? "sidebar-mini sidebar-collapse" : ""} >
                <div className='wrapper-admin layout-fixed'>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="food_all">
                        <nav className="main-header navbar bg-white shadow-sm" style={{ height: "60px" }}>
                            <Nav as="ul" className="nav overflow-hidden " style={{ marginTop: "-4px" }}>
                                <li>
                                    <div className="bg-white rounded-3 ms-2 position-relative">
                                        <div className=''>
                                            <div className='text-start py-3 weight-500 d-inline-block me-3'>ค้นหาสมาชิก</div>
                                            <div className='position-relative d-inline-block'>
                                                <Form onSubmit={handleSubmit} className="">
                                                    <div className='position-relative'>
                                                        <Form.Group controlId="dob">
                                                            <Form.Control type="text" name='search' className='font-14' placeholder='เบอร์โทรสมาชิก' />
                                                            <div className='position-absolute' style={{ bottom: "6px", right: "3px" }}>
                                                                <button type="submit" className='btn p-0'><img src={search} alt="" className="me-1" style={{ width: "30px" }} /></button>
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className='ms-3'>
                                    <button onClick={() => setModalMemberadd({ view: true, type: "add" })} className="w-100 btn btn-submit text-white py-2 px-3 mt-1">
                                        <img src={iplus} /> เพิ่มสมาชิก
                                    </button>
                                </li>
                            </Nav>
                            <ul className="navbar-nav ml-auto" style={{ marginTop: "-20px" }}>
                                <li className="">
                                    <div className='text-gray font-12'>
                                        <img src={help} alt="" className="me-1" style={{ width: "14px" }} /> ขอความช่วยเหลือ
                                    </div>
                                </li>
                            </ul>
                        </nav>
                        <Menuleft type="member" />
                        <div className="content-wrapper">
                            <section className="content scroll-custom">
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <div className="bg-white rounded-3 mt-3 h-100per">
                                            <div className='text-start p-2'>
                                                {/* {age('1992-05-17')} */}
                                                <Table striped bordered className='table-custom font-14'>
                                                    <thead>
                                                        <tr>
                                                            <th className='' style={{ width: "70px" }}>ลำดับที่</th>
                                                            <th className=''><span className='border-left-table me-2'></span>ชื่อ-นามสกุล</th>
                                                            <th className=''><span className='border-left-table me-2'></span>เบอร์โทร</th>
                                                            <th className=''><span className='border-left-table me-2'></span>สถานะ</th>
                                                            <th className=''><span className='border-left-table me-2'></span>วันที่สมัคร</th>
                                                            <th className=''><span className='border-left-table me-2'></span>ระยะเวลาเป็นสมาชิก</th>
                                                            <th className=''><span className='border-left-table me-2'></span>ยอดสะสม</th>
                                                            <th className=''><span className='border-left-table me-2'></span>จำนวนครั้ง</th>
                                                            <th className='' style={{ width: "170px" }}><span className='border-left-table me-2'></span></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            datamember.map((value2, index2) => (
                                                                <tr key={index2} >
                                                                    <td>{index2 + 1}</td>
                                                                    <td><span className='border-left-table me-2'></span>{value2.name}</td>
                                                                    <td><span className='border-left-table me-2'></span>{value2.tel}</td>
                                                                    <td><span className='border-left-table me-2'></span>{formatStatus(value2.date_exp)}</td>
                                                                    <td><span className='border-left-table me-2'></span>{value2.date_active}</td>
                                                                    <td><span className='border-left-table me-2'></span>{age(value2.date_active)}</td>
                                                                    <td><span className='border-left-table me-2'></span>{parseFloat(value2.data_abc).toLocaleString()}</td>
                                                                    <td><span className='border-left-table me-2'></span>{parseFloat(value2.data_bill_total).toLocaleString()}</td>
                                                                    <td><span className='border-left-table me-2'></span>
                                                                        <span className='me-2 cursor-pointer' onClick={() => setModalMemberadd({ view: true, id: value2.id, type: "edit", datamember: datamember.filter(vls => vls.id === value2.id)[0] })}>
                                                                            <img src={memedit} />
                                                                        </span>
                                                                        <span className='button-del me-2' style={{ padding: "7px 12px" }} onClick={() => setModalMemberadd({ view: true, id: value2.id, type: "del", datamember: datamember.filter(vls => vls.id === value2.id)[0] })}>
                                                                            <img src={icondel} alt="" className="" />
                                                                        </span>
                                                                        <span className='cursor-pointer' onClick={() => setModalMemberadd({ view: true, id: value2.id, type: "detail", datamember: datamember.filter(vls => vls.id === value2.id)[0] })}>
                                                                            <img src={memde} />
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                        </div>
                    </Tab.Container>
                </div>
            </div>

            {modalmemberadd.view ?
                <Modalmemberadd show={modalmemberadd.view} onHide={handlehide} type={modalmemberadd.type} id={modalmemberadd.id} datamember={modalmemberadd.datamember} />
                : null}

        </>
    );
};

export default Member;