import React, { useState } from 'react';
import { Form, Col, Row } from "react-bootstrap";
// import axios from 'axios';
import { useNavigate,Link } from "react-router-dom";

import AuthService from "../../components/services_member/auth.service";

import back from '../../assets/img/icon-back.svg';
import loading from '../../assets/img/mail-loading.gif';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


const Forgotpassword = () => {

    let history = useNavigate();

    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({})

    const [otpView, setOtpView] = useState({ view: false, email: '' })

    const findFormErrors = () => {

        const { email } = form

        const newErrors = {};

        // const emailval = errorsemail;

        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[0-9])(?=.{6,})");
        // const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
        if (!emailRegex.test(email)) {
            newErrors.email = 'This email cannot be used.!'
        }
        return newErrors
    }

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        })
    }

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {

        event.preventDefault();

        const newErrors = findFormErrors()

        const form = event.currentTarget;

        const data = new FormData(event.target);

        if (Object.keys(newErrors).length > 0) {
            // We got errors!
            setErrors(newErrors)
        } else {
            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            } else {

                Swal.fire({
                    position: 'center',
                    // icon: 'success',
                    title: '',
                    text: "กำลังส่งอีเมล",
                    html:'<img src="'+ loading +'" style="width:30%"/><div style="margin-top:-15px;margin-bottom:30px">Loading...</div>',
                    showConfirmButton: false,
                    // timer: 1500
                })

                AuthService.checkemail(data).then(
                    (res) => {
                        console.log(res);

                        if (res.status === 200) {
                            // localStorage.removeItem("register");
                            // history("/registersuccess"); 
                            // window.location.reload(); 
                            Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: '',
                                text: "ส่งรหัส OTP เรียบร้อยแล้ว",
                                showConfirmButton: false,
                                timer: 1500
                            }).then(function () {
                                setOtpView({ view: true, email: res.email })
                                setErrors({})
                            })
                            
                        } else {      

                            Swal.fire({
                                position: 'center',
                                icon: 'error',
                                title: '',
                                text: "This email does not exist in the system.!",
                                showConfirmButton: false,
                                timer: 1500
                            }).then(function () {
                                setErrors({ email: "This email does not exist in the system.!" })
                            })                            
                        }
                    },
                    (error) => {
                        console.log(error);
                        // setErrors(true)
                    }
                );
            }
        }
        setValidated(true);
    };

    const autoTab2 = (obj) => {

        var obj_l = obj.target.value;

        const newErrors = findFormErrors()

        if (Object.keys(newErrors).length > 0) {
            // We got errors!
            setErrors(newErrors)
        } else {
            setErrors({})
        }
    };

    return (
        <>
            <div className="wrapper" style={{ backgroundColor: "#EAEAEA", zIndex: "1" }}>
                <section className="" >
                    <div className="container-fluid">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-12 col-md-7 col-xl-4 text-start h-100vh position-relative">
                                <div className='position-absolute w-100 position-center px-3'>
                                    <div className='box-shadow pb-4 bg-white'>
                                        <div className='bg-topic text-white p-3'>
                                            <h4 className='weight-400 m-0'>
                                                ลืมรหัสผ่าน
                                            </h4>
                                        </div>
                                        {
                                        otpView.view ?
                                            <div className='px-5 mt-5'>
                                                <div className="mb-3 text-center">
                                                    กรุณาตรวจสอบรหัส OPT ที่ส่งไปยังอีเมล 
                                                    <div className='mt-3'><b>{otpView.email}</b></div>
                                                </div>

                                                <div className=''>
                                                    <Link to="/newpassword" className="w-100 btn btn-bg text-white mt-5">
                                                        ตั้งรหัสผ่านใหม่
                                                    </Link>
                                                </div>
                                            </div>
                                            :
                                            <Form noValidate validated={validated} onSubmit={handleSubmit} className="px-5 mt-4">
                                                <Row className="mb-3">
                                                    <Form.Group as={Col} md="12" className="position-relative mt-3" controlId="validationCustom01">
                                                        <Form.Label className='text-gray mb-1 font-14'>อีเมล (<span className='font-12 text-gray'>กรุณากรอกอีเมลที่ลงทะเบียน</span>) </Form.Label>
                                                        <Form.Control
                                                            required
                                                            type="email"
                                                            className=""
                                                            placeholder="อีเมล"
                                                            name="email"
                                                            onKeyUp={autoTab2}
                                                            onChange={e => setField('email', e.target.value)}
                                                            isInvalid={!!errors.email}
                                                        />
                                                        <Form.Control.Feedback type='invalid' className='position-absolute text-end pe-4'>
                                                            {errors.email}
                                                        </Form.Control.Feedback>

                                                    </Form.Group>
                                                </Row>
                                                <button type="submit" className="w-100 btn btn-bg text-white mt-5">ถัดไป</button>
                                            </Form>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        </>
    );
};

export default Forgotpassword;