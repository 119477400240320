import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getAllProvince = () => {
  return axios.post(API_URL + "/province/province_all");
};

const getAllBusinessPosts = () => {
  return axios.get(API_URL + "/business/business_all", { headers: authHeader.authHeaderMember() });
};
const getAllBusinessOne = () => {
  return axios.get(API_URL + "/business/business_one", { headers: authHeader.authHeaderBusiness() })
  .then((response) => {
    return response.data;
  });
};

const getAllBranchPosts = () => {
  return axios.get(API_URL + "/branch/branch_all", { headers: authHeader.authHeaderMember() })
  .then((response) => {
    return response.data;
  });;
};

// const getAllBranchPosts = (id) => {
//   return axios.post(API_URL + "/branch/branch_all", {id});
// };

const getAllPrivatePosts = () => {
  return axios.get(API_URL + "/register/data_private", { headers: authHeader.authHeaderMember() });
};

const getAllTable = (id) => {
  return axios.post(API_URL + "/settingtable/table_all", {id});
};
const getOneTable = (id) => {
  return axios.post(API_URL + "/settingtable/table_one", {id});
};

const getPayment = (id) => {
  return axios.post(API_URL + "/settingtable/payment_one", {id});
};

const getCategory = (id) => {
  return axios.post(API_URL + "/settingtable/category_one", {id});
};

const getReceipt = (id) => {
  return axios.post(API_URL + "/settingtable/receipt_one", {id});
};
const getBranchone = (id) => {
  return axios.post(API_URL + "/settingtable/branch_one", {id});
};
const getEmployeeall = (id,dateday) => {
  return axios.post(API_URL + "/settingtable/employeeall", {id,dateday});
};
const getBannerall = (id,dateday) => {
  return axios.post(API_URL + "/settingtable/bannerall", {id,dateday});
};

const getTableall = (id,dateday) => {
  return axios.post(API_URL + "/datamain/table_all", {id,dateday});
};

const getMenuall = (id,dateday) => {
  return axios.post(API_URL + "/datamain/menu_all", {id,dateday});
};

const getReserveall = (id,dateday,idre) => {
  return axios.post(API_URL + "/datamain/reserve_all", {id,dateday,idre});
};
const getReserveallpayment = (id,dateday,idre) => {
  return axios.post(API_URL + "/datamain/reserve_all_payment", {id,dateday,idre});
};
const getReserveallmember = (id) => {
  return axios.post(API_URL + "/datamain/reserve_all_member", {id});
};
const getReserveone = (id,dateday) => {
  return axios.post(API_URL + "/datamain/reserve_one", {id,dateday});
};
const getReserveonedetail = (id,dateday,type) => {
  return axios.post(API_URL + "/datamain/reserve_one_detail", {id,dateday,type});
};

const getOrderall = (id,dateday,idre) => {
  return axios.post(API_URL + "/datamain/order_all", {id,dateday,idre});
};
const getReceiptall = (id,dateday,idre) => {
  return axios.post(API_URL + "/datamain/receipt_all", {id,dateday,idre});
};
const getReceiptone = (id,dateday,idre) => {
  return axios.post(API_URL + "/datamain/receipt_one", {id,dateday,idre});
};

const getSummaryall = (idre,dateday) => {
  return axios
    .post(API_URL + "/datamain/summary_all", {idre,dateday})
    .then((response) => {
      return response.data;
    });
};
const getSummaryallmonth = (idre,dateday) => {
  return axios
    .post(API_URL + "/datamain/summary_all_month", {idre,dateday})
    .then((response) => {
      return response.data;
    });
};
const getOrderalltotal = (id,dateday) => {
  return axios.post(API_URL + "/datamain/order_all_total", {id,dateday});
};
const getWasteall = (id,dateday) => {
  return axios.post(API_URL + "/datamain/waste_all", {id,dateday});
};
const getWarehouseall = (id,dateday) => {
  return axios.post(API_URL + "/datamain/warehouse_all", {id,dateday});
};
const getWarehouseaddall = (id,dateday,type) => {
  return axios.post(API_URL + "/datamain/warehouse_add_all", {id,dateday,type});
};
const getWarehousereport = (id,dateday,type) => {
  return axios.post(API_URL + "/datamain/warehouse_report_all", {id,dateday,type});
};
const getWarehouseExport = (id,dateday,type) => {
  return axios.post(API_URL + "/datamain/exportcsv_report", {id,dateday,type});
};

const getWarehouseExportMonth = (id,dateday) => {
  return axios.post(API_URL + "/datamain/exportcsv_report_month", {id,dateday});
};
const getWarehouseExportMonthb = (id,dateday) => {
  return axios.post(API_URL + "/datamain/exportcsv_report_month_b", {id,dateday});
};
const getVatMonth = (id,dateday,type) => {
  return axios.post(API_URL + "/datamain/exportcsv_report_vat", {id,dateday,type});
};

const getMemberdataall = (id,dateday) => {
  return axios.post(API_URL + "/datamain/memberdata_all", {id,dateday});
};

const getEmployeedataall = (data) => {
  // return axios.post(API_URL + "/datamain/employeedata_all", {data});
  return axios
    .post(API_URL + "/datamain/employeedata_all", data)
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("branchpin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};

const getWastedataall = (data) => {
  // return axios.post(API_URL + "/datamain/employeedata_all", {data});
  return axios
    .post(API_URL + "/datamain/wastedata_all", data)
    .then((response) => {
      // if (response.data.pin) {
      //   localStorage.setItem("branchpin", JSON.stringify(response.data));
      // }
      return response.data;
    });
};

const postService = {
  getBranchone,
  getAllProvince,
  getAllBusinessPosts,
  getAllPrivatePosts,
  getAllBusinessOne,
  getAllBranchPosts,
  getAllTable,
  getOneTable,
  getPayment,
  getCategory,
  getReceipt,
  getEmployeeall,
  getWarehouseExportMonth,
  getWarehouseExportMonthb,
  getVatMonth,
  getTableall,
  getMenuall,
  getReserveall,
  getReserveallpayment,
  getReserveallmember,
  getReserveone,
  getReserveonedetail,
  getOrderall,
  getOrderalltotal,
  getWasteall,
  getMemberdataall,
  getEmployeedataall,
  getWastedataall,
  getReceiptall,
  getSummaryall,
  getSummaryallmonth,
  getReceiptone,
  getWarehouseall,
  getWarehouseaddall,
  getWarehousereport,
  getWarehouseExport,
  getBannerall
};

export default postService;