import React, { useState, useCallback, useEffect } from 'react';

import AuthService from "../../components/services_member/auth.service";
import { useNavigate,Link } from "react-router-dom";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import TimePicker from 'react-bootstrap-time-picker';
import 'bootstrap-daterangepicker/daterangepicker.css';


import calendar from '../../assets/img/calendar.svg';
import icontime from '../../assets/img/icon-time.svg';

import { Form, Tab, Nav, Row, Col } from "react-bootstrap";
// import { Form, Col, Row, InputGroup, Button } from "react-bootstrap";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useTranslation } from 'react-i18next';

// import "react-datepicker/dist/react-datepicker.css";

const Tablereserveedit = (data) => {

    // console.log(data.datareserve)

    let history = useNavigate();
    const { t } = useTranslation();

    const [todayDate, setTodayDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    const [time, setTime] = useState(data.datareserve.time_reserve);

    const [datatable, setDatatable] = useState(data.datatable);
    const [dtable, setDtable] = useState(data.datatable.filter(val => val.id === data.datareserve.zone)[0]);
    // const [datareserve, setDatareserve] = useState([]);

    const MySwal = withReactContent(Swal)

    const dateoption = {
        locale: {
            format: 'DD/MM/YYYY'
        },
        minDate: todayDate,
        startDate: data.dateday,
        endDate: startDate,
        singleDatePicker: true,
        autoApply: true,
        timePicker24Hour: true
    };

    const handleTimeChange = (time) => {
        setTime(time);
    }

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const data2 = new FormData(event.target);

        const branch = JSON.parse(localStorage.getItem("branch_data"));

        data2.append("id", data.datareserve.id);
        data2.append("branch_id", branch.id);

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

        } else {

            AuthService.updateReserve(data2).then(
                (res) => {
                    //   console.log(res)   
                    if (res.status === 200) {
                        // window.location.reload();
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: '',
                            text: "แก้ไขการจองโต๊ะเรียบร้อยแล้ว",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(function () {
                            window.location.reload()
                        })

                    } else {
                        // console.log(res.message)
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        }
        setValidated(true);
    };

    const handleZone = (e) => {
        setDtable(data.datatable.filter(val => val.id === e.target.value)[0])
    }

    const autoTab = (obj,typeCheck) => {
        var pattern = new String("___-___-____"); // กำหนดรูปแบบในนี้
        var pattern_ex = new String("-"); // กำหนดสัญลักษณ์หรือเครื่องหมายที่ใช้แบ่งในนี้
        var returnText = new String("");
        var obj_l = obj.target.value.length;
        var obj_l2 = obj_l - 1;
        var i = 0;
        for (i = 0; i < pattern.length; i++) {
            if (obj_l2 == i && pattern.charAt(i + 1) == pattern_ex) {
                returnText += obj.target.value + pattern_ex;
                obj.target.value = returnText;
            }
        }
        if (obj_l >= pattern.length) {
            obj.target.value = obj.target.value.substr(0, pattern.length);
        }
    };

    return (
        <>
            <Form noValidate validated={validated} onSubmit={handleSubmit} className="">
                <div className="bg-white rounded-3 ms-2 mt-3 h-100per-right position-relative">
                    <div className='px-4 text-start'>
                        <div className='py-3 font-18'><b>แก้ไขการจองโต๊ะ</b></div>
                        <div>
                            <Row className="">
                                <Form.Group as={Col} md="6" className="position-relative pe-1" controlId="validationCustom01">
                                    <Form.Label className="font-12 text-gray mb-1">เลือกโซน</Form.Label>
                                    <Form.Select name="zone" className='font-14' defaultValue={data.datareserve.zone} onChange={(e) => handleZone(e)} aria-label="Default select example" required>
                                        <option value="">เลือกโซน</option>
                                        {data.datatable.map((value, index) => (
                                            <option value={value.id} key={index}>{value.zone}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="6" className="position-relative ps-1" controlId="validationCustom01">
                                    <Form.Label className="font-12 text-gray mb-1">เลือกโต๊ะ</Form.Label>
                                    <Form.Select name="table_reserve" className='font-14' defaultValue={data.datareserve.table_reserve} aria-label="Default select example" required>
                                        <option value="">เลือกโต๊ะ</option>
                                        {dtable ?
                                            (JSON.parse(dtable.detail)).map((val, ind) => (
                                                <option value={val.id} key={ind}>{val.table_number}</option>
                                            ))
                                            : null}
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                            <Row className="mt-3">
                                <Form.Group as={Col} md="6" className="position-relative pe-1" controlId="validationCustom01">
                                    <Form.Label className="font-12 text-gray mb-0">จำนวนที่นั่ง</Form.Label>
                                    <Form.Control type="text" defaultValue={data.datareserve.number_seats} name="number_seats" className='font-14 input-custom' required />
                                </Form.Group>
                            </Row>
                            <Row className="mt-4">
                                <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                    <Form.Label className="font-12 text-gray mb-0">ชื่อลูกค้า</Form.Label>
                                    <Form.Control type="text" defaultValue={data.datareserve.name_member} name="name_member" className='font-14 input-custom' required />
                                </Form.Group>
                            </Row>
                            <Row className="mt-4">
                                <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                    <Form.Label className="font-12 text-gray mb-0">เบอร์โทร</Form.Label>
                                    <Form.Control type="text" defaultValue={data.datareserve.tel_member} name="tel_member" className='font-14 input-custom' placeholder='xxx-xxx-xxxx' onKeyUp={autoTab.bind(this)}
                                        pattern="\d{3}[\-]\d{3}[\-]\d{4}" required />
                                </Form.Group>
                            </Row>
                            <Row className="mt-4">
                                <Form.Group as={Col} md="6" className="position-relative pe-1" controlId="validationCustom01">
                                    <Form.Label className="font-12 text-gray mb-0">วันที่</Form.Label>                                 
                                    <DateRangePicker initialSettings={dateoption} onApply={data.selectdate} className="font-14">
                                        <input type="text" name="date_reserve" className="form-control font-14" readOnly />
                                    </DateRangePicker >
                                    <div className='position-absolute' style={{ bottom: "12px", right: "12px" }}>
                                        <img src={calendar} alt="" className="me-1" style={{ width: "18px" }} />
                                    </div>
                                </Form.Group>
                                <Form.Group as={Col} md="6" className="position-relative pe-1" controlId="validationCustom01">
                                    <Form.Label className="font-12 text-gray mb-0">เวลา</Form.Label>
                                    <TimePicker onChange={handleTimeChange} name="time_reserve" initialValue={time} format={24} step={10} className="font-14" />
                                    <div className='position-absolute' style={{ bottom: "12px", right: "12px" }}>
                                        <img src={icontime} alt="" className="me-1" style={{ width: "18px" }} />
                                    </div>
                                </Form.Group>
                            </Row>
                            <Row className="mt-4">
                                <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                    <Form.Label className="font-12 text-gray mb-0">รายละเอียดเพิ่มเติม</Form.Label>
                                    <Form.Control type="text" defaultValue={data.datareserve.detail} name="detail" className='font-14 input-custom' required />
                                </Form.Group>
                            </Row>
                        </div>
                    </div>

                </div>
                <div className='w-100 ps-2 mt-2'>
                    <div className='row'>
                        <div className="col-12 col-md-6 col-lg-6">
                            <span onClick={data.swit} transition="default-fade" className="w-100 btn btn-calcel text-white py-2 me-3">
                                ยกเลิก
                            </span>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <button type='submit' className="w-100 btn btn-submit text-white py-2 me-3">
                                บันทึก
                            </button>
                        </div>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default Tablereserveedit;