import React, { useState, useCallback } from 'react';
import { Modal, Button } from "react-bootstrap";

import '../../assets/css/radioandcheckbox.css';
import { Form, Tab, Nav, Row, Col } from "react-bootstrap";

import AuthService from "../../components/services_member/auth.service";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const Modalcancelreserve = (data) => {

    const MySwal = withReactContent(Swal);

    const [validated, setValidated] = useState(false);

    const [blob, setBlob] = useState(null);

    const [errorblob, setErrorblob] = useState();
    
    const [errordetail, setErrordetail] = useState(true);

    const [detailonly, setDetailonly] = useState(true);

    const handleSubmit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const data2 = new FormData(event.target);

        data2.append("id", data.data.id);
        data2.append("status", '0');

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

            if (!blob) {           
                setErrorblob("Please select !")
            }

        } else {

            AuthService.updateReservestatus(data2).then(
                (res) => {
                    //   console.log(res)   
                    if (res.status === 200) {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: '',
                            text: "ยกเลิกจองโต๊ะเรียบร้อยแล้ว",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(function () {
                            window.location.reload()
                        })

                    } else {
                        // console.log(res.message)
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        }
        setValidated(true);
    };

    const setOnchang = (e) => {

        // alert(e.target.value)

        setErrorblob(null);

        if(e.target.value === '4'){
            setBlob(e.target.value);
            setErrordetail(true);
            setDetailonly(false)
        }else{
            setBlob(e.target.value)
            setErrordetail(false)
            setDetailonly(true)
        }        
    }

    // console.log(data.data.id)

    return (
        <>
            <Modal {...data} className="modal-custom" size="md" backdrop="static" keyboard={false}>
                <Modal.Header closeButton className="btn-close-white ">

                </Modal.Header>
                <Modal.Body className="p-0 bg-white">
                    <Form noValidate validated={validated} onSubmit={handleSubmit} className="">
                        <div className="d-block w-100 py-3 px-4">
                            <Modal.Title className='pb-3'>
                                <div className='text-default font-22'>ยกเลิกการจองโต๊ะ</div>
                                <div className='text-gray font-14 weight-400'>โซน {data.datatable.zone} :
                                {(JSON.parse(data.datatable.detail)).filter(valb => valb.id === parseInt(data.data.table_reserve)).map((val2, ind2) => (
                                <span key={ind2} className="ms-1">{val2.table_number}</span>
                            ))}
                                </div>
                            </Modal.Title>
                            <div className="ex-radio4 mb-2">
                                <input type="radio" id="radio_one" name="checkboxt" value="เปลี่ยนโต๊ะ" onChange={(e) => setOnchang(e)} required />
                                <label className="radio_one mb-2 ps-4 ms-2" style={{ width: "100%" }} > เปลี่ยนโต๊ะ</label>
                            </div>
                            <div className="ex-radio4 mb-2">
                                <input type="radio" id="radio_one" name="checkboxt" value="ลูกค้าขอยกเลิก" onChange={(e) => setOnchang(e)} required/>
                                <label className="radio_one mb-2 ps-4 ms-2" style={{ width: "100%" }} >
                                    ลูกค้าขอยกเลิก
                                </label>
                            </div>
                            <div className="ex-radio4 mb-2">
                                <input type="radio" id="radio_one" name="checkboxt" value="หลุดจอง" onChange={(e) => setOnchang(e)} required/>
                                <label className="radio_one mb-2 ps-4 ms-2" style={{ width: "100%" }} >
                                    หลุดจอง
                                </label>
                            </div>
                            <div className="ex-radio4 mb-2">
                                <input type="radio" id="radio_one" name="checkboxt" value="4" onChange={(e) => setOnchang(e)} required/>
                                <label className="radio_one mb-2 ps-4 ms-2" style={{ width: "100%" }} >
                                    อื่นๆ ระบุ
                                </label>
                                <div className='font-12 weight-300' style={{color:"red"}}>{errorblob}</div>
                            </div>
                            <Row className="mt-2 px-3 mx-1 mb-5">
                                <Form.Group as={Col} md="12" className="position-relative pe-1" controlId="validationCustom01">
                                    <Form.Control type="text" name="detail_cancle" className='font-14 input-custom' placeholder='พิมพ์ละเอียด...' readOnly={detailonly ? true : null} required={errordetail ? true : false }/>
                                </Form.Group>
                            </Row>
                            <div className='text-center mb-3'>
                                <button type='submit' className="w-50 btn btn-submit text-white py-2 me-3">
                                    ยืนยัน
                                </button>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

        </>
    );
};

export default Modalcancelreserve;